import { AttributeTypeEnum } from '@celito.clients/enums';
import { Loader } from '@celito.clients/shared';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import { mergeClasses } from '@fluentui/react-components';
import DisplayView from 'libs/form-engine/src/lib/components/view-components/display-view/display-view.component';
import { SchemaDescription } from 'yup';

import { ActionsSetter } from '../../components/setter';
import { getStyles } from '../../styles';
import { useController } from './controller';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TODO = any;

export default function DocumentTypeViewPage() {
  const { fields, values, isLoading, contextMenuActions } = useController();
  const styles = getStyles();

  if (isLoading) return <Loader fullPage className={styles.loader} />;

  return (
    <div className={mergeClasses(styles.formContainer, styles.gap30)}>
      {fields.map((field) => {
        const attribute = (field as SchemaDescription)
          .meta as ObjectAttributeDefinition;
        if (
          attribute &&
          'name' in attribute &&
          typeof attribute.name === 'string'
        ) {
          return (
            <DisplayView
              {...({
                label: attribute.label,
                dataType: attribute.dataTypeKeyForFE,
                attributeConfig: attribute,
                recordDetails: null,
                value: values[attribute.name] ?? '',
              } as TODO)}
            />
          );
        }
        return null;
      })}
      <FollowUpFields values={values} />
      <ActionsSetter actions={contextMenuActions} />
    </div>
  );
}

const FollowUpFields = ({ values }: { values: Record<string, unknown> }) => {
  const attributes = [
    {
      name: 'followUpTriggerPeriodInDays',
      label: 'Follow Up Trigger Period in Days',
      dataTypeKeyForFE: AttributeTypeEnum.Number,
      isEditable: true,
      isMandatory: true,
    },
    {
      name: 'followUpTimePeriodInMonths',
      label: 'Follow Up Time Period in Months',
      dataTypeKeyForFE: AttributeTypeEnum.Number,
      isEditable: true,
      isMandatory: true,
    },
  ];
  if (values.isFollowUpRequired)
    return (
      <>
        {attributes.map((attribute) => {
          return (
            <DisplayView
              key={attribute.name}
              {...({
                label: attribute.label,
                dataType: attribute.dataTypeKeyForFE,
                attributeConfig: attribute,
                recordDetails: null,
                value: values[attribute.name],
              } as TODO)}
            />
          );
        })}
      </>
    );
  return null;
};
