import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const wizardTypesStyles = makeStyles({
  section: {
    ...shorthands.borderRadius('7.578px'),
    ...shorthands.padding('1rem'),
    ...shorthands.gap('10px'),

    height: '58px',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    marginBottom: '1rem',
    fontFamily: 'Metropolis',
  },

  text: { fontSize: '1rem' },

  currentType: {
    backgroundColor: Color.background_dark_blue_quiz,
    color: Color.white,

    '> svg': {
      width: '20px',
    },
  },

  errorType: {
    color: Color.error_color,
    ...shorthands.border('1px', 'solid', Color.error_border),
    backgroundColor: Color.error_bg,
  },

  successType: {
    ...shorthands.border('1px', 'solid', Color.success_border),
    color: Color.success_color,
    backgroundColor: Color.success_bg,

    '> svg': {
      width: '20px',
    },
  },

  inactiveType: {
    backgroundColor: Color.background_body_bg,
  },
});
