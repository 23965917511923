export enum ZIndex {
  Hide = -1,
  Base = 0,
  Docked = 10,
  Dropdown = 1000,
  Sticky = 1100,
  Banner = 1200,
  Overlay = 1300,
  Modal = 1400,
  Popover = 1500,
  SkipLink = 1600,
  Toast = 1700,
  Tooltip = 1800,
  Max = 2147483647,
}
