import { Icon, Popover } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { createTestAttribute } from '@celito.clients/utils';
import {
  PopoverSurface,
  PopoverTrigger,
  Skeleton,
  SkeletonItem,
} from '@fluentui/react-components';
import cn from 'classnames';
import { useState } from 'react';

import { SecondaryDropdownConfigItem } from '../../types';
import classes from '../primary-dropdown/primary-dropdown.styles.module.css';
import { SecondaryDropdownProps } from './secondary-dropdown.component';
import { secondaryDropdownStyles } from './secondary-dropdown.styles';

interface SecondaryDropdownViewProps extends SecondaryDropdownProps {
  handleDropdownItemClick: (item: SecondaryDropdownConfigItem) => void;
}

const SecondaryDropdownView = ({
  activeDropdownItem,
  showSecondaryDropdown,
  toggleSecondaryDropdown,
  isMobileView,
  handleDropdownItemClick,
}: SecondaryDropdownViewProps): JSX.Element | null => {
  const styles = secondaryDropdownStyles();

  const { cssVariables } = useTheme();

  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  const onImgLoad = (src: string) => {
    setLoadedImages((prevLoadedImages) => [...prevLoadedImages, src]);
  };

  if (!activeDropdownItem?.secondaryDropdown) {
    return null;
  }

  const { name, data } = activeDropdownItem.secondaryDropdown;

  return (
    <div className={classes.dropdownBtnContainer}>
      <Popover
        onOpenChange={toggleSecondaryDropdown}
        open={showSecondaryDropdown}
        positioning={'below-end'}
        withArrow
      >
        <PopoverTrigger disableButtonEnhancement>
          <button
            className={styles.customMenuDropdownButton}
            data-testid="button-request"
            id="secondary-dropdown-trigger"
          >
            {name}
            {
              <Icon
                iconName="ChevronDown16Regular"
                className={classes.dropdownBtnIcon}
                data-open={showSecondaryDropdown}
              />
            }
          </button>
        </PopoverTrigger>

        <PopoverSurface className={styles.popoverSurface}>
          <div
            className={cn(
              classes.dropdownContainer,
              classes.secondaryDropdownContainer
            )}
            style={{ ...cssVariables }}
          >
            {data?.map((item) => {
              const hasLoaded = loadedImages.some(
                (img) => img === item.iconSrc
              );

              if (item.CustomComponent) {
                return (
                  <div key={item.name} className={classes.dropdownItem}>
                    <item.CustomComponent />
                  </div>
                );
              }
              return (
                <button
                  key={item.name}
                  data-testid={`request-dropdown-option-${createTestAttribute(
                    item.name
                  )}`}
                  onClick={() => handleDropdownItemClick(item)}
                  className={cn(classes.dropdownItem, classes.dropdownItem2)}
                >
                  {item.CustomIcon && (
                    <div className={classes.dropdownItemIcon}>
                      <item.CustomIcon />
                    </div>
                  )}
                  {!hasLoaded && item.iconSrc && (
                    <Skeleton>
                      <SkeletonItem
                        shape="circle"
                        size={28}
                        className={classes.dropdownItemImage}
                      />
                    </Skeleton>
                  )}

                  {item.iconSrc && (
                    <img
                      onLoad={() => onImgLoad(item.iconSrc!)}
                      src={item.iconSrc}
                      alt={item.name}
                      className={classes.dropdownItemImage}
                    />
                  )}

                  <div className={classes.dropdownItemInnerContainer}>
                    <h3 className={classes.dropdownItemHeading}>{item.name}</h3>
                    <p>{item.descripton}</p>
                  </div>
                </button>
              );
            })}
          </div>
        </PopoverSurface>
      </Popover>

      {!isMobileView && (
        <div
          className={cn(
            classes.verticleDividerLine,
            classes.secondaryVerticleDividerLine
          )}
        />
      )}
    </div>
  );
};

export default SecondaryDropdownView;
