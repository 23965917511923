export const mimeTypesForDocumentViewer = [
  'application/pdf',
  'text/plain',
  'image/jpeg',
  'image/png',
  'video/mp4',
  'video/webm',
];

export const allowedFileTypes = [
  'application/pdf',
  'text/plain',
  'application/rtf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
];
