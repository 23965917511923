import { LocalizationString } from '@celito.clients/assets';
import { useTheme } from '@celito.clients/theme';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import { createTestAttribute } from '@celito.clients/utils';
import { Dialog } from '@fluentui/react-components';
import cn from 'classnames';
import {
  IOption,
  IRule,
} from 'libs/shared/src/lib/rules-component/rules-component.model';
import { RulesComponent } from 'libs/shared/src/lib/shared';
import { UseFormReturn } from 'react-hook-form';

import { FilterPanelProps, IFilterPanelFormData } from './filter-panel.model';
import { FilterPanelStyles } from './filter-panel.styles';
import classes from './filter-panel.styles.module.css';

interface FilterPanelViewProps extends FilterPanelProps {
  onAddRule: () => void;
  onRemoveRule: (index: number) => void;
  methods: UseFormReturn<IFilterPanelFormData>;
  rules: IRule[];
  conditionFieldsData: IOption[];
  onApplyFilter: (data: IFilterPanelFormData) => void;
  onResetFilters: () => void;
  attributeDefinitionOfColumns?: ObjectAttributeDefinition[];
}

const FilterPanelView = ({
  isFilterPanelOpen,
  methods,
  onAddRule,
  onRemoveRule,
  rules,
  conditionFieldsData,
  onApplyFilter,
  onResetFilters,
  hidePanelHeader = false,
  submitButtonTitle = 'Apply Filter',
  filterableColumns,
  attributeDefinitionOfColumns,
  objectName,
}: FilterPanelViewProps): JSX.Element => {
  const { cssVariables } = useTheme();

  const styles = FilterPanelStyles();

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  return isFilterPanelOpen ? (
    <div
      data-testid="filter-panel"
      className={cn({
        [classes.panelContainer]: !hidePanelHeader,
        [classes.noPanelContainer]: hidePanelHeader,
      })}
      style={{ ...cssVariables }}
    >
      {!hidePanelHeader && (
        <div className={classes.panelHeader}>
          <h4
            data-testid={`heading-${createTestAttribute(
              LocalizationString.FILTER
            )}`}
          >
            {LocalizationString.FILTER}
          </h4>
          <button
            disabled={!isDirty}
            data-testid={`button-${createTestAttribute(
              LocalizationString.RESET_ALL
            )}`}
            onClick={onResetFilters}
            className={styles.resetButton}
          >
            {LocalizationString.RESET_ALL}
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit(onApplyFilter)} noValidate>
        <RulesComponent
          methods={methods}
          fields={conditionFieldsData}
          onAddRule={onAddRule}
          onRemoveRule={onRemoveRule}
          ruleLayout={'singleRule'}
          rules={rules}
          submitButtonTitle={submitButtonTitle}
          isCompact
          filterableColumns={filterableColumns}
          attributeDefinitionOfColumns={attributeDefinitionOfColumns}
          objectName={objectName}
        />
      </form>
    </div>
  ) : (
    <></>
  );
};

export default FilterPanelView;
