import { LocalizationString } from '@celito.clients/assets';
import { QuizObjectAttributeNameEnum } from '@celito.clients/enums';
import { successToast } from '@celito.clients/utils';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { QuizEnumTypes } from '../../../../enums/quiz-enum';
import { questionsPageStepNumber } from '../../config';
import { IQuestion } from '../../types';
import { QuestionsColumnActionsProps } from './questions-column-actions.model';
import { QuestionsColumnActionsView } from './questions-column-actions.view';

interface QuestionsColumnActionsControllerProps
  extends QuestionsColumnActionsProps {}

export const QuestionsColumnActionsController = (
  props: QuestionsColumnActionsControllerProps
): JSX.Element => {
  const { getValues, setValue } = useFormContext();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const onEditClick = (rowData: RowItem) => {
    const formValues = getValues?.();
    const questions = JSON.parse(
      formValues[QuizObjectAttributeNameEnum.QUESTIONS]
    );
    const currQuestion = questions.find(
      (quest: IQuestion) => quest.order === rowData.order
    );

    setValue('currentQuestion', JSON.stringify(currQuestion));
    setValue('isEditMode', 'true');
    props.quizProps?.onStepClick(questionsPageStepNumber);
  };

  const deleteQuiz = (rowData: RowItem) => {
    const formValues = getValues?.();
    const questions = JSON.parse(
      formValues[QuizObjectAttributeNameEnum.QUESTIONS]
    );
    const currQuestion: IQuestion = JSON.parse(formValues.currentQuestion);

    let allQuest = [];
    if (questions.length > 0) {
      allQuest = questions
        .filter(
          (question: IQuestion) => question.order !== Number(rowData?.key)
        )
        .map((question: IQuestion, index: number) => {
          return {
            ...question,
            order: index + 1,
          };
        });

      Number(rowData?.key) === currQuestion.order &&
        setValue('currentQuestion', JSON.stringify(allQuest[0]));
    } else {
      allQuest = questions;
    }
    setValue(QuizEnumTypes.QUESTIONS, JSON.stringify(allQuest));
  };

  const isDelete = useMemo(() => {
    return !(props.totalQuestions > 1);
  }, [props.totalQuestions]);

  const closeDeleteAlert = (deleteRec = false) => {
    if (deleteRec) {
      deleteQuiz(props.item);
      successToast({
        message: LocalizationString.DELETED_QUESTION,
      });
    }
    setShowDeleteAlert(false);
  };

  const openDeleteAlert = () => {
    setShowDeleteAlert(true);
  };

  return (
    <QuestionsColumnActionsView
      {...props}
      closeDeleteAlert={closeDeleteAlert}
      showDeleteAlert={showDeleteAlert}
      openDeleteAlert={openDeleteAlert}
      isDelete={isDelete}
      onEditClick={onEditClick}
    />
  );
};
