import { performRequest } from '@celito.clients/network';

import Endpoints from '../config/endpoint';
import { ApiMethodType } from '../enums/api-constants';
import { RoleType } from '../types/permission-types';

export const getRolesInfo = async (params?: {
  limit: number | undefined;
  page: number | undefined;
}) => {
  const response = await performRequest<{ data: RoleType[] }>(
    `${Endpoints.OBJECT_DATA_URL}/role__s`,
    ApiMethodType.POST,
    { sort: { attribute: 'modified_at_utc__s', order: 'DESC' } },
    params
  );
  return response?.data;
};
