import { AttributeTypeEnum, ObjectEnum } from '@celito.clients/enums';
import {
  ObjectAttributeDefinitionBase,
  TrainingAssignmentData,
} from '@celito.clients/types';
import { userObjectName } from 'libs/form-engine/src/lib/config';
import { ColumnData } from 'libs/shared/src/lib/grid-view-new/src/types';

import { ActionColumn } from '../components/action-column/action-column';
import { CurriculaCell } from '../components/curricula-cell/curricula-cell';
import { STATUS_PICKLIST_NAME } from '../consts/constants';
import { ViewTypes } from '../enums/enums';
import { IColumns } from '../training-matrix.model';

export const getAssignmentViewColumns = (
  onColumnClick: IColumns['onColumnClick']
): IColumns[] => {
  const columnConfig: IColumns[] = [
    {
      fieldName: 'label',
      isResizable: true,
      key: 'label',
      maxWidth: 250,
      minWidth: 150,
      name: 'Training ID',
      isFilterable: true,
      dataType: AttributeTypeEnum.PlainText,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      showTooltip: true,
      data: {
        name: 'label',
      },
    },
    {
      fieldName: 'course',
      isResizable: true,
      key: 'course',
      maxWidth: 250,
      minWidth: 150,
      name: 'Course ID',
      isFilterable: true,
      dataType: AttributeTypeEnum.Reference,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      showTooltip: true,
      relationship: {
        objectName: ObjectEnum.COURSE,
      } as ObjectAttributeDefinitionBase['relationship'],
      data: {
        name: 'course',
      },
    },
    {
      fieldName: 'title',
      isResizable: true,
      key: 'courseTitle',
      maxWidth: 250,
      minWidth: 150,
      name: 'Course Name',
      dataType: AttributeTypeEnum.Reference,
      isFilterable: true,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      showTooltip: true,
      data: {
        name: 'course',
        columnNameToBePicked: 'title',
        dataType: AttributeTypeEnum.Reference,
      },
    },
    {
      fieldName: 'version',
      isResizable: true,
      key: 'courseVersion',
      maxWidth: 250,
      minWidth: 150,
      name: 'Course Version',
      dataType: AttributeTypeEnum.Reference,
      isFilterable: true,
      showSortIconWhenUnsorted: true,
      showTooltip: true,
      onColumnClick,
      data: {
        name: 'course',
        columnNameToBePicked: 'version',
        dataType: AttributeTypeEnum.Reference,
      },
    },
    {
      fieldName: 'user__a',
      isResizable: true,
      key: 'user',
      maxWidth: 250,
      minWidth: 150,
      name: 'Assigned To',
      isFilterable: true,
      dataType: AttributeTypeEnum.Reference,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      showTooltip: true,
      relationship: {
        objectName: userObjectName,
      } as ObjectAttributeDefinitionBase['relationship'],
      data: {
        name: 'user',
        columnNameToBePicked: 'label',
      },
    },
    {
      fieldName: 'directCurricula',
      isResizable: true,
      key: 'curriculum',
      minWidth: 150,
      maxWidth: 300,
      name: 'Direct Curricula',
      isFilterable: true,
      showTooltip: true,
      onRender: directCurriculaColumnRenderer,
      dataType: AttributeTypeEnum.Reference,
      relationship: {
        objectName: ObjectEnum.CURRICULUM,
      } as ObjectAttributeDefinitionBase['relationship'],
      data: {
        name: 'directCurricula',
      },
    },
    {
      fieldName: 'oneTimeCurricula',
      isResizable: true,
      key: 'oneTimeCurricula',
      minWidth: 150,
      maxWidth: 300,
      name: 'One Time Curricula',
      isFilterable: true,
      showTooltip: true,
      onRender: oneTimeCurriculaColumnRenderer,
      dataType: AttributeTypeEnum.Reference,
      relationship: {
        objectName: ObjectEnum.CURRICULUM,
      } as ObjectAttributeDefinitionBase['relationship'],
      data: {
        name: 'oneTimeCurricula',
      },
    },
    {
      fieldName: 'status',
      isResizable: true,
      key: 'status',
      maxWidth: 250,
      minWidth: 150,
      name: 'Status',
      isFilterable: true,
      dataType: AttributeTypeEnum.Picklist,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      showTooltip: true,
      picklistName: STATUS_PICKLIST_NAME,
      data: {
        name: 'status',
      },
    },
    {
      fieldName: 'dueDate',
      isResizable: true,
      key: 'dueDate',
      maxWidth: 250,
      minWidth: 150,
      name: 'Assignment Due Date',
      isFilterable: true,
      dataType: AttributeTypeEnum.Date,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      showTooltip: true,
      data: {
        name: 'dueDate',
      },
    },
    {
      fieldName: 'actions',
      isResizable: true,
      key: 'actions',
      maxWidth: 250,
      minWidth: 150,
      name: 'Actions',
      isFilterable: false,
      onRender: (item) => (
        <ActionColumn item={item} view={ViewTypes.ASSIGNMENT_VIEW} />
      ),
    },
  ];

  return columnConfig;
};

const directCurriculaColumnRenderer: ColumnData['onRender'] = (
  rowData: TrainingAssignmentData
) => {
  return (
    <CurriculaCell
      items={rowData.directCurricula ?? []}
      customStartValue={rowData.isDirectCourseAssignment ? 'N/A' : undefined}
    />
  );
};

const oneTimeCurriculaColumnRenderer: ColumnData['onRender'] = (
  rowData: TrainingAssignmentData
) => {
  return (
    <CurriculaCell
      items={rowData.oneTimeCurricula ?? []}
      customStartValue={rowData.isOneTimeCourseAssignment ? 'N/A' : undefined}
    />
  );
};
