import { ButtonTypes } from '@celito.clients/enums';
import { UserContext } from '@celito.clients/provider';
import { Container, CustomButton, Stack } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { Text } from '@fluentui/react-components';
import { Loader } from 'libs/shared/src/lib/loader/src';
import React, { useContext } from 'react';

import { userTransformType, userViewType } from './view-user.model';
import classes from './view-user.module.css';

const GridLayout: React.FC<{
  formDataList: Array<userTransformType>;
}> = ({ formDataList }) => {
  return (
    <div className={classes.cards}>
      {formDataList.map((item: userTransformType) => {
        return (
          <Stack className={classes.card}>
            <Text className={classes.label}>{item.label}</Text>
            <Text className={classes.value}>{item?.value || ''}</Text>
          </Stack>
        );
      })}
    </div>
  );
};

const ViewUser: React.FC<userViewType> = ({
  userData,
  transformFormData,
  isUserDataLoading,
  onEditBtnClick,
  navigateBack,
}) => {
  const { cssVariables } = useTheme();
  const { isAdmin } = useContext(UserContext);

  return (
    <Container
      dataTestId="container-view-user"
      title="User details"
      showBackBtn
      name={userData?.label}
      style={{ ...cssVariables }}
      className={classes.container}
    >
      {!isUserDataLoading ? (
        <>
          <Stack>
            <GridLayout
              formDataList={userData ? transformFormData(userData) : []}
            />
          </Stack>
          <Stack className={classes.footer}>
            <CustomButton
              dataTestId="button-edit-user-view"
              customStyles={classes.btn_container}
              buttonType={ButtonTypes.Ghost}
              buttonTitle="Cancel"
              onButtonClicked={navigateBack}
            />
            {isAdmin && (
              <CustomButton
                customStyles={classes.btn_container}
                leftIcon="Edit"
                buttonType={ButtonTypes.Primary}
                buttonTitle={'Edit'}
                onButtonClicked={onEditBtnClick}
              />
            )}
          </Stack>
        </>
      ) : (
        <Stack className={classes.loader}>
          <Loader />
        </Stack>
      )}
    </Container>
  );
};

export default ViewUser;
