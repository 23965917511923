import { DateRangePickerProps } from './date-range-picker.model';
import DateRangePickerView from './date-range-picker.view';

interface DateRangePickerControllerProps extends DateRangePickerProps {}

const DateRangePickerController = (
  props: DateRangePickerControllerProps
): JSX.Element => {
  const handleRangeValChange = (value: DateRangePickerProps['value']) => {
    props.onChange?.(value);
  };

  return (
    <DateRangePickerView
      handleRangeValChange={handleRangeValChange}
      {...props}
    />
  );
};

export default DateRangePickerController;
