import { LocalizationString } from '@celito.clients/assets';
import {
  AssignmentStatusEnum,
  ButtonTypes,
  CompletionTypeEnum,
} from '@celito.clients/enums';
import {
  ConditionalWrapper,
  CustomButton,
  ExportModal,
  HeaderActionButtonsContainer,
} from '@celito.clients/shared';
import { PropsWithChildren } from 'react';

import { CompleteTrainingButtonProps } from './complete-training-button.model';
import { SignOffPanel } from './components';

interface CompleteTrainingButtonViewProps extends CompleteTrainingButtonProps {
  handleComplete: () => void;
  isLoading: boolean;
  completionType: CompletionTypeEnum;
  showSignOffPanel: {
    isOpen: boolean;
    isAdmin: boolean;
  };
  closeSignOffPanel: () => void;
  openEsignModal: (taskPayload?: Record<string, unknown>) => void;
  showExportModal: boolean;
  setShowExportModal: (value: boolean) => void;
  viewCertificate: () => void;
}

const Wrapper = ({ children }: PropsWithChildren) => {
  return (
    <HeaderActionButtonsContainer>{children}</HeaderActionButtonsContainer>
  );
};

export const CompleteTrainingButtonView = ({
  dataTestId,
  showInHeaderActions = true,
  handleComplete,
  completionType,
  isLoading,
  showSignOffPanel,
  closeSignOffPanel,
  openEsignModal,
  trainingAssignmentData,
  showExportModal,
  setShowExportModal,
  viewCertificate,
}: CompleteTrainingButtonViewProps): JSX.Element => {
  const isCompleted =
    trainingAssignmentData.status === AssignmentStatusEnum.Completed;

  return (
    <ConditionalWrapper condition={showInHeaderActions} Wrapper={Wrapper}>
      {!showSignOffPanel.isOpen && (
        <CustomButton
          onClick={isCompleted ? viewCertificate : handleComplete}
          buttonType={
            showInHeaderActions ? ButtonTypes.Transparent : ButtonTypes.Primary
          }
          isLoading={!completionType || isLoading}
          buttonTitle={
            isCompleted
              ? LocalizationString.VIEW_CERTIFICATE
              : LocalizationString.COMPLETE
          }
          dataTestId={dataTestId}
          iconPosition="before"
        />
      )}
      {!isLoading && showSignOffPanel.isOpen && (
        <SignOffPanel
          isOpen={showSignOffPanel.isOpen}
          closePanel={closeSignOffPanel}
          openEsignModal={openEsignModal}
          completionType={completionType}
          trainingAssignmentData={trainingAssignmentData}
          isAdmin={showSignOffPanel.isAdmin}
        />
      )}
      {/* Export modal */}
      {showExportModal && (
        <ExportModal onClose={() => setShowExportModal(false)} />
      )}
    </ConditionalWrapper>
  );
};
