import { isValidEnvironment } from '@celito.clients/types';

if (!isValidEnvironment(import.meta.env.VITE_ENV_NAME)) {
  throw new Error(
    'Invalid NX_ENV_NAME, please check .env file for environment.'
  );
}

export const environment = {
  NX_ENV_NAME: import.meta.env.VITE_ENV_NAME,
  NX_API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  NX_REDIRECT_BASE_URL: import.meta.env.VITE_REDIRECT_BASE_URL,
  NX_NOT_INVITED_URL: import.meta.env.VITE_NOT_INVITED_URL,
  NX_NOT_ACTIVE_USER_URL: import.meta.env.VITE_NOT_ACTIVE_USER_URL,
  NX_CLIENT_ID: import.meta.env.VITE_CLIENT_ID,
  NX_CLIENT_SECRETS: import.meta.env.VITE_CLIENT_SECRETS,
  NX_AUTHORITY: import.meta.env.VITE_AUTHORITY,
  NX_MSAL_SCOPES: import.meta.env.VITE_MSAL_SCOPES,
  NX_GRAPH_API_SCOPE: import.meta.env.VITE_GRAPH_API_SCOPE,
  NX_PRESENCE_SCOPE: import.meta.env.VITE_PRESENCE_SCOPE,
  NX_DATE_FORMAT: import.meta.env.VITE_DATE_FORMAT,
  NX_DATE_TIME_FORMAT: import.meta.env.VITE_DATE_TIME_FORMAT,
  SUPERSET_BASE_URI: import.meta.env.SUPERSET_BASE_URI,
  NX_SYSTEM_TIMEZONE: import.meta.env.VITE_SYSTEM_TIMEZONE,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  SENTRY_RELEASE_NAME: import.meta.env.VITE_SENTRY_RELEASE_NAME,
  SENTRY_DIST: import.meta.env.VITE_SENTRY_DIST,
  MAX_LOG_LEVEL: import.meta.env.VITE_MAX_LOG_LEVEL,
  APPINSIGHTS_INSTRUMENTATIONKEY: import.meta.env
    .VITE_APPINSIGHTS_INSTRUMENTATIONKEY,
  APPINSIGHTS_CONNECTION_STRING: import.meta.env
    .VITE_APPINSIGHTS_CONNECTION_STRING,
  NX_WOPI_LOCK_REQUEST_INTERVAL_IN_MILLISECS: import.meta.env
    .VITE_WOPI_LOCK_REQUEST_INTERVAL_IN_MILLISECS,
};
