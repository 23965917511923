import { HeaderViewProps } from '../../header.view';
import SecondaryDropdownController from './secondary-dropdown.controller';

export type SecondaryDropdownProps = Pick<
  HeaderViewProps,
  | 'activeDropdownItem'
  | 'showSecondaryDropdown'
  | 'toggleSecondaryDropdown'
  | 'isMobileView'
  | 'isTabletView'
>;

const SecondaryDropdown = (
  props: SecondaryDropdownProps
): JSX.Element | null => {
  return <SecondaryDropdownController {...props} />;
};

export default SecondaryDropdown;
