export enum Constants {
  TOKEN = 'token',
  USER = 'user',
  AUTHORIZATION_TOKEN = 'authorizationToken',
  VERIFY_TOKEN = 'verifyToken',
  USER_ID = 'userId',
  IS_PUBLIC = 'isPublic',
  ACCESS_TOKEN = 'accessToken',
  OPEN_DIALOG = 'openDialog',
  CLOSE_DIALOG = 'closeDialog',
  FORM_STEP = 'formStep',
}
