import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import {
  Container,
  CustomButton,
  Loader,
  Stack,
  StackItem,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { Text } from '@fluentui/react-components';
import React from 'react';

import classes from './view-document-data.module.css';
import { documentTemplateStyles } from './view-document-data.styles';

const Row: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return <Stack className={classes.row}>{children}</Stack>;
};

const Column: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return <StackItem className={classes.column}>{children}</StackItem>;
};

export interface DocumentDataViewProps {
  documentData?: Record<string, number | string>;
  onEditClick: () => void;
  navigateBack: () => void;
  isLoading: boolean;
}

const DisplayItem: React.FC<{ label: string; value?: number | string }> = ({
  label,
  value,
}) => {
  return (
    <Stack>
      <Text className={classes.label}>{label}</Text>
      <Text className={classes.value}>{value || ''}</Text>
    </Stack>
  );
};

const AdminSettingsViewDocumentDataView = (props: DocumentDataViewProps) => {
  const { cssVariables } = useTheme();
  const styles = documentTemplateStyles();
  const { documentData, onEditClick, isLoading } = props;

  if (isLoading) {
    return <Loader fullPage />;
  }

  return (
    <Container
      title="Document Data"
      style={{
        ...cssVariables,
      }}
      className={styles.container}
    >
      <div>
        <Row>
          <Column>
            <DisplayItem
              label="Expiration Days"
              value={documentData?.expirationInDays}
            />
          </Column>
          <Column>
            <DisplayItem
              label="Document Watermark"
              value={
                documentData?.documentWaterMark
                  ? LocalizationString.YES
                  : LocalizationString.NO
              }
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <DisplayItem
              label="Effective Header Message"
              value={documentData?.effectiveHeaderMessage}
            />
          </Column>
          <Column>
            <DisplayItem
              label="Effective Footer Message"
              value={documentData?.effectiveFooterMessage}
            />
          </Column>
        </Row>
      </div>

      <Stack className={classes.footer}>
        <CustomButton
          data-testid="button-edit"
          customStyles={classes.btn_container}
          leftIcon="Edit"
          buttonTitle="Edit"
          buttonType={ButtonTypes.Primary}
          onClick={onEditClick}
        />
      </Stack>
    </Container>
  );
};

export default AdminSettingsViewDocumentDataView;
