import { mergeClasses } from '@fluentui/react-components';

import { ContainerComponentProps } from './container.model';
import classes from './container.module.css';

const ContainerView: React.FC<ContainerComponentProps> = ({
  children,
  style,
  showBackBtn = false,
  dataTestId,
  className,
  title,
  ...rest
}) => {
  return (
    <div
      data-testid={dataTestId}
      {...rest}
      className={mergeClasses(classes.container, className)}
    >
      {children}
    </div>
  );
};

export default ContainerView;
