import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledRichTextController } from './controlled-richtext.controller';
import { ControlledRichTextProps } from './controlled-richtext.model';

const ControlledRichText = <T extends FieldValues>(
  props: ControlledRichTextProps<T>
) => {
  return <ControlledRichTextController {...props} />;
};

export default ControlledRichText;
