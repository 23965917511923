import { Radio } from '@fluentui/react-components';
import { ColumnDef } from '@tanstack/react-table';

import { RowItem } from '../../../types';
import columnHelper from '../column-helper';
import SelectCheckbox from './components/select-checkbox';

const selectColumn = (
  enableMultiRowSelection?: boolean
): ColumnDef<RowItem, unknown> => {
  return columnHelper.display({
    id: 'select',
    header: enableMultiRowSelection
      ? ({ table }) => (
          <SelectCheckbox
            checked={
              table.getIsSomeRowsSelected()
                ? 'mixed'
                : table.getIsAllRowsSelected()
            }
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        )
      : undefined,
    cell: ({ row }) => {
      const isSelected = row.getIsSelected();

      return enableMultiRowSelection ? (
        <SelectCheckbox
          checked={isSelected}
          disabled={!row.getCanSelect()}
          onChange={() => row.toggleSelected()}
        />
      ) : (
        <Radio
          checked={isSelected}
          disabled={!row.getCanSelect()}
          onChange={() => row.toggleSelected()}
        />
      );
    },

    size: 56,
    meta: {
      columnType: 'select',
    },
  });
};

export default selectColumn;
