import React from 'react';

import { TextFieldAreaModel } from './text-field-area.model';
import { TextFieldAreaView } from './text-field-area.view';

// The controller component for the TextField, responsible for handling props and rendering the TextFieldView
export const TextFieldAreaController: React.FC<TextFieldAreaModel> = ({
  ...props
}) => {
  const { required, errorMessage, disabled } = props;

  return (
    <TextFieldAreaView
      errorMessage={errorMessage}
      required={required}
      disabled={disabled}
      {...props}
    />
  );
};
