import { ObjectEnum } from '@celito.clients/enums';
import { useObjectDefinition } from '@celito.clients/hooks';
import { useMemo } from 'react';

import { useScreenContext } from '../../context';
import { useRecordData } from '../../hooks/use-record-data';
import { getActiveLifeCycleStage, getLifeCycleStageGroups } from '../../utils';
import { TLifecycleProps } from './component';

export const useController = () => {
  const { recordName, version, task } = useScreenContext();

  const { data: record } = useRecordData();

  const { data: objectDefinition } = useObjectDefinition({
    objectName: ObjectEnum.CONTROLLED_DOCUMENT,
    version,
    recordName,
    queryOptions: {
      enabled: false,
    },
  });

  const wizardData = useMemo(() => {
    if (!objectDefinition || !record)
      return {
        activeWizardState: '',
        wizardStates: [],
      };
    const lifecycleStage = getLifeCycleStageGroups(
      objectDefinition,
      record?.lifecycleStage || ''
    );
    const wizardState = getActiveLifeCycleStage(objectDefinition, record);
    return {
      activeWizardState: wizardState ?? '',
      wizardStates: lifecycleStage,
    };
  }, [record, objectDefinition]);

  return {
    wizardData,
    response: record,
    recordName,
    objectActionDefinition: objectDefinition?.objectActionDefinitions || [],
    objectDefinitions: objectDefinition!,
    task,
  } satisfies TLifecycleProps;
};
