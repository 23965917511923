import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const FilterPanelStyles = makeStyles({
  resetButton: {
    backgroundColor: 'transparent',
    ...shorthands.borderWidth('0px'),
    fontFamily: 'var(--regular)',
    color: 'var(--blue-700)',
    fontWeight: 500,
    fontSize: '1rem',
    cursor: 'pointer',

    ':hover': {
      ...shorthands.textDecoration('underline'),
    },

    ':disabled': {
      cursor: 'not-allowed',
      color: Color.gray_500,

      ':hover': {
        ...shorthands.textDecoration('none'),
      },
    },
  },
});
