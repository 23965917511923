import { FieldArrayMethodProps, UseFieldArrayRemove } from 'react-hook-form';

import { IRule } from '../rules-component.model';
import { defineEmptyRule, getDefaultRules } from '../utils/utils';

export const getOnAddRuleFunc =
  (
    appendFunc: <T extends IRule>(
      value: T | T[],
      options?: FieldArrayMethodProps | undefined
    ) => void
  ) =>
  () => {
    appendFunc(defineEmptyRule()[0]);
  };

export const getOnAddMultipleRulesFunc =
  (
    appendFunc: <T extends IRule>(
      value: T | T[],
      options?: FieldArrayMethodProps | undefined
    ) => void
  ) =>
  () => {
    appendFunc(getDefaultRules()[0]);
  };

export const getOnRemoveRuleFunc =
  (removeFunc: UseFieldArrayRemove) => (index: number) => {
    removeFunc(index);
  };
