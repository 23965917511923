import { FieldEnum } from './field-enum';

export type TField = FieldEnum & {
  fullWidth?: boolean;
  alwaysVisible?: boolean;
  disabled?: boolean;
};

export type TSection = {
  name: string;
  fields: TField[];
};

export const sections: TSection[] = [
  {
    name: 'General',
    fields: [
      FieldEnum.INITIATION_TYPE,
      Object.assign(FieldEnum.LABEL, {
        alwaysVisible: true,
      }),
      FieldEnum.DOCUMENT_STATUS,
      FieldEnum.VERSION,
      FieldEnum.IS_GXP,
      FieldEnum.GXP_TYPES,
      FieldEnum.TITLE,
      FieldEnum.DEPARTMENT,
      FieldEnum.DOCUMENT_TYPE,
      FieldEnum.DOCUMENT_SUB_TYPE,
      FieldEnum.IS_TRAINABLE,
    ],
  },
  {
    name: 'User Roles & Due Dates',
    fields: [
      FieldEnum.OWNER,
      FieldEnum.REQUESTOR,
      FieldEnum.COLLABORATORS,
      FieldEnum.COLLABORATION_DUE_DATE,
      FieldEnum.REQUIRED_APPROVERS,
      FieldEnum.QUALITY_APPROVERS,
      FieldEnum.IS_BIZ_ADMIN_APPROVAL_REQUIRED,
      FieldEnum.APPROVAL_DUE_DATE,
    ],
  },
  {
    name: 'Document',
    fields: [
      FieldEnum.IS_REVISION_OF_PRE_APPROVED_DOCUMENT,
      Object.assign(FieldEnum.IS_ORIGINAL, { fullWidth: true }),
      Object.assign(FieldEnum.SHOULD_REPLACE_HEADER_FOOTER, {
        fullWidth: true,
      }),
      FieldEnum.SUMMARY_FOR_CHANGES,
      FieldEnum.REASON_FOR_CHANGES,
      Object.assign(FieldEnum.REFERENCE_DOCUMENTS, { fullWidth: true }),
      Object.assign(FieldEnum.ATTACHMENTS, { fullWidth: true }),
      FieldEnum.OBSOLETE_JUSTIFICATION,
    ],
  },
  {
    name: 'Follow Up',
    fields: [
      FieldEnum.FOLLOW_UP_REQUIRED,
      FieldEnum.FOLLOW_UP_APPROVAL_DATE,
      FieldEnum.FOLLOW_UP_COMMENT,
      FieldEnum.FOLLOW_UP_ACTION,
      FieldEnum.FOLLOW_UP_DUE_DATE,
    ],
  },
  {
    name: 'Key Dates',
    fields: [
      FieldEnum.APPROVED_DATE,
      FieldEnum.PROPOSED_EFFECTIVE_DATE,
      FieldEnum.EFFECTIVE_DATE,
      FieldEnum.PROPOSED_OBSOLETION_DATE,
      FieldEnum.OBSOLETION_DATE,
      FieldEnum.SUPERSEDED_DATE,
    ],
  },
];
