import { Datum } from '@celito.clients/list-view-engine';

import { NavigationConfigItem } from '../../../header/src';
import { ReferenceSelectorItem } from '../reference-selector.model';
import { getObjects } from '../services/getSelectorData';
import { getReferenceDocumentsFilter } from './filters-by-object';
import { transformToGridRow } from './transform-data';

export const getReferenceDocuments = async (
  objectName: string,
  items: Record<string, any>[],
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  activeModule: NavigationConfigItem | undefined
) => {
  setIsLoading(true);
  try {
    const refernceDocs = await getObjects(
      objectName,
      activeModule ? [activeModule?.systemName] : [],
      { getLatestVersionOnly: false, skipRLS: true },
      getReferenceDocumentsFilter(items),
      undefined,
      ''
    );
    const objects = refernceDocs.objects as Datum[];
    const selectorItems: ReferenceSelectorItem[] =
      objects.map(transformToGridRow);
    setIsLoading(false);
    return selectorItems;
  } catch (_error) {
    // No need to raise error
  }
};
