import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { ObjectAttributeType } from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';

export const getAllObjectsData = async (modulesToRestrict?: string[]) => {
  let url = `${Endpoints.OBJECT_METADATA_URL}?includeNonDomainEntity=true`;

  if (modulesToRestrict) {
    modulesToRestrict.forEach((module) => {
      url += `&module=${module}`;
    });
  }

  const response = await performRequest<ObjectAttributeType[]>(
    url,
    ApiMethodType.GET
  );
  return response.data;
};
