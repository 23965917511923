import { createSuggestionMenuStyle } from '../suggestion-menu.modal.style';
import { SearchBannerProps } from '../suggestion-menu.model';

const SearchBanner: React.FC<SearchBannerProps> = (props) => {
  const styles = createSuggestionMenuStyle();

  return (
    <div className={styles.searchBanner}>
      Search Keyword <b>"{props.value}"</b>
    </div>
  );
};

export default SearchBanner;
