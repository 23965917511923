import {
  BrandVariants,
  createLightTheme,
  Theme,
} from '@fluentui/react-components';

const brands: BrandVariants = {
  10: '#030207',
  20: '#15132F',
  30: '#1D1E57',
  40: '#20267A',
  50: '#212F9E',
  60: '#1D39C4',
  70: '#3E46C9',
  80: '#5555CF',
  90: '#6963D4',
  100: '#7A72D9',
  110: '#8B81DE',
  120: '#9B91E2',
  130: '#ABA1E7',
  140: '#BAB1EC',
  150: '#C9C1F0',
  160: '#D8D2F4',
};

export const lightTheme: Theme = {
  ...createLightTheme(brands),
  fontFamilyBase: 'Metropolis',
};
