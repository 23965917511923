import { ApiMethodType } from '@celito.clients/enums';
import { ObjectData, SortConfig } from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import Endpoints from '../config/endpoint';
import { FilterAsConditions } from '../types/filter-criteria-type';

interface GetObjectDataOptions {
  filters?: RulesComponentListViewFilter[] | FilterAsConditions[];
  sort?: SortConfig;
}

interface GetModuleUsersOptions {
  modules: string[];
  roles?: string[];
  filterByExactRoles?: boolean;
  includeSystemUser?: boolean;
}

interface PaginationConfig {
  limit?: number;
  page?: number;
}

export const getModuleUsers = async <T>(
  { filters, sort }: GetObjectDataOptions,
  {
    modules,
    roles,
    filterByExactRoles,
    includeSystemUser,
  }: GetModuleUsersOptions,
  { limit, page }: PaginationConfig = {},
  signal?: AbortSignal
) => {
  return await performRequest<ObjectData<T>>(
    `${Endpoints.MODULE_USER}`,
    ApiMethodType.POST,
    {
      filters,
      sort,
      modules,
      roles,
    },
    {
      filter_by_exact_roles: filterByExactRoles ?? false,
      include_system_user: includeSystemUser ?? false,
    },
    undefined,
    signal
  );
};
