import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ButtonTypes, OperatorsEnum } from '@celito.clients/enums';
import { useCallbackPrompt } from '@celito.clients/hooks';
import {
  ConfirmDialog,
  CustomButton,
  DatePicker,
  Icon,
  Loader,
  PeoplePicker,
  TextField,
  TextFieldArea,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { getZonedBasedTime } from '@celito.clients/utils';
import { defaultDatePickerStrings } from '@fluentui/react-datepicker-compat';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import { startOfMonth } from 'date-fns';
import { TimeOfDayPrefenceEnum } from 'libs/core/src/enums/date-formats';
import { IDatePickerStrings } from 'libs/shared/src/lib/date-picker/date-picker.model';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { DelegateFormViewProps } from './delegation-form.model';
import { delegationFormStyles } from './delegation-form.styles';

const DelagationFormView: React.FC<DelegateFormViewProps> = ({
  isEdit,
  isBizAdmin,
  onDateChange,
  endDateMin,
  onButtonClicked,
  onDelegateSelected,
  itemSelectedToEdit,
  onCreateClicked,
  onInputDateChanged,
  datePickeEndrRef,
  onJustificationChange,
  errors,
  isDirty,
  formFields,
  attributes,
  hasDelegationStarted,
  isLoading,
  ...props
}) => {
  const { cssVariables } = useTheme();
  const styles = delegationFormStyles();
  const datePickerStartRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const currentDateFormatted = new Date().toLocaleDateString();
  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt(
    !!isDirty
  );
  const datePickerstrings: IDatePickerStrings = useMemo(
    () => ({
      ...defaultDatePickerStrings,
      isResetStatusMessage: `Invalid entry, Enter date as mm/dd/yyyy, ex: ${currentDateFormatted} or 4 july 2023 or july 4 2023`,
    }),
    [currentDateFormatted]
  );

  const onItemPickedWrapper = (key: string) => (item?: Date) => {
    if (item) {
      onDateChange(key, item);
    }
  };

  const onInputChangeWrapper =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      onInputDateChanged(key, e.target.value);
    };

  const getRoles = () => {
    if (!isBizAdmin) {
      return props?.currentUser?.roles;
    } else if (props?.delegationFromRole.length) {
      return props?.delegationFromRole;
    } else {
      return undefined;
    }
  };

  const isEndDateEditable =
    isEdit &&
    getZonedBasedTime(itemSelectedToEdit.endAtUtcdate) <
      new Date(getZonedBasedTime(new Date()).setHours(23, 59, 59, 999));

  if (isLoading) {
    return <Loader fullPage className={styles.loaderWrapper} />;
  }

  return (
    <div style={{ ...cssVariables }}>
      <div className={styles.formHeading}>
        <button onClick={onButtonClicked}>
          <Icon iconName="ArrowLeft16Regular" />
        </button>
        {isEdit ? (
          <b>{LocalStrings.editDelegate}</b>
        ) : (
          <b>{LocalStrings.createNewDelegate}</b>
        )}
      </div>
      <div className={styles.formView}>
        {isEdit && (
          <div className={`${(styles.item, styles.fieldMinWdith)}`}>
            <div className={styles.fieldWidth}>
              <TextField
                label={attributes.label?.label}
                value={itemSelectedToEdit?.label}
                readOnly
                disabled
              />
            </div>
          </div>
        )}
        <div className={styles.item}>
          <DatePicker
            label={attributes.startAtUtc?.label}
            helperLabelText={attributes.startAtUtc?.helpText}
            required
            data-testid="date-picker-delegation-start-date"
            minDate={new Date()}
            value={formFields?.startDate}
            onDateChange={
              onItemPickedWrapper('startDate') as (
                date: Date | null | undefined
              ) => void
            }
            onInputChange={onInputChangeWrapper('startDate')}
            strings={{
              ...datePickerstrings,
              isOutOfBoundsErrorMessage: `Delegation start date must be from today's date ${new Date().toLocaleDateString()}`,
            }}
            ref={datePickerStartRef}
            error={
              errors?.startDate
                ? { message: errors.startDate, type: 'required' }
                : undefined
            }
            disabled={hasDelegationStarted}
            timeOfDayPreference={TimeOfDayPrefenceEnum.START}
          />
        </div>

        <div className={styles.item}>
          <DatePicker
            label={attributes.endAtUtc?.label}
            helperLabelText={attributes.endAtUtc?.helpText}
            data-testid="date-picker-delegation-end-date"
            minDate={endDateMin}
            required
            value={formFields?.endDate}
            onDateChange={
              onItemPickedWrapper('endDate') as (
                date: Date | null | undefined
              ) => void
            }
            onInputChange={onInputChangeWrapper('endDate')}
            strings={{
              ...datePickerstrings,
              isOutOfBoundsErrorMessage:
                'Delegation end date must be after start date or empty',
            }}
            ref={datePickeEndrRef}
            error={
              errors?.endDate
                ? { message: errors.endDate, type: 'required' }
                : undefined
            }
            disabled={!formFields?.startDate || isEndDateEditable}
            initialPickerDate={
              formFields?.startDate
                ? startOfMonth(formFields.startDate)
                : undefined
            }
            timeOfDayPreference={TimeOfDayPrefenceEnum.END}
          />
        </div>

        <div className={styles.item}>
          <PeoplePicker
            onOptionSelect={(_ev, data) => {
              onDelegateSelected('delegateFrom', data.selectedOptions[0]);
            }}
            selectedOptions={formFields?.primaryUserName ?? []}
            roles={!isBizAdmin ? props?.currentUser?.roles : undefined}
            dataTestId="people-picker-delegate-from"
            label={attributes.primaryUserName?.label}
            helperLabelText={attributes.primaryUserName?.helpText}
            required
            filterByExactRoles={!isBizAdmin}
            isDisabled={!isBizAdmin || !!itemSelectedToEdit.primaryUserName}
            errorMessage={errors?.selectedFromId}
            referencePickerProps={{
              defaultReferenceFilter: [
                {
                  attribute: 'isActive',
                  operator: OperatorsEnum.EQUALS,
                  value: true,
                },
              ],
              disableOptionsWhenFetching: true,
            }}
          />
        </div>

        <div className={styles.item}>
          <PeoplePicker
            onOptionSelect={(_ev, data) =>
              onDelegateSelected('delegateTo', data.selectedOptions[0])
            }
            selectedOptions={formFields?.delegatedUserName}
            roles={getRoles()}
            label={attributes.delegatedUserName?.label}
            helperLabelText={attributes.delegatedUserName?.helpText}
            required
            disabled={
              !formFields?.primaryUserName?.value || hasDelegationStarted
            }
            filterByExactRoles={!!getRoles()}
            errorMessage={errors?.selectedToId}
            excludeUsers={
              formFields?.primaryUserName?.value
                ? [formFields?.primaryUserName.value]
                : []
            }
            referencePickerProps={{
              disableOptionsWhenFetching: true,
              defaultReferenceFilter: [
                {
                  attribute: 'isActive',
                  operator: OperatorsEnum.EQUALS,
                  value: true,
                },
              ],
            }}
          />
        </div>
        <div className={styles.item}>
          <TextFieldArea
            label={attributes.justification?.label}
            helperLabelText={attributes.justification?.helpText}
            value={formFields?.justification}
            className={styles.textArea}
            size="large"
            onChange={(e) => onJustificationChange(e?.currentTarget?.value)}
            required
            errorMessage={errors?.justification}
          ></TextFieldArea>
        </div>
      </div>

      <div className={styles.footerContent}>
        <CustomButton
          buttonTitle={LocalStrings.cancelLabel}
          buttonType={ButtonTypes.Ghost}
          onClick={() => {
            isDirty
              ? navigate(
                  '../../md/controlled_docs__a/tb/cd_delegation_tab__a/stb/default/delegation'
                )
              : onButtonClicked();
          }}
        />
        <CustomButton
          buttonTitle={
            isEdit ? LocalStrings.saveLabel : LocalStrings.createLabel
          }
          leftIcon={isEdit ? 'Save' : 'Add'}
          buttonType={ButtonTypes.Primary}
          onButtonClicked={onCreateClicked}
          disabled={!isDirty}
        />
      </div>
      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={() => {
          confirmNavigation();
          onButtonClicked();
        }}
        onCancelClicked={() => {
          cancelNavigation();
        }}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
    </div>
  );
};

export default DelagationFormView;
