import { DeleteIcon, LocalizationString } from '@celito.clients/assets';
import { isArrayOfStrings } from '@celito.clients/utils';
import cn from 'classnames';
import React from 'react';
import type { FieldValues } from 'react-hook-form';

import { ConfirmDialog, Icon } from '../shared';
import { ShowFilePreview } from './components';
import { FileUploadButtonProps } from './file-upload.model';
import classes from './file-upload.module.css';

interface FileUploadButtonViewProps<T extends FieldValues>
  extends FileUploadButtonProps<T> {
  fileName?: string;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDrop: (event: React.DragEvent<HTMLButtonElement>) => void;
  handleButtonClick: () => void;
  isDragging: boolean;
  handleDragEnter: (event: React.DragEvent<HTMLButtonElement>) => void;
  handleDragLeave: (event: React.DragEvent<HTMLButtonElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLButtonElement>) => void;
  removeFile: () => void;
  handleFileView?: () => void;
  allowedFileExtensions: any;
  showDeleteAlert: boolean;
  closeDeleteAlert: (deleteRec?: boolean) => void;
  setShowDeleteAlert: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

const FileInputButton = <T extends FieldValues>(
  {
    fileName,
    handleFileChange,
    handleButtonClick,
    handleDrop,
    isDragging,
    handleDragEnter,
    handleFileView,
    handleDragLeave,
    handleDragOver,
    error,
    allowedFileExtensions = [],
    mode,
    objectName,
    documentId,
    showCustomPreviewComponent,
    customPreviewComponent,
    showDeleteAlert,
    closeDeleteAlert,
    setShowDeleteAlert,
    disabled = false,
    styles = {},
    recordName,
  }: FileUploadButtonViewProps<T>,
  fileInputRef: React.ForwardedRef<HTMLInputElement>
) => {
  const onBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handleButtonClick();
  };

  return !fileName ? (
    <>
      <input
        type="file"
        className={classes.input_file_upload}
        ref={fileInputRef}
        id="input-file-upload"
        onChange={handleFileChange}
        accept={allowedFileExtensions}
        disabled={disabled}
        data-testid={`file-upload`}
      />
      <label
        className={cn(classes.container, {
          [classes.disabled]: disabled,
        })}
        style={styles}
        id="label-file-upload"
        htmlFor="input-file-upload"
      >
        {' '}
        <button
          className={cn(classes.button, {
            [classes.error]: error?.message,
            [classes.active_drag]: isDragging,
            [classes.inactive_drag]: !isDragging,
            [classes.cursor_not_allowed]: disabled,
          })}
          style={styles}
          onClick={onBtnClick}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          type="button"
        >
          <Icon iconName="ArrowUpload24Regular" className={classes.icon} />
          <div>
            {isDragging ? (
              'Drop the file here'
            ) : (
              <div className={classes.label}>
                <p>
                  <span className={classes.blue_text}>Choose file</span> or{' '}
                  <span>drag here</span> to upload
                </p>
                {allowedFileExtensions !== '*' ? (
                  <p>
                    {`Supports: ${(allowedFileExtensions as string)
                      .split(',')
                      .map((ext) => {
                        if (['.wopitest', '.wopitestx'].includes(ext))
                          return null;
                        return ext.toUpperCase();
                      })
                      .filter((ext) => ext !== null)
                      .join(', ')}`}
                  </p>
                ) : null}
              </div>
            )}
          </div>
        </button>
      </label>
    </>
  ) : (
    <>
      <ConfirmDialog
        open={showDeleteAlert}
        onCancelClicked={(e) => {
          e.stopPropagation();
          closeDeleteAlert();
        }}
        onConfirmClicked={(e) => {
          e.stopPropagation();
          closeDeleteAlert(true);
        }}
        primaryButtonText={LocalizationString.DELETE}
        title={LocalizationString.DELETE_RECORD}
        iconSrc={DeleteIcon}
        description={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_DELETE}
        secondaryButtonText={LocalizationString.CANCEL}
        isBlocking
      />
      <ShowFilePreview
        documentId={documentId}
        objectName={objectName}
        fileName={fileName}
        removeFile={() => setShowDeleteAlert(true)}
        handleFileView={handleFileView}
        mode={mode}
        customPreviewComponent={customPreviewComponent}
        showCustomPreviewComponent={showCustomPreviewComponent}
        disabled={disabled}
        recordName={recordName}
      />
    </>
  );
};

export default React.forwardRef(FileInputButton) as <T extends FieldValues>(
  props: FileUploadButtonViewProps<T> & {
    ref?: React.ForwardedRef<HTMLUListElement>;
  }
) => ReturnType<typeof FileInputButton>;
