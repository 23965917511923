import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const GridViewTableStyles = makeStyles({
  noDataDisplay: {
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...shorthands.gap('8px'),

    '> img': {
      width: '128px',
    },
  },

  noDataText: {
    fontFamily: ThemeFontFamily.sans,
    fontSize: '20px',
    color: Color.gray_500,
    paddingBottom: '20px',
  },
});
