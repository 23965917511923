import { INavLinkGroup } from '../../header.model';
import { NavigationLink } from '../../types';
import HeaderSidebarController from './header-sidebar.controller';

export interface HeaderSidebarProps {
  activeNavItem: NavigationLink | undefined;
  handleNavLinkClick: (selectedLinkKey?: string) => void;
  getNavLinkGroups: () => INavLinkGroup[];
  toggleSidebar: (value?: boolean) => void;
}

const HeaderSidebar = (props: HeaderSidebarProps): JSX.Element => {
  return <HeaderSidebarController {...props} />;
};

export default HeaderSidebar;
