import { UserTypes } from '../../types/user-types';

export enum LogLevel {
  ERROR = 0,
  INFO = 1,
  DEBUG = 2,
}

export const logLevels = ['error', 'info', 'debug'] as const;

export type TMessage = string | Error;

export type Dictionary = Record<string, unknown>;

export interface TAttributes extends Dictionary {
  /**
   * Primarily to be used for sentry breadcrumbs
   * @see https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
   */
  type?: 'navigation' | 'http' | 'ui' | 'error' | 'info' | 'query' | 'other';
  origin?: string;
}
export interface Transport {
  handle(message: TMessage, level: LogLevel, attributes?: TAttributes): void;
  identity(user: User | null): void;
}

export type User = UserTypes | null;
