import React from 'react';

import { TextFieldModel } from './text-field.model';
import { TextFieldView } from './text-field.view';

// The controller component for the TextField, responsible for handling props and rendering the TextFieldView
export const TextFieldController: React.FC<TextFieldModel> = ({ ...props }) => {
  const { required, errorMessage, disabled } = props;

  return (
    <TextFieldView
      errorMessage={errorMessage}
      required={required}
      disabled={disabled}
      {...props}
    />
  );
};
