import { ApiMethodType, AttributeTypeEnum } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { AdvancedSearchPayload } from './apiServices.model';

export interface IField {
  columnName: string;
  columnValue: string;
  columnKey: string;
  canUseInHyperLink: boolean;
  detailViewName: string;
}

interface IAdvancedSearchResultInfo {
  index: string;
  fields: IField[];
}

export interface IFactStatusValue {
  key: string;
  doc_count: number;
}
export interface IFactStatus {
  key: string;
  dataType: AttributeTypeEnum;
  name: string;
  module: string;
  values: IFactStatusValue[];
}

export interface IFact {
  [key: string]: IFactStatus;
}

interface IAdvancedSearchResult {
  module: string;
  resultCount: number;
  searchResult: IAdvancedSearchResultInfo[];
}

export interface IAdvancedSearch {
  result: IAdvancedSearchResult[];
  Fact: IFact;
}

export const getAdvancedSearchResults = async (
  payload: AdvancedSearchPayload
) => {
  const response = await performRequest<IAdvancedSearch>(
    Endpoints.ADVANCED_SEARCH,
    ApiMethodType.POST,
    payload
  );
  return response.data;
};
