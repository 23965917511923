import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { ObjectActionDefinition, Task } from '@celito.clients/types';
import { createContext, useContext } from 'react';

import { ControlledDocs } from '../types';

export type TScreenContext = {
  mode: FormEngineModeTypeEnum;
  recordName: string;
  version?: string;
  userActions: ObjectActionDefinition[];
  isTaskResponseLoading: boolean;
  populateUserActions: (response: ControlledDocs) => void;
  showEditDocumentButton: boolean;
  taskName?: string;
  task?: Task;
  setTask: React.Dispatch<React.SetStateAction<Task | undefined>>;
  invalidateRecordCache: () => void;
  refetchTaskData: () => void | Promise<void>;
};

const ScreenContext = createContext<TScreenContext | null>(null);

export const useScreenContext: () => TScreenContext | Record<string, never> =
  function () {
    const context = useContext(ScreenContext);
    if (context === undefined)
      throw new Error('Please use inside the context provider');
    return context ?? {};
  };

export const ScreenContextProvider = ScreenContext.Provider;
