import { pngIconNameMap } from '@celito.clients/endpoints';

import { IconProps } from '../icon.component';

interface PngIcon extends IconProps {}

const PngIcon = (props: PngIcon): JSX.Element => {
  if (props.iconName && pngIconNameMap?.[props.iconName]) {
    return (
      <img
        src={pngIconNameMap[props.iconName]}
        alt={props.iconName}
        data-testid={props.dataTestId}
        onClick={
          props.onClick as unknown as React.MouseEventHandler<HTMLImageElement>
        }
        style={props.style}
        className={props.className}
      />
    );
  }

  return <img alt="" />;
};

export default PngIcon;
