import { LocalizationString, UrlString } from '@celito.clients/assets';
import { AttributeTypeEnum, DateFormat } from '@celito.clients/enums';
import { GridViewProps, Icon } from '@celito.clients/shared';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import {
  formatDate,
  getDaysDifference,
  isDateValid,
  isValidJsonString,
  removeEmptyValues,
} from '@celito.clients/utils';
import {
  makeStyles,
  mergeClasses,
  shorthands,
  Tooltip,
} from '@fluentui/react-components';
import { convert } from 'html-to-text';
import { ActionLevelEnum } from 'libs/core/src/enums/action-type';
import {
  ColumnData,
  SubRowItem,
} from 'libs/shared/src/lib/grid-view-new/src/types';
import { Color } from 'libs/theme/src/lib/colors';
import { get, isEqual } from 'lodash';

import { ActionColumn, subrowColumnRenderer } from '../components';
import { TaskNameCell } from '../components/task-name-cell';
import { ObjectData, ObjectMetadata, SubGridData } from '../types';
import { RecordAction, View, ViewMetadata } from '../types/view-metadata.type';
import { CustomStyles } from './custom-styles';
import getNavigateUrl from './get-navigate-url.util';

export const getMinWidthForColumn = (dataType: AttributeTypeEnum) => {
  switch (dataType) {
    case AttributeTypeEnum.PlainText:
      return 165;
    case AttributeTypeEnum.LongText:
      return 180;
    case AttributeTypeEnum.YesNo:
    case AttributeTypeEnum.ActiveInactive:
      return 80;
    case AttributeTypeEnum.Date:
    case AttributeTypeEnum.DateTime:
      return 180;
    case AttributeTypeEnum.Email:
      return 150;
    case AttributeTypeEnum.RichText:
      return 150;
    case AttributeTypeEnum.Reference:
      return 120;
    case AttributeTypeEnum.ObjectDefinition:
      return 150;
    default:
      return 80;
  }
};

export const getMaxWidthForColumn = (dataType: AttributeTypeEnum) => {
  switch (dataType) {
    case AttributeTypeEnum.PlainText:
      return 425;
    case AttributeTypeEnum.LongText:
      return 500;
    case AttributeTypeEnum.YesNo:
    case AttributeTypeEnum.ActiveInactive:
      return 100;
    case AttributeTypeEnum.Date:
    case AttributeTypeEnum.DateTime:
      return 250;
    case AttributeTypeEnum.Email:
      return 350;
    case AttributeTypeEnum.RichText:
      return 550;
    case AttributeTypeEnum.Reference:
      return 350;
    case AttributeTypeEnum.ObjectDefinition:
      return 450;
    default:
      return 400;
  }
};

export const iconClass = makeStyles({
  root: {
    width: '32px',
    height: '32px',
    backgroundColor: 'transparent',
    color: Color.label_color,
    ...shorthands.borderRadius('50%'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '> svg': {
      width: '100%',
    },
  },

  dueDateMoreThan7: {
    color: Color.green,
    backgroundColor: Color.success_status_bg,
  },

  dueDatePassed: {
    color: Color.error_status,
    backgroundColor: Color.error_border,
  },

  elseCond: {
    color: Color.warning_status,
    backgroundColor: Color.warning_status_bg,
  },
});

interface DelegateIconProps {
  /**
   * Label is the task title
   */
  label?: string;
  delegates: string[] | string;
  owner: string;
}

export const getDelegatedTaskContentString = ({
  label,
  owner,
  delegates,
}: DelegateIconProps) => {
  return `${label} task is delegated from ${owner} to ${
    Array.isArray(delegates) ? delegates.map((d) => d) : delegates
  }`;
};

export const DelegateIcon: React.FC<DelegateIconProps> = (props) => {
  const styles = CustomStyles();
  return (
    <Tooltip
      relationship="label"
      content={getDelegatedTaskContentString(props)}
    >
      <span>
        <Icon iconName="PeopleSwap20Regular" className={styles.icon} />
      </span>
    </Tooltip>
  );
};

const createColumnConfig = (
  attribute: ObjectAttributeDefinition,
  isSortableColumn: boolean,
  isAlreadySorted: boolean,
  onColumnClick: ColumnData['onColumnClick'],
  label?: string
): ColumnData => {
  const columnConfig: ColumnData = {
    key: String(attribute.key),
    minWidth: getMinWidthForColumn(attribute.dataType),
    maxWidth: getMaxWidthForColumn(attribute.dataType),
    name: label ?? attribute.label ?? '',
    fieldName: attribute.key,
    isResizable: true,
    isSorted: isAlreadySorted,
    showSortIconWhenUnsorted: isSortableColumn,
    data: {
      dataType: attribute.dataType,
      name: attribute.name,
      isSortableColumn,
      relationship: attribute.relationship,
      columnName: attribute.columnName,
    },
    onColumnClick,
    showTooltip: true,
  };

  if (attribute.key === 'isActive') {
    columnConfig.onRender = (item) => {
      return <span>{item.rowData[attribute.key] ? 'Active' : 'Inactive'}</span>;
    };
  }

  // Hardcoded logic to render delegated task icon for "Task Name" column as this is not a generic requirement
  // and specific to task__s object list view similar to Task Due Date status icon column
  if (attribute.label === 'Task Name') {
    columnConfig.onRender = (item) => {
      return <TaskNameCell attribute={attribute} item={item} />;
    };
  }

  return columnConfig;
};

export const getIsColumnSortableFunc =
  (viewData: View) =>
  (columnName: string): boolean => {
    return viewData.defaultColumns.some(
      ({ name, isSortable }) => name === columnName && isSortable
    );
  };

export const getGridColumnDataFromObjectMetaData = (
  objectMetadata: ObjectMetadata,
  viewMetadata: ViewMetadata,
  onColumnClick: ColumnData['onColumnClick']
): GridViewProps['columns'] => {
  const viewData = viewMetadata?.viewDto?.view?.[0];
  const columnsToShow = viewData.defaultColumns;
  const isColumnSortable = getIsColumnSortableFunc(viewData);
  const defaultSortColumn = viewData?.defaultSortColumn;
  const subGridAttributeName = viewData.subGirdAttributeName;

  let columnData: ColumnData[] = [];

  for (const columnToShow of columnsToShow) {
    if (!columnToShow?.columnNameToBePicked) {
      if (columnToShow.name === subGridAttributeName) continue;

      const attribute = objectMetadata.objectAttributeDefinitions.find(
        (attr) => attr.name === columnToShow.name
      );

      if (!attribute) continue;

      const isSortableColumn = isColumnSortable(attribute.name);
      const isAlreadySorted =
        isSortableColumn && attribute.name === defaultSortColumn;

      columnData.push(
        createColumnConfig(
          attribute,
          isSortableColumn,
          isAlreadySorted,
          onColumnClick,
          columnToShow?.label
        )
      );
    } else {
      if (columnToShow.name === subGridAttributeName) continue;

      const { name, label, columnNameToBePicked } = columnToShow;

      const attribute = objectMetadata.objectAttributeDefinitions.find(
        (attr) => attr.name === name
      );

      if (!attribute) continue;

      columnData.push({
        key: `${attribute?.key}-${columnNameToBePicked}`,
        minWidth: getMinWidthForColumn(attribute.dataType),
        maxWidth: getMaxWidthForColumn(attribute.dataType),
        name: label ?? attribute.label ?? '',
        // fieldName: attribute.key, optional if onRender is used
        isResizable: true,
        isSorted: name === defaultSortColumn,
        showSortIconWhenUnsorted: isColumnSortable(name),
        data: {
          name,
          dataType: attribute.dataType,
          isSortableColumn: isColumnSortable(attribute.name),
          relationship: attribute.relationship,
          columnName: attribute.columnName,
          columnNameToBePicked: columnNameToBePicked,
        },
        onColumnClick,

        onRender: (item) => {
          return (
            get(item, `rowData.${attribute?.key}.${columnNameToBePicked}`) ??
            // FIXME: As we support column name to be picked from nested object, we need not to do this anymore
            getRowValueFromDataType(
              attribute.dataType,
              item.rowData[attribute.key],
              {
                forAllObjects: item.forAllObjects,
              }
            ) ??
            '-'
          );
        },
        showTooltip: true,
      });
    }
  }

  if (viewData.actions) {
    const objectActionDefinitions = objectMetadata.objectActionDefinitions;

    const recordActions = removeEmptyValues(
      viewData.actions.reduce<(RecordAction | undefined)[]>(
        (acc, currAction) => {
          acc.push(
            objectActionDefinitions.find(
              (actionDef) => actionDef.name === currAction.name
            )
          );
          return acc;
        },
        []
      )
    ).filter((a) => a?.actionLevel === ActionLevelEnum.RecordAction);

    if (recordActions.length > 0) {
      const actionColumn: ColumnData = {
        key: 'actions',
        minWidth: 60,
        maxWidth: 80,
        name: LocalizationString.ACTIONS,
        isResizable: false,
        onRender: (item, _index, column) =>
          ActionColumn({
            column,
            defaultActions: recordActions,
            rowItem: item,
            objectName: viewData.objectName,
            objectMetadata,
            viewMetadata,
          }),
      };

      columnData.push(actionColumn);
    }
  }

  if (viewData.enableSubgrid && viewData.subGirdAttributeName) {
    const subGridColumn = objectMetadata.objectAttributeDefinitions.find(
      (item) => item.name === viewData.subGirdAttributeName
    );

    if (subGridColumn) {
      columnData = [
        {
          key: 'column00',
          name: '',
          minWidth: 48,
          maxWidth: 48,
          fieldName: 'subRow',
          isResizable: false,
          onRender: subrowColumnRenderer,
        },
        ...columnData,
      ];
    }
  }

  const addIconViewNames = [
    UrlString.CD_MY_TASKS_LIST_VIEW,
    UrlString.UL_MY_TASKS_LIST_VIEW,
    UrlString.UL_MY_TRAINING_LIST_VIEW,
    UrlString.CD_ALL_TASK_LIST_VIEW,
    UrlString.UL_ALL_TASK_LIST_VIEW,
  ];
  // Shows task due date status icon column only for cd taks list view
  if (addIconViewNames.includes(viewData.name)) {
    columnData.unshift({
      key: 'due-date-status-icon',
      minWidth: 50,
      maxWidth: 50,
      name: '',
      isResizable: false,
      onRender: (item, _index, column) => getIconByTaskDueDate(item),
    });
  }

  return columnData.sort(
    (a, b) => (a.displayOrder as number) - (b.displayOrder as number)
  );
};

const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'Warning':
      return <Icon iconName="Warning24Regular" />;
    case 'Timer': // > 7 days
      return <Icon iconName="Timer24Regular" />;
    case 'Clock':
      return <Icon iconName="Clock24Regular" />;
    default:
      return <>NA</>;
  }
};

const getIconByTaskDueDate = (item: any) => {
  const dueDate = item?.rowData?.dueDate;
  let iconName = '';
  let title = '';

  if (dueDate && isDateValid(dueDate)) {
    const daysDiff = getDaysDifference(dueDate);

    if (daysDiff > 7) {
      iconName = 'Timer';
      title = 'Due Date > 7 Days';
    } else if (daysDiff < 0) {
      iconName = 'Warning';
      title = 'Overdue';
    } else {
      iconName = 'Clock';
      title = 'Due Date <= 7 Days';
    }

    const styles = iconClass();

    return (
      <div>
        <Tooltip content={title} relationship="label" withArrow>
          <span
            className={mergeClasses(
              styles.root,
              daysDiff > 7 && styles.dueDateMoreThan7,
              daysDiff < 0 && styles.dueDatePassed,
              daysDiff >= 0 && daysDiff <= 7 && styles.elseCond
            )}
          >
            {getIcon(iconName)}
          </span>
        </Tooltip>
      </div>
    );
  } else {
    title = 'No Due Date';

    const styles = iconClass();

    return (
      <div>
        <Tooltip content={title} relationship="label" withArrow>
          <span className={styles.root}>{getIcon(iconName)}</span>
        </Tooltip>
      </div>
    );
  }
};

export const getGridRowDataFromObjectMetaData = (
  objectMetadata: ObjectMetadata,
  objectData: ObjectData,
  viewMetadata: ViewMetadata,
  handleSubRowToggle: (itemKey: string | number) => void
): GridViewProps['items'] => {
  const viewData = viewMetadata?.viewDto?.view?.[0];

  if (!viewData) {
    return [];
  }

  const {
    isRowClickable,
    rowClickableViewName,
    enableSubgrid,
    subGirdAttributeName,
  } = viewData;

  const fieldNames = objectMetadata.objectAttributeDefinitions
    .filter((col) => col.name !== subGirdAttributeName)
    .map((attribute) => ({
      fieldName: attribute.key,
      fieldType: attribute.dataTypeKeyForFE ?? attribute.dataType,
      objectDefinition: attribute,
    }));

  return objectData.data.map((item, index) => {
    const rowData: GridViewProps['items'][0] = {
      key: index,
    };
    rowData.rowData = item;

    fieldNames.forEach(({ fieldName, fieldType, objectDefinition }) => {
      rowData[fieldName] = getRowValueFromDataType(
        fieldType,
        item[fieldName],
        {
          forAllObjects: item.forAllObjects,
        },
        objectDefinition
      );
    });

    if (isRowClickable) {
      rowData.clickable = true;
      rowData.onClickNavigateRoute = getNavigateUrl(rowClickableViewName, item);
    }

    if (enableSubgrid && subGirdAttributeName) {
      const subGridAttribute = objectMetadata.objectAttributeDefinitions.find(
        (item) => item.name === subGirdAttributeName
      );

      if (subGridAttribute) {
        const subRowRawData = item[subGridAttribute.key] as string;

        let subGridItemData: SubRowItem[] = [];

        if (isValidJsonString(subRowRawData)) {
          const subGridData = JSON.parse(subRowRawData) as SubGridData[];

          if (Array.isArray(subGridData)) {
            subGridItemData = subGridData.map((item, index) => ({
              key: index,
              field: item.fieldName,
              beforeValue: getRowValueFromDataType(
                getItemDataType(item),
                item.beforeValue
              ),
              afterValue: getRowValueFromDataType(
                getItemDataType(item),
                item.afterValue
              ),
            }));
          }
        }

        rowData.subGridData = {
          isSubGridOpen: false,
          onSubGridToggle: handleSubRowToggle,
          subGridColumnData: [
            {
              key: 0,
              name: LocalizationString.FIELD,
            },
            {
              key: 1,
              name: LocalizationString.BEFORE_VALUE,
            },
            {
              key: 2,
              name: LocalizationString.AFTER_VALUE,
            },
          ],
          subGridItemData,
        };
      }
    }

    return rowData;
  });
};

interface GetRowValueFromDataTypeOptions {
  forAllObjects?: boolean;
}

export const getRowValueFromDataType = (
  columnFieldType: AttributeTypeEnum,
  dataFieldEntry: unknown,
  options?: GetRowValueFromDataTypeOptions,
  objectAttributeDefinition?: ObjectAttributeDefinition
) => {
  switch (columnFieldType) {
    case AttributeTypeEnum.DateTime:
      if (isDateValid(dataFieldEntry as string)) {
        return formatDate(dataFieldEntry as string, DateFormat.DateTime);
      }
      break;

    case AttributeTypeEnum.Date:
      if (
        isNaN(+(dataFieldEntry as string)) &&
        isDateValid(dataFieldEntry as string)
      ) {
        return formatDate(dataFieldEntry as string, DateFormat.Date);
      }
      break;

    case AttributeTypeEnum.ObjectDefinition:
      if (options?.forAllObjects) {
        return LocalizationString.FOR_ALL_OBJECTS;
      }
      return (dataFieldEntry as Record<string, unknown>)?.label;

    case AttributeTypeEnum.Reference:
      if (Array.isArray(dataFieldEntry) && dataFieldEntry.length > 0) {
        if (typeof dataFieldEntry[0] === 'object') {
          return dataFieldEntry
            .map((item) => (item as Record<string, string>)?.label ?? '')
            .join(', ');
        }
        if (typeof dataFieldEntry[0] === 'string') {
          return dataFieldEntry.join(', ');
        }
      }

      if (objectAttributeDefinition?.propertyLookupKey) {
        return (dataFieldEntry as Record<string, unknown>)?.[
          objectAttributeDefinition?.propertyLookupKey
        ];
      }

      return (
        (dataFieldEntry as Record<string, unknown>)?.label ||
        (dataFieldEntry as Record<string, string>)?.name ||
        dataFieldEntry
      );

    case AttributeTypeEnum.Document:
      return (dataFieldEntry as Record<string, unknown>)?.nameWithExtension;
    case AttributeTypeEnum.User:
    case AttributeTypeEnum.Record:
      return (dataFieldEntry as Record<string, unknown>)?.label;

    case AttributeTypeEnum.RichText:
      return convert(dataFieldEntry as string);
  }

  if (dataFieldEntry === null) {
    return '';
  }
  if (typeof dataFieldEntry === 'object') {
    return JSON.stringify(dataFieldEntry);
  }
  if (typeof dataFieldEntry === 'boolean') {
    return dataFieldEntry ? LocalizationString.YES : LocalizationString.NO;
  }
  if (typeof dataFieldEntry === 'number') {
    return dataFieldEntry.toString();
  }
  if (typeof dataFieldEntry === 'string') {
    if (dataFieldEntry.startsWith('<')) {
      return convert(dataFieldEntry);
    }
    return dataFieldEntry;
  }
  if (typeof dataFieldEntry === 'undefined') {
    return '';
  }
  if (typeof dataFieldEntry === 'function') {
    return '';
  }
  if (typeof dataFieldEntry === 'symbol') {
    return '';
  }
  return dataFieldEntry;
};

const getItemDataType = (item: SubGridData) => {
  if (item.dataType) return item.dataType;
  if (isEqual(item.fieldName, 'Title')) return AttributeTypeEnum.PlainText;
  return AttributeTypeEnum.Date;
};
