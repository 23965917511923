import { Environment as EnvironmentManager } from '@celito.clients/singleton';
import { Environment } from '@celito.clients/types';

export const ENV = EnvironmentManager.getInstance().getParam(
  'NX_ENV_NAME'
) as Environment;

// For testing purposes
window.CURRENT_ENVIRONMENT = ENV;

export const isTesting = ENV === Environment.Testing;

export const isDevelopment = ENV === Environment.Development;

export const isStaging = ENV === Environment.Staging;

export const isProduction = ENV === Environment.Production;
