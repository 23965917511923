import {
  AttributeTypeEnum,
  ObjectEnum,
  OperatorsEnum,
} from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import { Datum, SortConfig } from '@celito.clients/list-view-engine';
import { errorToast } from '@celito.clients/utils';
import {
  isFunction,
  OnChangeFn,
  RowSelectionState,
} from '@tanstack/react-table';
import { isAxiosError } from 'axios';
import {
  getIsSortedAscending,
  getSortOrder,
  isReferenceSortSupported,
} from 'libs/list-view-engine/src/lib/utils/sort.util';
import { GridViewProps } from 'libs/shared/src/lib/grid-view-new/src';
import { ColumnData } from 'libs/shared/src/lib/grid-view-new/src/types';
import { head } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import useRowSelection from '../../hooks/use-row-selection';
import { ReferenceSelectorItem } from '../../reference-selector.model';
import { getObjects } from '../../services/getSelectorData';
import { getColumnsConfigByObjectName, transformToGridRow } from '../../utils';
import { getFiltersByObject } from '../../utils/filters-by-object';
import { ShowAllResultPanelProps } from './show-all-results-panel.model';
import { ShowAllResultPanelView } from './show-all-results-panel.view';

interface ShowAllResultPanelControllerProps extends ShowAllResultPanelProps {}
const ITEMS_PER_PAGE = 10;
const DEBOUNCE_INTERVAL_IN_MILLI_SECONDS = 300;

const combineFilters = <F extends Array<unknown>, D extends Array<unknown>>(
  rawFilters: F,
  defaultReferenceFilter?: D,
  excludeSelectedItems?: D
): F => {
  const filters = head(rawFilters);
  // Type narrowing to prevent the usage of `any`
  if (
    filters &&
    typeof filters === 'object' &&
    'conditions' in filters &&
    filters.conditions &&
    typeof filters.conditions === 'object' &&
    'all' in filters.conditions &&
    Array.isArray(filters.conditions.all)
  ) {
    const newConditions: Array<unknown> = [];

    if (defaultReferenceFilter) {
      newConditions.push({ all: defaultReferenceFilter });
    }

    if (excludeSelectedItems) {
      newConditions.push({ all: excludeSelectedItems });
    }

    filters.conditions.all.push(...newConditions);
  }
  return rawFilters;
};

export const ShowAllResultPanelController = (
  props: ShowAllResultPanelControllerProps
) => {
  const { excludeItems, objectName } = props;
  const [availableItems, setAvailableItems] = useState<ReferenceSelectorItem[]>(
    []
  );
  const [isLoadingAvailableItems, setIsLoadingAvailableItems] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState(1);
  const [searchKey, setSearchKey] = useState(props.searchKey);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [columnData, setColumnData] = useState<GridViewProps['columns']>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>();
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [isUserViewPanelOpen, setIsUserViewPanelOpen] = useState(false);
  const { rowSelection, setRowSelection, selectedRows } =
    useRowSelection(availableItems);

  const { defaultReferenceFilter } = props;
  const activeModule = useActiveModule();

  const fetchAvailableItems = async (
    page: number,
    sortConf?: SortConfig | undefined
  ) => {
    if (abortController) {
      abortController.abort();
    }

    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    try {
      setIsLoadingAvailableItems(true);
      const excludeItemIds =
        excludeItems?.map?.((item) =>
          getVersionFilters()?.getAllVersions ? item.uuid : item.name
        ) ?? [];
      const excludedSelfAttachedId = props.excludeSelfAttachedId
        ? [props.excludeSelfAttachedId]
        : [];
      const params: Record<string, any> = {
        limit: ITEMS_PER_PAGE,
        page: page,
        ...getVersionFilters(),
      };

      const filterExcludeSelectedItems = [
        {
          attribute: getVersionFilters()?.getAllVersions ? 'uuid' : 'name',
          operator: OperatorsEnum.NOT_IN,
          value: [...excludeItemIds, ...excludedSelfAttachedId],
        },
      ];
      const data = await getObjects(
        objectName,
        activeModule ? [activeModule?.systemName] : [],
        params,
        combineFilters(
          getFiltersByObject(objectName, searchKey),
          defaultReferenceFilter,
          filterExcludeSelectedItems
        ),
        newAbortController.signal,
        sortConf
      );

      const objects = data.objects as Datum[];

      setTotalPagesCount(Math.ceil((data.total as number) / ITEMS_PER_PAGE));

      const selectorItems: ReferenceSelectorItem[] =
        objects.map(transformToGridRow);

      if (objectName === ObjectEnum.USER) {
        const startIndex = (page - 1) * ITEMS_PER_PAGE;
        const endIndex =
          ITEMS_PER_PAGE * page - 1 > (data.total as number)
            ? (data.total as number)
            : ITEMS_PER_PAGE * page - 1;
        setAvailableItems(selectorItems.slice(startIndex, endIndex + 1));
      } else setAvailableItems(selectorItems);
      setIsLoadingAvailableItems(false);
    } catch (error: unknown) {
      if (
        isAxiosError(error) &&
        error?.name !== 'TypeError' &&
        error.code !== 'ERR_CANCELED'
      ) {
        errorToast({
          message: `Could not fetch the items`,
        });
        setIsLoadingAvailableItems(false);
      }
    }
  };

  const handlePageUpdate = (page: number) => {
    setCurrentPage(page);
    fetchAvailableItems(page, sortConfig);
  };

  useEffect(() => {
    const debounceHandler = setTimeout(() => {
      fetchAvailableItems(currentPage, sortConfig);
    }, DEBOUNCE_INTERVAL_IN_MILLI_SECONDS);
    if (searchKey) {
      handlePageUpdate(1);
    }
    return () => {
      clearTimeout(debounceHandler);
    };
  }, [searchKey]);

  const handleOnRowSelection: OnChangeFn<RowSelectionState> = (updater) => {
    if (!isFunction(updater)) return;
    const values = updater(rowSelection);
    setRowSelection(values);
  };

  const onAddClick = () => {
    props.onAddClick(selectedRows.current);
  };

  const onColumnClick: ColumnData['onColumnClick'] = useCallback(
    (_ev: React.MouseEvent<HTMLElement, MouseEvent>, column: ColumnData) => {
      setColumnData((prev) =>
        prev.map((c) => {
          if (c.key === column.key) {
            return {
              ...c,
              isSortedAscending: getIsSortedAscending(
                c.isSorted,
                c.isSortedAscending
              ),
              isSorted: true,
            };
          }
          return {
            ...c,
            isSorted: false,
          };
        })
      );
      const sortConf: SortConfig = {
        attribute: column.key,
        order: getSortOrder(column.isSorted, column.isSortedAscending),
        ...(isReferenceSortSupported(
          column?.data?.dataType as AttributeTypeEnum
        ) && {
          referencedTableColumnName: column?.data?.columnNameToBePicked,
        }),
      };
      setSortConfig(sortConf);
      fetchAvailableItems(currentPage, sortConf);
    },
    [currentPage, searchKey]
  );

  const onUserView = (name: string) => {
    setIsUserViewPanelOpen(true);
    setUserName(name);
  };

  const onUserViewDismiss = () => {
    setIsUserViewPanelOpen(false);
  };

  useEffect(() => {
    const columnConfigByObjectName = getColumnsConfigByObjectName(
      objectName,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      onColumnClick,
      onUserView
    );

    setColumnData((prev) => {
      const prevSortMap: Record<string, any> = prev.reduce((result, column) => {
        result[column.key] = {
          isSorted: column.isSorted,
          isSortedAscending: column.isSortedAscending,
        };
        return result;
      }, {} as Record<string, any>);
      return columnConfigByObjectName.map((column) => ({
        ...column,
        isSorted: prevSortMap[column.key]?.isSorted,
        isSortedAscending: prevSortMap[column.key]?.isSortedAscending,
      }));
    });
  }, [onColumnClick]);

  const getVersionFilters = () => {
    if (objectName === ObjectEnum.COURSE) {
      return { getAllVersions: true };
    }
    if (objectName === ObjectEnum.CONTROLLED_DOCUMENT) {
      return { getLatestVersionOnly: false };
    }
    return { getMaxMajorVersion: true };
  };

  return (
    <ShowAllResultPanelView
      {...props}
      onAddClick={onAddClick}
      searchKey={searchKey}
      setSearchKey={setSearchKey}
      rowSelection={rowSelection}
      availableItems={availableItems}
      isLoadingAvailableItems={isLoadingAvailableItems}
      paginationProps={{
        currentPage: currentPage,
        totalPageCount: totalPagesCount,
        handlePageUpdate: handlePageUpdate,
        isLoading: isLoadingAvailableItems,
      }}
      onRowSelection={handleOnRowSelection}
      columnData={columnData}
      isUserViewPanelOpen={isUserViewPanelOpen}
      userName={userName}
      onUserViewDismiss={onUserViewDismiss}
    />
  );
};
