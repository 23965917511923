import { Stack } from '@celito.clients/shared';
import EmptySearchIcon from 'libs/assets/src/images/search-result-empty-search-icon.svg';

import classes from '../search-result.module.css';

const EmptySearchResult: React.FC = () => {
  return (
    <Stack className={classes.center}>
      <img src={EmptySearchIcon} alt="" />
      <p className={classes.empty_search_label}>
        Add keyword and start searching
      </p>
    </Stack>
  );
};

export default EmptySearchResult;
