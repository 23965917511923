import { useEffect } from 'react';

export const useCloseOnScroll = (
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  useEffect(() => {
    const handleScroll = () => {
      setMenuOpen(false);
    };

    const addScrollListeners = () => {
      document.addEventListener('scroll', handleScroll, true);
      window.addEventListener('scroll', handleScroll, true);
    };

    const removeScrollListeners = () => {
      document.removeEventListener('scroll', handleScroll, true);
      window.removeEventListener('scroll', handleScroll, true);
    };

    addScrollListeners();

    return () => removeScrollListeners();
  }, [setMenuOpen]);
};
