import { useParams } from 'react-router';

import ListViewScreenView from './list-view-screen.view';

const ListViewScreenController = (): JSX.Element => {
  const params = useParams();

  return <ListViewScreenView viewName={params.viewName} />;
};

export default ListViewScreenController;
