import { Icon } from '@celito.clients/shared';
import { mergeClasses } from '@fluentui/react-components';
import classes from 'libs/list-view-engine/src/lib/list-view.module.css';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

export default function Pagination({
  className,
  pageCount = 0,
  ...props
}: Partial<ReactPaginateProps>) {
  if (pageCount < 2) return null;

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={
        <Icon
          data-testid="icon-pagination-right"
          iconName="ChevronRight16Regular"
        />
      }
      previousLabel={
        <Icon
          data-testid="icon-pagination-left"
          iconName="ChevronLeft16Regular"
        />
      }
      marginPagesDisplayed={1}
      activeClassName={classes.active}
      disabledClassName={classes.disabled}
      containerClassName={mergeClasses(classes.pagination, className)}
      pageCount={pageCount}
      {...props}
    />
  );
}
