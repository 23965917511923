import { Row } from '@tanstack/react-table';
import cn from 'classnames';
import { useDrag, useDrop } from 'libs/shared/src/hooks/drag-and-drop';
import { Icon } from 'libs/shared/src/lib/shared';
import { RefObject } from 'react';

import { RowItem } from '../../types';
import TableCell from '../table-cell/table-cell';
import classes from './draggable-row.module.css';

export const DraggableRow: React.FC<{
  row: Row<RowItem>;
  reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void;
}> = ({ row, reorderRow }) => {
  const { dropRef } = useDrop({
    accept: 'row',
    drop: (draggedRow) => reorderRow?.(draggedRow.index, row.index),
  });

  const { isDragging, dragRef, previewRef } = useDrag({
    ...row,
    type: 'row',
  });

  return (
    <tr
      ref={previewRef as RefObject<HTMLTableRowElement>}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <td ref={dropRef as RefObject<HTMLTableCellElement>}>
        <div
          className={cn(classes.dragHandleContainer, {
            [classes.dragHandle]: !isDragging,
            [classes.isDragging]: isDragging,
          })}
          ref={dragRef as RefObject<HTMLDivElement>}
        >
          <Icon iconName="ReOrderDotsVertical24Regular" />
        </div>
      </td>
      {row.getVisibleCells().map((cell, j) => (
        <TableCell key={cell.id} cell={cell} showPlaceholderIfEmpty={true} />
      ))}
    </tr>
  );
};
