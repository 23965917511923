import { createContext, ReactNode, useContext } from 'react';

import { DisplayViewContextType } from './display-view.model';

export const DisplayViewContext = createContext<DisplayViewContextType>({
  isPreviewTab: false,
});

export const useDisplayViewContext = () => useContext(DisplayViewContext);

const DisplayViewProvider = ({
  children,
  isPreviewTab,
}: {
  children: ReactNode;
  isPreviewTab?: boolean;
}) => {
  return (
    <DisplayViewContext.Provider value={{ isPreviewTab }}>
      {children}
    </DisplayViewContext.Provider>
  );
};

export default DisplayViewProvider;
