import React from 'react';

const withLayout = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  // You can use the "P" generic type to extend the props of the WrappedComponent
  // This will allow TypeScript to infer the correct types when using the HOC
  const WithLayout: React.FC<P> = (props) => {
    return <WrappedComponent {...(props as P)} />;
  };
  return WithLayout;
};

export default withLayout;
