import { ApiMethodType, ExportFileType } from '@celito.clients/enums';
import { SortConfig } from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import { defaultSortConfig } from '../config';

export const getCourseViewExportData = async (
  fileType: ExportFileType,
  filters: RulesComponentListViewFilter[],
  sortConfig?: SortConfig
) => {
  return await performRequest<{ message: string }>(
    `${Endpoints.COURSE_VIEW_METADATA_URL}`,
    ApiMethodType.POST,
    {
      filters: filters,
      sort: sortConfig ?? defaultSortConfig,
    },
    {
      export: true,
      format: fileType,
      getMaxMajorVersionOnly: true,
    }
  );
};
