import { ObjectEnum, OperatorsEnum } from '@celito.clients/enums';
import { FilterCriteria } from '@celito.clients/types';

import { GridViewProps } from '../../../grid-view-new/src';
import { RulesComponentListViewFilter } from '../../../rules-component/types/rules-component.types';

export const trainingPlanObjectName = 'training_plan__a';
export const trainingPlanTypeObjectName = 'training_plan_type__a';
export const courseObjectName = ObjectEnum.COURSE;
export const curriculumObjectName = ObjectEnum.CURRICULUM;
const ReadyForAssignment = 'ReadyForAssignment';
const STATUS = 'status';
const VERSION = 'version';
const VERSION_ENDS_WITH_VALUE = '.0';

export const defaultCourseReferenceFilter: FilterCriteria[] = [
  // {
  //   attribute: trainingPlanTypeObjectName,
  //   operator: OperatorsEnum.EQUALS,
  //   value: TrainingPlanType.Course,
  // },
  {
    attribute: STATUS,
    operator: OperatorsEnum.EQUALS,
    value: ReadyForAssignment,
  },
  {
    attribute: VERSION,
    operator: OperatorsEnum.ENDS_WITH,
    value: VERSION_ENDS_WITH_VALUE,
  },
];

export const defaultCurriculumnReferenceFilter: FilterCriteria[] = [
  // {
  //   attribute: trainingPlanTypeObjectName,
  //   operator: OperatorsEnum.EQUALS,
  //   value: TrainingPlanType.Curriculum,
  // },
  {
    attribute: STATUS,
    operator: OperatorsEnum.EQUALS,
    value: ReadyForAssignment,
  },
  {
    attribute: VERSION,
    operator: OperatorsEnum.ENDS_WITH,
    value: VERSION_ENDS_WITH_VALUE,
  },
];

export const getLoadingRowData = (totalItemsInRow = 13) => {
  const loadingRowData = [];
  for (let i = 0; i < totalItemsInRow; i++) {
    loadingRowData.push(null);
  }
  return loadingRowData as unknown as GridViewProps['items'];
};

export const defaultCompanyAssignmentFilters: RulesComponentListViewFilter = {
  conditions: {
    all: [
      {
        all: [
          {
            attribute: 'isActive',
            operator: OperatorsEnum.EQUALS,
            value: true,
          },
        ],
      },
    ],
  },
};
