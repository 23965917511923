import { useMsal } from '@azure/msal-react';

import {
  msalScopes,
  presenceScope,
} from '../../../../../apps/web-client/src/authConfig';

export const useGetAccessToken = (isGraphRequest?: boolean) => {
  const { accounts, instance } = useMsal();

  const account = accounts[0];

  const tokenRequest = {
    scopes: [isGraphRequest ? presenceScope : msalScopes],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    account: account!,
    forceRefresh: false,
  };

  const getAccessToken = async () => {
    try {
      const tokenResponse = instance.acquireTokenSilent(tokenRequest);

      return (await tokenResponse).accessToken;
    } catch (error) {
      instance.logoutRedirect();

      return error as unknown as string;
    }
  };

  return { getAccessToken };
};
