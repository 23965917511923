import { LocalizationString } from '@celito.clients/assets';
import {
  NavigationApplicationItem,
  NavigationConfig,
  NavigationConfigItem,
  NavigationLink,
  SecondaryDropdownConfig,
} from '@celito.clients/shared';
import { HeaderConfigManager } from '@celito.clients/singleton';

import pngIconNameMap from '../config/icon-map';
import {
  CelitoApplicationDtoList,
  CelitoModule,
  HeaderConfig,
  Tab,
} from '../types/header-config';
import { getUrlForModule, getUrlForSubTab, getUrlForTab } from './url.util';

export const transformHeaderConfig = (
  headerConfigFromApi: HeaderConfig
): NavigationConfig => {
  const transformedHeaderConfig = headerConfigFromApi?.celitoApplicationDtoList
    ?.map((application) => transformApplication(application))
    .filter((application) => application.modules.length > 0);
  const defaultApplicationLink =
    transformedHeaderConfig?.[0]?.modules?.[0]?.link;
  HeaderConfigManager.setTransformedHeaderConfig(transformedHeaderConfig);
  HeaderConfigManager.setDefaultApplicationLink(defaultApplicationLink);

  return transformedHeaderConfig;
};

// Keep this function for future reference
//
// const filterTabsBasedOnUserPermission = (
//   applicationList: CelitoApplicationDtoList,
//   tabs: string[]
// ) => {
//   return applicationList.celitoModules?.map((module: CelitoModule) => {
//     const filteredTabs = module?.celitoTabs?.filter((tab: Tab) =>
//       checkTabs(tab?.name, tabs)
//     );
//     filteredTabs.filter((tab: Tab) => {
//       if (tab?.childTabs) {
//         tab.childTabs = tab?.childTabs?.filter((childTab: Tab) =>
//           checkTabs(childTab?.name, tabs)
//         );
//       }
//     });
//     return (module.celitoTabs = filteredTabs);
//   });
// };

const transformApplication = (
  application: CelitoApplicationDtoList
): NavigationApplicationItem => {
  return {
    name: application.label,
    modules: application?.celitoModules
      ?.map(transformModule)
      .filter((module) => module.navigationLinks.length > 0),
  };
};

const transformModule = (module: CelitoModule): NavigationConfigItem => {
  const requestDropdownTabs: Tab[] = module.celitoTabs?.filter(
    (tab) => tab.isPartOfRequestMenu
  );

  const navigationLiks = sortTabs(module?.celitoTabs).map((tab) =>
    transformTab(tab, module)
  );

  return {
    systemName: module.name,
    name: module.label,
    link: getUrlForModule(module),
    description: module.description,
    // spread the array to create a copy since the sort function mutates the array
    navigationLinks: navigationLiks.filter((link) => !link.isModuleSettings),
    settingLinks:
      navigationLiks.find((link) => link.isModuleSettings) ??
      ({} as NavigationLink), // Getting the tabs with isModuleSettings true to set up in the settings icon in the right menu
    secondaryDropdown: getSecondaryDropdown(requestDropdownTabs, module),
  };
};

const transformTab = (tab: Tab, module: CelitoModule): NavigationLink => {
  return {
    isModuleSettings: tab.isModuleSettings,
    systemName: tab.name,
    name: tab.label,
    pageName: tab.title,
    link: getUrlForTab(module, tab),
    subLinks: sortTabs(tab?.childTabs).map((childTab) =>
      transformSubTab(childTab, tab, module)
    ),
    isHidden: tab.isPartOfRequestMenu,
  };
};

const transformSubTab = (
  childTab: Tab,
  tab: Tab,
  module: CelitoModule
): NavigationLink => {
  return {
    systemName: childTab.name,
    name: childTab.label,
    pageName: childTab.title,
    link: getUrlForSubTab(module, tab, childTab),
  };
};

const sortTabs = (tabs?: Tab[]) => {
  if (!tabs) {
    return [];
  }

  // create a copy to avoid mutation
  return [...tabs].sort((a, b) => a.displayOrder - b.displayOrder);
};

const getSecondaryDropdown = (
  requestDropdownTabs: Tab[],
  parentCelitoModule: CelitoModule
): SecondaryDropdownConfig | undefined => {
  if (requestDropdownTabs.length) {
    return {
      name: LocalizationString.INITIATE,
      data: requestDropdownTabs
        .map((tab) => ({
          name: tab.label,
          route: getUrlForTab(parentCelitoModule, tab),
          descripton: tab.description ?? '',
          iconSrc: pngIconNameMap[tab?.iconName ?? ''],
          displayOrder: tab.displayOrder,
        }))
        .sort((a, b) => a.displayOrder - b.displayOrder),
    };
  }

  return undefined;
};
