import { raiseErrorToast, successToast } from '@celito.clients/utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { createWorkflowAPI, getObjectName } from '../../services';
import {
  CreateWorkflowControllerProps,
  ObjectNameOptions,
} from './create-workflow-modal.model';
import { CreateWorklowModalView } from './create-workflow-modal.view';

const CreateWorkflowController = (props: CreateWorkflowControllerProps) => {
  const [objectNameOptions, setObjectNameOptions] = useState<
    ObjectNameOptions[]
  >([]);

  const methods = useForm();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onCreateWorkflowSubmit = async (data: any) => {
    setIsLoading(true);
    let payload: {
      label: string;
      description: string;
      objectName: string;
      executionType: string;
      executionSchedule?: string;
      executionEvent?: string;
    } = {
      label: data.label,
      description: data.description,
      objectName: data.objectName,
      executionType: data.executionType,
    };
    if (data.executionType === 'SCHEDULED') {
      payload = { ...payload, executionSchedule: '* * * * * *' };
    } else if (data.executionType === 'OBJECT-EVENT') {
      payload = { ...payload, executionEvent: data.lifeCycleEvent };
    }
    await createWorkflowAPI(payload)
      .then((res: any) => {
        successToast({ message: 'Successfully created workflow' });
        setIsLoading(false);
        navigate(
          `../custom/workflows/node_editor/${res.data.name}/${res.data.version}`
        );
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  };
  useEffect(() => {
    getObjectName()
      .then((res: any) => {
        const tempList: any = [];
        res?.data?.forEach((option: any) => {
          tempList.push({
            text: option.label,
            value: option.name,
            isVersioningEnabled: option.isVersioningEnabled,
          });
        });
        setObjectNameOptions(tempList);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  }, []);

  return (
    <CreateWorklowModalView
      control={control}
      handleSubmit={handleSubmit}
      reset={reset}
      errors={errors}
      objectNameOptions={objectNameOptions}
      onCreateWorkflowSubmit={onCreateWorkflowSubmit}
      methods={methods}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default CreateWorkflowController;
