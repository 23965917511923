/* eslint-disable no-console */
import { get, isString, upperCase } from 'lodash';

import {
  LogLevel,
  logLevels,
  TAttributes,
  TMessage,
  Transport,
  User,
} from '../types';

export class ConsoleTransport implements Transport {
  console: Console;
  constructor(customConsole?: Console) {
    this.console = customConsole ?? console;
  }
  handle(message: TMessage, level: LogLevel, attributes?: TAttributes): void {
    const method = level === LogLevel.ERROR ? 'error' : 'log';

    this.console.group(
      `[${upperCase(logLevels[level])}] ${new Date().toISOString()}`
    );

    this.console[method](message);
    if (attributes) this.console[method](attributes);

    this.console.groupEnd();
  }

  identity(user: User) {
    const resolveNonStringValues = (value: unknown) =>
      Array.isArray(value)
        ? value.map((item) => item.name)
        : get(value, 'name');

    this.console.group(`[USER] ${new Date().toISOString()}`);

    if (user)
      Object.entries(user).forEach(([key, value]) => {
        if (value) {
          const val = isString(value) ? value : resolveNonStringValues(value);
          if (val) this.console.log(`${key}: ${val}`);
        }
      });
    this.console.groupEnd();
  }
}
