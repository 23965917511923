import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledNumberRangePickerController } from './controlled-numberrange-picker.controller';
import { ControlledNumberRangeProps } from './controlled-numberrange-picker.model';

const ControlledTextField = <T extends FieldValues>(
  props: ControlledNumberRangeProps<T>
) => {
  return <ControlledNumberRangePickerController {...props} />;
};

export default ControlledTextField;
