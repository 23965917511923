import { ButtonTypes } from '@celito.clients/enums';
import { useTheme } from '@celito.clients/theme';
import { createTestAttribute } from '@celito.clients/utils';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components';

import { CustomButton } from '../shared';
import { DialogViewProps } from './custom.dialog.model';
import { createModalStyles } from './custom.dialog.style';

const CustomDialogView = (props: DialogViewProps): JSX.Element => {
  const styles = createModalStyles();
  const { cssVariables } = useTheme();

  return (
    <Dialog open={props.open}>
      {props.showDialogTrigger ? (
        <DialogTrigger disableButtonEnhancement>
          <CustomButton
            buttonTitle={props.buttonTitle}
            buttonType={ButtonTypes.Primary}
            leftIcon={props.icon}
          />
        </DialogTrigger>
      ) : (
        <></>
      )}
      <DialogSurface
        className={styles.container}
        style={{ padding: 0, ...cssVariables }}
      >
        <DialogBody>
          <DialogTitle
            data-testid={`popup-heading-${createTestAttribute(
              props.headerTitle
            )}`}
            className={styles.modalHeader}
          >
            {props.headerTitle}
          </DialogTitle>
          <DialogContent className={styles.modalBody}>
            {props.children}
          </DialogContent>
          {props.showFooter ? (
            <DialogActions className={styles.modalFooter}>
              <DialogTrigger disableButtonEnhancement>
                <CustomButton
                  buttonTitle={props.cancelButtonTitle}
                  buttonType={ButtonTypes.Secondary}
                  onClick={props.cancel}
                />
              </DialogTrigger>
              <CustomButton
                buttonTitle={props.confirmButtonTitle}
                buttonType={ButtonTypes.Primary}
                onClick={props.confirm}
                isLoading={props?.isLoading}
              />
            </DialogActions>
          ) : (
            <></>
          )}
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
export default CustomDialogView;
