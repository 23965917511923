import { FormActionButtonsContainerProps } from './form-action-buttons-container.model';
import FormActionButtonsContainerView from './form-action-buttons-container.view';

interface FormActionButtonsContainerControllerProps
  extends FormActionButtonsContainerProps {}

const FormActionButtonsContainerController = (
  props: FormActionButtonsContainerControllerProps
): JSX.Element => {
  return <FormActionButtonsContainerView {...props} />;
};

export default FormActionButtonsContainerController;
