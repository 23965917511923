import { Icon } from '@celito.clients/shared';
import { useNavigate } from 'react-router';

import classes from './header.module.css';

interface HeaderProps {
  title: string;
  onBack?: () => void;
}

export const Header = ({ title, onBack }: HeaderProps): JSX.Element => {
  const navigate = useNavigate();

  const onBackClick = () => {
    if (onBack) {
      onBack();
      return;
    }
    navigate(-1);
  };

  return (
    <div className={classes.headingContainer}>
      <button className={classes.backBtn} onClick={onBackClick}>
        <Icon iconName="ArrowLeft16Regular" />
      </button>
      <h5 className={classes.heading}>{title}</h5>
    </div>
  );
};
