import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const ChoicesStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    ...shorthands.gap('1rem'),
    width: '100%',
  },

  choicesContainer: {
    display: 'flex',
    width: '100%',
  },

  choicesInnerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    ...shorthands.gap('8px'),
    width: '100%',
  },

  btnAddChoice: {
    width: '10rem',
    fontSize: '14px',
    color: Color.blue_700,

    ':hover': {
      ...shorthands.textDecoration('underline'),
      cursor: 'pointer',
    },
  },

  deleteIcon: {
    color: Color.DELETE_ICON_COLOR,
  },

  choicesAction: {
    display: 'flex',
    height: '32px',
    ...shorthands.gap('8px'),
  },

  textFieldContainer: {
    display: 'flex',
    ...shorthands.gap('8px'),
    alignItems: 'center',
  },

  draggableIcon: {
    cursor: 'grab',
    ...shorthands.padding('3px'),
  },

  draggableIconContainer: {
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Color.gray_1700,
  },

  draggableContainer: {
    display: 'flex',
    ...shorthands.flex(1),
    ...shorthands.gap('8px'),
    alignItems: 'center',
  },

  booleanContainer: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.gap('8px'),
    ...shorthands.flex(1),
  },

  customErrorLabel: {
    color: 'var(--colorPaletteRedForeground1)',
  },

  addChoiceContainer: { display: 'flex', justifyContent: 'space-between' },
});
