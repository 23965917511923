import {
  getRedirectionBaseUrl,
  getSmartGroupViewUrl,
} from '@celito.clients/utils';
import { Link } from '@fluentui/react-components';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { Fragment } from 'react';

import { UserAssignmentType } from '../../enums/enums';
import classes from '../../training-matrix.module.css';
interface UserAssignmentCellProps {
  item: RowItem;
  index: number | undefined;
  onUserView: (name: string) => void;
}

export const UserAssignmentCell = (props: UserAssignmentCellProps) => {
  const getUrl = (user: RowItem) => {
    return `${getRedirectionBaseUrl()}${getSmartGroupViewUrl(
      user.smartGroupName
    )}`;
  };

  return (
    <div className={classes.cellWrapping}>
      {props.item.userAssignment &&
        props.item.userAssignment.map((user: any, index: any) => {
          return user.type === UserAssignmentType.SMART_GROUP ? (
            <Fragment>
              <Link
                href={getUrl(user)}
                target="_blank"
                className={classes.hyperlink}
              >
                {user.userAssignment}
              </Link>
              {index !== props.item.userAssignment.length - 1 && ', '}
            </Fragment>
          ) : (
            <div
              onClick={() => props.onUserView(user.tranieeName)}
              className={classes.hyperlink}
            >
              {user.userAssignment}
              {index !== props.item.userAssignment.length - 1 && ', '}
            </div>
          );
        })}
    </div>
  );
};
