export enum QuizObjectAttributeNameEnum {
  PASSING_SCORE = 'passing_score__a',
  SHUFFLE_QUESTIONS = 'is_shuffled__a',
  QUESTIONS_TO_BE_DISPLAYED = 'questions_to_be_displayed__a',
  MAX_RETAKE_BEFORE_REVIEW = 'max_retake_before_review__a',
  DESCRIPTION = 'description__a',
  QUESTIONS = 'questions__a',
  TITLE = 'title__a',
  VERSION = 'version__s',
  isSubmitted = 'is_submitted__a',
}

export enum QuizObjectKeyEnum {
  PASSING_SCORE = 'passingScore',
  SHUFFLE_QUESTIONS = 'isShuffled',
  QUESTIONS_TO_BE_DISPLAYED = 'questionsToBeDisplayed',
  MAX_RETAKE_BEFORE_REVIEW = 'maxRetakeBeforeReview',
  DESCRIPTION = 'description',
  QUESTIONS = 'questions',
  TITLE = 'title',
  VERSION = 'version',
  isSubmitted = 'isSubmitted',
}

export enum QuizObjectColumnNameEnum {
  PASSING_SCORE = 'passingScore',
  SHUFFLE_QUESTIONS = 'isShuffled',
  QUESTIONS_TO_BE_DISPLAYED = 'questionsToBeDisplayed',
  MAX_RETAKE_BEFORE_REVIEW = 'maxRetakeBeforeReview',
  DESCRIPTION = 'description',
  QUESTIONS = 'questions',
  TITLE = 'title',
  VERSION = 'version',
  isSubmitted = 'isSubmitted',
}
