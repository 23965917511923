import { Skeleton, SkeletonItem } from '@fluentui/react-components';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';

import { RowItem } from '../../types';
import getColumnDefs from '../../utils/column-defs/getColumnDefs';
import GridViewTableUI from '../grid-view-table/grid-view-table.ui';
import { GridViewLoadingProps } from './grid-view-loading.model';

export const GridViewLoading = ({
  className,
  columns,
  disableSelection,
  dataTestId,
  disableDefaultSorting,
  hasGroups = false,
  rowCount = 10,
}: GridViewLoadingProps) => {
  const tableData: RowItem[] = React.useMemo(
    () => Array(rowCount).fill({}),
    [rowCount]
  );

  const tableColumns: ColumnDef<RowItem>[] = React.useMemo(
    () =>
      getColumnDefs(columns, disableSelection, false, hasGroups).map(
        (column) => ({
          ...column,
          cell: () => {
            return (
              <Skeleton>
                <SkeletonItem size={8} />
              </Skeleton>
            );
          },
        })
      ),
    [columns, disableSelection, hasGroups]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <GridViewTableUI
      dataTestId={dataTestId}
      className={className}
      table={table}
      disableDefaultSorting={disableDefaultSorting}
      expandedGroups={[]}
      expandGroupHandler={() => null}
      expandAllGroups={() => null}
      isLoading
    />
  );
};
