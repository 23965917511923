import { FilterChipsProps } from '../search-result.model';
import classes from '../search-result.module.css';

const FilterChips: React.FC<FilterChipsProps> = (props) => {
  const { selectedFacets, onFilterDismissHandler } = props;

  return (
    <div>
      {selectedFacets?.flatMap((facets, indexOfFacetObject) =>
        facets.values.map((facetValue) => (
          <div className={classes.tag}>
            {facetValue}
            <span
              className={classes.close}
              onClick={() =>
                onFilterDismissHandler(facetValue, indexOfFacetObject)
              }
            >
              X
            </span>
          </div>
        ))
      )}
    </div>
  );
};

export default FilterChips;
