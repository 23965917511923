import { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';

import { CustomRouterProps } from './custom-router.model';

interface CustomRouterViewProps extends CustomRouterProps {}

const CustomRouterView = ({
  basename,
  children,
  history,
}: CustomRouterViewProps): JSX.Element => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      basename={basename}
      location={state.location}
      navigator={history}
      navigationType={state.action}
    >
      {children}
    </Router>
  );
};

export default CustomRouterView;
