import { SubGridData } from '../../types';
import classes from './custom-table.styles.module.css';

interface CustomTableProps {
  dataTestId?: string;
  subGridData: SubGridData;
}

const CustomTable = ({
  dataTestId,
  subGridData,
}: CustomTableProps): JSX.Element => {
  const getTableCellMaxWidth = () => {
    if (subGridData.subGridColumnData.length <= 3) {
      return '50vw';
    }
    if (subGridData.subGridColumnData.length <= 5) {
      return '40vw';
    }
    return '30vw';
  };

  return (
    <div
      className={classes.container}
      data-testid={`sub-grid-view-with-header-row`}
    >
      <table className={classes.table}>
        <thead>
          <tr data-testid="sub-grid-header-row">
            {subGridData.subGridColumnData.map((columnData) => (
              <th key={columnData.key} data-testid="sub-grid-header-cell">
                {columnData.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {subGridData.subGridItemData.map((itemData) => (
            <tr key={itemData.key} data-testid={`sub-grid-body-row`}>
              {itemData.onRender
                ? itemData.onRender(itemData)
                : Object.keys(itemData)
                    .filter((key) => key !== 'key')
                    .map((key, index) => (
                      <td
                        key={index}
                        style={{ maxWidth: getTableCellMaxWidth() }}
                        data-testid={`sub-grid-body-cell`}
                      >
                        {itemData[key as keyof typeof itemData] as string}
                      </td>
                    ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
