export interface APIError {
  path: string;
  statusCode: number;
  method: string;
  timeStamp: string;
  errorMessage: string | string[];
  errorTitle: string;
}

export const getApiErrorMessageString = (error: APIError): string => {
  const { errorMessage: msg } = error;

  if (typeof msg === 'string') return msg;
  if (Array.isArray(msg)) return 'Unhandled array type error message in error.';

  return `Application responded with an error, please try again...`;
};
