import { LocalizationString } from '@celito.clients/assets';
import { makeStyles, shorthands } from '@fluentui/react-components';
import cn from 'classnames';
import { Color } from 'libs/theme/src/lib/colors';
import React from 'react';
import type { FieldError, FieldValues } from 'react-hook-form';

import classes from '../file-upload/file-upload.module.css';
import { Icon } from '../shared';
import { ShowFilesPreview } from './components';
import {
  FileDataProps,
  MultiFileUploadButtonProps,
} from './multi-file-upload.model';

interface MultiFileUploadButtonViewProps<T extends FieldValues>
  extends MultiFileUploadButtonProps<T> {
  fileNames: (File | FileDataProps)[];
  handleFilesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDrop: (event: React.DragEvent<HTMLButtonElement>) => void;
  handleButtonClick: () => void;
  isDragging: boolean;
  handleDragEnter: (event: React.DragEvent<HTMLButtonElement>) => void;
  handleDragLeave: (event: React.DragEvent<HTMLButtonElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLButtonElement>) => void;
  removeFile: (fileName: string, key: number) => () => void;
  allowedFileExtensions: any;
  disabled?: boolean;
}

const uploadStyles = makeStyles({
  errorMessage: {
    color: Color.error_color,
    ...shorthands.marginBlock('4px'),
    display: 'inline-flex',
    ...shorthands.gap('4px'),
    alignItems: 'center',
    maxWidth: '100%',
  },
});

const MultiFileInputButton = <T extends FieldValues>(
  {
    fileNames,
    handleFilesChange,
    handleButtonClick,
    handleDrop,
    isDragging,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    attribute,
    error,
    removeFile,
    allowedFileExtensions = [],
    mode,
    objectName,
    documentIds,
    uploadedBy,
    disabled = false,
  }: MultiFileUploadButtonViewProps<T>,
  fileInputRef: React.ForwardedRef<HTMLInputElement>
) => {
  const onBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handleButtonClick();
  };

  const styles = uploadStyles();

  return (
    <>
      <input
        type="file"
        className={classes.input_file_upload}
        ref={fileInputRef}
        id="input-multi-file-upload"
        onChange={handleFilesChange}
        accept={allowedFileExtensions}
        multiple
        disabled={disabled}
        data-testid={`multi-file-upload`}
      />
      <label
        className={cn(classes.container, {
          [classes.disabled]: disabled,
        })}
        id="label-multi-file-upload"
        htmlFor="input-multi-file-upload"
      >
        {' '}
        <button
          className={cn(classes.button, {
            [classes.error]: error?.message,
            [classes.active_drag]: isDragging,
            [classes.inactive_drag]: !isDragging,
            [classes.cursor_not_allowed]: disabled,
          })}
          onClick={onBtnClick}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          type="button"
        >
          <Icon iconName="ArrowUpload24Regular" className={classes.icon} />
          <div>
            {isDragging ? (
              LocalizationString.DROP_FILES_HERE
            ) : (
              <div className={classes.label}>
                <p>
                  <span className={classes.blue_text}>Choose files</span> or{' '}
                  <span>drag here</span> to upload
                </p>
                {allowedFileExtensions !== '*' ? (
                  <p>
                    {`Supports: ${(allowedFileExtensions as string)
                      .split(',')
                      .map((ext) => {
                        return ext.slice(1).toUpperCase();
                      })
                      .join(', ')}`}
                  </p>
                ) : null}
              </div>
            )}
          </div>
        </button>
      </label>
      {error?.message ? (
        <span className={styles.errorMessage}>
          <Icon iconName="ErrorCircle12Filled" color="currentColor" />
          {attribute?.errorParser
            ? (attribute.errorParser(error) as FieldError).message
            : error?.message}
        </span>
      ) : null}
      {fileNames?.length > 0 && (
        <div className={classes.mt1}>
          <ShowFilesPreview
            documentIds={documentIds}
            objectName={objectName}
            fileNames={fileNames}
            removeFile={removeFile}
            mode={mode}
            uploadedBy={uploadedBy}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
};

export default React.forwardRef(MultiFileInputButton) as <
  T extends FieldValues
>(
  props: MultiFileUploadButtonViewProps<T> & {
    ref?: React.ForwardedRef<HTMLUListElement>;
  }
) => ReturnType<typeof MultiFileInputButton>;
