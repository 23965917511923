import { ObjectAttributeDefinition } from '@celito.clients/types';
import { get, isEmpty, isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { CustomStyles } from '../utils/custom-styles';
import { DelegateIcon } from '../utils/transform-data.util';

export const TaskNameCell = ({
  item,
  attribute,
}: {
  item: any;
  attribute: ObjectAttributeDefinition;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const styles = CustomStyles();
  const navigate = useNavigate();

  const showIcon =
    !isEmpty(get(item, 'delegates', [])) && isEqual(attribute.name, 'label__s');

  useEffect(() => {
    const onClick = () => {
      const url = get(item, 'onClickNavigateRoute', null);
      if (url) navigate(url);
    };
    const container = containerRef.current;
    if (container) {
      container.addEventListener('click', onClick);
    }
    return () => container?.removeEventListener('click', onClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  return (
    <div ref={containerRef} className={styles.delegateContainer}>
      {showIcon && (
        <DelegateIcon
          delegates={item.delegates}
          owner={item.owner}
          label={item.label as string}
        />
      )}
      {item.label}
    </div>
  );
};
