import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes, TrainingAssignmentTypeEnum } from '@celito.clients/enums';
import {
  ExportButton,
  ExportItem,
  FilterableColumn,
  FilterPanel,
  useExportBtnStyles,
} from '@celito.clients/list-view-engine';
import { useTheme } from '@celito.clients/theme';
import { ObjectAttributeType } from '@celito.clients/types';
import {
  MenuButton,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
} from '@fluentui/react-components';
import ReactPaginate from 'react-paginate';

import {
  getViewSelectorMenu,
  viewSeletorDropdownOptions,
} from '../../../../../../apps/web-client/src/screens/training-matrix/utils';
import { GridView, GridViewProps } from '../../grid-view-new/src';
import { RulesComponentListViewFilter } from '../../rules-component/types/rules-component.types';
import { CustomButton, Icon, Menu } from '../../shared';
import { AssignmentsListProps } from './assignments-list.model';
import classes from './assignments-list.module.css';
import { getAsssignmentName } from './utils';

interface AssignmentsListViewProps extends AssignmentsListProps {
  columnData: GridViewProps['columns'];
  rowData: GridViewProps['items'];
  isLoading: boolean;
  customObjectMetadata: ObjectAttributeType;
  allColumns: FilterableColumn[];
  filters: RulesComponentListViewFilter[];
  applyFilters: (
    newFilters: RulesComponentListViewFilter,
    doApiCall?: boolean
  ) => void;
  onResetFilters: () => void;
  isFilterPanelOpen: boolean;
  toggleFilterPanel: () => void;
  closeFilterPanel: () => void;
  numberOfFilters: number;
  currentPageNumber: number;
  handlePageChange: (page: number) => void;
  totalItems: number;
  itemsPerRow: number;
  exportFile: (exportItemData: ExportItem) => Promise<void>;
  groupNames?: string[];
  resetColumnSizes: boolean;
  setResetColumnSizes: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AssignmentsListView = ({
  dataTestId,
  trainingAssignmentType,
  columnData,
  rowData,
  isLoading,
  customObjectMetadata,
  allColumns,
  filters,
  applyFilters,
  onResetFilters,
  isFilterPanelOpen,
  toggleFilterPanel,
  closeFilterPanel,
  numberOfFilters,
  currentPageNumber,
  handlePageChange,
  itemsPerRow,
  totalItems,
  exportFile,
  isTrainingMatrix = false,
  groupNames,
  onSelectedViewChange,
  setResetColumnSizes,
  resetColumnSizes,
}: AssignmentsListViewProps): JSX.Element => {
  const { cssVariables } = useTheme();
  const exportBtnClasses = useExportBtnStyles();

  const pageCount = Math.ceil(totalItems / itemsPerRow);
  const getPageRange = () => {
    if (currentPageNumber === 1) {
      return itemsPerRow + 1;
    }
    if (pageCount - currentPageNumber <= 5) {
      return itemsPerRow + 1;
    }
    if (itemsPerRow - currentPageNumber <= 3) {
      return itemsPerRow - 1;
    }

    return itemsPerRow;
  };

  return (
    <div data-testid={dataTestId} className={classes.container}>
      <div
        className={mergeClasses(
          classes.header,
          isTrainingMatrix && classes.headerEnd,
          !isTrainingMatrix && classes.headerBetween
        )}
      >
        {!isTrainingMatrix && (
          <h5 className={classes.pageTitle}>
            {getAsssignmentName(trainingAssignmentType)}
          </h5>
        )}

        <div className={classes.gapContainer}>
          {isTrainingMatrix && (
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <MenuButton className={classes.menuBtn}>
                  {viewSeletorDropdownOptions[1].text}
                </MenuButton>
              </MenuTrigger>
              <MenuPopover className={classes.menuList}>
                <MenuList>
                  {getViewSelectorMenu(cssVariables, onSelectedViewChange!)}
                </MenuList>
              </MenuPopover>
            </Menu>
          )}

          {/* *For now hiding filter and export options in case of ILC Training as API doest support filters and export */}
          {trainingAssignmentType !==
          TrainingAssignmentTypeEnum.ILCAssignments ? (
            <ExportButton cssObject={cssVariables} onClick={exportFile} />
          ) : null}

          {trainingAssignmentType !==
          TrainingAssignmentTypeEnum.ILCAssignments ? (
            <CustomButton
              buttonTitle={
                numberOfFilters > 0
                  ? `${numberOfFilters} ${LocalizationString.FILTER}`
                  : LocalizationString.FILTER
              }
              buttonType={ButtonTypes.Primary}
              onClick={toggleFilterPanel}
              leftIcon="Filter"
              customStyles={exportBtnClasses.root}
            />
          ) : null}
        </div>
      </div>
      <FilterPanel
        isFilterPanelOpen={isFilterPanelOpen}
        closeFilterPanel={closeFilterPanel}
        applyFilters={applyFilters}
        filterableColumns={allColumns}
        objectMetadata={customObjectMetadata}
        objectName=""
      />

      <GridView
        columns={columnData}
        items={rowData}
        updateEmptyCells={
          trainingAssignmentType === TrainingAssignmentTypeEnum.MyAssignments ||
          trainingAssignmentType === TrainingAssignmentTypeEnum.ILCAssignments
        }
        disableDefaultSorting
        disableSelection
        groupNames={
          trainingAssignmentType ===
            TrainingAssignmentTypeEnum.CompanyAssignments ||
          trainingAssignmentType ===
            TrainingAssignmentTypeEnum.MyTeamsAssignments
            ? groupNames
            : undefined
        }
        resetColumnSizes={resetColumnSizes}
        setResetColumnSizes={setResetColumnSizes}
      />

      {/* Pagination */}
      {pageCount > 1 &&
      trainingAssignmentType !== TrainingAssignmentTypeEnum.ILCAssignments ? (
        <div className={classes.bottomContainer}>
          <div
            data-testid="pagination-list-view"
            className={classes.pagination}
            style={{ ...cssVariables }}
          >
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <Icon
                  data-testid="icon-pagination-right"
                  iconName="ChevronRight16Regular"
                />
              }
              previousLabel={
                <Icon
                  data-testid="icon-pagination-left"
                  iconName="ChevronLeft16Regular"
                />
              }
              forcePage={currentPageNumber - 1}
              onPageChange={({ selected }) => handlePageChange(selected)}
              pageCount={Math.ceil(totalItems / itemsPerRow)}
              activeClassName={classes.active}
              disabledClassName={classes.disabled}
              marginPagesDisplayed={1}
              pageRangeDisplayed={getPageRange()}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
