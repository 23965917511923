import { makeStyles, shorthands } from '@fluentui/react-components';

export const getStyles = makeStyles({
  formContainer: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: 'repeat(4, auto) 1fr',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'start',
    ...shorthands.gap('16px'),
    ...shorthands.overflow('auto'),
    ...shorthands.padding('1.625rem'),
  },
  gap30: {
    ...shorthands.gap('30px'),
  },
  footer: {
    display: 'flex',
    alignItems: 'end',
    width: '100%',
    alignSelf: 'end',
    justifyContent: 'space-between',
    gridColumnStart: '1',
    gridColumnEnd: '4',
    position: 'sticky',
    bottom: 0,
    left: 0,
    backgroundColor: 'var(--gray-1300)',
    boxShadow: '0px 1px 0px 0px var(--gray-200) inset',
    ...shorthands.padding('1rem', '1.625rem'),
  },
  loader: {
    height: '100%',
    justifyContent: 'center',
  },
});
