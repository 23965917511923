import {
  LocalizationString,
  SaveIcon,
  WarningIcon,
} from '@celito.clients/assets';
import {
  AttributeTypeEnum,
  DocumentSubTypeObjectKeyEnum,
} from '@celito.clients/enums';
import {
  ConfirmDialog,
  InHouseInputSelect,
  Loader,
} from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { FormProvider } from 'react-hook-form';

import { FormMode } from '../../../document-templates/types';
import { ActionsFooter } from '../../components/actions';
import DocumentSubType from '../../components/document-sub-type';
import DocumentType from '../../components/document-type';
import { Field } from '../../components/field';
import { FollowUpFields } from '../../components/follow-up';
import { ActionsSetter } from '../../components/setter';
import { TComponentProps } from '../../types';
import { useController } from './controller';

function CreateDocumentTypePage(props: Readonly<TComponentProps>) {
  const {
    form,
    styles,
    fields,
    handleSubmit,
    navigation: { confirmNavigation, cancelNavigation, showPrompt },
    isSaveModalOpen,
    toggleSaveModalBtnClick,
    templateNames,
    onCancel,
    toggleCancelModalBtnClick,
    isCancelModalOpen,
    docTypeAttributes,
    docSubtypeAttributes,
    isLoading,
  } = useController(props);

  if (isLoading) {
    return <Loader fullPage />;
  }

  return (
    <FormProvider {...form}>
      <form className={styles.formContainer} noValidate>
        <DocumentType attributes={docTypeAttributes} />
        <DocumentSubType attributes={docSubtypeAttributes} />
        {fields.map((field) => {
          const { attribute } = field;
          if (
            !attribute?.dataTypeKeyForFE ||
            attribute?.dataTypeKeyForFE === AttributeTypeEnum.Reference
          )
            return null;

          if (attribute.name === 'templateName')
            return (
              <InHouseInputSelect
                label={docTypeAttributes.documentTemplate?.label}
                helperLabelText={docTypeAttributes.documentTemplate?.helpText}
                required={attribute.isMandatory}
                options={templateNames}
                selectedOptions={{
                  value: form.watch('templateName'),
                  text:
                    templateNames.find(
                      (tmp) => tmp.value === form.watch('templateName')
                    )?.text ?? '',
                }}
                errorMessage={form.formState.errors.templateName?.message}
                onOptionSelect={(_e, data) => {
                  if (data.optionValue) {
                    form.setValue('templateName', data.optionValue, {
                      shouldDirty: true,
                    });
                    form.trigger('templateName');
                  }
                }}
              />
            );

          const attributeDetails =
            docSubtypeAttributes[
              attribute.name as DocumentSubTypeObjectKeyEnum
            ];

          const updatedAttribute = {
            ...attribute,
            label: attributeDetails?.label,
            helpText: attributeDetails?.helpText,
          };
          return <Field key={field.name} attribute={updatedAttribute} />;
        })}
        <FollowUpFields attributes={docTypeAttributes} />
      </form>
      <ActionsSetter actions={[]} />
      <ActionsFooter
        mode={props.mode}
        handleSubmit={handleSubmit}
        onCancel={onCancel}
      />
      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
      <ConfirmDialog
        dataTestId={`save-modal-${createTestAttribute(
          LocalizationString.SAVE_REQUEST
        )}`}
        open={isSaveModalOpen}
        onCancelClicked={toggleSaveModalBtnClick}
        onConfirmClicked={toggleSaveModalBtnClick}
        primaryButtonText={LocalizationString.GREAT}
        title={
          props.mode === FormMode.CREATE
            ? LocalizationString.CREATE_REQUEST
            : LocalizationString.UPDATE_REQUEST
        }
        iconSrc={SaveIcon}
        description={
          props.mode === FormMode.CREATE
            ? LocalizationString.CREATED_DESCRIPTION
            : LocalizationString.UPDATED_DESCRIPTION
        }
      />
      <ConfirmDialog
        open={isCancelModalOpen}
        onCancelClicked={() => toggleCancelModalBtnClick()}
        onConfirmClicked={() => toggleCancelModalBtnClick(true)}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL}
        iconSrc={WarningIcon}
        description={LocalizationString.CANCEL_POPUP_MSG}
      />
    </FormProvider>
  );
}

CreateDocumentTypePage.displayName = 'Create Document Type Page';

export default CreateDocumentTypePage;
