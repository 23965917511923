import {
  AssignmentStatusEnum,
  CompletionTypeEnum,
  TaskStatusEnum,
  TrainingAssignmentTypeEnum,
} from '@celito.clients/enums';
import { useLayout, useQueryParams } from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import { fetchObjectTaskInfo } from '@celito.clients/services';
import { Loader } from '@celito.clients/shared';
import { CourseData, TrainingAssignmentData } from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';

import { courseObjectName, trainingAssignmentObjectName } from './config';
import { DocumentViewType } from './documents-view-page.model';
import { DocumentsViewPageView } from './documents-view-page.view';
import {
  getCourseDetails,
  getTrainingAssignmentData,
  markTrainingMaterialAsCompleted,
  resetQuizAttempts,
} from './services';

export const DocumentViewPageController = () => {
  const { configureLayout } = useLayout();
  const { getSearchParams } = useQueryParams();

  const { user, isBizAdmin } = useContext(UserContext);

  const [documents, setDocuments] = useState<DocumentViewType[]>([]);

  const [currentDocument, setCurrentDocument] =
    useState<DocumentViewType | null>(null);

  const [isLoadingMaterials, setIsLoadingMaterials] = useState(false);
  const [openCompleteModal, setOpenCompleteModal] = useState(false);

  const [trainingAssignmentData, setTrainingAssignmentData] =
    useState<TrainingAssignmentData | null>(null);
  const [trainingAssignmentName, setTrainingAssignmentName] = useState('');
  const [quizName, setQuizName] = useState<string | null>(null);
  const [courseData, setCourseData] = useState<CourseData | null>(null);

  const [trainingAssignmentType, setTrainingAssignmentType] =
    useState<TrainingAssignmentTypeEnum | null>(null);

  const [trainingMaterialName, setTrainingMaterialName] = useState(
    documents[0]?.trainingMaterialName
  );
  const [trainingMaterialVersion, setTrainingMaterialVersion] = useState(
    documents[0]?.trainingMaterialVersion
  );

  const [isCompletedAllDocuments, setIsCompletedAllDocuments] = useState(false);
  const [isHandleDocumentComplete, setIsHandleDocumentComplete] =
    useState(false);
  const [showCompleteButton, setShowCompleteButton] = useState(false);

  const [error, setError] = useState<Error | null>(null);

  const handleSelectCurrentDocument = (updatedDocument: DocumentViewType) => {
    setCurrentDocument(updatedDocument);
  };

  const checkOpenCompleteModalInitially = () => {
    const params = getSearchParams();

    if (params.openCompleteModal) {
      setOpenCompleteModal(true);
    }
  };

  const handleFetchDocuments = async () => {
    try {
      setIsLoadingMaterials(true);
      setTrainingAssignmentName(trainingAssignmentData?.name ?? '');

      configureLayout({
        pageTitle: '',
        headerTitle: trainingAssignmentData?.label,
        breadcrumbTitle: 'Training Material',
        showBackButton: false,
        enablePadding: false,
      });

      const trainingMaterialDocument: DocumentViewType = {
        documentId: courseData?.document?.documentId ?? '',
        label: courseData?.document?.nameWithExtension ?? '',
        isCompleted: trainingAssignmentData?.isDocumentComplete ?? undefined,
        trainingMaterialVersion: courseData?.version ?? '',
        trainingMaterialName: courseData?.name ?? '',
        trainingMaterialTitle: courseData?.title ?? '',
        objectName: courseObjectName,
      };

      setDocuments([trainingMaterialDocument]);
      setTrainingMaterialName(courseData?.name ?? '');
      setTrainingMaterialVersion(courseData?.version ?? '');
      setCurrentDocument(trainingMaterialDocument);
      setIsLoadingMaterials(false);
    } catch (_error) {
      raiseErrorToast(_error);
    }
  };

  const handleCompleteTrainingMaterial = async (
    trainingMaterialName: string,
    trainingMaterialVersion: string
  ) => {
    if (
      !trainingAssignmentName ||
      !trainingMaterialName ||
      !trainingMaterialVersion
    ) {
      return;
    }
    try {
      await markTrainingMaterialAsCompleted(trainingAssignmentName);
    } catch (_error) {
      raiseErrorToast(_error);
    }
  };

  const handleFetchCompletionType = async () => {
    const params = getSearchParams();
    try {
      const trainingAssingmentResponse = await getTrainingAssignmentData(
        params['training_assignment_name']
      );
      const courseDetailsResponse = await getCourseDetails(
        trainingAssingmentResponse
      );

      setTrainingAssignmentData(trainingAssingmentResponse);
      setCourseData(courseDetailsResponse);

      if (user?.name === trainingAssingmentResponse.user.name) {
        setTrainingAssignmentType(TrainingAssignmentTypeEnum.MyAssignments);
        setShowCompleteButton(true);
      } else if (isBizAdmin) {
        setShowCompleteButton(true);
      } else {
        const taskInfo = await fetchObjectTaskInfo(
          trainingAssignmentObjectName,
          trainingAssingmentResponse! && trainingAssingmentResponse?.name,
          trainingAssingmentResponse?.status === AssignmentStatusEnum.Completed
            ? {
                status: TaskStatusEnum.Done,
              }
            : undefined
        );
        const isDelegatedTask = JSON.parse(
          taskInfo.task.delegatedUserNames ?? '[]'
        ).includes(user?.name);
        const isSignoffTask = taskInfo.task.ownerName === user?.name;
        setShowCompleteButton(isSignoffTask || isDelegatedTask);
      }

      const course = trainingAssingmentResponse.course;

      if (course?.completionType === CompletionTypeEnum.Quiz) {
        const quizName = courseDetailsResponse?.quiz?.name || null;
        setQuizName(quizName);
      }
      checkOpenCompleteModalInitially();
    } catch (_error) {
      setError(_error as AxiosError);
    }
  };

  const [
    documentsCompletedInCurrentSession,
    setDocumentsCompletedInCurrentSession,
  ] = useState<string[]>([]);

  const handleDocumentsSelect = (
    document: DocumentViewType,
    documentIndex: number
  ) => {
    handleSelectCurrentDocument(document);
    setTrainingMaterialName(document.trainingMaterialName);
    setTrainingMaterialVersion(document.trainingMaterialVersion);
  };

  const handleDocumentComplete = async () => {
    const params = getSearchParams();

    if (!currentDocument) return;
    if (!isDocumentCompleted(currentDocument)) {
      await handleCompleteTrainingMaterial(
        trainingMaterialName,
        trainingMaterialVersion
      );

      const documentCompleted = [
        ...documentsCompletedInCurrentSession,
        trainingMaterialName,
      ];

      setDocumentsCompletedInCurrentSession(documentCompleted);

      const incompleteDocument = documents.filter(
        (doc) =>
          !doc.isCompleted &&
          !documentCompleted.includes(doc.trainingMaterialName)
      );
      if (incompleteDocument.length === 0) {
        setIsCompletedAllDocuments(true);
      }
    }
    if (quizName && quizName?.length > 0) {
      await resetQuizAttempts(params['training_assignment_name']);
    }
    setIsHandleDocumentComplete(true);
  };

  const isDocumentCompleted = (document: DocumentViewType): boolean => {
    if (document.isCompleted) {
      return true;
    }
    if (
      documentsCompletedInCurrentSession.includes(document.trainingMaterialName)
    ) {
      return true;
    }
    return false;
  };

  const isDocumentActive = (document: DocumentViewType): boolean => {
    return document.documentId === currentDocument?.documentId;
  };

  useEffect(() => {
    configureLayout({
      pageTitle: '',
      breadcrumbTitle: 'Training Material',
      showHeadingLoader: true,
      showBackButton: false,
      enablePadding: false,
    });

    handleFetchCompletionType();
  }, []);

  useEffect(() => {
    handleFetchDocuments();
  }, [trainingAssignmentData]);

  if (isLoadingMaterials) {
    return <Loader />;
  }

  if (!currentDocument) {
    return <p>Training Assingment does not have training Material</p>;
  }

  // This will be caught by the error boundary
  if (error) throw error;

  return (
    <DocumentsViewPageView
      documents={documents}
      currentDocumentId={currentDocument.documentId}
      currentObjectName={currentDocument.objectName}
      handleSelectDocument={handleSelectCurrentDocument}
      trainingAssignmentName={trainingAssignmentName}
      trainingPlanName={courseData?.name ?? ''}
      trainingAssignmentType={trainingAssignmentType}
      trainingAssignmentData={trainingAssignmentData}
      openCompleteModal={openCompleteModal}
      isDocumentCompleted={isDocumentCompleted}
      isDocumentActive={isDocumentActive}
      handleDocumentComplete={handleDocumentComplete}
      trainingMaterialName={trainingMaterialName}
      trainingMaterialVersion={trainingMaterialVersion}
      handleDocumentsSelect={handleDocumentsSelect}
      isCompletedAllDocuments={isCompletedAllDocuments}
      isHandleDocumentComplete={isHandleDocumentComplete}
      showCompleteButton={showCompleteButton}
    />
  );
};
