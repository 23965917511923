import { LocalizationString } from '@celito.clients/assets';
import { TrainingAssignmentTypeEnum } from '@celito.clients/enums';

import { RulesComponentListViewFilter } from '../../../rules-component/types/rules-component.types';

export const getAsssignmentName = (
  trainingAssignmentType: TrainingAssignmentTypeEnum
): string => {
  switch (trainingAssignmentType) {
    case TrainingAssignmentTypeEnum.CompanyAssignments:
      return LocalizationString.COMPANY_ASSIGNMENTS;
    case TrainingAssignmentTypeEnum.MyTeamsAssignments:
      return LocalizationString.MY_TEAMS_ASSIGNMENTS;
    case TrainingAssignmentTypeEnum.ILCAssignments:
      return LocalizationString.ILC_TRAINING_ASSIGNMENT;
    default:
      return LocalizationString.MY_ASSIGNMENTS;
  }
};

export const getFinalFilterList = (
  filters: RulesComponentListViewFilter[],
  defaultFilters: any[]
): RulesComponentListViewFilter[] => {
  return filters;
};
