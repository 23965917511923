import withLayout from 'apps/web-client/src/hoc/with-layout';
import React from 'react';

import DelegationFormController from './delegation-form.controller';
import { DelegationFormProps } from './delegation-form.model';

const DelegationFormScreen: React.FC<DelegationFormProps> = (props) => {
  return <DelegationFormController {...props} />;
};

export default withLayout(DelegationFormScreen);
