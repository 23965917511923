import { makeStyles, shorthands } from '@fluentui/react-components';

export const DateTimePickerStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    ...shorthands.gap('0.8rem'),
    flexWrap: 'wrap',

    '> *': {
      ...shorthands.flex(1),
    },
  },
});
