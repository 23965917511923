import { Stack } from '@celito.clients/shared';
import React from 'react';

import classes from './space-component.module.css';

const SpaceComponent: React.FC = () => {
  return (
    <Stack className={classes.section}>
      <div></div>
    </Stack>
  );
};

export default SpaceComponent;
