import { Environment as ENV } from '@celito.clients/singleton';
import { history } from '@celito.clients/utils';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { isString } from 'lodash';

import { LogLevel, TAttributes, TMessage, Transport, User } from '../types';

export const appInsightsReactPlugin = new ReactPlugin();

export class AppInsightsTransport implements Transport {
  appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: String(
          ENV.getInstance().getParam('APPINSIGHTS_CONNECTION_STRING')
        ),
        instrumentationKey: String(
          ENV.getInstance().getParam('APPINSIGHTS_INSTRUMENTATIONKEY')
        ),

        extensions: [appInsightsReactPlugin],
        extensionConfig: {
          [appInsightsReactPlugin.identifier]: { history },
        },
        enableAutoRouteTracking: true, // temporary to analyze the metrics
      },
    });

    this.appInsights.loadAppInsights();

    this.appInsights.context.application.build = String(
      ENV.getInstance().getParam('SENTRY_RELEASE_NAME')
    );
  }

  handle(message: TMessage, level: LogLevel, attributes?: TAttributes): void {
    if ([LogLevel.DEBUG, LogLevel.INFO].includes(level)) {
      if (!isString(message)) return;

      this.appInsights.trackTrace({
        message,
        properties: {
          attributes,
          level,
        },
      });
    } else {
      message = isString(message) ? new Error(message) : message;
      this.appInsights.trackException({
        exception: message,
        properties: {
          attributes,
          level,
        },
      });
      this.appInsights.flush();
    }
  }

  identity(user: User) {
    this.appInsights.setAuthenticatedUserContext(
      user?.name ?? '',
      user?.email,
      true
    );
  }
}
