import { TypographyProps } from './typography.model';
import TypographyView from './typography.view';

type TypographyControllerProps = TypographyProps;

const TypographyController = (
  props: TypographyControllerProps
): JSX.Element => {
  return <TypographyView {...props} />;
};

export default TypographyController;
