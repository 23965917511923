import { DatePicker, TimePicker } from '@celito.clients/shared';
import {
  TimeSelectionData,
  TimeSelectionEvents,
} from '@fluentui/react-timepicker-compat-preview';

import { DateTimePickerProps } from './date-time-picker.model';
import { DateTimePickerStyles } from './date-time-picker.styles';

interface DateTimePickerViewProps extends DateTimePickerProps {
  onSelectDate: (selectedDate: Date) => void;
  onTimePickerChange: (
    _event: TimeSelectionEvents,
    data: TimeSelectionData
  ) => void;
}

const DateTimePickerView = ({
  dataTestId,
  minDate,
  maxDate,
  datePickerProps,
  timePickerProps,
  value,
  onSelectDate,
  onTimePickerChange,
  label,
  error,
  onChange,
}: DateTimePickerViewProps): JSX.Element => {
  const styles = DateTimePickerStyles();

  return (
    <div className={styles.root} data-testid={dataTestId}>
      <DatePicker
        data-testid={dataTestId}
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onDateChange={onSelectDate}
        error={error}
        {...datePickerProps}
      />
      <TimePicker
        data-testid={dataTestId}
        value={value ?? undefined}
        selectedTimeValue={value ?? undefined}
        dateAnchorValue={value ? new Date(value) : undefined}
        onTimeChange={onTimePickerChange}
        label=""
        {...timePickerProps}
        onChange={onChange}
      />
    </div>
  );
};

export default DateTimePickerView;
