import { useEffect } from 'react';

import { TAction, useDocumentTypeLayoutContext } from '../context';

/**
 *  Context Consumer Component
 *
 *  It renders nothing, it's sole purpose is to set context menu actions
 */
export function ActionsSetter({ actions = [] }: { actions?: Array<TAction> }) {
  const { setContextMenuActions } = useDocumentTypeLayoutContext();
  useEffect(() => {
    setContextMenuActions(actions);
  }, [actions]);
  return null;
}
