import { performRequest } from '@celito.clients/network';
import { useQuery } from 'react-query';

import Endpoints from '../../config/endpoint';
import { ApiMethodType } from '../../enums/api-constants';

interface IUserAdminSettings {
  departmentIsSynced: boolean;
  managerIsSynced: boolean;
}

export function useGetUserAdminSettings() {
  async function fetchUserAdminSettings() {
    const response = await performRequest<IUserAdminSettings>(
      `${Endpoints.ADMIN_USER_SETTINGS}`,
      ApiMethodType.GET,
      null
    );
    return response.data;
  }

  return useQuery(['userAdminSettings'], fetchUserAdminSettings);
}
