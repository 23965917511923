import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes, FormEngineModeTypeEnum } from '@celito.clients/enums';
import {
  CustomButton,
  FormActionButtonsContainer,
  GridView,
  Icon,
  Stack,
} from '@celito.clients/shared';
import { Label } from 'libs/shared/src/lib/label/src';
import { useFormContext } from 'react-hook-form';
import ReactPaginate from 'react-paginate';

import { relatedCourseColumns } from './config';
import { RelatedCourseViewProps } from './related-course.model';
import classes from './related-course.module.css';

export const RelatedCourseView = (props: RelatedCourseViewProps) => {
  const {
    formState: { isDirty },
  } = useFormContext();

  const isSubmitDisabled =
    props.isSubmitting ||
    props.isLoading ||
    props.wizardStepsState.some((step) => step.isValid === false);
  return (
    <>
      <div className={classes.container}>
        <Stack className={classes.innerContainer}>
          <Label size="large">{LocalizationString.RELATED_COURSE}</Label>

          <div className={classes.grid}>
            <GridView
              columns={relatedCourseColumns}
              items={props.relatedCourse}
              disableDefaultSorting={true}
              disableSelection={true}
              enableShimmer={props.isCoursesLoading}
            />
            {props.paginationProps.totalPageCount > 1 && (
              <div className={classes.pagination}>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <Icon
                      data-testid="icon-pagination-right"
                      iconName="ChevronRight16Regular"
                    />
                  }
                  forcePage={props.paginationProps.currentPage - 1}
                  onPageChange={({ selected }) =>
                    props.paginationProps.handlePageUpdate(selected + 1)
                  }
                  previousLabel={
                    <Icon
                      data-testid="icon-pagination-left"
                      iconName="ChevronLeft16Regular"
                    />
                  }
                  pageCount={props.paginationProps.totalPageCount}
                  marginPagesDisplayed={1}
                  activeClassName={classes.active}
                  disabledClassName={classes.disabled}
                />
              </div>
            )}
          </div>
        </Stack>
      </div>

      <FormActionButtonsContainer>
        <CustomButton
          buttonTitle={LocalizationString.CANCEL}
          buttonType={ButtonTypes.Ghost}
          onClick={props.onCancel}
          disabled={props.isLoading || props.isSubmitting}
        />
        <div className={classes.formActionBtns}>
          <CustomButton
            buttonTitle={LocalizationString.PREVIOUS}
            leftIcon="Previous"
            disabled={props.isLoading || props.isSubmitting}
            buttonType={ButtonTypes.Ghost}
            onClick={props.goToPreviousStep}
          />
          <CustomButton
            buttonTitle={LocalizationString.NEXT}
            leftIcon="Next"
            disabled={props.isLoading || props.isSubmitting}
            buttonType={ButtonTypes.Ghost}
            onClick={props.goToNextStep}
          />
          {(props.mode === FormEngineModeTypeEnum.Edit ||
            props.mode === FormEngineModeTypeEnum.Submit) && (
            <CustomButton
              buttonTitle="Save"
              leftIcon="Save"
              buttonType={ButtonTypes.Secondary}
              onClick={() => props.onSave?.(false)}
              disabled={props.isLoading || props.isSubmitting || !isDirty}
              isLoading={props.isLoading}
            />
          )}
          {props.mode === FormEngineModeTypeEnum.Submit &&
            props.step === props.totalSteps - 1 && (
              <CustomButton
                buttonTitle="Submit"
                buttonType={ButtonTypes.Primary}
                onClick={() => props.onSave?.(true)}
                disabled={isSubmitDisabled}
                isLoading={props.isSubmitting}
              />
            )}
        </div>
      </FormActionButtonsContainer>
    </>
  );
};
