import { ApiMethodType, ObjectEnum } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { ObjectAttributeType } from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import { AxiosResponse } from 'axios';
import Endpoints from 'libs/core/src/config/endpoint';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

type TPerformRequestArgsWithoutURL = {
  data: Parameters<typeof performRequest>[2];
  params: Parameters<typeof performRequest>[3];
  config: Parameters<typeof performRequest>[4];
};

export const getObjectDefinition = async <T = unknown>(
  objectName: string,
  recordName = '',
  version = '',
  performRequestArgs?: TPerformRequestArgsWithoutURL
) => {
  const args = {
    ...performRequestArgs,
  };
  const response = (await performRequest(
    `${Endpoints.OBJECT_DEFINITION_URL}/${objectName}?record_name=${recordName}&record_version=${version}`,
    ApiMethodType.GET,
    args.data,
    args.params,
    args.config
  )) as AxiosResponse<T, never>;
  return response;
};

export type TUseObjectDefinitionArgs<T> = {
  objectName: ObjectEnum;
  recordName?: string;
  queryKey?: QueryKey;
  requestArgs?: TPerformRequestArgsWithoutURL;
  queryOptions?: UseQueryOptions<T>;
  version?: string;
};

/**
 * FIXME: Better query Keys and custom query options
 *
 * React hook for fetching object definition from the meta data api
 *
 * Supports passing custom `performRequest` args
 */
export const useObjectDefinition = <T = ObjectAttributeType>({
  objectName,
  recordName,
  version,
  queryKey,
  requestArgs,
  queryOptions,
}: TUseObjectDefinitionArgs<T>) => {
  const defaultQueryKey = ['definition'];

  if (version) {
    defaultQueryKey.unshift(version);
  }

  if (recordName) {
    defaultQueryKey.unshift(recordName);
  }

  defaultQueryKey.unshift(objectName);

  return useQuery(queryKey ?? defaultQueryKey, {
    queryFn: async () => {
      const { data } = await getObjectDefinition<T>(
        objectName,
        recordName,
        version,
        requestArgs
      );
      return data;
    },
    staleTime: 100 * 1000,
    retryDelay: () => 10 * 1000,
    onError: (error) => raiseErrorToast(error),
    ...queryOptions,
  });
};
