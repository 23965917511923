import {
  ApiMethodType,
  DocumentDownloadActionEnum,
  ObjectEnum,
} from '@celito.clients/enums';
import { performFormRequest, performRequest } from '@celito.clients/network';
import {
  DocData,
  ObjectAttributeType,
  TaskCompleteResponse,
} from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';

import { ControlledDocs, DocumentFile } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TODO = any;

const CONTROLLED_DOCS_OBJECT_NAME = 'controlled_document__a';
const objectName = ObjectEnum.CONTROLLED_DOCUMENT;
const attributeName = 'attachments__a';

export const getControlledDocsData = async (
  name: string,
  version?: string | '',
  view_type = 'view'
) => {
  const response = await performRequest<ControlledDocs>(
    version
      ? `${Endpoints.OBJECT_DATA_URL}/${CONTROLLED_DOCS_OBJECT_NAME}/${name}?version=${version}&view_type=${view_type}`
      : `${Endpoints.OBJECT_DATA_URL}/${CONTROLLED_DOCS_OBJECT_NAME}/${name}?view_type=${view_type}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const getViewDto = async () => {
  const response = await performRequest<any>(
    `${Endpoints.VIEW_METADATA_URL}/controlled_document_detail_view__a`,
    ApiMethodType.GET,
    null
  );
  return response.data;
};
export const getDocumentData = async (
  id: string,
  recordName?: string,
  version?: string,
  downloaAction = DocumentDownloadActionEnum.Download
) => {
  const response = await performRequest<TODO>(
    `${Endpoints.DOCUMENT_DATA_URL}/${id}`,
    ApiMethodType.GET,
    null,
    {
      objectName: CONTROLLED_DOCS_OBJECT_NAME,
      recordName: recordName,
      versionNumber: version,
      action: downloaAction,
    }
  );
  return response.data;
};

export const getTaskData = async (name: string) => {
  const response = await performRequest<DocumentFile>(
    `${Endpoints.TASK_OBJECT_URL}/${CONTROLLED_DOCS_OBJECT_NAME}/record/${name}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const getObjectData = async (name: string, version: string) => {
  const response = await performRequest<ObjectAttributeType>(
    `${Endpoints.OBJECT_METADATA_URL}/${CONTROLLED_DOCS_OBJECT_NAME}?record_name=${name}&record_version=${version}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const saveDetailsAPI = async (
  recordName: string,
  payload: object,
  object: string = objectName,
  params?: object
) => {
  const response = await performFormRequest(
    `${Endpoints.OBJECT_DATA_URL}/${object}/${recordName}`,
    ApiMethodType.PUT,
    payload,
    params
  );
  return response.data;
};

export const deleteDocuments = async (
  documentIds: string[],
  recordName: string
) => {
  const response = await performRequest<DocData>(
    Endpoints.DOCUMENT_DATA_URL,
    ApiMethodType.DELETE,
    {
      documentIds,
      objectName,
      attributeName,
      recordName,
    }
  );
  return response.data;
};

export const fetchObjectTaskInfo = async (
  objectName: string,
  recordName: string
) => {
  const response = await performRequest<TaskCompleteResponse>(
    `${Endpoints.TASK_OBJECT_URL}/${objectName}/record/${recordName}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const taskComplete = async (
  taskName: string,
  formData: FormData
): Promise<TODO> => {
  const response = await performRequest<TODO>(
    `${Endpoints.TASK_URL}/${taskName}/complete`,
    ApiMethodType.PUT,
    formData,
    undefined,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data;
};

export const getCollaboratorTaskList = async (recordName: string) => {
  const response = await performRequest<any>(
    `${Endpoints.TASK_OBJECT_URL}/${objectName}/record/${recordName}/checkExists?tag=cd_create_collaboration`,
    ApiMethodType.GET
  );

  return response.data;
};

export const claimTask = async (taskName: string) => {
  const response = await performRequest<TaskCompleteResponse>(
    `${Endpoints.TASK_URL}/${taskName}/claim`,
    ApiMethodType.PUT,
    {}
  );
  return response.data;
};

export const getTaskDetails = async (payload: any = {}) => {
  //FIXME: remove limit
  const limit = 1000000;
  const response = await performRequest<any>(
    `${Endpoints.OBJECT_DATA_URL}/task__s?limit=${limit}&page=1&skipRLS=true`,
    ApiMethodType.POST,
    payload
  );
  return response.data;
};

export const getDocumentBasedOnType = async (
  docId: string,
  objectName: string,
  recordName?: string,
  downloaAction = DocumentDownloadActionEnum.Download
) => {
  const response = await performRequest<DocData>(
    `${Endpoints.DOCUMENT_DATA_URL}/${docId}`,
    ApiMethodType.GET,
    null,
    {
      objectName,
      action: downloaAction,
      recordName: recordName,
    }
  );
  return response.data;
};
