import { ObjectEnum, OperatorsEnum } from '@celito.clients/enums';
import { FilterCriteria } from '@celito.clients/types';

import { TrainingPlanType } from '../enums';

export const trainingPlanObjectName = 'training_plan__s';
export const trainingPlanTypeObjectName = 'training_plan_type__s';
export const courseKey = ObjectEnum.COURSE;
export const curriculamKey = ObjectEnum.CURRICULUM;
const ReadyForAssignment = 'ReadyForAssignment';
const STATUS = 'status';
const VERSION = 'version';
const VERSION_ENDS_WITH_VALUE = '.0';

export const defaultCourseReferenceFilter: FilterCriteria[] = [
  {
    attribute: 'lifecycle_stage__s',
    operator: OperatorsEnum.EQUALS,
    value: 'Approved',
  },
  {
    attribute: 'version__s',
    operator: OperatorsEnum.ENDS_WITH,
    value: '.0',
  },
  {
    attribute: 'version__s',
    operator: OperatorsEnum.NOT_EQUALS,
    value: '0.0',
  },
];

export const defaultCurriculumnReferenceFilter: FilterCriteria[] = [
  {
    attribute: trainingPlanTypeObjectName,
    operator: OperatorsEnum.EQUALS,
    value: TrainingPlanType.Curriculum,
  },
  {
    attribute: STATUS,
    operator: OperatorsEnum.EQUALS,
    value: ReadyForAssignment,
  },
  {
    attribute: VERSION,
    operator: OperatorsEnum.ENDS_WITH,
    value: VERSION_ENDS_WITH_VALUE,
  },
];

export const defaultReferencePickerProps = {
  filters: {
    conditions: {
      all: [
        {
          all: [
            {
              attribute: 'isActive',
              operator: OperatorsEnum.EQUALS,
              value: true,
            },
          ],
        },
      ],
    },
  },
};
