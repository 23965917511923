import { SearchBox } from '@fluentui/react-search-preview';

import { Icon } from '../../shared';
import { SearchInputViewsProps } from './search-input.model';

const SearchInputView = (props: SearchInputViewsProps) => {
  return (
    <SearchBox
      size="large"
      data-testid="input-field-search"
      value={props.value ?? ''}
      placeholder="Search"
      onChange={props.handleSearchKeyChange}
      dismiss={<Icon iconName="Dismiss16Regular" onClick={props.onDismiss} />}
    />
  );
};

export default SearchInputView;
