import { LayoutPageTitleEnum } from '@celito.clients/enums';
import { logger } from '@celito.clients/services';
import { ErrorOrUnauthorized, Icon } from '@celito.clients/shared';
import cn from 'classnames';
import React, { PropsWithChildren, ReactNode, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import classes from '../layouts.module.css';

interface PageWrapperProps extends PropsWithChildren {
  children: ReactNode;
  pageTitle?: string;
  showBackButton?: boolean;
  handleBackClick?: () => void;
  pageTitleVariant?: LayoutPageTitleEnum;
}

/**
 *  Wrapper for all the pages in the application
 *  1. has a page level error boundary
 *  2. displays the back button and page title if provided
 */
const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  pageTitle,
  showBackButton,
  handleBackClick,
  pageTitleVariant,
}) => {
  const onError = useCallback((error: Error, info: React.ErrorInfo) => {
    logger.error(error, { origin: 'ErrorBoundary', ...info });
  }, []);
  return (
    <ErrorBoundary onError={onError} FallbackComponent={ErrorOrUnauthorized}>
      {showBackButton || pageTitle ? (
        <div className={classes.titleContainer}>
          {showBackButton && (
            <button
              className={classes.backBtn}
              onClick={handleBackClick}
              data-testid="arrow-back"
            >
              <Icon iconName="ArrowLeft16Regular" />
            </button>
          )}
          {pageTitle && (
            <h5
              data-testid={`tab-heading`}
              className={cn(classes.pageTitle, {
                [classes.pageTitleWithBackBtn]: showBackButton,
                [classes.pageTitleSecondary]:
                  pageTitleVariant === LayoutPageTitleEnum.Secondary,
              })}
            >
              {pageTitle}
            </h5>
          )}
        </div>
      ) : null}
      {children}
    </ErrorBoundary>
  );
};

export default PageWrapper;
