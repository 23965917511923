import { LocalizationString } from '@celito.clients/assets';
import {
  DocumentTemplateObjectKeyEnum,
  ObjectEnum,
} from '@celito.clients/enums';
import {
  useCallbackPrompt,
  useConfigureLayout,
  useObjectAttributeDefinitions,
} from '@celito.clients/hooks';
import { logger } from '@celito.clients/services';
import { infoToast, raiseErrorToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';

import {
  createDocumentTemplate,
  getDocumentTemplate,
  updateDocumentTemplate,
} from '../../../services/document-templates';
import {
  DocumentTemplateScreenProps,
  FormMode,
  FormValues,
  ICopyLabel,
} from '../../types';
import DocumentTemplatesView from './document-templates.view';

const schema = yup.object().shape({
  title: yup.string().required(LocalizationString.REQUIRED_MSG),
  description: yup.string(),
  file__a: yup.mixed().required(LocalizationString.REQUIRED_MSG),
  field: yup.string(),
  fieldType: yup.object().shape({ value: yup.string(), text: yup.string() }),
}) as yup.ObjectSchema<FormValues>;

const DocumentTemplatesController = (props: DocumentTemplateScreenProps) => {
  const { name: recordName } = useParams();
  const navigate = useNavigate();

  const [copyButtonLabel, setCopyButtonLabel] = useState<ICopyLabel[]>([
    { id: 1, label: 'Copy' },
    { id: 2, label: 'Copy' },
  ]);
  const [copiedValues, setCopiedValues] = useState<string[]>([]);
  const [documentTemplateLoading, setDocumentTemplateLoading] =
    useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { data: atributes } =
    useObjectAttributeDefinitions<DocumentTemplateObjectKeyEnum>({
      objectName: ObjectEnum.DOCUMENT_TEMPLATE,
    });

  const form = useForm<FormValues>({
    defaultValues: {
      label: '',
      title: '',
      description: '',
      fieldType: {
        value: ObjectEnum.CONTROLLED_DOCUMENT,
        text: 'Controlled Documents',
      },
      field: '',
      file__a: null,
    },
    resolver: yupResolver(schema),
    mode: 'all',
    shouldUnregister: false,
    reValidateMode: 'onChange',
  });
  const {
    handleSubmit,
    control,
    setValue,

    formState: { isSubmitting, isDirty },
    reset,
    getValues,
  } = form;
  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(isDirty);

  useConfigureLayout({
    pageTitle: '',
    enablePadding: false,
    headerTitle: 'Document Template',
  });

  const onSubmit = async () => {
    await handleSubmit((data) => {
      const formData = new FormData();
      formData.append('title__a', data.title);
      formData.append('description__a', data.description as string);
      formData.append('is_active__s', 'true');
      formData.append(
        'mergeFeildValues',
        JSON.stringify(copiedValues.map((val) => val.slice(2, -1)))
      );
      if (data['file__a'] instanceof File) {
        formData.append('file__a', data.file__a as Blob);
      }

      setIsSaving(true);
      if (props.mode === FormMode.CREATE) {
        createDocumentTemplate(formData)
          .then(() => {
            reset({ ...getValues() });
            toggleSaveModalBtnClick();
          })
          .catch((_error) => {
            raiseErrorToast(_error);
          })
          .finally(() => setIsSaving(false));
      } else {
        updateDocumentTemplate(recordName ?? '', formData)
          .then(() => {
            reset({ ...getValues() });
            toggleSaveModalBtnClick();
          })
          .catch((_error) => {
            setIsSaving(false);
            raiseErrorToast(_error);
          })
          .finally(() => setIsSaving(false));
      }
    })();
  };
  /**
   * Handles actions for the values copied
   */
  const copiedValueAction = useCallback(
    (action: 'copy' | 'remove', idx: number) => {
      switch (action) {
        case 'remove':
          return setCopiedValues((ol) => {
            return ol.filter((_, i) => i !== idx);
          });
        case 'copy':
        default:
          if (navigator.clipboard) {
            navigator.clipboard.writeText(copiedValues[idx]);
            infoToast(
              {
                message: 'Copied to clipboard',
              },
              { toastId: copiedValues[idx] }
            );
          }
      }
    },
    [copiedValues]
  );

  const onCopy = useCallback(
    (value: string, copyLabelId: number) => {
      const updatedButtons = copyButtonLabel.map((button) => {
        if (button.id === copyLabelId) {
          return { ...button, label: 'Copied' };
        }
        return button;
      });

      setCopyButtonLabel(updatedButtons);

      if (navigator.clipboard) {
        navigator.clipboard.writeText(value);
        if (!copiedValues.includes(value))
          setCopiedValues((ol) => [...ol, value]);
      }

      setTimeout(() => {
        const revertedButtons = copyButtonLabel.map((button) => {
          if (button.id === copyLabelId) {
            return { ...button, label: 'Copy' };
          }
          return button;
        });
        setCopyButtonLabel(revertedButtons);
      }, 3000); // Revert after 3 seconds
    },
    [copiedValues, copyButtonLabel]
  );

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isDirty) {
      setIsCancelModalOpen(true);
    } else {
      navigate(-1);
    }
  };

  const toggleCancelModalBtnClick = (navigateBack?: boolean) => {
    setIsCancelModalOpen((prev) => !prev);
    if (navigateBack) {
      reset({ ...getValues() });
      confirmNavigation();
      navigate(-1);
    }
  };

  const toggleSaveModalBtnClick = () => {
    setIsSaveModalOpen(!isSaveModalOpen);

    if (isSaveModalOpen) navigate(-1);
  };

  useEffect(() => {
    if (props.mode !== FormMode.EDIT) {
      return;
    }
    setDocumentTemplateLoading(true);
    getDocumentTemplate(recordName as string)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        reset({
          ...getValues(),
          ...res,
          file__a: res.document,
        });
        try {
          setCopiedValues(
            JSON.parse(res.mergeFeildValues)
              .filter((val: string) => val)
              .map((val: string) => `\${${val}}`)
          );
        } catch (error) {
          logger.error(error as Error);
          setCopiedValues([]);
        }
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => setDocumentTemplateLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...form}>
      <DocumentTemplatesView
        {...{ onSubmit, control, handleSubmit, isSubmitting }}
        setValue={setValue}
        isLoading={documentTemplateLoading}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
        isCancelModalOpen={isCancelModalOpen}
        toggleCancelModalBtnClick={toggleCancelModalBtnClick}
        onCancel={onCancel}
        isSaveModalOpen={isSaveModalOpen}
        toggleSaveModalBtnClick={toggleSaveModalBtnClick}
        isSaving={isSaving}
        isDirty={isDirty}
        mode={props.mode}
        copiedValues={copiedValues}
        copiedValueAction={copiedValueAction}
        onCopy={onCopy}
        attributes={atributes}
      />
    </FormProvider>
  );
};

export default DocumentTemplatesController;
