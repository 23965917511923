import { useEffect, useState } from 'react';

import { ConfirmDialogProps } from './confirm-dialog.model';
import ConfirmDialogView from './confirm-dialog.view';

interface ConfirmDialogControllerProps extends ConfirmDialogProps {}

const ConfirmDialogController = (
  props: ConfirmDialogControllerProps
): JSX.Element => {
  const [disabled, setDisabled] = useState(false);

  const handleOnConfirmClick = (
    e:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
  ) => {
    setDisabled(true);
    props.onConfirmClicked(e);
  };

  useEffect(() => {
    if (props.open) {
      setDisabled(false);
    }
  }, [props.open]);

  return (
    <ConfirmDialogView
      {...props}
      onConfirmClicked={handleOnConfirmClick}
      isPrimaryButtonDisabled={disabled || props.isPrimaryButtonDisabled}
    />
  );
};

export default ConfirmDialogController;
