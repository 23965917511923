import { ObjectAttributeType } from '@celito.clients/types';
import { TAttribute } from 'libs/form-engine/src/lib/components/form/field';

import { FieldOption } from '../e-signature.model';

export const mergeFieldsWithAttributeConfig = (
  fields: FieldOption[],
  attributeConfig: ObjectAttributeType
) => {
  return fields.map((field) => {
    const attribute = attributeConfig?.objectAttributeDefinitions.find(
      (attr) => attr.name === field.columnName
    );
    return {
      ...attribute,
      ...field,
    } as TAttribute;
  });
};
