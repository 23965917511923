import { UrlString } from '@celito.clients/assets';
import {
  ActionTypeEnum,
  ButtonTypes,
  TaskStatusEnum,
} from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import { CustomButton, Icon, Popover } from '@celito.clients/shared';
import {
  createTestAttribute,
  getWizardStatusLabel,
} from '@celito.clients/utils';
import {
  PopoverSurface,
  PopoverTrigger,
  PositioningImperativeRef,
  Skeleton,
  SkeletonItem,
} from '@fluentui/react-components';
import cn from 'classnames';
import { useContext, useEffect, useRef } from 'react';

import activeStateWizard from '../../../../../assets/src/images/active-state-wizard.svg';
import {
  ActionButtonType,
  OwnerOrModifiedBy,
} from '../workflow-history/task/types';
import WorkflowActions from '../workflow-history/workflow-actions/workflow-actions.component';
import { Actions } from '../workflow-history/workflow-actions/workflow-actions.model';
import WorkflowHistory from '../workflow-history/workflow-history.component';
import { WizardStateViewProps } from './wizard-state.model';
import { wizardStateStyles } from './wizard-state.styles';

interface ICircle {
  index: number;
}

const ActiveCircle = ({ index }: ICircle) => {
  const styles = wizardStateStyles();

  return (
    <>
      <img className={styles.activeImage} src={activeStateWizard} alt="" />
      <div data-testid="active-status" className={styles.active_outer_circle}>
        {index + 1}
      </div>
    </>
  );
};

const InactiveCircle = ({ index }: ICircle) => {
  const styles = wizardStateStyles();

  return (
    <div data-testid="inactive-status" className={styles.inactive_outer_circle}>
      {index + 1}
    </div>
  );
};

const SuccessCircle = ({ index }: ICircle) => {
  const styles = wizardStateStyles();

  return (
    <div
      data-testid="success-status-green"
      className={styles.success_outer_circle}
    >
      <span className={styles.mt3}>
        {' '}
        <Icon iconName="Checkmark24Filled" />
      </span>
    </div>
  );
};

export const WizardSkeleton = () => {
  const styles = wizardStateStyles();
  return (
    <Skeleton className={styles.skeleton}>
      <div className={styles.container}>
        <SkeletonItem size={24} shape="circle" />
        <SkeletonItem size={16} />
      </div>
      <div className={styles.container}>
        <SkeletonItem size={24} shape="circle" />
        <SkeletonItem size={16} />
      </div>
      <div className={styles.container}>
        <SkeletonItem size={24} shape="circle" />
        <SkeletonItem size={16} />
      </div>
      <div className={styles.container}>
        <SkeletonItem size={24} shape="circle" />
        <SkeletonItem size={16} />
      </div>
    </Skeleton>
  );
};

// FIXME: This component is dependent on object specific data and should be generic
const WizardState: React.FC<WizardStateViewProps> = ({
  progressTabs,
  wizardState,
  objectName,
  loading,
  recordData,
  open,
  handleOpenChange,
  objectActionDefinitions,
  objectDefinitions,
  recordName,
  taskGroupNames,
  onRefetchRequest,
  task,
}) => {
  const positioningRef = useRef<PositioningImperativeRef>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const styles = wizardStateStyles();
  const loggedInUserData = useContext(UserContext);
  const activeModule = useActiveModule();
  const currentModuleBizRole =
    activeModule?.systemName === UrlString.CD_BASE_URL
      ? 'cd_biz_admin_role__a'
      : 'ul_biz_admin_role__a';
  const popupSurfaceRef = useRef<HTMLDivElement>(null);
  const showWorkflowDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (buttonRef.current) {
      positioningRef.current?.setTarget(buttonRef.current);
    }
  }, [buttonRef, positioningRef]);
  if (loading || !progressTabs.length) return <WizardSkeleton />;
  const currentIndex = progressTabs.findIndex((tab) => {
    return tab === wizardState;
  });

  const checkIfWorkflowActionExist = () => {
    if ((recordData?.recordWorkflowActions as Actions[])?.length) {
      if (loggedInUserData?.roles?.includes(currentModuleBizRole)) {
        return true;
      }
      if (
        (recordData?.owner as OwnerOrModifiedBy)?.name ===
        loggedInUserData?.user?.name
      ) {
        return true;
      }
      if (isDelegatedTask()) {
        return true;
      }
    }
    return false;
  };

  const checkIfDocumentIsInEffectiveState = () => {
    if (recordData?.lifecycleStage === 'Effective') {
      if (recordData?.activeFlowName) {
        return checkIfWorkflowActionExist();
      } else if ((recordData?.recordWorkflowActions as Actions[])?.length) {
        return true;
      }
    }
    return checkIfWorkflowActionExist();
  };
  const isDelegatedTask = () => {
    if (
      task?.ownerName === recordData?.owner?.name &&
      task?.status !== TaskStatusEnum.Done &&
      task?.delegatedUserNames &&
      JSON.parse(task?.delegatedUserNames)?.includes(
        loggedInUserData?.user?.name
      )
    ) {
      return true;
    }
    return false;
  };
  const validateWizadStateHasTask = (tab: string, index: number) => {
    return taskGroupNames.includes(tab) ||
      (recordData?.approvedDate && tab === 'Approved') ||
      !objectDefinitions?.isLifecycleEnabled ? (
      <SuccessCircle index={index} />
    ) : (
      <InactiveCircle index={index} />
    );
  };

  return (
    <div
      className={styles.overflowContainer}
      data-testid="wizard-state-progress-bar"
    >
      <div
        className={cn({
          [styles.workflowHistoryContainer]:
            objectDefinitions?.isLifecycleEnabled,
          [styles.hideButton]: !objectDefinitions?.isLifecycleEnabled,
          [styles.flex90]: !checkIfDocumentIsInEffectiveState(),
        })}
      >
        {progressTabs.map((tab: string, index: number) => {
          return (
            <div
              data-testid={`wizard-state-${createTestAttribute(tab)}`}
              className={styles.container}
              key={index}
            >
              {currentIndex === index ? (
                progressTabs.length - 1 === index ? (
                  <SuccessCircle index={index} />
                ) : (
                  <ActiveCircle index={index} />
                )
              ) : currentIndex > index ? (
                (tab === 'In Review' &&
                  recordData?.isBizAdminApprovalRequired !== undefined &&
                  !recordData?.isBizAdminApprovalRequired) ||
                (tab === 'In Approval' &&
                  recordData?.isApprovalRequired !== undefined &&
                  !recordData?.isApprovalRequired) ? (
                  <InactiveCircle index={index} />
                ) : (
                  validateWizadStateHasTask(tab, index)
                )
              ) : (
                <InactiveCircle index={index} />
              )}
              <div className={styles.state_style}>
                {getWizardStatusLabel(tab)}
              </div>
            </div>
          );
        })}
      </div>
      {objectDefinitions?.isLifecycleEnabled ? (
        <div
          className={cn({
            [styles.workflowHistroyButton]: checkIfDocumentIsInEffectiveState(),
            [styles.flex10]: !checkIfDocumentIsInEffectiveState(),
          })}
        >
          <Popover
            withArrow={true}
            positioning={'below'}
            open={open}
            onOpenChange={handleOpenChange}
          >
            <PopoverTrigger disableButtonEnhancement>
              <div
                className={cn({
                  [styles.triggerButton]: checkIfDocumentIsInEffectiveState(),
                  [styles.flex100]: !checkIfDocumentIsInEffectiveState(),
                })}
                ref={showWorkflowDivRef}
              >
                <CustomButton
                  buttonTitle="Show Workflow"
                  buttonType={ButtonTypes.Primary}
                  rightIcon={open ? 'ArrowUp' : 'ArrowDown'}
                  id="show-worflow-button"
                  customStyles={styles.fullWidth}
                />
              </div>
            </PopoverTrigger>
            <PopoverSurface
              className={styles.popupSurface}
              ref={popupSurfaceRef}
            >
              <WorkflowHistory
                objectName={objectName}
                recordName={recordName}
                progressTabs={progressTabs}
                open={open}
                objectActionDefinitions={objectActionDefinitions}
                objectDefinitions={objectDefinitions}
                recordData={recordData}
                refetchRecordData={onRefetchRequest}
                popupRef={popupSurfaceRef}
                showWorkflowDivRef={showWorkflowDivRef}
              />
            </PopoverSurface>
          </Popover>
          {checkIfDocumentIsInEffectiveState() ? (
            <WorkflowActions
              buttonType={ActionButtonType.BUTTON}
              actionType={ActionTypeEnum.WORKFLOW}
              recordData={recordData}
              objectActionDefinitions={objectActionDefinitions}
              objectDefinitions={objectDefinitions}
              refetchRecordData={onRefetchRequest}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default WizardState;
