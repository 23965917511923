import { Icon, Popover } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { createTestAttribute } from '@celito.clients/utils';
import { PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { PrimaryDropdownProps } from './primary-dropdown.component';
import classes from './primary-dropdown.styles.module.css';
import { primaryDropdownStyles } from './primary-dropdown-styles';

interface PrimaryDropdownViewProps extends PrimaryDropdownProps {}

const PrimaryDropdownView = ({
  navigationConfig,
  PrimaryDropdownButton,
  activeDropdownItem,
  showPrimaryDropdown,
  togglePrimaryDropdown,
  PrimaryDropdownLinksComponent,
  isTabletView,
  isMobileView,
  handleDropdownLinkClick,
}: PrimaryDropdownViewProps): JSX.Element => {
  const styles = primaryDropdownStyles();

  const { cssVariables } = useTheme();

  const handleClick = (item: any) => {
    togglePrimaryDropdown();
    handleDropdownLinkClick(item);
  };

  return (
    <div className={classes.dropdownBtnContainer}>
      <Popover
        onOpenChange={togglePrimaryDropdown}
        open={showPrimaryDropdown}
        trapFocus
        positioning={'below-end'}
      >
        <PopoverTrigger disableButtonEnhancement>
          {PrimaryDropdownButton ? (
            <PrimaryDropdownButton
              activeItem={activeDropdownItem}
              allItems={navigationConfig}
            />
          ) : (
            <button
              className={styles.customMenuDropdownButton}
              data-testid="button-application-selector"
              id="primary-dropdown-trigger"
            >
              {(() => {
                const text = activeDropdownItem?.name ?? 'Select Module';
                return <span title={text}>{text}</span>;
              })()}
              <Icon
                iconName="ChevronDown16Regular"
                className={classes.dropdownBtnIcon}
                data-open={showPrimaryDropdown}
              />
            </button>
          )}
        </PopoverTrigger>
        <PopoverSurface className={styles.popoverSurface}>
          <div
            className={classes.dropdownContainer}
            style={{ ...cssVariables }}
          >
            {navigationConfig.map((navigationConfigItem) => (
              <div
                data-testid={`div-application-${createTestAttribute(
                  navigationConfigItem.name
                )}`}
                key={navigationConfigItem.name}
                className={classes.dropdownItemContainer}
              >
                <h2 className={classes.dropdownApplicationTitle}>
                  {navigationConfigItem.name}
                </h2>
                <div
                  key={navigationConfigItem.name}
                  className={classes.navigationConfigItemContainer}
                >
                  {navigationConfigItem.modules.map((item) => {
                    if (PrimaryDropdownLinksComponent) {
                      return (
                        <div className={classes.dropdownItem} key={item.name}>
                          <PrimaryDropdownLinksComponent
                            activeItem={activeDropdownItem}
                            allItems={navigationConfig}
                            onClick={() => handleDropdownLinkClick(item)}
                          />
                        </div>
                      );
                    }
                    return (
                      <Link
                        key={item.name}
                        to={item.link}
                        onClick={() => handleClick(item)}
                        className={cn(classes.dropdownItem, {
                          [classes.dropdownItemActive]:
                            activeDropdownItem?.name === item.name,
                        })}
                      >
                        <h3
                          data-testid={`div-module-${createTestAttribute(
                            item.name
                          )}`}
                          className={classes.dropdownItemHeading}
                        >
                          {item.name}
                        </h3>
                        <p className={classes.dropdownItemDescription}>
                          {item.description}
                        </p>
                      </Link>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </PopoverSurface>
      </Popover>

      {!isTabletView &&
        (activeDropdownItem?.navigationLinks || []).length > 0 && (
          <div className={classes.verticleDividerLine} />
        )}
    </div>
  );
};

export default PrimaryDropdownView;
