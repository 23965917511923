import { LayoutURLParamsEnum } from '@celito.clients/enums';
import { HeaderConfigManager } from '@celito.clients/singleton';
import { getValueFromParams } from '@celito.clients/utils';
import { useParams } from 'react-router';

export const useActiveModule = () => {
  const params = useParams();
  const moduleName =
    params.moduleName ??
    getValueFromParams(LayoutURLParamsEnum.moduleName, params);
  const HeaderConfig = HeaderConfigManager.getTransformedHeaderConfig();
  const activeModule = HeaderConfig?.[0]?.modules?.find(
    (module) => module?.systemName === moduleName
  );
  return activeModule;
};
