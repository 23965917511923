/* eslint-disable no-case-declarations */
import { LocalizationString } from '@celito.clients/assets';
import { AttributeTypeEnum, OperatorsEnum } from '@celito.clients/enums';
import { ActionRule } from '@celito.clients/types';
import { getCurrentSystemTimeInISOString } from '@celito.clients/utils';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';

import { ObjectMetadata } from '../types';

export const validateActionRules = (
  actionRules: ActionRule[] | null,
  rowItem: RowItem,
  objectMetadata: ObjectMetadata
): boolean => {
  if (!actionRules) {
    return true;
  }

  return actionRules.every((actionRule) =>
    validateActionRule(actionRule, rowItem, objectMetadata)
  );
};

export const validateActionRule = (
  actionRule: ActionRule,
  rowItem: RowItem,
  objectMetadata: ObjectMetadata
): boolean => {
  const column = objectMetadata.objectAttributeDefinitions.find(
    (attr) => attr.name === actionRule.attribute
  );
  const fieldName = column?.columnName;

  if (!fieldName) {
    return false;
  }
  switch (actionRule.operator) {
    case OperatorsEnum.EQUALS:
      switch (column.dataType) {
        case AttributeTypeEnum.YesNo: {
          if (typeof rowItem[fieldName] === 'boolean') {
            return rowItem[fieldName] === actionRule.value;
          }
          const booleanValue1 = rowItem[fieldName] === LocalizationString.YES;
          return booleanValue1 === actionRule.value;
        }
        case AttributeTypeEnum.ActiveInactive: {
          const booleanValue2 =
            rowItem[fieldName] === LocalizationString.ACTIVE;
          return booleanValue2 === actionRule.value;
        }
        default:
          return rowItem[fieldName] === actionRule.value;
      }

    case OperatorsEnum.NOT_EQUALS:
      return rowItem[fieldName] !== actionRule.value;

    case OperatorsEnum.ENDS_WITH:
      return rowItem[fieldName]?.endsWith?.(actionRule.value);
    case OperatorsEnum.NOT_ENDS_WITH:
      return !rowItem[fieldName]?.endsWith?.(actionRule.value);

    case OperatorsEnum.IS_BLANK:
      if (Array.isArray(rowItem[fieldName])) {
        return rowItem[fieldName].length === 0;
      }
      return (
        rowItem[fieldName] === null ||
        rowItem[fieldName] === undefined ||
        rowItem[fieldName] === ''
      );
    case OperatorsEnum.IS_NOT_BLANK:
      if (Array.isArray(rowItem[fieldName])) {
        return rowItem[fieldName].length > 0;
      }
      return (
        rowItem[fieldName] !== null &&
        rowItem[fieldName] !== undefined &&
        rowItem[fieldName] !== ''
      );

    case OperatorsEnum.IN_PAST:
      return rowItem[fieldName] < getCurrentSystemTimeInISOString();
    case OperatorsEnum.IN_FUTURE:
      return rowItem[fieldName] > getCurrentSystemTimeInISOString();

    default:
      return false;
  }
};
