import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledDropdownProps } from './controlled-dropdown.model';
import { ControlledDropDownView } from './controlled-dropdown.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const ControlledDropdownController = <T extends FieldValues>(
  props: ControlledDropdownProps<T>
) => {
  return <ControlledDropDownView {...props} />;
};
