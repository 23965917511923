import { ObjectAttributeType } from '@celito.clients/types';

import { TAttribute } from '../components/form/field';
import { FieldType } from '../types/field-types';

export const mergeFieldsWithAttributeConfig = (
  fields: FieldType[],
  attributeConfig: ObjectAttributeType
) => {
  return fields.map((field) => {
    const attribute = attributeConfig?.objectAttributeDefinitions.find(
      (attr) => attr.name === field.columnName
    );
    return {
      ...attribute,
      ...field,
    } as TAttribute;
  });
};
