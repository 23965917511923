import { Icon, Loader, Stack } from '@celito.clients/shared';
import cn from 'classnames';
import ReactPaginate from 'react-paginate';

import { allApplicationsRegex, resultModuleRegex } from '../consts/constants';
import QuickFilter from '../quick-filter/quick-filter.component';
import { ResultSectionProps } from '../search-result.model';
import classes from '../search-result.module.css';
import SortFacet from '../sort-facet/sort-facet.component';
import { getSortedResults } from '../utils/search-result.util';
import ResultCard from './ResultCard';
import ViewTypeMenu from './ViewTypeMenu';

const ResultSection: React.FC<ResultSectionProps> = (props) => {
  const {
    quickFilters,
    selectedQuickFilter,
    setSelectedQuickFilter,
    selectedViewType,
    setSelectedViewTypeHandler,
    pageLimit,
    resultCount,
    currentPage,
    setCurrentPage,
    searchResult,
    onSearchCardClickHandler,
    selectedSortList,
    onSortUpdationHandler,
    isCardLoading,
  } = props;

  return (
    <Stack className={classes.result_section}>
      <div className={classes.tabs}>
        <QuickFilter
          filters={quickFilters}
          selectedFilter={selectedQuickFilter}
          setSelectedFilterHandler={setSelectedQuickFilter}
        />
        <div className={classes.tabs_group}>
          <ViewTypeMenu
            selectedOption={selectedViewType}
            onChange={setSelectedViewTypeHandler}
          />
          <SortFacet
            selectedSortList={selectedSortList}
            onSortUpdationHandler={onSortUpdationHandler}
          />
        </div>
      </div>
      <div
        className={cn(
          { [classes.main_page_bottom]: true },
          { [classes.center_flex]: isCardLoading }
        )}
      >
        {isCardLoading ? (
          <Loader />
        ) : (
          <div>
            <div
              className={
                selectedViewType.view[0] === 'tile'
                  ? classes.tile_grid
                  : classes.card_grid
              }
            >
              {allApplicationsRegex.test(selectedQuickFilter ?? '')
                ? getSortedResults(searchResult, selectedSortList[0])
                    .slice(
                      currentPage * pageLimit,
                      (currentPage + 1) * pageLimit
                    )
                    ?.flatMap((result) => (
                      <ResultCard
                        result={result}
                        onViewSearchDoc={onSearchCardClickHandler}
                      />
                    ))
                : searchResult
                    .filter(
                      (result) =>
                        result.moduleName ===
                        selectedQuickFilter?.replace(resultModuleRegex, '')
                    )
                    .slice(
                      currentPage * pageLimit,
                      (currentPage + 1) * pageLimit
                    )
                    .map((result) => (
                      <ResultCard
                        result={result}
                        onViewSearchDoc={onSearchCardClickHandler}
                      />
                    ))}
            </div>
            <div className={classes.bottom_container}>
              <div className={classes.pagination}>
                <ReactPaginate
                  forcePage={currentPage}
                  breakLabel="..."
                  nextLabel={<Icon iconName="ChevronRight16Regular" />}
                  previousLabel={<Icon iconName="ChevronLeft16Regular" />}
                  pageCount={Math.ceil(resultCount / pageLimit)}
                  pageRangeDisplayed={pageLimit}
                  activeClassName={classes.active}
                  disabledClassName={classes.disabled}
                  onPageChange={({ selected }) => {
                    setCurrentPage(selected);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Stack>
  );
};

export default ResultSection;
