import HeaderActionButtonsContainerController from './header-action-buttons-container.controller';
import { HeaderActionButtonsContainerProps } from './header-action-buttons-container.model';

const HeaderActionButtonsContainer = (
  props: HeaderActionButtonsContainerProps
) => {
  return <HeaderActionButtonsContainerController {...props} />;
};

export default HeaderActionButtonsContainer;
