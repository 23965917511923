import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { takingAssignmentObjectName } from '../config';
import { AssignmentData } from '../types';

export const updateAssignment = async (
  updateData: Pick<AssignmentData, 'name' | 'isActive'> &
    Partial<AssignmentData>
) => {
  const { name, ...rest } = updateData;
  const response = await performRequest(
    `${Endpoints.OBJECT_DATA_URL}/${takingAssignmentObjectName}/${name}`,
    ApiMethodType.PUT,
    rest
  );
  return response.data;
};
