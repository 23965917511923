import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const curriculumModalStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.borderRadius('6px'),
    boxSizing: 'border-box',
    width: '25rem',
  },

  modalHeader: {
    display: 'flex',
    ...shorthands.padding('0.75rem'),
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    backgroundColor: Color.drawer_header_color,
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 700,
    justifyContent: 'space-between',
  },

  modalBody: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    minHeight: '250px',
    ...shorthands.overflow('hidden'),
    ...shorthands.padding('1.5rem'),
  },

  modalFooter: {
    ...shorthands.gap('1rem'),
    ...shorthands.padding('0.75rem'),
    marginLeft: '-1.6rem',
    width: '24.81rem',
    backgroundColor: Color.drawer_header_color,
    boxShadow: 'inset 0px 1px 0px var(--gray-200)',
    justifyContent: 'center',
  },

  referencePickerSize: {
    width: '100%',
  },

  fieldContainer: {
    display: 'inherit',
  },

  listViewAddBtn: {
    ...shorthands.textDecoration('none'),
  },

  dialogBody: {
    height: '35rem',
  },
});
