import { AttributeTypeEnum, SortOrderEnum } from '@celito.clients/enums';

export const getIsSortedAscending = (
  isSorted: boolean | undefined,
  isSortedAscending: boolean | undefined
): boolean => {
  return !isSorted ? true : !isSortedAscending;
};

export const getSortOrder = (
  isSorted: boolean | undefined,
  isSortedAscending: boolean | undefined,
  lowerCase = false
): SortOrderEnum => {
  let sortOrder: SortOrderEnum;

  sortOrder = getIsSortedAscending(isSorted, isSortedAscending)
    ? SortOrderEnum.ASC
    : SortOrderEnum.DESC;

  if (lowerCase)
    sortOrder =
      sortOrder === SortOrderEnum.DESC
        ? SortOrderEnum.DESC_LOWERCASE
        : SortOrderEnum.ASC_LOWERCASE;

  return sortOrder;
};

export const isReferenceSortSupported = (datatype: AttributeTypeEnum) => {
  if (
    [
      AttributeTypeEnum.Reference,
      AttributeTypeEnum.Record,
      AttributeTypeEnum.ObjectDefinition,
    ].includes(datatype)
  ) {
    return true;
  }
  return false;
};
