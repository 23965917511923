import { ApiMethodType } from '@celito.clients/enums';
import axiosInstance, { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { Task, TaskInfo } from '../e-signature.model';

export const eSignatureService = () => {
  const fetchTaskInfo = async (taskName: string): Promise<TaskInfo> => {
    const response = await axiosInstance.get(
      `${Endpoints.TASK_URL}/${taskName}`
    );
    return response.data.task;
  };

  const updateTask = async (
    taskName: string,
    formData: FormData
  ): Promise<Task> => {
    const response = await performRequest<Task>(
      `${Endpoints.TASK_URL}/${taskName}/complete`,
      ApiMethodType.PUT,
      formData,
      undefined,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  };

  return {
    fetchTaskInfo,
    updateTask,
  };
};
