import {
  LayoutRuleConditionEnum,
  ObjectAttributeType,
  Section,
} from '@celito.clients/types';
import { changeAtIndex, insertAtIndex } from '@celito.clients/utils';

export const documentPropName = 'file__a';
export const replaceHeaderFooterPropName = 'should_replace_header_footer__a';
export const referenceDocumentsPropsName = 'reference_documents__a';
export const reasonForChangesPropName = 'reason_for_changes__a';
export const summaryForChangesPropName = 'summary_for_changes__a';
export const attachmentsPropsName = 'attachments__a';
export const isGxpPropName = 'is_gxp__a';
export const initiationTypePropName = 'initiation_type__a';

export const getSectionData = (
  sections: Section[] = [],
  step: number,
  attributeConfig: ObjectAttributeType,
  recordDetails?: Record<string, unknown>
): Section => {
  const section = sections[step];
  let refactoredSectionFields = [...section.fields];

  const conditionalObjectAttributes =
    attributeConfig.objectAttributeDefinitions.filter((item) => !!item.childs);

  let insertedItems = 0;
  section.fields.forEach((item, index) => {
    const foundAttribute = conditionalObjectAttributes.find(
      (c) => c.name === item.columnName
    );

    if (foundAttribute && foundAttribute.childs?.length) {
      const childAttributeName =
        foundAttribute?.childs?.[0]!.lookupAttributeName;
      const childAttributeColumnName =
        attributeConfig.objectAttributeDefinitions.find(
          (c) => c.name === childAttributeName
        )!.columnName;

      refactoredSectionFields = changeAtIndex(
        refactoredSectionFields,
        insertedItems + index,
        {
          ...item,
          width: 1,
        }
      );

      refactoredSectionFields = insertAtIndex(
        refactoredSectionFields,
        insertedItems + index + 1,
        {
          columnName: recordDetails?.[childAttributeColumnName]
            ? childAttributeName
            : 'SPACE',
          width: 1,
        }
      );

      refactoredSectionFields = insertAtIndex(
        refactoredSectionFields,
        insertedItems + index + 2,
        {
          columnName: 'SPACE',
          width: 1,
        }
      );

      insertedItems = insertedItems + 2;
    }
  });

  return { ...section, fields: refactoredSectionFields };
};

export const getDocumentSectionFieldConfig = () => [
  {
    columnName: documentPropName,
    width: 3,
    row: 1,
    layoutConfiguration: {
      jsonRuleCondition: LayoutRuleConditionEnum.VALUE,
      layoutConfig: {
        value: {
          isEditable: true,
          isRequired: false,
          isVisible: true,
        },
      },
    },
  },
  {
    columnName: replaceHeaderFooterPropName,
    width: 3,
    row: 2,
    layoutConfiguration: {
      jsonRuleCondition: LayoutRuleConditionEnum.VALUE,
      layoutConfig: {
        value: {
          isEditable: true,
          isRequired: true,
          isVisible: true,
        },
      },
    },
  },
  {
    columnName: reasonForChangesPropName,
    width: 1.5,
    row: 3,
    layoutConfiguration: {
      jsonRuleCondition: LayoutRuleConditionEnum.VALUE,
      layoutConfig: {
        value: {
          isEditable: true,
          isRequired: true,
          isVisible: true,
        },
      },
    },
  },
  {
    columnName: summaryForChangesPropName,
    width: 1.5,
    row: 3,
    layoutConfiguration: {
      jsonRuleCondition: LayoutRuleConditionEnum.VALUE,
      layoutConfig: {
        value: {
          isEditable: true,
          isRequired: true,
          isVisible: true,
        },
      },
    },
  },
  {
    columnName: referenceDocumentsPropsName,
    width: 3,
    row: 4,
    layoutConfiguration: {
      jsonRuleCondition: LayoutRuleConditionEnum.VALUE,
      layoutConfig: {
        value: {
          isEditable: true,
          isRequired: false,
          isVisible: true,
        },
      },
    },
  },
  {
    columnName: attachmentsPropsName,
    width: 3,
    row: 5,
    layoutConfiguration: {
      jsonRuleCondition: LayoutRuleConditionEnum.VALUE,
      layoutConfig: {
        value: {
          isEditable: true,
          isRequired: false,
          isVisible: true,
        },
      },
    },
  },
];
