import { ButtonTypes } from '@celito.clients/enums';
import { CustomButton } from '@celito.clients/shared';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FormMode } from '../../document-templates/types';
import { TFieldValues } from '../screens/edit/controller';
import { getStyles } from '../styles';

export const ActionsFooter = ({
  handleSubmit,
  onCancel,
  mode,
}: {
  handleSubmit: () => void;
  onCancel: () => void;
  mode: FormMode;
}) => {
  const {
    formState: { isDirty, isSubmitting },
  } = useFormContext<TFieldValues>();

  const saveDisabled = useMemo(() => {
    if (!isDirty) return true;
    return false;
  }, [isDirty]);

  const styles = getStyles();
  return (
    <div className={styles.footer}>
      <CustomButton
        type="button"
        buttonType={ButtonTypes.Ghost}
        buttonTitle="Cancel"
        onClick={onCancel}
      />
      <CustomButton
        type="button"
        disabled={saveDisabled}
        buttonType={ButtonTypes.Primary}
        isLoading={isSubmitting}
        onButtonClicked={handleSubmit}
        buttonTitle={(() => {
          if (mode === FormMode.EDIT)
            if (isSubmitting) return 'Saving';
            else return 'Save';
          else if (isSubmitting) return 'Creating';
          else return 'Create';
        })()}
        leftIcon="Save"
      />
    </div>
  );
};
