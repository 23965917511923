import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledFileUploadProps } from './controlled.file-upload.model';
import { ControlledFileUploadView } from './controlled.file-upload.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const ControlledFileUploadController = <T extends FieldValues>(
  props: ControlledFileUploadProps<T>
) => {
  return <ControlledFileUploadView {...props} />;
};
