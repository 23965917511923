import { TrainingAssignmentTypeEnum } from '@celito.clients/enums';
import { AssignmentsList } from '@celito.clients/shared';
import { OptionProps } from '@fluentui/react-components';

interface TrainingAssignmentListScreenProps {
  trainingAssignmentType: TrainingAssignmentTypeEnum;
  isTrainingMatrix?: boolean;
  onSelectedViewChange?: (viewOption: OptionProps) => void;
}

const TrainingAssignmentListScreen = (
  props: TrainingAssignmentListScreenProps
): JSX.Element => {
  return <AssignmentsList {...props} />;
};

export default TrainingAssignmentListScreen;
