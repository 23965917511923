import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormHandleSubmit,
  UseFormReturn,
} from 'react-hook-form/dist/types';

export interface CreateWorkflowProps {
  dataTestId?: string;
  control: Control<FieldValues>;
  // handleSubmit: UseFormHandleSubmit<FieldValues>;
  errors: FieldErrors<FieldValues>;
  objectNameOptions: ObjectNameOptions[];
  methods: UseFormReturn<any>;
}

export type ObjectNameOptions = {
  text: string;
  value: string;
};

export interface CreateWorkflowModalViewProps {
  dataTestId?: string;
  control: Control<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  errors: FieldErrors<FieldValues>;
  objectNameOptions: ObjectNameOptions[];
  openWorkFlowModal: boolean;
  reset: () => void;
  handleWorkflowModalOpen: () => void;
  onCreateWorkflowSubmit: (data: any) => Promise<void>;
  methods: UseFormReturn<any>;
  isLoading?: boolean;
}

export interface CreateWorkflowControllerProps {
  openWorkFlowModal: boolean;
  handleWorkflowModalOpen: () => void;
}
export interface LifeCycleEventOption {
  text: string;
  value: string;
}
export const lifeCycleEventOptions: LifeCycleEventOption[] = [
  { text: 'Create', value: 'CREATE' },
  { text: 'Delete', value: 'DELETE' },
  { text: 'Lifecycle', value: 'LIFECYCLE' },
  { text: 'Edit', value: 'EDIT' },
];

export const executionTypeOptions = [
  //{ text: 'Scheduled', value: 'SCHEDULED' },
  { text: 'User-Triggered', value: 'USER-TRIGGERED' },
  { text: 'Object-Event', value: 'OBJECT-EVENT' },
];

export interface SelectedObject {
  optionValue?: string;
  optionText?: string;
  selectedOptions?: IOption[] | IOption;
}
