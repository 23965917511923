import { actionColumnListViewStyles } from '@celito.clients/list-view-engine';
import { createTestAttribute } from '@celito.clients/utils';
import { MenuItem, mergeClasses } from '@fluentui/react-components';

import { ViewTypes } from '../enums/enums';
import { MatrixView } from '../training-matrix.model';
import actionClasses from '../training-matrix.module.css';

export const viewSeletorDropdownOptions: MatrixView[] = [
  {
    text: 'Assignment View',
    value: ViewTypes.ASSIGNMENT_VIEW,
    isDefault: true,
  },
  {
    text: 'User View',
    value: ViewTypes.USER_VIEW,
    isDefault: false,
  },
  {
    text: 'Course View',
    value: ViewTypes.COURSE_VIEW,
    isDefault: false,
  },
];

export const getViewSelectorMenu = (
  cssVariables: object,
  onClick: (item: MatrixView) => void
) => {
  const styles = actionColumnListViewStyles();
  return viewSeletorDropdownOptions.map((item) => {
    return (
      <MenuItem
        className={mergeClasses(
          actionClasses.btnContainer,
          styles.viewMenuItem
        )}
        style={{ ...cssVariables }}
        onClick={() => onClick(item)}
        key={item.value}
        data-testid={`dropdown-${createTestAttribute(item.text)}`}
      >
        {item.text}
      </MenuItem>
    );
  });
};

export const getSelectedViewFromUrl = (viewName?: ViewTypes): MatrixView => {
  if (!viewName)
    return (
      viewSeletorDropdownOptions.find((option) => option.isDefault) ??
      viewSeletorDropdownOptions[0]
    );
  return (
    viewSeletorDropdownOptions.find((option) => option.value === viewName) ??
    viewSeletorDropdownOptions[0]
  );
};
