import { Stack } from '@celito.clients/shared';
import { LayoutRulesDataSchema } from '@celito.clients/types';
import {
  makeStyles,
  mergeClasses,
  shorthands,
} from '@fluentui/react-components';
import { FC } from 'react';

import { groupFieldsByRow } from '../../../utils/helper';
import { SectionView } from '../../wizard-container/section/section';
import {
  Field,
  IFormProps,
  TAttributeDefinition,
  TRecordDetails,
} from '../field';

const getStyles = makeStyles({
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('1rem'),
    ...shorthands.flex(1),
    rowGap: '24px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '40px',
  },
});

/**
 *
 * @returns Form with row support
 */
export const withRowSupport = (Form: FC<IFormProps>) => {
  return (props: Omit<IFormProps, 'renderAttributes'>) => {
    const styles = getStyles();
    return (
      <Form
        {...props}
        className={mergeClasses(styles.rowContainer, props.className)}
        renderAttributes={(attributes, fieldsState, data) => {
          const groupedFieldsByRow = groupFieldsByRow(attributes) as Record<
            number,
            TAttributeDefinition[]
          >;

          return (
            <>
              {Object.entries(groupedFieldsByRow).map(([row, fields]) => (
                <Row
                  key={row}
                  attributes={fields}
                  fieldsState={fieldsState}
                  data={data}
                  excludeUsers={props?.excludeUsers}
                />
              ))}
            </>
          );
        }}
      />
    );
  };
};

function Row({
  attributes,
  fieldsState,
  readonly,
  data,
  excludeUsers,
}: Readonly<{
  attributes: TAttributeDefinition[];
  fieldsState: Record<string, LayoutRulesDataSchema>;
  readonly?: boolean;
  data: TRecordDetails;
  excludeUsers?: string[];
}>) {
  const styles = getStyles();
  if (attributes.every((field) => !fieldsState[field.columnName]?.isVisible))
    return null;
  return (
    <Stack className={styles.row}>
      {attributes.map((attr) => {
        return (
          <SectionView width={attr.width} key={attr.name}>
            <Field
              readonly={readonly}
              recordDetails={data}
              attributeDefinition={attr}
              fieldState={fieldsState[attr.name]}
              excludeUsers={excludeUsers}
            />
          </SectionView>
        );
      })}
    </Stack>
  );
}
