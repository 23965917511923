import { ObjectAttributeType } from '@celito.clients/types';
import { raiseErrorToast, successToast } from '@celito.clients/utils';
import { GetSearchParamsFunc } from 'libs/core/src/hooks/useQueryParams';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import { getExportData } from '../services';
import { ExportItem, SortConfig, View, ViewMetadata } from '../types';
import { getFilterViewFromQueryParams } from './filters-form.util';
import { getDefaultFiltersAndRecordData } from './get-default-filters-and-record-data';

export const exportFile =
  ({
    viewData,
    rawColumnData,
    getSearchParams,
    objectName,
    filters,
    sortConfig,
    viewName,
    shouldNotGetLatestVersion,
    viewMetadata,
    moduleName,
    pageTitle,
  }: {
    viewData: View | undefined;
    rawColumnData: ObjectAttributeType | null;
    getSearchParams: GetSearchParamsFunc;
    objectName: string;
    filters: RulesComponentListViewFilter | undefined;
    sortConfig: SortConfig | undefined;
    viewName: string;
    shouldNotGetLatestVersion: boolean;
    viewMetadata: ViewMetadata | null;
    moduleName?: string;
    pageTitle?: string;
  }) =>
  async (exportItemData: ExportItem) => {
    try {
      const {
        defaultFilters,
        recordData: recordDataFilter,
        defaultSortConfig,
      } = getDefaultFiltersAndRecordData(
        viewData,
        rawColumnData,
        getSearchParams
      );

      const selectedFilterView = getFilterViewFromQueryParams(
        viewMetadata?.viewDto.view[0].filterViews,
        getSearchParams
      );
      const response = await getExportData(
        objectName,
        exportItemData.data.fileType,
        {
          filters,
          sortConfig,
          defaultFilters: selectedFilterView?.filtersToBeApplied
            ? selectedFilterView?.filtersToBeApplied[0]
            : defaultFilters,
          defaultSortConfig,
          recordDataFilter,
        },
        viewName,
        shouldNotGetLatestVersion,
        moduleName,
        pageTitle
      );
      successToast({ message: response.data.message });
    } catch (error) {
      raiseErrorToast(error);
    }
  };
