import { LayoutRulesDataSchema } from '@celito.clients/types';
import { createContext } from 'react';

import { FormEngineContextType } from '../types';

export const FormEngineContext = createContext<FormEngineContextType>({
  fieldsState: {},
});

interface FormEngineContextProviderProps {
  children: React.ReactNode;
  fieldsState: Record<string, LayoutRulesDataSchema>;
  setFieldsState?: React.Dispatch<
    React.SetStateAction<Record<string, LayoutRulesDataSchema>>
  >;
  fetchAttributeConfig?: (objectName: string) => Promise<void>;
  isWizardStateVisible?: boolean;
  isCompleterBarVisible?: boolean;
}

export const FormEngineContextProvider = ({
  children,
  fieldsState,
  setFieldsState,
  fetchAttributeConfig,
  isWizardStateVisible,
  isCompleterBarVisible,
}: FormEngineContextProviderProps) => {
  return (
    <FormEngineContext.Provider
      value={{
        fieldsState,
        setFieldsState,
        fetchAttributeConfig,
        isWizardStateVisible,
        isCompleterBarVisible,
      }}
    >
      {children}
    </FormEngineContext.Provider>
  );
};
