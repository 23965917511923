import { FieldValues } from 'react-hook-form';

import ControlledDaterangePickerController from './controlled-daterange-picker.controller';
import { ControlledDaterangePickerProps } from './controlled-daterange-picker.model';

const ControlledDaterangePicker = <T extends FieldValues>(
  props: ControlledDaterangePickerProps<T>
) => {
  return <ControlledDaterangePickerController {...props} />;
};

export default ControlledDaterangePicker;
