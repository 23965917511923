import {
  LocalizationString,
  SaveIcon,
  WarningIcon,
} from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { ConfirmDialog, CustomButton, Stack } from '@celito.clients/shared';
import { Field, Section } from '@celito.clients/types';
import { createTestAttribute } from '@celito.clients/utils';
import { useFormEngineContext } from 'libs/form-engine/src/lib/hooks';
import { getFieldContainer } from 'libs/form-engine/src/lib/utils/attribute-config';
import { FooterActionsButtonContainer } from 'libs/shared/src/lib/footer-actions-button-container';
import React, { useEffect } from 'react';
import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form';

import { SectionView } from '../../section/section';
import { AddLayoutProps } from './add.model';
import classes from './add.module.css';

interface AddLayoutViewProps extends AddLayoutProps {
  toggleContainer: boolean;
  setToggleContainer: React.Dispatch<React.SetStateAction<boolean>>;
  methods: UseFormReturn<FieldValues>;
  isCancelModalOpen: boolean;
  isCreatedModalOpen: boolean;
  toggleCancelModalBtnClick: (navigateBack?: boolean) => void;
  toggleCreateModalBtnClick: () => void;
  onCreationModeModal?: 'create' | 'save';
  showPrompt: boolean;
  confirmNavigation: () => void;
  cancelNavigation: () => void;
  successDescription: string;
}

export const AddLayoutView: React.FC<AddLayoutViewProps> = ({
  sections = [],
  attributeConfig,
  onCancel,
  onSave,
  handleSubmit,
  control,
  watch,
  setValue,
  trigger,
  onFormSubmit,
  isWarningModalOpen,
  toggleWarningModalBtnClick,
  toggleCreateModalBtnClick,
  isCreatedModalOpen,
  onCreationModeModal,
  dataTestIdForSaveModal,
  dataTestIdForWarningModal,
  mode,
  objectName = '',
  toggleContainer = false,
  setToggleContainer,
  methods,
  isCancelModalOpen,
  toggleCancelModalBtnClick,
  isLoading,
  showPrompt,
  confirmNavigation,
  successDescription,
  cancelNavigation,
  ...rest
}) => {
  const [isShow, setIsShow] = React.useState(false);

  const {
    unregister,
    formState: { isDirty },
  } = useFormContext();
  const { fieldsState } = useFormEngineContext();

  const fieldsStateStringified = JSON.stringify(fieldsState);

  // Unregister fields when they become hidden
  useEffect(() => {
    const fieldsStateParsed = JSON.parse(fieldsStateStringified);

    (sections[0]?.fields || []).forEach((field: Field) => {
      if (
        !fieldsStateParsed[field.columnName]?.isVisible &&
        field.columnName !== 'space'
      ) {
        unregister(field.columnName);
      }
    });
  }, [fieldsStateStringified, unregister, watch]);

  const sectionTypeLayout = (sections: Section[]) => {
    if (sections[0]?.fields.length > 0) {
      return (
        <>
          <Stack className={classes.rowContainer}>
            <div className={classes.container}>
              {(sections[0]?.fields || []).map((field: Field, idx) => {
                if (!fieldsState[field.columnName]?.isVisible) {
                  return null;
                }

                return (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <SectionView
                    width={field.width}
                    key={field.columnName + idx}
                    style={{ display: isShow ? 'none' : '' }}
                    className={
                      field.columnName === 'users__a'
                        ? classes.userContainer
                        : ''
                    }
                  >
                    {getFieldContainer(
                      objectName,
                      attributeConfig,
                      field.columnName,
                      control,
                      watch,
                      setValue,
                      trigger,
                      mode,
                      undefined,
                      undefined,
                      { ...field, ...fieldsState[field.columnName] }
                    )}
                  </SectionView>
                );
              })}
            </div>
          </Stack>
          <FooterActionsButtonContainer isPositionRelative>
            <CustomButton
              buttonTitle="Cancel"
              buttonType={ButtonTypes.Ghost}
              disabled={isLoading}
              onButtonClicked={onCancel}
            />
            <CustomButton
              buttonTitle="Create"
              leftIcon="Submit"
              buttonType={ButtonTypes.Primary}
              disabled={isLoading || !isDirty}
              isLoading={isLoading}
              onSubmit={onSave}
            />
          </FooterActionsButtonContainer>
        </>
      );
    }
  };

  useEffect(() => {
    window.addEventListener('TOGGLE_ON', () => {
      setIsShow(true);
    });
    window.addEventListener('TOGGLE_OFF', () => {
      setIsShow(false);
    });
    return () => {
      window.removeEventListener('TOGGLE_ON', () => {
        setIsShow(true);
      });
      window.removeEventListener('TOGGLE_OFF', () => {
        setIsShow(false);
      });
    };
  }, []);

  return (
    <form
      onSubmit={onFormSubmit}
      noValidate
      encType="multipart/form-data"
      className={classes.form}
    >
      <Stack className={classes.formContainer} {...rest}>
        {sectionTypeLayout(sections)}
      </Stack>

      <ConfirmDialog
        dataTestId={dataTestIdForWarningModal}
        open={isCancelModalOpen}
        onCancelClicked={() => toggleCancelModalBtnClick()}
        onConfirmClicked={() => toggleCancelModalBtnClick(true)}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL}
        iconSrc={WarningIcon}
        description={LocalizationString.CANCEL_POPUP_MSG}
      />

      <ConfirmDialog
        dataTestId={`save-modal-${createTestAttribute(
          LocalizationString.SAVE_REQUEST
        )}`}
        open={isCreatedModalOpen}
        onCancelClicked={toggleCreateModalBtnClick}
        onConfirmClicked={toggleCreateModalBtnClick}
        primaryButtonText={LocalizationString.GREAT}
        title={LocalizationString.CREATE_REQUEST}
        iconSrc={SaveIcon}
        description={successDescription}
      />
      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
    </form>
  );
};
