import { ButtonTypes } from '@celito.clients/enums';
import { CustomButton } from '@celito.clients/shared';

import { ColumnData, RowItem } from '../../../grid-view-new/src/types';
import classes from '../reference-documents-preview.module.css';

interface DeleteIconProps {
  item: RowItem | undefined;
  onItemDelete: (val: RowItem) => void;
}
interface ActionComponentProps extends DeleteIconProps {
  column: ColumnData | undefined;
  index: number | undefined;
  isPreviewTab?: boolean;
}

const ActionComponent = ({
  item,
  onItemDelete,
  isPreviewTab = false,
}: ActionComponentProps) => {
  return (
    <div>
      <CustomButton
        leftIcon="Delete"
        title="Remove file"
        onClick={() => onItemDelete(item!)}
        disabled={isPreviewTab}
        buttonType={ButtonTypes.Destructive}
        customStyles={classes.delete_btn}
      />
    </div>
  );
};

export default ActionComponent;
