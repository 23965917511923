import { useEffect, useState } from 'react';

import { SelectedFacetsObject } from '../search-result.model';
import { CheckboxFacetProps, CheckboxItem } from './checkbox-facet.model';
import CheckboxFacetView from './checkbox-facet.view';

const CheckboxFacetController: React.FC<CheckboxFacetProps> = (props) => {
  const {
    id,
    items,
    selectedCheckboxes,
    onCheckboxStateChangeHandler,
    disabled,
    module,
  } = props;

  const [checkboxes, setCheckboxes] = useState<CheckboxItem[]>(items);
  const isShowMoreOptionNeeded = checkboxes.length > 5;
  const [showMoreClicked, setShowMore] = useState<boolean>(false);
  const visibleCheckboxes = showMoreClicked
    ? checkboxes
    : checkboxes.slice(0, 4);

  useEffect(() => {
    const selectedCheckBoxesObjectFromProvidedId = selectedCheckboxes.find(
      (obj: SelectedFacetsObject) => obj.columnKey === id
    );
    const selectedCheckboxesWithGivenId = selectedCheckBoxesObjectFromProvidedId
      ? selectedCheckBoxesObjectFromProvidedId.values
      : [];

    const tempCheckBoxes = items.map((item: CheckboxItem) => {
      return {
        option: item.option,
        docCount: item.docCount,
        checked: selectedCheckboxesWithGivenId.includes(item.option)
          ? true
          : false,
        module: module,
        dataType: item.dataType,
      };
    });
    setCheckboxes(tempCheckBoxes);
  }, [selectedCheckboxes, items]);

  const setShowMorehandler = () => {
    setShowMore(!showMoreClicked);
  };

  const onToggelCheckboxHandler = (option: string, dataType: string) => {
    setCheckboxes((prevState) =>
      prevState.map((checkbox) =>
        checkbox.option === option
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      )
    );

    onCheckboxStateChangeHandler(option, id, dataType);
  };

  return (
    <CheckboxFacetView
      isShowMoreOptionNeeded={isShowMoreOptionNeeded}
      visibleCheckboxes={visibleCheckboxes}
      onToggelCheckboxHandler={onToggelCheckboxHandler}
      setShowMorehandler={setShowMorehandler}
      showMoreClicked={showMoreClicked}
      disabled={disabled}
    />
  );
};

export default CheckboxFacetController;
