export const UrlString = {
  LIBRARY_TRAINING_PLAN: 'library_training_plan_tab__a',
  TRAINING_PLAN_VIEW: 'training_plan_view__a',
  MY_TASKS: 'my_tasks__a',
  CD_MY_TASKS: 'cd_my_tasks__a',
  CD_MY_RECORDS: 'cd_my_records__a',
  CD_UNSUBMITTED_LIST_VIEW: 'list/custom/controlled_document_view__a',
  WORKFLOWS: 'workflow_tab__s',
  CD_MY_TASKS_LIST_VIEW: 'cd_my_task_list__a',
  CD_PENDING_RECORDS_UNSUBMITTED_LIST_VIEW:
    'cd_unsubmitted_controlled_documents__a',
  CD_LIBRABY: 'library_controlled_documents_list_view_name__a',
  CD_BASE_URL: 'controlled_docs__a',
  ADMIN_ALL_TASKS: 'cd_manage_tasks_list_view__a',
  CONTROLLED_DOCS_CUSTOM_EDIT: 'edit/custom/controlled_document_edit_view__a',
  CONTROLLED_DOCS_CUSTOM_VIEW: 'view/custom/controlled_document_detail_view__a',
  UL_MY_TASKS_LIST_VIEW: 'my_task_list__a',
  UL_MY_TRAINING_LIST_VIEW: 'my_assignment_list__a',
  UL_ALL_TASK_LIST_VIEW: 'ul_manage_task_list__a',
  CD_ALL_TASK_LIST_VIEW: 'cd_manage_task_list__a',
};
