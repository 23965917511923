import { makeStyles, shorthands } from '@fluentui/react-components';

export const FieldStyles = makeStyles({
  root: {
    marginBottom: '4px',
    position: 'relative',
    width: '100%',
  },

  error: {
    position: 'absolute',
    alignItems: 'flex-start',
    display: 'flex',
    ...shorthands.gap('4px'),
    color: 'var(--colorPaletteRedForeground1)',
    fontSize: '10px',
    lineHeight: '1rem',
  },
  mt2: {
    marginTop: '2px',
  },

  infoLabel: {
    display: 'flex',
    alignItems: 'center',

    '> button': {
      width: '20px',
    },
  },
});
