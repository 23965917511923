import { performRequest } from '@celito.clients/network';
import { Client } from '@microsoft/microsoft-graph-client';
import { useQuery } from 'react-query';

import Endpoints from '../../config/endpoint';
import { ApiMethodType } from '../../enums/api-constants';
import { ModuleNamesEnum } from '../../types/module-types';
import {
  AdUserTypes,
  AllUserResponseType,
  userPermissionType,
  UserResponseType,
  UserTypes,
} from '../../types/user-types';
import { getDateDifferenceInHours } from '../../utils/date-utils';
import { useGetAccessToken } from '../useAccessToken';

interface FetchAllUsersOptions {
  useCachedResponse?: boolean;
}

let allUsersCachedResponse: AllUserResponseType | null = null;
let cachedResponseTime: Date | null = null;

export function useUser() {
  const fetchMyInfo = async () => {
    try {
      const response = await performRequest<UserResponseType>(
        `${Endpoints.USER_URL}/me`,
        ApiMethodType.GET,
        null
      );
      return response.data.user;
    } catch (error) {
      return {} as UserTypes;
    }
  };

  const fetchAllAdUsers = async () => {
    const response = await performRequest<AdUserTypes[] | []>(
      `${Endpoints.ALL_AD_USERS}`,
      ApiMethodType.GET,
      null
    );
    return response.data;
  };

  const fetchAllAdUsersNotInvited = async () => {
    const response = await performRequest<AdUserTypes[] | []>(
      `${Endpoints.AD_USERS}`,
      ApiMethodType.GET,
      null
    );
    return response.data;
  };

  const fetchAllUsers = async (options?: FetchAllUsersOptions) => {
    try {
      if (
        options?.useCachedResponse &&
        allUsersCachedResponse &&
        Math.abs(getDateDifferenceInHours(new Date(), cachedResponseTime!)) < 2
      ) {
        return allUsersCachedResponse.users;
      }

      const response = await performRequest<AllUserResponseType>(
        `${Endpoints.USER_URL}`,
        ApiMethodType.GET,
        null
      );

      allUsersCachedResponse = response.data;
      cachedResponseTime = new Date();

      return response.data.users;
    } catch (error) {
      return {} as UserTypes[];
    }
  };

  const fetchUserInfo = async (name: string) => {
    try {
      const response = await performRequest<UserResponseType>(
        `${Endpoints.USER_URL}/${name}`,
        ApiMethodType.GET,
        null
      );
      return response.data.user;
    } catch (error) {
      return {} as UserTypes;
    }
  };

  const addUser = async (
    userId: string,
    department?: string,
    manager?: string
  ) => {
    const payload = {
      idpOid: userId,
      department: department ?? null,
      manager: manager ?? null,
    };

    try {
      const response = await performRequest<UserTypes>(
        `${Endpoints.USER_URL}`,
        ApiMethodType.POST,
        payload
      );
      return response.data;
    } catch (error) {
      throw error as UserTypes;
    }
  };

  const updateUser = async (
    name: string,
    userStatus: {
      user: {
        isActive: boolean;
        department?: string | null;
        managerOid?: string | null;
      };
    }
  ) => {
    try {
      const response = await performRequest<UserTypes>(
        `${Endpoints.USER_URL}/${name}`,
        ApiMethodType.PUT,
        userStatus
      );
      return response.data;
    } catch (error) {
      return {} as UserTypes;
    }
  };

  const fetchUserPermission = async (
    name: string,
    moduleName?: ModuleNamesEnum
  ) => {
    try {
      const response = await performRequest<userPermissionType>(
        `${Endpoints.USER_URL}/${name}/permissions${
          moduleName ? `?module_name=${moduleName}` : ''
        }`,
        ApiMethodType.GET,
        null
      );
      return response.data;
    } catch (error) {
      return {} as userPermissionType;
    }
  };

  return {
    fetchMyInfo,
    fetchAllAdUsers,
    fetchAllAdUsersNotInvited,
    fetchAllUsers,
    fetchUserInfo,
    fetchUserPermission,
    updateUser,
    addUser,
  };
}

interface IGraphPresenceResponse {
  availability: string;
  activity: string;
}

export function useUserPresence(userOid?: string) {
  const isGraphRequest = true;

  const { getAccessToken } = useGetAccessToken(isGraphRequest);

  async function fetchUserPresence() {
    try {
      const accessToken = await getAccessToken();

      const client = Client.init({
        authProvider: (done) => {
          done(null, accessToken);
        },
      });

      const presenceEndpoint = userOid
        ? `communications/presences/${userOid}`
        : 'me/presence';

      const data = client
        .api(presenceEndpoint)
        .get()
        .then((presence) => presence);

      return data;
    } catch (error) {
      return {} as IGraphPresenceResponse;
    }
  }

  return useQuery(['myUserPresence'], fetchUserPresence, {
    initialData: {} as IGraphPresenceResponse,
    refetchInterval: 30000,
  });
}
