import { useIsAuthenticated } from '@azure/msal-react';
import { useActiveModule } from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import { getHasUnreadNotifications } from '@celito.clients/services';
import React, { useContext, useEffect, useState } from 'react';

import { ModuleNamesEnum } from '../../types/module-types';
import { NotificationContext } from './context';
type props = {
  children: React.ReactNode;
};

const NotificationProvider: React.FC<props> = ({ children }) => {
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  const isAuthenticated = useIsAuthenticated();
  const { user = null } = useContext(UserContext);
  const activeModule = useActiveModule();

  useEffect(() => {
    if (!isAuthenticated || !user) {
      return;
    }

    const fetchData = async () => {
      try {
        const moduleName =
          activeModule &&
          activeModule.systemName !== ModuleNamesEnum.UNIFIED_DASHBOARD
            ? activeModule.systemName
            : undefined;
        const response = await getHasUnreadNotifications(moduleName);
        setHasUnreadNotifications(response.hasUnreadNotification);
      } catch (error) {
        return {};
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 10000);

    return () => clearInterval(intervalId);
  }, [isAuthenticated, user, activeModule?.systemName]);

  const value = {
    hasUnreadNotifications,
    setHasUnreadNotifications,
    refetchUnreadNotificationsStatus: () => {
      const moduleName =
        activeModule &&
        activeModule.systemName !== ModuleNamesEnum.UNIFIED_DASHBOARD
          ? activeModule.systemName
          : undefined;
      getHasUnreadNotifications(moduleName).then((response) => {
        setHasUnreadNotifications(response.hasUnreadNotification);
      });
    },
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
