import { Environment as EnvironmentManager } from '@celito.clients/singleton';
import { Environment, UserTypes } from '@celito.clients/types';
import { pick } from 'lodash';

export const getEnvironment = () =>
  EnvironmentManager.getInstance().getParam('NX_ENV_NAME') as Environment;

export const getMaxLogLevel = () =>
  EnvironmentManager.getInstance().getParam('MAX_LOG_LEVEL');

/**
 * Refactors user object for the logger context
 * @param user
 * @returns
 */
export const refactorUser = (user: UserTypes) => {
  const newUser = pick(user, 'name', 'isActive', 'roles', 'permissionGroups');
  return {
    user: Object.entries(newUser).reduce<Record<string, unknown>>(
      (acc, [key, value]) => {
        if (!value) return acc;

        if (Array.isArray(value)) {
          acc[key] = value.map((item) => item.name);
        } else {
          acc[key] = value;
        }

        return acc;
      },
      {}
    ),
  };
};
