import { LocalizationString, UrlString } from '@celito.clients/assets';
import {
  ActionTypeEnum,
  ControlledDocumentStatusEnum,
  CustomViewTypeEnum,
  DocumentDownloadActionEnum,
  ObjectEnum,
  ROUTES,
} from '@celito.clients/enums';
import {
  useActiveModule,
  useCloseOnScroll,
  useEffectWithDependencyMonitor,
  useUpdateInitiationConfirmationModal,
} from '@celito.clients/hooks';
import { ModalContext, UserContext } from '@celito.clients/provider';
import {
  claimTask,
  downloadTrainingCertificate,
  files,
  getDocumentCertificateMetadata,
  getDocumentData,
  getRecordDetailApi,
} from '@celito.clients/services';
import { UserActionPopup } from '@celito.clients/shared';
import { DocData } from '@celito.clients/types';
import {
  errorToast,
  getRedirectionBaseUrl,
  infoToast,
  isExistInUrl,
  raiseErrorToast,
  requiresVersionParam,
  successToast,
} from '@celito.clients/utils';
import { ActionLevelEnum } from 'libs/core/src/enums/action-type';
import { getFormValues } from 'libs/form-engine/src/lib/utils/helper';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import { ListViewModuleType } from '../../config/list-view.config';
import { useListViewContext } from '../../hooks';
import { deleteObject, deleteWorkflowObject } from '../../services';
import { triggerWorkflow } from '../../services/trigger-workflow';
import { RecordAction } from '../../types';
import getNavigateUrl from '../../utils/get-navigate-url.util';
import {
  ActionColumnProps,
  IActionItem,
  LifeCycleStageUserActionsPermissionEnum,
  UserActionWithPermisson,
} from './action-column.model';
import ActionColumnView from './action-column.view';
import { getRecordUserActions } from './utils/apiService';
import getActionMenuProps from './utils/getActionMenuProps';

// libs\list-view-engine\src\lib\utils

interface ActionColumnControllerProps extends ActionColumnProps {}

const ActionColumnController = (
  props: ActionColumnControllerProps
): JSX.Element => {
  const navigate = useNavigate();
  const { objectName } = props;
  const recordName = props.rowItem.name;
  const recordVersion = props.rowItem.version;
  const { isLifecycleEnabled = false } = props.objectMetadata;
  const activeModule = useActiveModule();
  const {
    isLoading: isRecordUserActionsLoading,
    data: recordUserActionsResponse,
    refetch: refetchRecordUserActions,
    isStale: userActionsResponseIsStale,
    isFetched: isUserActionsFetched,
  } = useQuery(
    [
      // adding viewDto name to make sure unsubmitted record's actions
      // does not end up in submitted record's actions
      props.viewMetadata?.viewDto?.name,
      objectName,
      recordName,
      recordVersion,
    ],
    {
      enabled: false, // disable this query from automatically running
      queryFn: () => {
        if (!isLifecycleEnabled) return;
        return getRecordUserActions(objectName, recordName, recordVersion);
      },
      staleTime: 30000,
    }
  );

  const refetchUserActionsIfStale = () => {
    if (userActionsResponseIsStale) {
      refetchRecordUserActions();
    }
  };

  const userActionsWithPermisson: UserActionWithPermisson[] = useMemo(
    () =>
      isLifecycleEnabled
        ? recordUserActionsResponse?.recordUserActions || []
        : (props?.rowItem?.rowData
            ?.recordUserActions as UserActionWithPermisson[]),
    [
      isLifecycleEnabled,
      props?.rowItem?.rowData?.recordUserActions,
      recordUserActionsResponse?.recordUserActions,
    ]
  );

  const {
    fetchRowData,
    showShimmeringRows,
    currentPageNumber,
    filters,
    sortConfig,
  } = useListViewContext();
  const { objectMetadata } = props;
  const { openModal, isModalOpen } = useContext(ModalContext);
  const [openDelegateModal, setOpenDelegateModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showClaimAlert, setShowClaimAlert] = useState(false);
  const [isTaskCompleteModalOpen, setIsTaskCompleteModalOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [respectiveUserActionRes, setRespectiveUserActionRes] =
    useState<RecordAction>();
  const [isShowText, setIsShowText] = useState(false);
  const [nameAndVersion, setNameAndVersion] = useState({
    version: '',
    name: '',
  });
  const { user, isAdmin } = useContext(UserContext);
  const isUnsubmittedControlledDocsListView =
    props.viewMetadata?.viewDto?.name ===
      CustomViewTypeEnum.UNSUBMITTED_CONTROL_DOCS_LIST_VIEW ||
    props.viewMetadata?.viewDto?.name ===
      'cd_unsubmitted_controlled_documents__a';

  const isULModule = window.location.pathname.includes('lms__a');
  const isTakingTrainingRow =
    props.rowItem?.rowData?.objectRecord?.objectDefinitionName ===
    ListViewModuleType.TAKING_TRAINING;

  const updateInitiationConfirmationProps =
    useUpdateInitiationConfirmationModal({
      objectName: objectName as ObjectEnum,
    });

  const { setShowUpdateInititationConfirmationModal, handleOnUpdateInitiate } =
    updateInitiationConfirmationProps;

  useCloseOnScroll(setMenuOpen);
  useEffectWithDependencyMonitor(
    (changedDeps) => {
      if (
        // to make sure other dependecies doesn't cause unecessary cause-effect
        Object.keys(changedDeps).includes('isModalOpen') &&
        !isModalOpen &&
        isTaskCompleteModalOpen
      ) {
        fetchRowData(props.objectName, currentPageNumber, filters, sortConfig);
        setIsTaskCompleteModalOpen(false);
      }
    },
    [
      currentPageNumber,
      fetchRowData,
      filters,
      isModalOpen,
      isTaskCompleteModalOpen,
      props.objectName,
      sortConfig,
    ],
    [
      'currentPageNumber',
      'fetchRowData',
      'filters',
      'isModalOpen',
      'isTaskCompleteModalOpen',
      'props.objectName',
      'sortConfig',
    ]
  );

  const deleteRecord = async () => {
    try {
      if (objectMetadata?.name === ObjectEnum.WORKFLOW_DEFINITION) {
        await deleteWorkflowObject(
          props?.rowItem?.rowData?.name,
          props?.rowItem?.rowData?.version
        );
      } else {
        await deleteObject(props.objectName, props?.rowItem?.name as string);
      }
      successToast({
        message: isUnsubmittedControlledDocsListView
          ? LocalizationString.DOCUMENT_INITIATION_DELETED
          : LocalizationString.DELETED_SUCCESSFULLY,
      });

      fetchRowData(
        objectMetadata?.name,
        currentPageNumber,
        filters,
        sortConfig
      );
    } catch (_err) {
      raiseErrorToast(_err);
    }
  };

  const openDeleteAlert = () => {
    setShowDeleteAlert(true);
  };
  const closeDeleteAlert = (deleteRec = false) => {
    if (deleteRec) {
      deleteRecord();
    }
    setShowDeleteAlert(false);
  };
  const closeDelegateModal = () => {
    setOpenDelegateModal(false);
  };

  const callClaimAction = async () => {
    try {
      await claimTask(props.rowItem?.name as string);
      fetchRowData(props.objectName, currentPageNumber, filters, sortConfig);
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      setShowClaimAlert(false);
    }
  };

  const callTriggerWorkflow = async (
    actionName: string,
    recordName: string,
    version: string,
    actionType: string,
    shouldRefetch = false,
    title = ''
  ) => {
    try {
      if (
        actionType === ActionTypeEnum.CHECK_OUT ||
        actionType === ActionTypeEnum.CANCEL_CHECK_OUT
      ) {
        infoToast({
          message:
            actionType === ActionTypeEnum.CHECK_OUT
              ? LocalizationString.CHECK_OUT_INITIATION
              : LocalizationString.CANCEL_CHECK_OUT_INITIATION,
        });
      }
      await triggerWorkflow(actionName, recordName, version);
      if (actionType === ActionTypeEnum.DEACTIVATE) {
        successToast({
          message: `${title ?? recordName} Deactivated successfully`,
        });
      }
      if (actionType === ActionTypeEnum.ACTIVATE) {
        successToast({
          message: `The ${title ?? recordName} has been Activated`,
        });
      }
      if (shouldRefetch) {
        showShimmeringRows();
        fetchRowData(props.objectName, currentPageNumber, filters, sortConfig);
      }
      if (
        actionType === ActionTypeEnum.CHECK_OUT ||
        actionType === ActionTypeEnum.CANCEL_CHECK_OUT
      ) {
        successToast({
          message:
            actionType === ActionTypeEnum.CHECK_OUT
              ? LocalizationString.CHECK_OUT
              : LocalizationString.CANCEL_CHECK_OUT,
        });
        if (actionType === ActionTypeEnum.CHECK_OUT) {
          const document =
            props?.rowItem?.rowData?.objectRecord?.document ??
            props?.rowItem?.rowData?.document;

          const documentId = document?.documentId;

          const objectName =
            props?.rowItem?.['rowData']?.['object']?.['name'] ??
            props?.['objectName'];

          const recordName =
            props?.rowItem?.['rowData']?.['objectRecord']?.['name'] ??
            props?.rowItem?.['rowData']?.['name'];
          const recordStatus =
            props?.rowItem?.['rowData']?.['objectRecord']?.['documentStatus'] ??
            props?.rowItem?.['rowData']?.['documentStatus'];

          if (!documentId) {
            errorToast({ message: 'Document not found' });
            return;
          }

          files.downloadFile(
            getDocumentData(
              documentId,
              objectName,
              recordName,
              requiresVersionParam(version ?? '', recordStatus ?? '')
                ? version
                : '',
              DocumentDownloadActionEnum.DownloadRaw
            ),
            (documentData: DocData) => {
              handleDownload(documentData);
            }
          );
        }
      }
    } catch (_error) {
      raiseErrorToast(_error);
    }
  };

  const handleDownload = (documentData: DocData) => {
    try {
      if (documentData.fileBuffer?.data) {
        files.downloadFileBuffer({
          data: documentData.fileBuffer.data,
          label: documentData.label,
          mimeType: documentData.mimeType,
        });
      }
      if (documentData.certificateBuffer?.data) {
        const parts = documentData.label.split('.');
        parts.pop(); // popping file extension
        const name = `${parts.join('.')} Approval Page.pdf`;
        files.downloadFileBuffer({
          data: documentData.certificateBuffer.data,
          label: name,
          mimeType: documentData.mimeType,
        });
      }
    } catch (error) {
      errorToast({ message: `${error}` });
    }
  };

  const onMenuItemClick = async (action: RecordAction, rowItem: RowItem) => {
    setMenuOpen(false);
    if (action.actionType?.toLowerCase() === 'delete') {
      openDeleteAlert();
      return;
    }

    if (action.actionType === ActionTypeEnum.COMPLETE_TASK) {
      if (isTakingTrainingRow) {
        navigate(
          `../${ROUTES.DOCUMENT_VIEW}?training_assignment_name=${props.rowItem?.rowData?.objectRecord?.name}&openCompleteModal=true`
        );
        return;
      }

      if (rowItem.rowData?.enableCompleteTaskWithoutViewing === false) {
        const rowClickableViewName =
          props.viewMetadata?.viewDto?.view?.[0]?.rowClickableViewName;
        const { rowData } = props.rowItem;

        if (rowData) {
          const url = getNavigateUrl(rowClickableViewName, rowData);
          navigate(url);
          return;
        }

        raiseErrorToast(
          new Error(LocalizationString.CANNOT_FIND_URL_TO_TASK_COMPLETE_PAGE)
        );
        return;
      }

      const { objectRecord } = props.rowItem?.rowData || {};
      const { objectDefinitionName, name, version } = objectRecord;
      const params = {
        version,
        view_type: 'view',
      };
      try {
        const getRowData = await getRecordDetailApi(
          objectDefinitionName,
          name,
          params
        );
        openModal(
          rowItem.name as string,
          { name: rowItem?.rowData?.object.name },
          activeModule?.link,
          getRowData
        );
        setIsTaskCompleteModalOpen(true);
      } catch (_error) {
        raiseErrorToast(_error);
      }
      return;
    }

    if (action.actionType === ActionTypeEnum.DELEGATE_TASK) {
      setOpenDelegateModal(true);
      return;
    }
    const recordName = (rowItem?.rowData?.objectRecord?.name ??
      rowItem?.name) as string;
    const objectname = action?.objectName;
    const version = (rowItem?.version ||
      rowItem?.rowData?.objectRecord?.version) as string;
    if (action?.properties?.fields?.length) {
      if (action.actionType === ActionTypeEnum.CANCEL) {
        setRespectiveUserActionRes(action);
        setIsShowText(true);
        setIsConfirmDialogOpen(true);
        setNameAndVersion({
          version: version,
          name: recordName,
        });
        return;
      }
      if (action.actionType === ActionTypeEnum.CHECK_IN) {
        setIsShowText(false);
        setIsConfirmDialogOpen(true);
        setRespectiveUserActionRes(action);
        setNameAndVersion({
          version: version,
          name: recordName,
        });
        return;
      }
      openEsignModal(
        props?.rowItem?.rowData,
        action as unknown as Record<string, unknown>
      );
      return;
    }
    if (
      [
        ActionTypeEnum.TRIGGER_WORKFLOW,
        ActionTypeEnum.CHECK_OUT,
        ActionTypeEnum.CANCEL_CHECK_OUT,
      ].includes(action.actionType as ActionTypeEnum) &&
      recordName &&
      version
    ) {
      callTriggerWorkflow(action.name, recordName, version, action.actionType!);
      return;
    }

    const rowDataRecordName = props?.rowItem?.rowData?.name;
    const rowDataTitle =
      rowItem?.rowData?.objectRecord?.title ?? rowItem?.title;

    if (
      action.actionType === ActionTypeEnum.DEACTIVATE &&
      (recordName ?? rowDataRecordName)
    ) {
      const recordNameToBeUsed = recordName ?? rowDataRecordName;

      const rowDataLabel =
        rowItem?.rowData?.objectRecord?.label ?? rowItem?.label;

      // Using || instead of ?? because rowDataTitle can be empty string
      infoToast({
        message: `Deactivating ${
          rowDataTitle || rowDataLabel || recordNameToBeUsed
        }`,
      });

      await callTriggerWorkflow(
        action.name,
        recordNameToBeUsed,
        version,
        action?.actionType,
        true,
        rowDataTitle || rowDataLabel
      );

      return;
    }

    if (action.actionType === ActionTypeEnum.DOWNLOAD_CERTIFICATE) {
      if (!recordName) {
        errorToast({ message: 'Document certificate not found' });
        return;
      }

      infoToast({ message: 'Downloading document certificate...' });

      try {
        const documentData = await getDocumentCertificateMetadata(recordName);

        handleDownload(documentData as DocData);
      } catch (_error) {
        raiseErrorToast(_error);
      }

      return;
    }

    if (
      action.actionType === ActionTypeEnum.ACTIVATE &&
      (recordName ?? rowDataRecordName)
    ) {
      const recordNameToBeUsed = recordName ?? rowDataRecordName;

      const rowDataLabel =
        rowItem?.rowData?.objectRecord?.label ?? rowItem?.label;

      infoToast({
        message: `Activating ${
          rowDataTitle || rowDataLabel || recordNameToBeUsed
        }`,
      });
      await callTriggerWorkflow(
        action.name,
        recordNameToBeUsed,
        version,
        action?.actionType,
        true,
        rowDataTitle || rowDataLabel
      );

      return;
    }

    const parentObjectName = props?.rowItem?.rowData?.object?.name;
    const parentObjectRecordName = props?.rowItem?.rowData?.objectRecord?.name;
    // task__s object does not have audit trail so we are redirecting to audit trail of the parent object
    // rowData.objectRecord will have the parent object record details
    if (
      action.actionType === ActionTypeEnum.LIST &&
      action.label?.toLowerCase()?.includes('audit') &&
      objectname === 'task__s' &&
      parentObjectName &&
      parentObjectRecordName
    ) {
      navigate(
        `../${action.actionType?.toLowerCase()}/${
          action.viewName
        }?recordname=${parentObjectRecordName}&objectname=${parentObjectName}`
      );
      return;
    }

    if (
      action.actionType?.toLowerCase() === 'list' &&
      recordName &&
      objectname
    ) {
      navigate(
        `../${action.actionType?.toLowerCase()}/${
          action.viewName
        }?recordname=${recordName}&objectname=${objectname}`
      );
      return;
    }

    if (action.actionType?.toLowerCase() === 'custom') {
      if (action.name === 'complete_course_action__a') {
        navigate(
          `../${ROUTES.DOCUMENT_VIEW}?training_assignment_name=${rowItem?.rowData?.name}&openCompleteModal=true`
        );
        return;
      }
      if (action.name === 'claim_action__s') {
        setShowClaimAlert(true);
        callClaimAction();
        return;
      }
      if (action.name === 'view_ta_certificate__a') {
        infoToast({ message: LocalizationString.DOWNLOADING_CERTIFICATE });
        if (!rowItem?.name) {
          errorToast({ message: LocalizationString.CERTIFICATE_NOT_FOUND });
          return;
        }
        downloadTrainingCertificate(rowItem?.name)
          .then(() => {
            successToast({
              message: LocalizationString.CERTIFICATE_DOWNLOADED,
            });
          })
          .catch((_error) => {
            raiseErrorToast(_error);
          });
      }
      return;
    }

    if (action.actionType?.toLowerCase() === 'customview') {
      if (rowItem?.rowData?.object?.detailViewName?.includes?.('custom/')) {
        navigate(
          `../view/${rowItem?.rowData?.object?.detailViewName}/${rowItem?.rowData?.objectRecord?.name}?taskName=${rowItem?.name}`
        );
        return;
      }
      const versionString = version ? `&version=${version}` : '';
      navigate(
        `../customview/${rowItem?.rowData?.object?.detailViewName}/${rowItem?.rowData?.objectRecord?.name}?taskName=${rowItem?.name}${versionString}`
      );
      return;
    }

    if (action.actionType === ActionTypeEnum.UPDATE) {
      handleOnUpdateInitiate(props.rowItem?.rowData ?? {}, action.viewName!);
      setShowUpdateInititationConfirmationModal(true);

      return;
    }

    if (isULModule && rowItem?.rowData?.version) {
      navigate(
        `../${action.actionType?.toLowerCase()}/${action.viewName}/${
          props?.rowItem?.name
        }${
          rowItem?.rowData?.version
            ? `?version=${rowItem?.rowData?.version}`
            : ''
        }`
      );
      return;
    }

    if (action.objectName === 'workflow_definition__s') {
      navigate(
        `../custom/workflows/node_editor/${rowItem.name}/${rowItem.version}`
      );
      return;
    }

    if (
      action.actionType === ActionTypeEnum.DOWNLOAD ||
      action.actionType === ActionTypeEnum.DOWNLOAD_RAW
    ) {
      const downloadAction =
        action.actionType === ActionTypeEnum.DOWNLOAD
          ? DocumentDownloadActionEnum.Download
          : DocumentDownloadActionEnum.DownloadRaw;

      const document =
        props?.rowItem?.rowData?.objectRecord?.document ??
        props?.rowItem?.rowData?.document;

      const documentId = document?.documentId;

      const objectName =
        props?.rowItem?.['rowData']?.['object']?.['name'] ??
        props?.['objectName'];

      const recordName = props?.rowItem?.['rowData']?.['name'];
      const recordVersion = props?.rowItem?.rowData?.version;
      if (!documentId) {
        errorToast({ message: 'Document not found' });
        return;
      }

      files.downloadFile(
        getDocumentData(
          documentId,
          objectName,
          recordName,
          recordVersion,
          downloadAction,
          recordVersion
        ),
        handleDownload
      );

      return;
    }

    if (action.actionType === ActionTypeEnum.VERSION_HISTORY) {
      const documentId =
        props?.rowItem?.['rowData']?.['objectRecord']?.['document']?.[
          'documentId'
        ] ?? props?.rowItem?.['rowData']?.['document']?.['documentId'];

      const objectName =
        props?.rowItem?.['rowData']?.['object']?.['name'] ??
        props?.['objectName'];
      if (!documentId) {
        errorToast({ message: 'Document not found' });
        return;
      }
      const recordName =
        props?.rowItem?.['rowData']?.['objectRecord']?.['name'] ??
        props?.rowItem?.['rowData']?.['name'];
      navigate(
        `../${ROUTES.VERSIONHISTORY.replace(
          ':documentId',
          documentId
        )}?objectName=${objectName}&recordName=${recordName}`
      );
      return;
    }
    if (action.actionType === ActionTypeEnum.EDIT_DOCUMENT) {
      const baseUrl = getRedirectionBaseUrl();
      const queryParams = `version=${version}&viewType=listView`;
      const documentId =
        props?.rowItem?.['rowData']?.['objectRecord']?.['document']?.[
          'documentId'
        ] ?? props?.rowItem?.['rowData']?.['document']?.['documentId'];

      window.open(
        `${baseUrl}/${ROUTES.WOPI_EDITOR.replace(
          ':documentId',
          documentId
        )}?${queryParams}`,
        '_blank',
        'noreferrer'
      );
      return;
    }
    if (
      (action.actionType === ActionTypeEnum.VIEW ||
        action.actionType === ActionTypeEnum.EDIT) &&
      isExistInUrl(UrlString.CD_BASE_URL)
    ) {
      if (
        isExistInUrl(UrlString.CD_MY_TASKS) ||
        isExistInUrl(UrlString.ADMIN_ALL_TASKS)
      ) {
        const version = props?.rowItem?.rowData?.objectRecord?.version;

        const versionParam = requiresVersionParam(
          version as string,
          props?.rowItem?.documentStatus as ControlledDocumentStatusEnum
        )
          ? `&version=${version}`
          : '';

        navigate(
          `../${action.actionType?.toLowerCase()}/${
            action.viewName
          }/${recordName}?taskName=${
            props?.rowItem?.rowData?.name
          }${versionParam}`
        );
        return;
      } else if (
        isExistInUrl(UrlString.CD_MY_RECORDS) ||
        isExistInUrl(UrlString.CD_LIBRABY)
      ) {
        const versionParam = requiresVersionParam(
          props?.rowItem?.version as string,
          props?.rowItem?.documentStatus as ControlledDocumentStatusEnum
        )
          ? `?version=${props?.rowItem?.version}`
          : '';

        navigate(
          `../${action.actionType?.toLowerCase()}/${
            action.viewName
          }/${recordName}${versionParam}`
        );
        return;
      }
      navigate(
        `../${action.actionType?.toLowerCase()}/${action.viewName}/${
          props?.rowItem?.rowData?.name
        }${
          isUnsubmittedControlledDocsListView
            ? `?requestType=${props?.rowItem?.rowData?.initiationType}&version=${props?.rowItem?.rowData?.version}`
            : ''
        }`
      );
      return;
    }

    navigate(
      `../${action.actionType?.toLowerCase()}/${action.viewName}/${
        props?.rowItem?.rowData?.name
      }${
        isUnsubmittedControlledDocsListView
          ? `?requestType=${props?.rowItem?.rowData?.initiationType}`
          : ''
      }`
    );
  };

  const recordUserActions = useMemo(() => {
    const objectActionDefinitions = objectMetadata.objectActionDefinitions;

    return userActionsWithPermisson
      ?.filter(
        (action) =>
          action.permission !== LifeCycleStageUserActionsPermissionEnum.HIDE
      )
      ?.reduce((acc: RecordAction[], currAction: UserActionWithPermisson) => {
        acc.push({
          ...objectActionDefinitions.find(
            (actionDef) => actionDef.name === currAction.name
          ),
          ...currAction,
        });
        return acc;
      }, [] as RecordAction[])
      ?.filter(
        (a: RecordAction) => a?.actionLevel === ActionLevelEnum.RecordAction
      );
  }, [objectMetadata.objectActionDefinitions, userActionsWithPermisson]);

  const openEsignModal = (
    recordData?: Record<string, unknown>,
    contextMenuAction?: Record<string, unknown>,
    taskPayload?: Record<string, unknown>
  ) => {
    openModal(
      '',
      taskPayload,
      activeModule?.link,
      recordData,
      contextMenuAction
    );
  };

  const menuProps: IActionItem[] = getActionMenuProps(
    props,
    user,
    isAdmin,
    recordUserActions,
    onMenuItemClick
  );

  return (
    <>
      <ActionColumnView
        {...{
          menuOpen,
          setMenuOpen,
          openDelegateModal,
          closeDelegateModal,
          showClaimAlert,
          closeClaimAlert: () => setShowClaimAlert(false),
          closeDeleteAlert,
          showDeleteAlert,
          menuProps,
          fetchUserActions: refetchUserActionsIfStale,
          isUserActionsLoading: isRecordUserActionsLoading,
          isUserActionsFetched: isLifecycleEnabled
            ? isUserActionsFetched
            : true,
          updateInitiationConfirmationProps,
          ...props,
        }}
      />
      <UserActionPopup
        open={isConfirmDialogOpen}
        onConfirmClicked={() => null}
        onCancelClicked={() => {
          setRespectiveUserActionRes(undefined);
          setIsConfirmDialogOpen(false);
        }}
        title={respectiveUserActionRes?.label ?? ''}
        primaryButtonText={
          respectiveUserActionRes?.actionType === ActionTypeEnum.CANCEL
            ? LocalizationString.YES
            : LocalizationString.CHECK_IN
        }
        secondaryButtonText={
          respectiveUserActionRes?.actionType === ActionTypeEnum.CANCEL
            ? LocalizationString.NO
            : LocalizationString.CANCEL
        }
        attributeConfig={objectMetadata}
        userActionData={respectiveUserActionRes}
        recordName={nameAndVersion?.name}
        version={nameAndVersion?.version}
        isTextRequired={isShowText}
        recordDetails={getFormValues(
          props.objectMetadata,
          props.rowItem.rowData ?? {}
        )}
      />
    </>
  );
};

export default ActionColumnController;
