import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const conditionalFieldPickerStyles = makeStyles({
  dropdownStyles: {
    width: '100%',
    ...shorthands.borderRadius('6px'),
  },

  dropdownStylesError: {
    ...shorthands.border('1px', 'solid', Color.error_color),

    '&:after': {
      ...shorthands.border('1px', 'solid', Color.error_color),
    },
  },

  field: {
    fontSize: '1rem',
    fontFamily: ThemeFontFamily.bold,
    fontWeight: 600,
  },
});
