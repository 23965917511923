import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { CourseData, TrainingAssignmentData } from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';

import {
  COURSE_OBJECT_NAME,
  QUIZ_OBJECT_NAME,
  TRAINING_ASSIGNMENT_OBJECT_NAME,
} from '../enums';
import {
  Question,
  QuizData,
  QuizInfo,
  QuizScore,
  Result,
  SubmitQuiz,
} from '../types';

export const getTrainingAssignmentData = async (
  trainingAssignmentName: string
) => {
  const response = await performRequest<TrainingAssignmentData>(
    `${Endpoints.OBJECT_DATA_URL}/${TRAINING_ASSIGNMENT_OBJECT_NAME}/${trainingAssignmentName}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const getCourseData = async (assignmentData: TrainingAssignmentData) => {
  const response = await performRequest<CourseData>(
    `${Endpoints.OBJECT_DATA_URL}/${COURSE_OBJECT_NAME}/${assignmentData.course.name}`,
    ApiMethodType.GET,
    undefined,
    {
      version: assignmentData.course.version,
    }
  );

  return response.data;
};

export const getQuizDataByQuizName = async (quizName: string) => {
  const response = await performRequest<QuizData>(
    `${Endpoints.OBJECT_DATA_URL}/${QUIZ_OBJECT_NAME}/${quizName}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const getQuizStart = async (trainingAssignmentName: string) => {
  const response = await performRequest<QuizInfo>(
    `${Endpoints.ASSIGNMENTS_URL}/${trainingAssignmentName}/start-quiz`,
    ApiMethodType.POST,
    {}
  );
  return response.data;
};

export const getQuizQuestions = async (quizName: string, version: string) => {
  const response = await performRequest<Question[]>(
    `/quiz/${quizName}/version/${version}/questions`,
    ApiMethodType.GET
  );
  return response.data;
};

export const verifyIfQuizIsActive = async (
  quizName: string,
  version: string
) => {
  const response = await performRequest<QuizData>(
    `${Endpoints.OBJECT_DATA_URL}/quiz__a/${quizName}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const validateSelectedData = async (
  checkedValue: string[],
  currQueId: string | undefined,
  assignmentName: string
) => {
  const body = {
    selectedChoices: checkedValue,
  };
  const response = await performRequest<Result>(
    `${Endpoints.ASSIGNMENTS_URL}/${assignmentName}/question/${currQueId}`,
    ApiMethodType.POST,
    body
  );
  return response.data;
};

export const submitQuiz = async (assignmentName: string) => {
  const response = await performRequest<SubmitQuiz>(
    `${Endpoints.ASSIGNMENTS_URL}/${assignmentName}/submit-quiz`,
    ApiMethodType.POST,
    {}
  );
  return response.data;
};

export const getLastQuizAttemptScore = async (assignmentName: string) => {
  const response = await performRequest<QuizScore>(
    `${Endpoints.ASSIGNMENTS_URL}/${assignmentName}/quiz-score`,
    ApiMethodType.GET
  );
  return response.data;
};
