import { LocalizationString } from '@celito.clients/assets';
import { DateFormat, DocumentDownloadActionEnum } from '@celito.clients/enums';
import { getDocumentData } from '@celito.clients/services';
import { GridView } from '@celito.clients/shared';
import { DocData } from '@celito.clients/types';
import { errorToast, raiseErrorToast } from '@celito.clients/utils';
import { formatDate } from 'date-fns';
import { Label } from 'libs/shared/src/lib/label/src';
import { useEffect, useState } from 'react';

import { taskObjectName } from '../config';
import { UploadedFilesViewProps } from '../taking-assingment.model';
import { getUploadedFilesViewColumns } from '../utils';

export const UploadedFiles = (props: UploadedFilesViewProps) => {
  const { assignmentData, assignmentDoc } = props;
  const [loading, setLoading] = useState(true);
  const [docData, setDocData] = useState<DocData>();

  const handleDownload = () => {
    try {
      if (docData?.fileBuffer) {
        const uint8Array = new Uint8Array(docData.fileBuffer?.data);
        const blob = new Blob([uint8Array], {
          type: docData.mimeType,
        });
        const downloadLink = document?.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = docData?.label; // Set the desired filename and extension
        downloadLink.click();
      }
    } catch (error) {
      errorToast({ message: `${error}` });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const docId = assignmentDoc?.documentId;
    getDocumentData(
      docId,
      taskObjectName,
      undefined,
      undefined,
      DocumentDownloadActionEnum.DownloadRaw
    )
      .then((r) => {
        setDocData(r);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <Label weight="semibold">{LocalizationString.UPLOADED_FILES}</Label>
      <GridView
        columns={getUploadedFilesViewColumns(docData, handleDownload)}
        items={[
          {
            key: assignmentDoc?.documentId ?? '',
            name: assignmentDoc?.nameWithExtension ?? '',
            uploadDate: docData?.uploadedAtUtc
              ? formatDate(docData?.uploadedAtUtc, DateFormat.Date)
              : '-',
            uploadBy: docData?.uploadedByUserName ?? '-',
            justification: assignmentData?.justification ?? '',
            completionDate: assignmentData?.completionDate
              ? formatDate(assignmentData?.completionDate, DateFormat.Date)
              : '',
          },
        ]}
        disableSelection
        enableShimmer={loading}
      />
    </div>
  );
};
