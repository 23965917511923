import {
  ButtonTypes,
  DocumentDownloadActionEnum,
  ObjectEnum,
} from '@celito.clients/enums';
import { getDocumentData, logger } from '@celito.clients/services';
import { CustomButton } from '@celito.clients/shared';
import { raiseErrorToast, successToast } from '@celito.clients/utils';
import { handleDownload } from 'apps/web-client/src/screens/controlled-document/utils';
import { AxiosError } from 'axios';
import { get, isArray, isString } from 'lodash';

/**
 * Actions cell for the merge field values table
 */
export function MFVCellActions({
  index,
  styles,
  value,
}: {
  index: number;
  value: string[];
  styles: Record<string, string>;
}) {
  return (
    <div className={styles.actionsContainer}>
      <CustomButton
        onClick={() => {
          const copiedValue = isArray(value) ? `\${${value[index]}}` : '';
          navigator.clipboard?.writeText(copiedValue);
          successToast({
            title: 'Success',
            message: `${copiedValue} copied to clipboard`,
          });
        }}
        buttonType={ButtonTypes.Destructive}
        leftIcon="Copy"
      />
    </div>
  );
}

/**
 * Actions cell for the Document Grid
 */
export function DocCellActions({
  index,
  styles,
  value,
}: {
  index?: number;
  value: Record<string, unknown>;
  styles?: Record<string, string>;
}) {
  return (
    <div style={{ display: 'flex' }}>
      <CustomButton
        leftIcon="Download"
        dataTestId="button-download-document"
        onClick={async () => {
          if (isString(value)) return;
          const documentId = get(value, 'documentId');
          try {
            if (documentId && isString(documentId)) {
              const data = await getDocumentData(
                documentId,
                ObjectEnum.DOCUMENT_TEMPLATE,
                get(value, 'recordName') as string,
                undefined,
                DocumentDownloadActionEnum.DownloadRaw
              );
              handleDownload(data as any);
            }
          } catch (error) {
            const err = error as AxiosError | Error;
            raiseErrorToast(err);
            logger.error(err);
          }
        }}
      />
    </div>
  );
}
