import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';

export const returnSelectedOptionFromOptions = (
  options: IOption[] | undefined,
  value: string | undefined | null | boolean | string[],
  activeOptions?: boolean
): IOption | IOption[] => {
  if (!value) return [];

  if (!options) return {} as IOption;

  if (Array.isArray(value)) {
    const selectedOptions: IOption[] = value.map((v) => {
      return {
        text: options.find((opt) => opt.value === v)?.text ?? '',
        value: v,
      };
    });

    return selectedOptions;
  }

  if (activeOptions) {
    if (value) return { value: 'active', text: 'Active' };

    return { value: 'inactive', text: 'Inactive' };
  }

  return {
    value: (value as string) ?? '',
    text: options.find((opt) => opt.value === value)?.text ?? '',
  };
};
