import { Icon } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import {
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
} from '@fluentui/react-components';
import { Link } from 'react-router-dom';

import classes from '../nav-link/nav-link.styles.module.css';
import { navLinkStyles } from '../nav-link/nav-link-styles';
import { NavLinkMultipleProps } from './nav-link-multiple.model';

export const NavLinkMultipleView = ({
  onShowContextualMenu,
  menuItems,
  handleNavLinkClick,
  showNameNotMoreIcon,
  icon,
}: NavLinkMultipleProps) => {
  const styles = navLinkStyles();

  return (
    // Switching back to original menu from FluentUI to fix
    // Bug 32534: [Issue- 520] Sub menu is disappearing before reading or selecting the value
    <Menu onOpenChange={onShowContextualMenu}>
      <MenuTrigger disableButtonEnhancement>
        <button
          className={mergeClasses(
            styles.customMenuButton,
            styles.subMenu,
            styles.buttonTrigger,
            showNameNotMoreIcon && styles.maxWidthMenu
          )}
          data-testid={`button-tab-admin-settings`}
          onClick={onShowContextualMenu}
        >
          {showNameNotMoreIcon ?? icon}
          {showNameNotMoreIcon ? (
            <Icon
              iconName="ChevronRight16Regular"
              className={classes.dropdownIcon}
            />
          ) : null}
        </button>
      </MenuTrigger>

      <MenuPopover className={styles.menuPopover}>
        <MenuList>
          {menuItems?.map((mainLink) => {
            return (
              <MenuItem key={mainLink.name} className={styles.menuItem}>
                {mainLink.subLinks && mainLink.subLinks?.length > 0 ? (
                  <NavLinkMultipleView
                    icon={icon}
                    menuItems={mainLink.subLinks}
                    handleNavLinkClick={handleNavLinkClick}
                    showNameNotMoreIcon={mainLink.name}
                  />
                ) : (
                  <Link
                    data-testid={`dropdown-option-${createTestAttribute(
                      mainLink.name
                    )}`}
                    className={classes.navSubLink}
                    to={mainLink.link ?? ''}
                    onClick={() =>
                      handleNavLinkClick && handleNavLinkClick(mainLink.link)
                    }
                  >
                    {mainLink.name}
                  </Link>
                )}
              </MenuItem>
            );
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
