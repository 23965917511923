import { UrlString } from '@celito.clients/assets';
import { ActionTypeEnum, FormEngineModeTypeEnum } from '@celito.clients/enums';
import { UserTypes } from '@celito.clients/types';
import { isActionAllowed, isExistInUrl } from '@celito.clients/utils';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';

import { ListViewModuleType } from '../../../config/list-view.config';
import { RecordAction } from '../../../types';
import { validateActionRules } from '../../../utils';
import {
  ActionColumnProps,
  IActionItem,
  LifeCycleStageUserActionsPermissionEnum,
} from '../action-column.model';

const getActionMenuProps = (
  props: ActionColumnProps,
  user: UserTypes | null,
  isAdmin: boolean,
  recordUserActions: RecordAction[],
  onMenuItemClick: (action: RecordAction, rowItem: RowItem) => Promise<void>
): IActionItem[] => {
  let actions = props?.defaultActions;
  if (recordUserActions) {
    actions = recordUserActions;
  }
  return actions
    ?.filter(filterActionColumnItems(props, user, isAdmin))
    .map((action) => ({
      type: action.name,
      label: action.label,
      iconName: action.iconName,
      onActionClick: (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onMenuItemClick(action, props.rowItem);
      },
      permission: action.permission as LifeCycleStageUserActionsPermissionEnum,
    }));
};

const filterActionColumnItems = (
  props: ActionColumnProps,
  user: UserTypes | null,
  isAdmin: boolean
) => {
  const isPendingTaskView = props.objectName === ListViewModuleType.TASKS;
  const isTrainingPlanRow =
    props.rowItem?.rowData?.objectRecord?.objectDefinitionName !==
    ListViewModuleType.TRAINING_PLAN;
  const isUpdateTrainingPlanTaskRow =
    props.rowItem?.rowData?.tag === ListViewModuleType.UPDATE_TRAINING_PLAN;

  return (action: RecordAction) => {
    if (action.label === ActionTypeEnum.DELEGATE_TASK) {
      if (!props.rowItem?.rowData?.ownerName) {
        return false;
      }

      if (props.rowItem?.rowData?.isDelegationAllowed === false) {
        return false;
      }
    }

    if (props?.viewMetadata?.viewDto?.roles) {
      // checks action based on the user roles
      return isActionAllowed(
        user?.roles ?? [],
        props?.viewMetadata?.viewDto?.roles,
        action.actionType?.toLowerCase() as FormEngineModeTypeEnum
      );
    }

    // validates the action based on the row data
    if (action.actionRule) {
      return validateActionRules(
        action.actionRule,
        props.rowItem,
        props.objectMetadata
      );
    }

    if (
      isUpdateTrainingPlanTaskRow &&
      action.actionType === ActionTypeEnum.CUSTOM &&
      action.label === 'Claim' &&
      props.rowItem?.rowData?.ownerName
    ) {
      return false;
    }

    if (action.actionType === ActionTypeEnum.COMPLETE_TASK) {
      return isCompleteTaskActionShown(
        isPendingTaskView,
        isUpdateTrainingPlanTaskRow,
        isTrainingPlanRow,
        props.rowItem?.rowData?.ownerName
      );
    }

    if (action.actionType === ActionTypeEnum.CUSTOM) {
      return isCustomActionShown(isAdmin, props.rowItem?.rowData?.ownerName);
    }

    if (action.actionType === ActionTypeEnum.CANCEL) {
      return isCancelActionShown(props.rowItem?.rowData?.tag);
    }

    return true;
  };
};

const isCustomActionShown = (
  isAdmin: boolean,
  ownerName: string | undefined
) => {
  if (isExistInUrl(UrlString.MY_TASKS) && isAdmin && ownerName) {
    return false;
  }

  return true;
};

const isCancelActionShown = (tag: string) => {
  if (
    isExistInUrl(UrlString.CD_MY_TASKS) &&
    [
      'cd_follow_up',
      'cd_follow_up_quality_approval',
      'cd_follow_up_functional_approval',
    ].includes(tag)
  ) {
    return false;
  }

  return true;
};

const isCompleteTaskActionShown = (
  isPendingTaskView: boolean,
  isUpdateTrainingPlanTaskRow: boolean,
  isTrainingPlanRow: boolean,
  ownerName: string | undefined
) => {
  if (isPendingTaskView && isUpdateTrainingPlanTaskRow && !ownerName) {
    return false;
  }

  if (!isTrainingPlanRow && isPendingTaskView && !isUpdateTrainingPlanTaskRow) {
    return false;
  }

  return true;
};

export default getActionMenuProps;
