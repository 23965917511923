import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { useDeepCompareEffect } from '@celito.clients/hooks';
import { Loader, Stack } from '@celito.clients/shared';
import { isEmpty } from 'lodash';
import React from 'react';

import { WizardStepType } from '../../enums/wizard-enum';
import { evaluateConditions } from '../../utils/layout-rules';
import CurrentTypeSectionView from './types/current-type';
import ErrorTypeSectionView from './types/error-type';
import InactiveTypeSectionView from './types/inactive-type';
import SuccessTypeSectionView from './types/success-type';
import { WizardStepsProps } from './wizard-steps.model';
import classes from './wizard-steps.module.css';

const getWizardStepWrapper = (
  state: string,
  label: string,
  index: number,
  onClick: () => void
) => {
  const props = { label, index: index + 1, onClick };
  switch (state) {
    case WizardStepType.CURRENT:
      return <CurrentTypeSectionView {...props} />;
    case WizardStepType.INACTIVE:
      return <InactiveTypeSectionView {...props} />;
    case WizardStepType.SUCCESS:
      return <SuccessTypeSectionView {...props} />;
    case WizardStepType.ERROR:
      return <ErrorTypeSectionView {...props} />;
    default:
      return <InactiveTypeSectionView {...props} />;
  }
};

const WizardSteps: React.FC<WizardStepsProps> = ({
  sections = [],
  onStepClick,
  mode,
  step,
  wizardStepsState,
  recordDetails,
  setWizardConfig,
}) => {
  const getWizardChild = (
    index: number,
    mode: FormEngineModeTypeEnum,
    step: number
  ) => {
    const currentStepState = wizardStepsState[index];

    if (index === step) {
      // current step should not reflect error state even if it has
      return WizardStepType.CURRENT;
    }

    if (!currentStepState) {
      return WizardStepType.INACTIVE;
    }

    if (currentStepState.isValid === undefined && !currentStepState.isDirty) {
      return WizardStepType.INACTIVE;
    }

    return currentStepState.isValid
      ? WizardStepType.SUCCESS
      : WizardStepType.ERROR;
  };

  const sectionsVisibilityState = sections.reduce((acc, section) => {
    if (!section.layoutConfiguration) {
      acc[section.label] = true;
      return acc;
    }

    const { layoutConfig, defaultLayoutRules } =
      section?.layoutConfiguration ?? {};

    if (layoutConfig && 'rules' in layoutConfig) {
      layoutConfig.rules.forEach((rule) => {
        const conditionsMet = evaluateConditions(
          rule.conditions,
          recordDetails
        );

        if (conditionsMet) {
          acc[section.label] = rule.event.params?.isVisible ?? false;
        } else {
          acc[section.label] = defaultLayoutRules?.isVisible ?? false;
        }
      });
    }

    return acc;
  }, {} as Record<string, boolean>);

  useDeepCompareEffect(() => {
    if (isEmpty(recordDetails)) {
      return;
    }
    setWizardConfig(
      (prev) =>
        prev?.filter((section) => sectionsVisibilityState[section.label]) ?? []
    );
  }, [sectionsVisibilityState, recordDetails]);

  return (
    <Stack data-testid="wizard-step" className={classes.container}>
      {Object.keys(recordDetails).length <= 0 &&
      mode !== FormEngineModeTypeEnum.Create ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        sections.map((section, index) => {
          return (
            <React.Fragment key={section.name}>
              {getWizardStepWrapper(
                getWizardChild(index, mode, step),
                section.label,
                index,
                () => onStepClick(index)
              )}
            </React.Fragment>
          );
        })
      )}
    </Stack>
  );
};

export default WizardSteps;
