import { getHomeUrl } from '@celito.clients/utils';
import { FooterComponentProps } from 'libs/shared/src/lib/footer/src/footer.model';
import React from 'react';
import { useNavigate } from 'react-router';

import { PageNotFoundProps } from './page-not-found.model';
import PageNotFoundView from './page-not-found.view';

const PageNotFoundController: React.FC<PageNotFoundProps> = (props) => {
  const navigate = useNavigate();

  const handleNagivate = () => {
    navigate(getHomeUrl());
  };

  const footerData: FooterComponentProps = {
    itemList: [
      {
        label: 'Terms of Use',
        url: 'https://celitotech.com/terms-of-service/',
      },
      {
        label: 'Privacy Poilcy',
        url: 'https://celitotech.com/privacy-policy/',
      },
    ],
  };

  return (
    <PageNotFoundView
      onNavigate={handleNagivate}
      footerData={footerData}
      showFooter={props?.showFooter ?? true}
    />
  );
};

export default PageNotFoundController;
