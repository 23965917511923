import { ColumnDef } from '@tanstack/react-table';
import { Icon } from 'libs/shared/src/lib/shared';

import { RowItem } from '../../../types';
import columnHelper from '../column-helper';

const expandColumn = (areAllExpanded: boolean): ColumnDef<RowItem, unknown> => {
  return columnHelper.display({
    id: 'expand',
    header: () =>
      areAllExpanded ? (
        <Icon iconName="ChevronDown20Regular" />
      ) : (
        <Icon iconName="ChevronRight20Regular" />
      ),
    size: 20,
    meta: {
      columnType: 'expand',
    },
  });
};

export default expandColumn;
