import { ZIndex } from '@celito.clients/theme';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const navLinkStyles = makeStyles({
  customMenuButton: {
    ...shorthands.padding('0.3rem', '0.4rem'),
    color: Color.white,
    cursor: 'pointer',
    ...shorthands.textDecoration('none'),
    backgroundColor: 'transparent',
    ...shorthands.borderWidth(0),
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontFamily: 'Metropolis',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    justifyContent: 'start',
    '&>span, &>a': {
      display: 'block',
      textAlign: 'left',
    },
    '&>span::before, &>a::before': {
      display: 'block',
      content: 'attr(title)',
      fontWeight: 600,
      height: 0,
      ...shorthands.overflow('hidden'),
      visibility: 'hidden',
    },
    '&:hover>span, &:hover>a': {
      opacity: 1,
      fontWeight: 600,
    },
  },

  activeMenu: {
    ...shorthands.borderWidth(0, 0, '2px', 0),
    ...shorthands.borderBottom('3px', ' solid', Color.background_body_bg),
    fontWeight: 600,
  },

  maxWidthMenu: {
    width: '100%',
  },

  subMenu: {
    color: Color.black,
  },

  menuPopover: {
    zIndex: ZIndex.Popover,
  },

  menuItem: {
    ...shorthands.padding(0),
    color: Color.black,

    '> span > button': {
      display: 'flex',
      justifyContent: 'space-between',
    },

    '> span > button:hover': { backgroundColor: 'rgba(37, 72, 247, 0.05)' },

    '> span > a:hover': {
      backgroundColor: 'rgba(37, 72, 247, 0.05)',
    },
  },

  buttonTrigger: {
    backgroundColor: 'transparent',
    ...shorthands.borderWidth(0),
    cursor: 'pointer',
    ...shorthands.padding('0.5rem'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...shorthands.borderRadius('0.3125rem'),

    '&:hover': {
      backgroundColor: 'var(--action-hover)',
    },
  },
});
