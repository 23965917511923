import { Environment } from '@celito.clients/singleton';

import { defaultUrlConfig, NavigationRoutes } from '../config';
import { Constants } from '../enums/constants';
import { FormEngineModeTypeEnum } from '../enums/form-engine-mode-type';
import { ROUTES } from '../enums/routes';
import { ViewTypeEnum } from '../enums/view-type';
import { CelitoModule, Tab } from '../types/header-config';

const url: string = window.location.search;
const urlParams = new URLSearchParams(url);

export const checkIfPublicRoute = () => {
  const isPublicRoute = urlParams.get(Constants.IS_PUBLIC);
  if (isPublicRoute) {
    return true;
  }
  return false;
};

export const getLayoutUrl = (
  url: string,
  moduleName: string,
  tabName: string = defaultUrlConfig.defaultTabName,
  subTabName: string = defaultUrlConfig.defaultSubTabName
) => {
  let layoutURL: string = ROUTES.LAYOUT;
  layoutURL = layoutURL.replace(':moduleName', moduleName);
  layoutURL = layoutURL.replace(':tabName', tabName);
  layoutURL = layoutURL.replace(':subTabName', subTabName);

  return `${layoutURL}/${url}`;
};

export const getUrlForModule = (celitoModule: CelitoModule): string => {
  if (celitoModule.defaultRedirectTabName) {
    const tab = celitoModule.celitoTabs?.find(
      (t) => t.name === celitoModule.defaultRedirectTabName
    );
    if (tab) {
      return getUrlForTab(celitoModule, tab);
    }
  }
  return getUrlForTab(celitoModule, celitoModule.celitoTabs?.[0]);
};

const getReportingViewUrl = (route: string, viewName?: string | null) => {
  if (viewName) return route.replace(':viewName', viewName);

  return '';
};

export const getUrlForTab = (
  parentCelitoModule: CelitoModule,
  tab?: Tab,
  subTab?: Tab
): string => {
  const viewName = subTab?.viewName ?? tab?.viewName;
  switch (subTab?.viewType ?? tab?.viewType) {
    case ViewTypeEnum.List:
      return getLayoutUrl(
        `${ROUTES.LIST}/${viewName}`,
        parentCelitoModule.name,
        tab?.name,
        subTab?.name ?? tab?.childTabs?.[0]?.name
      );
    case ViewTypeEnum.Create:
      return getLayoutUrl(
        `${ROUTES.CREATE}/${viewName}`,
        parentCelitoModule.name,
        tab?.name,
        subTab?.name ?? tab?.childTabs?.[0]?.name
      );

    case ViewTypeEnum.Custom:
      return getLayoutUrl(
        `${viewName}`,
        parentCelitoModule.name,
        tab?.name,
        subTab?.name ?? tab?.childTabs?.[0]?.name
      );
    case ViewTypeEnum.ReportView:
      return getLayoutUrl(
        getReportingViewUrl(
          `${ROUTES.REPORTING_VIEW}`,
          subTab?.name ? subTab.name : tab?.name
        ),
        parentCelitoModule.name,
        tab?.name,
        subTab?.name ?? tab?.childTabs?.[0]?.name
      );

    default:
      if (!subTab && tab?.childTabs && tab?.childTabs?.length > 0) {
        return getUrlForTab(parentCelitoModule, tab, tab.childTabs?.[0]);
      }

      return getLayoutUrl(
        tab?.viewName ? tab.viewName : '',
        parentCelitoModule.name,
        tab?.name,
        subTab?.name
      );
  }
};

export const getUrlForSubTab = (
  parentCelitoModule: CelitoModule,
  tab: Tab,
  subTab: Tab
): string => {
  return getUrlForTab(parentCelitoModule, tab, subTab);
};

export const getHomeUrl = () => {
  return NavigationRoutes.home;
};

export const getListViewUrl = (viewName: string) => {
  return ROUTES.LIST_VIEW.replace(':viewName', viewName);
};

export const getTrainingMaterialViewUrl = () => {
  return getListViewUrl('training_material_view__a');
};

export const getUserLearningUrl = (
  mode: FormEngineModeTypeEnum,
  viewName: string,
  recordName: string
) => {
  let userLearningUrl = ROUTES.USER_LEARNING as string;

  userLearningUrl = userLearningUrl.replace(':mode', mode);
  userLearningUrl = userLearningUrl.replace(':viewName', viewName);
  userLearningUrl = userLearningUrl.replace(':recordName?', recordName);

  return userLearningUrl;
};

export const getUserDetailsViewUrl = (name: string) => {
  return `/md/admin__a/tb/users_and_groups__s/stb/user__s/view/custom/user__s/${name}`;
};

export const getSmartGroupViewUrl = (name: string) => {
  return `/md/lms__a/tb/user_learning__a/stb/smart_groups_tab__a/view/smart_group_detail_view__a/${name}`;
};

export const getTrainingRoleViewUrl = (name: string) => {
  return `/md/admin__a/tb/user_learning__a/stb/training_role_tab__s/view/role_detail_view__s/${name}`;
};

export const getTrainingMaterialCompleteUrl = (name: string) => {
  return `/md/lms__a/tb/training_assignment_parent__a/stb/my_assignment_tab__a/view/custom/taking_training/document_view__a?training_assignment_name=${name}`;
};

export const getMyAssignmentsListUrl = () => {
  return '/md/lms__a/tb/training_assignment_parent__a/stb/my_assignment_tab__a/list/custom/my_assignments_view__a';
};

export const getMyPendingTasksListUrl = () => {
  return '/md/lms__a/tb/pending_task__a/stb/my_tasks__a/list/my_tasks__a';
};

export const getDocumentViewUrl = (name: string, version: string) => {
  return `/md/controlled_docs__a/tb/controlled_document_tab__a/stb/default/view/custom/controlled_document_detail_view__a/${name}?version=${version}`;
};
export const getCDHomePageUrl = () => {
  return '/md/controlled_docs__a/tb/cd_home__a/stb/default/reporting/cd_home__a';
};

export const getULHomePageUrl = () => {
  return '/md/lms__a/tb/home__a/stb/default/reporting/home__a';
};

export const getRedirectionBaseUrl = () => {
  return Environment.getInstance().getParam('NX_REDIRECT_BASE_URL') as string;
};

export const getCoursePageUrl = (name: string, version: string) => {
  return `/md/lms__a/tb/library_parent__a/stb/library_course_tab__a/view/course_detail_view__a/${name}?version=${version}`;
};

export const getCurriculumPageUrl = (name: string, version: string) => {
  return `/md/lms__a/tb/library_parent__a/stb/library_curriculum_tab__a/view/curriculum_detail_view__a/${name}?version=${version}`;
};

export const setUrlHashParams = (
  url: string,
  params: Record<string, string>
) => {
  try {
    const urlObj = new URL(url);
    const dummyObj = new URL('https://dummy.com');

    dummyObj.search = urlObj.hash.substring(1);

    Object.entries(params).forEach(([key, value]) => {
      dummyObj.searchParams.set(key, value);
    });

    urlObj.hash = dummyObj.searchParams.toString();

    return urlObj.href;
  } catch (e) {
    return '';
  }
};

export const getUrlHashParams = (url: string): Record<string, string> => {
  try {
    const urlObj = new URL(url);

    return urlObj.hash
      .split('&')
      .reduce<Record<string, string>>((res, item) => {
        const parts = item.split('=');

        return {
          ...res,
          [parts[0]]: parts[1],
        };
      }, {});
  } catch (e) {
    return {};
  }
};
