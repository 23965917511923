import 'react-quill/dist/quill.snow.css';

import { Field, Stack } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { mergeClasses } from '@fluentui/react-components';
import { Controller, FieldValues, Path } from 'react-hook-form';
import ReactQuill from 'react-quill';

import { ControlledRichTextProps } from './controlled-richtext.model';
import classes from './controlled-richtext.module.css';
import { controlledRichTextStyles } from './controlled-richtext.styles';

export const ControlledRichTextView = <T extends FieldValues>({
  size = 'large',
  fieldWrapperClassName,
  containerClassName,
  ...props
}: ControlledRichTextProps<T>) => {
  const styles = controlledRichTextStyles();

  return (
    <Controller
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const handleChange = (text: string) => {
          if (text === '<p><br></p>') {
            text = '';
          }
          onChange(text);
        };

        return (
          <Stack
            data-testid={`richtext-container-${createTestAttribute(
              props.attribute?.label
            )}`}
            className={mergeClasses(classes.section, containerClassName)}
          >
            <Field
              label={props.attribute?.label}
              helperTextLabel={props.attribute.helpText}
              validationMessage={error?.message}
              validationState={error?.message ? 'error' : 'none'}
              required={
                'isRequired' in props.attribute
                  ? props.attribute.isRequired
                  : props.attribute.isMandatory
              }
              size={size}
            >
              <div
                data-testid={`rich-text-${createTestAttribute(
                  props.attribute?.label
                )}`}
                className={styles.richtextContainer}
              >
                <ReactQuill
                  placeholder={'Write Description'}
                  onChange={handleChange}
                  value={value}
                  className={mergeClasses(
                    classes.richtext,
                    size === 'large' && styles.large,
                    size === 'medium' && styles.medium,
                    size === 'small' && styles.small
                  )}
                  readOnly={!props.attribute?.isEditable}
                />
              </div>
            </Field>
          </Stack>
        );
      }}
    />
  );
};
