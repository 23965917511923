import { HeaderSidebarProps } from './header-sidebar.component';
import HeaderSidebarView from './header-sidebar.view';

interface HeaderSidebarControllerProps extends HeaderSidebarProps {}

const HeaderSidebarController = (
  props: HeaderSidebarControllerProps
): JSX.Element => {
  return <HeaderSidebarView {...props} />;
};

export default HeaderSidebarController;
