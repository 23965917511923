import { errorToast, infoToast, successToast } from '@celito.clients/utils';
import { useCallback } from 'react';

import { DocumentTypeService } from '../../services/document-type';

// FIXME: Record Activation Toggle doesn't require a hook, a simple function will work
export default function useToggleRecordActivation(
  props: {
    label?: string;
    name: string;
    isActive: boolean;

    /**
     * If true, document type will be toggled instead of document sub type
     */
    noSubTypes?: boolean;
  },
  onActivationToggle?: () => void
) {
  const toggleRecordActivation = useCallback(async () => {
    try {
      const { label, name, isActive } = props;
      infoToast({
        message: `${isActive ? 'Deactivating' : 'Activating'} ${
          label ?? 'Record'
        }`,
      });
      if (
        await DocumentTypeService.toggleDocumentType(
          name,
          isActive,
          props.noSubTypes
        )
      ) {
        successToast({
          title: label ?? 'Record',
          message: isActive ? 'Deactivated successfully' : 'has been Activated',
        });
        onActivationToggle?.();
      }
    } catch {
      errorToast({
        message: 'Failed to toggle record activation',
      });
    }
  }, [props]);
  return toggleRecordActivation;
}
