export enum AssignmentStatusEnum {
  Assigned = 'Assigned',
  InProgress = 'In Progress',
  Completed = 'Complete',
  Overdue = 'Overdue',
  Aborted = 'Aborted',
  UnAssigned = 'Unassigned',
  PendingSignOff = 'Pending SignOff',
  EvaluatingTraining = 'Evaluating Training',
}
