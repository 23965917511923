import { FieldValues } from 'react-hook-form';

import { ControlledDaterangePickerProps } from './controlled-daterange-picker.model';
import ControlledDaterangePickerView from './controlled-daterange-picker.view';

interface ControlledDaterangePickerControllerProps<T extends FieldValues>
  extends ControlledDaterangePickerProps<T> {}

const ControlledDaterangePickerController = <T extends FieldValues>(
  props: ControlledDaterangePickerControllerProps<T>
): JSX.Element => {
  return <ControlledDaterangePickerView {...props} />;
};

export default ControlledDaterangePickerController;
