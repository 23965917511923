import { TrainingAssignmentTypeEnum } from '@celito.clients/enums';
import { ListView } from '@celito.clients/list-view-engine';

interface OverdueTrainingAssignmentListScreenProps {
  trainingAssignmentType: TrainingAssignmentTypeEnum;
}

const OverdueTrainingAssignmentListScreen = (
  props: OverdueTrainingAssignmentListScreenProps
): JSX.Element => {
  return <ListView viewName="overdue_assignments_view__a" />;
};

export default OverdueTrainingAssignmentListScreen;
