import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const questionsColumnActionsStyles = makeStyles({
  btnTriiger: {
    ...shorthands.padding('6px'),
    display: 'flex',
    justifyContent: 'center',
    width: '38px',

    ':hover': {
      backgroundColor: Color.gray_1300,
    },
  },

  actionMenuItem: {
    '&:hover > span > div': {
      fontFamily: ThemeFontFamily.bold,
    },
    ...shorthands.padding('14px', '8px'),
  },

  menuList: {
    '> div:hover': {
      backgroundColor: 'rgba(37, 72, 247, 0.05)',
    },
  },

  itemContent: {
    display: 'flex',
    ...shorthands.gap('8px'),
    alignItems: 'center',
  },
});
