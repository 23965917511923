import { VirtualizerViewProps } from './virtualizer.model';

export const VirtualizerView = <T,>({
  items,
  handleScroll,
  rowHeight,
  visibleItemsLength,
  totalHeight,
  offsetY,
  visibleItems,
  positionRef,
  styles,
  itemRenderer,
  noItemRenderer,
  lastElementRef,
}: VirtualizerViewProps<T>) => {
  return (
    <div
      className={styles}
      ref={positionRef}
      onScroll={handleScroll}
      style={{ maxHeight: visibleItemsLength * rowHeight }}
    >
      <div style={{ height: totalHeight, width: '100%' }}>
        <div style={{ transform: `translateY(${offsetY}px)` }}>
          {visibleItems.map((option) => itemRenderer(option, lastElementRef))}
        </div>
      </div>
      {items.length === 0 && noItemRenderer && noItemRenderer()}
    </div>
  );
};
