import { LoaderProps } from './loader.model';
import LoaderView from './loader.view';

interface LoaderControllerProps extends LoaderProps {}

const LoaderController = (props: LoaderControllerProps): JSX.Element => {
  return <LoaderView {...props} />;
};

export default LoaderController;
