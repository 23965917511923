import { Field, FileUploadButton, Stack } from '@celito.clients/shared';
import { LayoutRuleProperties } from '@celito.clients/types';
import { isArrayOfStrings } from '@celito.clients/utils';
import { mergeClasses } from '@fluentui/react-components';
import { Controller, FieldError, FieldValues, Path } from 'react-hook-form';

import { evaluateRulesToAnOutcome } from '../../../utils/layout-rules';
import { ControlledFileUploadProps } from './controlled.file-upload.model';
import classes from './controlled.file-upload.module.css';

export const ControlledFileUploadView = <T extends FieldValues>({
  size = 'large',
  containerClassName,
  ...props
}: ControlledFileUploadProps<T>) => {
  return (
    <Controller<T>
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const { documentId = '' } = (props.watch &&
          props.watch('file__a' as Path<T>)) || {
          documentId: '',
        };

        const handleFileSelect = (file: File | null) => {
          onChange({
            target: {
              value: file ? file : null,
              name: file?.name,
            },
          });
        };

        const allowedFileExtensions = (() => {
          const defualutAllowedExtensions = ['*'];

          if (!props.attribute?.allowedExtensions) {
            return defualutAllowedExtensions;
          }

          if (
            props.attribute.allowedExtensions &&
            isArrayOfStrings(props.attribute.allowedExtensions)
          ) {
            return props.attribute.allowedExtensions as string[];
          }

          const formData = props?.watch?.() ?? {};

          const fieldValues = { ...props?.recordDetails, ...formData };

          const allowedExtensionsFromRulesOutcome = evaluateRulesToAnOutcome(
            props.attribute.allowedExtensions as LayoutRuleProperties[],
            (fieldValues ?? {}) as FieldValues
          )?.params?.message;

          return allowedExtensionsFromRulesOutcome ?? defualutAllowedExtensions;
        })();

        const notAllowedFileExtensions =
          (props.attribute?.notAllowedExtensions as string[]) ?? [];

        return (
          <Stack className={mergeClasses(classes.section, containerClassName)}>
            <Field
              label={props.attribute?.label}
              helperTextLabel={props.attribute.helpText}
              validationMessage={
                props.attribute?.errorParser
                  ? (props.attribute.errorParser(error) as FieldError).message
                  : error?.message
              }
              validationState={error?.message ? 'error' : 'none'}
              required={
                'isRequired' in props.attribute
                  ? props.attribute.isRequired
                  : props.attribute.isMandatory
              }
              size={size}
            >
              <FileUploadButton
                onFileSelect={handleFileSelect}
                fileName={
                  value?.nameWithExtension || value?.label || value?.name
                }
                attribute={props.attribute}
                watch={props.watch}
                setValue={props.setValue}
                reset={props.reset}
                error={error}
                mode={props.mode}
                documentId={documentId}
                objectName={props?.objectName}
                disabled={!props.attribute.isEditable}
                styles={props.styles}
                allowedExtensions={allowedFileExtensions}
                notAllowedExtensions={notAllowedFileExtensions}
                maxFileSizeInBytes={props.attribute?.maxFileSizeInBytes}
                recordName={props?.recordDetails?.name}
              />
            </Field>
          </Stack>
        );
      }}
    />
  );
};
