import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { useTheme } from '@celito.clients/theme';
import { Dialog, DialogSurface } from '@fluentui/react-components';
import { CustomButton, Loader } from 'libs/shared/src/lib/shared';

import classes from './export-modal.module.css';

interface ExportModalProps {
  onClose: () => void;
}

export const ExportModal = ({ onClose }: ExportModalProps): JSX.Element => {
  const { cssVariables } = useTheme();

  return (
    <Dialog open={true}>
      <DialogSurface>
        <div className={classes.container} style={{ ...cssVariables }}>
          <Loader />
          <h2>{LocalizationString.DOWNLOADING_FILE}</h2>
          <p>({LocalizationString.THIS_MAY_TAKE_A_FEW_MOMENTS})</p>
          <CustomButton
            buttonType={ButtonTypes.Primary}
            buttonTitle={LocalizationString.OK}
            onClick={onClose}
          />
        </div>
      </DialogSurface>
    </Dialog>
  );
};
