import {
  ObjectActionDefinition,
  WorkflowControlsDefinition,
} from '@celito.clients/types';

import {
  Actions,
  TaskActions,
  TaskAndUserActions,
} from './workflow-actions.model';

export const getWorkflowActions = (
  useractions: Actions[],
  listOfActions: ObjectActionDefinition[]
) => {
  const filterActions: ObjectActionDefinition[] = [];
  useractions?.map((useraction: Actions) => {
    listOfActions?.map((actions: ObjectActionDefinition) => {
      if (actions?.name === useraction?.name) {
        actions.permission = useraction.permission;
        filterActions.push(actions);
      }
    });
  });
  return filterActions;
};

export const getFilteredTaskActions = (
  actions: TaskActions[],
  listOfActions: ObjectActionDefinition[]
) => {
  const stageTaskActions: TaskAndUserActions = {
    task: [],
    user: [],
  };
  actions.forEach((useraction: TaskActions) => {
    listOfActions.forEach((actions: ObjectActionDefinition) => {
      if (actions.name === useraction.name) {
        actions.permission = useraction.permission;
        actions.controlName = useraction.controlName || [];
        actions.objectAttributes = useraction.objectAttributes || [];
        actions.workflowDefinitionName =
          useraction.workflowDefinitionName ?? '';
        if (actions.actionLevel === 'TaskGroup') {
          stageTaskActions.task.push(actions);
        } else {
          stageTaskActions.user.push(actions);
        }
      }
    });
  });
  return stageTaskActions;
};

export const getSortedControls = (controls: WorkflowControlsDefinition[]) => {
  return controls.sort((a, b) => a?.displayOrder - b?.displayOrder);
};
