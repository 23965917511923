export enum AttributeTypeEnum {
  Number = 'Number',
  PlainText = 'PlainText',
  RichText = 'RichText',
  Reference = 'Reference',
  YesNo = 'YesNo',
  ActiveInactive = 'ActiveInactive',
  Document = 'Document',
  DateTime = 'DateTime',
  AvailableMembers = 'AvailableMembers',
  Date = 'Date',
  Dropdown = 'Dropdown',
  SetRule = 'SetRule',
  Picklist = 'Picklist',
  Department = 'Department',
  JSON = 'JSON',
  MultipleDocument = 'MultipleDocument',
  ReferenceDocuments = 'ReferenceDocuments',
  RadioYesNo = 'RadioYesNo',
  ReferenceSelector = 'ReferenceSelector',
  Array = 'ARRAY',
  String = 'STRING',
  UUID = 'uuid',
  FixedList = 'FixedList',

  // Additional types from filters
  Email = 'Email',
  USMobileNumber = 'USMobileNumber',
  MobileNumberWithCountryCode = 'MobileNumberWithCountryCode',
  PeopleSearch = 'PeopleSearch',
  LongText = 'LongText',
  User = 'User',
  Record = 'Record',
  ObjectDefinition = 'ObjectDefinition',

  // custom types (apart from the ones defined in backend)
  None = 'None',
  DateRange = 'DateRange',
  NumberRange = 'NumberRange',
  NumberWithAll = 'NumberWithAll',
}
