import { defaultUrlConfig } from '@celito.clients/endpoints';

import { resultModuleRegex } from '../consts/constants';
import {
  FacetObject,
  ResultCardObject,
  SelectedFacetsObject,
  SelectedSortObject,
} from '../search-result.model';

export const isAnyFacetSelected = (
  selectedFacetList: SelectedFacetsObject[]
) => {
  return (
    selectedFacetList.reduce((result: string[], item: SelectedFacetsObject) => {
      return result.concat(item.values);
    }, []).length > 0
  );
};

export const getDetailsViewUrl = (
  detailViewName: string,
  recordName: string,
  version: string
) => {
  if (version)
    return `../view/${detailViewName}/${recordName}?version=${version}`;
  return `../view/${detailViewName}/${recordName}`;
};

export const isFacetOfSelectedModule = (
  facet: FacetObject,
  selectedModule: string | null
) => {
  return (
    facet.module === selectedModule?.replace(resultModuleRegex, '') ||
    (facet.module === 'All' &&
      selectedModule?.replace(resultModuleRegex, '') === 'All Application')
  );
};

export const getCurrentModule = (moduleName: string | undefined) => {
  let selectedModule = '';

  if (!moduleName || moduleName === defaultUrlConfig.defaultModuleName) {
    return '';
  }

  switch (moduleName) {
    case 'lms__a':
      selectedModule = 'User Learning';
      break;
    case 'admin__a':
      selectedModule = 'Platform';
      break;
    case 'controlled_docs__a':
      selectedModule = 'Controlled Documents';
      break;
    default:
      break;
  }

  return selectedModule;
};

export const getSelectedQuickFilterBasedOnApiResponse = (
  selectedQuickFilter: string,
  newQuickFilters: string[]
) => {
  return (
    newQuickFilters.find((quickFilter) =>
      quickFilter.includes(selectedQuickFilter)
    ) ?? newQuickFilters[0]
  );
};

export const getSortedResults = (
  searchResults: ResultCardObject[],
  selectedSortOption: SelectedSortObject
) => {
  if (!selectedSortOption) {
    return searchResults.sort((firstResult, secondResult) =>
      secondResult.createdAtDate!.localeCompare(firstResult.createdAtDate!)
    );
  }

  if (selectedSortOption.column === 'modifiedAtUtc') {
    if (selectedSortOption.isAscending) {
      return searchResults.sort((firstResult, secondResult) =>
        firstResult.modifiedAtDate!.localeCompare(secondResult.modifiedAtDate!)
      );
    }

    return searchResults.sort((firstResult, secondResult) =>
      secondResult.modifiedAtDate!.localeCompare(firstResult.modifiedAtDate!)
    );
  } else {
    if (selectedSortOption.isAscending) {
      return searchResults.sort((firstResult, secondResult) =>
        firstResult.createdAtDate!.localeCompare(secondResult.createdAtDate!)
      );
    }

    return searchResults.sort((firstResult, secondResult) =>
      secondResult.createdAtDate!.localeCompare(firstResult.createdAtDate!)
    );
  }
};
