import { CourseData } from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { RelatedCourse, RelatedCourseProps } from './related-course.model';
import { RelatedCourseView } from './related-course.view';
import { getCourseData, ITEM_LIMIT_PER_PAGE } from './services';

export const RelatedCourseController = (props: RelatedCourseProps) => {
  const params = useParams();

  const [relatedCourse, setRelatedCourse] = useState<RelatedCourse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPageCount: 1,
    isLoading: true,
  });

  const handleRelatedCourse = async (currentPage: number) => {
    try {
      setIsLoading(true);
      setPagination((prev) => ({
        ...prev,
        isLoading: true,
      }));
      const trainingMaterialName = params.recordName;

      if (!trainingMaterialName) return;

      const courseData = await getCourseData(
        trainingMaterialName,
        props.attributeConfig.name,
        currentPage
      );
      const relatedTrainingCourse = courseData.data.map(
        (course: CourseData): RelatedCourse => ({
          docId: course.label ? course.label : '',
          courseName: course.title,
          version: course.version,
          status: course.status ? course.status : '',
          key: course.name,
        })
      );
      setRelatedCourse(relatedTrainingCourse);
      setPagination((prev) => ({
        ...prev,
        totalPageCount: Math.ceil(courseData.total / ITEM_LIMIT_PER_PAGE),
      }));
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      setIsLoading(false);
      setPagination((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const handlePageUpdate = (page: number) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    handleRelatedCourse(page);
  };

  useEffect(() => {
    handleRelatedCourse(1);
  }, [params]);

  const handleGoToNextStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.goToNextStep(e);
  };

  useEffect(() => {
    return () => {
      props.setWizardStepsState((prev) =>
        prev.map((step) =>
          step.number === props.step
            ? {
                ...step,
                isDirty: false,
                hasNavigated: undefined,
                isValid: undefined,
              }
            : step
        )
      );
    };
  }, []);

  return (
    <RelatedCourseView
      {...props}
      relatedCourse={relatedCourse}
      isCoursesLoading={isLoading}
      paginationProps={{
        handlePageUpdate: handlePageUpdate,
        currentPage: pagination.currentPage,
        totalPageCount: pagination.totalPageCount,
        isLoading: pagination.isLoading,
      }}
      goToNextStep={handleGoToNextStep}
    />
  );
};
