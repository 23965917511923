import { ButtonTypes } from '@celito.clients/enums';
import { CustomButton, GridView, Icon } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { Skeleton, SkeletonItem } from '@fluentui/react-components';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import React from 'react';
import ReactPaginate from 'react-paginate';

import { DelegationListViewProps } from './delegation-list.model';
import classes from './delegation-list.module.css';

const DelagationListView: React.FC<DelegationListViewProps> = ({
  onCreateDelegation,
  isLoading = false,
  isBizAdmin,
  paginateInfo,
  handlePaginatedDelegates,
  columnData,
  rowData,
}) => {
  const { cssVariables } = useTheme();

  return (
    <div style={{ ...cssVariables }}>
      <div className={classes.createGrid}>
        <div className={classes.createGridRow}>
          <b>{LocalStrings.startWithNewDelegation}</b>
          <CustomButton
            buttonType={ButtonTypes.Primary}
            buttonTitle={LocalStrings.createLabel}
            onButtonClicked={onCreateDelegation}
            leftIcon="AddIn"
          />
        </div>
      </div>
      <div className={classes.delegateListGrid}>
        <div className={classes.recentDelagate}>
          <b>{LocalStrings.recentDelegates}</b>
        </div>
        <div>
          {isLoading ? (
            <Skeleton animation="pulse">
              <SkeletonItem size={40} />
              <SkeletonItem style={{ marginTop: '10px', height: '400px' }} />
            </Skeleton>
          ) : (
            <>
              <GridView
                columns={columnData}
                items={rowData}
                disableSelection
                disableDefaultSorting
              />
              <div className={classes.bottomContainer}>
                {isBizAdmin && paginateInfo.total > paginateInfo.limit && (
                  <div className={classes.pagination}>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<Icon iconName="ChevronRight16Regular" />}
                      previousLabel={<Icon iconName="ChevronLeft16Regular" />}
                      onPageChange={({ selected }) =>
                        handlePaginatedDelegates(selected)
                      }
                      pageRangeDisplayed={paginateInfo.limit}
                      pageCount={Math.ceil(
                        paginateInfo.total / paginateInfo.limit
                      )}
                      activeClassName={classes.active}
                      disabledClassName={classes.disabled}
                      forcePage={paginateInfo.page - 1}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DelagationListView;
