import { forwardRef } from 'react';

import { PreviewPanelController } from './preview-panel.controller';
import { PreviewPanelProps } from './preview-panel.model';

export const PreviewPanel = forwardRef<HTMLDivElement, PreviewPanelProps>(
  (props, ref) => {
    return <PreviewPanelController {...props} ref={ref} />;
  }
);
