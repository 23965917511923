import { Color } from '@celito.clients/theme';
import { makeStyles } from '@fluentui/react-components';

export const styles = makeStyles({
  toastContainer: {
    minWidth: 'fit-content',
    maxWidth: '70vw',
    marginTop: '1.5rem',
  },
  toastRoot: {
    '>.fui-ToastTitle__action': {
      '>button': {
        width: '1rem',
        height: '1rem',
        padding: '0.25rem',
        minWidth: 0,
        ':hover': {
          backgroundColor: 'unset',
        },
        '>span': {
          width: '1rem',
          height: '1rem',
        },
      },
    },
    '>.fui-ToastTitle__media': {
      fontSize: '1.25rem',
    },
  },
  toastBody: {
    color: Color.toast_body,
    lineHeight: '1.25rem',
    wordBreak: 'break-word',
    paddingTop: '0',
  },
  toastTitle: {
    fontWeight: 'bold',
  },
});
