export enum CustomViewTypeEnum {
  TRAINING_PLAN_DETAIL_VIEW = 'plan_detail_view__a',
  TRAINING_PLAN_EDIT_VIEW = 'plan_edit_view__a',
  TRAINING_PLAN_LIST_VIEW = 'training_plan_view__a',
  QUIZ_EDIT_VIEW = 'quiz_edit_view__a',
  MATERIAL_EDIT_VIEW = 'material_edit_view__a',
  UNSUBMITTED_CONTROL_DOCS_LIST_VIEW = 'controlled_document_view__a',
  CONTROLLED_DOCUMENT_PLAN_DETAIL_VIEW = 'controlled_document_detail_view__a',
  CONTROLLED_DOCUMENT_EDIT_VIEW = 'controlled_document_edit_view__a',
  COURSE_DETAIL_VIEW = 'course_detail_view__a',
  CURRICULUM_DETAIL_VIEW = 'curriculum_detail_view__a',
  COURSE_DETIAL_NOT_APPROVED_VIEW = 'course_detail_not_approved_view__a',
}
