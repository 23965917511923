import { makeStyles, shorthands } from '@fluentui/react-components';

export const getStyles = makeStyles({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    ...shorthands.flex(1),
    ...shorthands.gap('16px'),
    ...shorthands.overflow('hidden'),
  },
  gridView: {
    ...shorthands.flex(1),
    ...shorthands.overflow('auto'),
  },
  link: {
    ...shorthands.textDecoration('none'),
  },
  pagination: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    marginTop: 'auto',
  },
});
