import { LocalizationString } from '@celito.clients/assets';
import {
  ActionTypeEnum,
  ButtonTypes,
  ViewTypeEnum,
} from '@celito.clients/enums';
import { actionColumnListViewStyles } from '@celito.clients/list-view-engine';
import { CustomButton, Icon } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import {
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
  Spinner,
} from '@fluentui/react-components';

import ActionHandler from '../actions-handler/actions-handler.component';
import { getStyles } from '../task/styles';
import { ActionButtonType } from '../task/types';
import { WorkflowActionsViewProps } from './workflow-actions.model';
import { actionStyles } from './workflow-actions.styles';

const WorkflowActionsView: React.FC<WorkflowActionsViewProps> = (
  props: WorkflowActionsViewProps
) => {
  const actionColumnStyles = actionColumnListViewStyles();
  const styles = getStyles();
  const classes = actionStyles();
  const currentAction = props?.workflowActions?.length
    ? props?.workflowActions
    : props?.actions;

  return (
    <>
      <div
        className={
          props?.buttonType !== ActionButtonType.ICON
            ? styles.flex50
            : undefined
        }
      >
        {props?.loading ? (
          <Spinner className={classes.spinner} size="tiny" />
        ) : (
          <Menu
            positioning={'below-start'}
            open={props?.open}
            onOpenChange={props?.handleOpenChange}
          >
            <MenuTrigger>
              <div
                className={
                  props?.actionType === ActionTypeEnum.STAGE
                    ? classes.stageActionIcon
                    : classes.taskActionIcon
                }
              >
                {props?.buttonType === ActionButtonType.ICON ? (
                  <Icon
                    data-testid={props.dataTestId}
                    iconName="MoreHorizontal24Regular"
                    onClick={() =>
                      props?.getActionsBasedOnActionType(props?.actionType)
                    }
                  />
                ) : (
                  <CustomButton
                    buttonTitle="Actions"
                    buttonType={ButtonTypes.Ghost}
                    rightIcon={props?.open ? 'ArrowUp' : 'ArrowDown'}
                    customStyles={styles.fullWidth}
                    isLoading={props?.isActionsLoading}
                    disabled={props?.isActionsLoading}
                  ></CustomButton>
                )}
              </div>
            </MenuTrigger>
            <MenuPopover className={styles.actionContainer}>
              <MenuList className={actionColumnStyles.menuList}>
                {!currentAction?.length && (
                  <div
                    className={mergeClasses(
                      actionColumnStyles.itemContent,
                      classes.m5
                    )}
                  >
                    {LocalizationString.NO_ACTION_FOUND}
                  </div>
                )}
                {currentAction?.map((action) =>
                  !action ? null : (
                    <MenuItem
                      key={action?.name}
                      disabled={action?.permission === ViewTypeEnum.View}
                      className={actionColumnStyles.actionMenuItem}
                      onClick={() => props?.callActionsHandler(action)}
                    >
                      <div
                        className={actionColumnStyles.itemContent}
                        data-testid={`context-menu-option-${createTestAttribute(
                          action?.label
                        )}`}
                      >
                        {action?.label}
                      </div>
                    </MenuItem>
                  )
                )}
              </MenuList>
            </MenuPopover>
          </Menu>
        )}
      </div>
      <ActionHandler
        closeActionPopup={props?.closeActionPopup}
        openHandler={props?.openHandler}
        attributeConfig={props.objectDefinitions!}
        recordData={props.recordData!}
        selectedAction={props?.selectedAction}
        controls={props?.controls}
        prePopulatedData={props.prePopulatedData!}
        openActionPopup={props?.openActionPopup}
        setActionsToLoad={props?.setActionsToLoad}
        taskName={props?.taskName}
        users={props?.users}
        currentTaskDueDate={props?.currentTaskDueDate ?? ''}
        currentTaskTag={props?.currentTaskTag ?? ''}
      />
    </>
  );
};

export default WorkflowActionsView;
