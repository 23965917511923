import { SpinnerSize } from '@celito.clients/enums';
import { makeStyles, mergeClasses, Spinner } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

import { Stack } from '../../shared';
import { LoaderProps } from './loader.model';

interface LoaderViewProps extends LoaderProps {}

const useLoaderStyles = makeStyles({
  loaderStack: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const LoaderView = ({
  withLabel = true,
  label = 'Loading...',
  labelPosition = 'below',
  dataTestId,
  color = Color.blue_2000,
  size = SpinnerSize.large,
  fullPage,
  className,
  style,
}: LoaderViewProps): JSX.Element => {
  const loaderStyles = useLoaderStyles();
  return (
    <Stack
      className={mergeClasses(fullPage && loaderStyles.loaderStack, className)}
      data-testid={dataTestId}
    >
      <Spinner
        size={size}
        labelPosition={labelPosition}
        style={style}
        {...(withLabel && { label: label })}
      />
    </Stack>
  );
};

export default LoaderView;
