import React from 'react';

import PanelController from './panel.controller';
import type { IPanelComponentProps } from './panel.model';

const PanelComponent: React.FC<IPanelComponentProps> = (props) => {
  return <PanelController {...props} />;
};

export default PanelComponent;
