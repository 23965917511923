import { FieldValues } from 'react-hook-form';

import { ControlledPicklistController } from './controlled-picklist.controller';
import { ControlledPicklistProps } from './controlled-picklist.model';

export const ControlledPicklist = <T extends FieldValues>(
  props: ControlledPicklistProps<T>
) => {
  return <ControlledPicklistController {...props} />;
};
