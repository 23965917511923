import { ObjectEnum } from '@celito.clients/enums';
import { useConfigureLayout, useQueryParams } from '@celito.clients/hooks';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { SchemaFieldDescription } from 'yup';

import { DocumentSubTypeService } from '../../../services/document-sub-type';
import { DocumentTypeService } from '../../../services/document-type';
import { TAction } from '../../context';
import useToggleRecordActivation from '../../hooks/use-toggle-record-activation';
import { formSchema } from '../../schema';

const createTextObject = (text: string) => ({ text, label: text });

export const useController = () => {
  useConfigureLayout({
    pageTitle: '',
    enablePadding: false,
  });

  const pathParams = useParams();

  const { getSearchParams } = useQueryParams();

  const fields = Object.values(formSchema.fields).reduce((acc, field) => {
    return [...acc, field.describe()];
  }, [] as SchemaFieldDescription[]);

  const [values, setValues] = useState<Record<string, unknown>>({});

  const documentSubType = useMemo(() => {
    return getSearchParams().documentSubType as string | undefined | null;
  }, [getSearchParams]);

  const { isLoading, refetch } = useQuery(
    [ObjectEnum.DOCUMENT_TYPE, pathParams?.documentType, documentSubType],
    {
      queryFn: async () => {
        if (!pathParams?.documentType) return null;
        if (documentSubType) {
          const {
            data: { data },
          } = await DocumentSubTypeService.getDocumentSubType(documentSubType);
          const {
            isFollowUpRequired,
            followUpTimePeriodInMonths,
            followUpTriggerPeriodInDays,
            effectivenessPeriodInDays,
            isActive,
            isTrainable,
            isAdminReviewRequired,
            code,
            isRawFileDownloadAllowed,
            documentTemplate,
            documentType,
            label,
            name,
          } = data[0];
          const templateName = documentTemplate
            ? {
                text: documentTemplate.title,
                value: documentTemplate.name,
              }
            : '';
          return {
            documentType: createTextObject(documentType.label),
            documentTypeName: createTextObject(documentType.name),
            documentTypeCode: createTextObject(documentType.code),
            documentSubType: createTextObject(label),
            documentSubTypeCode: createTextObject(code),
            documentSubTypeName: name,
            isRawFileDownloadAllowed,
            isFollowUpRequired,
            followUpTimePeriodInMonths,
            followUpTriggerPeriodInDays,
            effectivenessPeriodInDays,
            isTrainable,
            isActive,
            templateName,
            isAdminReviewRequired,
          };
        }
        const { data } = await DocumentTypeService.getDocumentType(
          pathParams?.documentType
        );
        const {
          documentTemplate,
          isFollowUpRequired,
          followUpTimePeriodInMonths,
          followUpTriggerPeriodInDays,
          effectivenessPeriodInDays,
          isTrainable,
          isAdminReviewRequired,
          code,
          isRawFileDownloadAllowed,
          isActive,
          label,
          name,
        } = data;
        const templateName = documentTemplate
          ? {
              text: documentTemplate.title,
              value: documentTemplate.name,
            }
          : '';
        return {
          documentType: createTextObject(label),
          documentTypeName: createTextObject(name),
          documentTypeCode: createTextObject(code),
          documentSubType: createTextObject(''),
          documentSubTypeCode: createTextObject(''),
          isRawFileDownloadAllowed,
          isFollowUpRequired,
          followUpTimePeriodInMonths,
          followUpTriggerPeriodInDays,
          effectivenessPeriodInDays,
          isTrainable,
          isActive,
          templateName,
          isAdminReviewRequired,
        };
      },
      onSuccess(data) {
        if (data) setValues(data);
      },
      enabled: !!pathParams?.documentType,
    }
  );
  const { documentType } = useParams();
  const toggleRecordActivation = useToggleRecordActivation(
    {
      isActive: Boolean(values?.isActive),
      name: documentSubType ?? (documentType as string),
      label: documentSubType
        ? (values.documentSubType as { label: string })?.label
        : (values.documentType as { label: string })?.label,
      noSubTypes: !documentSubType,
    },
    refetch
  );
  const navigate = useNavigate();
  const contextMenuActions: TAction[] = useMemo(() => {
    return [
      {
        label: 'Edit',
        name: 'Edit',
        onClick: () => {
          navigate(
            (function EditRoute() {
              const currentLocation = window.location;
              const newLocation =
                currentLocation.pathname.replace(
                  'view/custom/document_type_view__a',
                  'edit/custom/document_type_edit_view__a'
                ) + currentLocation.search;
              return newLocation;
            })()
          );
        },
        iconName: 'Edit20Regular',
      },
      {
        name: 'Deactivate',
        label: values?.isActive ? 'Deactivate' : 'Activate',
        onClick: toggleRecordActivation,
        iconName: values?.isActive
          ? 'ToggleLeft24Regular'
          : 'ToggleRight24Regular',
      },
    ];
  }, [values, toggleRecordActivation, navigate]);
  return {
    fields,
    contextMenuActions,
    values,
    isLoading: isLoading || Object.values(values).length === 0,
  };
};
