import {
  FormEngineModeTypeEnum,
  ObjectEnum,
  SortOrderEnum,
} from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import { Datum } from '@celito.clients/list-view-engine';
import { GridViewProps } from '@celito.clients/shared';
import { errorToast } from '@celito.clients/utils';
import { AxiosError } from 'axios';
import {
  getIsSortedAscending,
  getSortOrder,
} from 'libs/list-view-engine/src/lib/utils/sort.util';
import {
  ColumnData,
  RowItem,
} from 'libs/shared/src/lib/grid-view-new/src/types';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { ReferenceSelectorItem } from '../../reference-selector/src/reference-selector.model';
import { getObjects } from '../../reference-selector/src/services/getSelectorData';
import {
  getColumnsConfigByObjectName,
  transformToGridRow,
} from '../../reference-selector/src/utils';
import { getUserFilter } from '../../reference-selector/src/utils/filters-by-object';
import { getReferenceDocuments } from '../../reference-selector/src/utils/getReferenceDocumentData';
import { ReferenceSelectorPreviewProps } from './reference-selector-preview.model';
import ReferenceSelectorPreviewView from './reference-selector-preview.view';

const ReferenceSelectorPreviewController = (
  props: ReferenceSelectorPreviewProps
): JSX.Element => {
  const [referenceDocuments, setReferenceDocuments] = useState<RowItem[]>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState<RowItem | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [isUserViewPanelOpen, setIsUserViewPanelOpen] = useState(false);
  const [columnData, setColumnData] = useState<GridViewProps['columns']>([]);
  const activeModule = useActiveModule();
  const isReferenceDocsFetched = useRef(true);

  useEffect(() => {
    if (isReferenceDocsFetched.current) {
      isReferenceDocsFetched.current = false;
      setColumnData(
        getColumnsConfigByObjectName(
          props.objectName,
          handleActionClick,
          props.mode as FormEngineModeTypeEnum,
          true,
          undefined,
          undefined,
          onColumnClick,
          onUserView
        )
      );
    }
  }, [referenceDocuments]);

  const handleCancelClick = () => {
    setIsConfirmDialogOpen(false);
    setItemToBeDeleted(null);
  };

  const handleConfirmClick = () => {
    if (props.removeItem) {
      props.removeItem(itemToBeDeleted as RowItem);
      setItemToBeDeleted(null);
      setIsConfirmDialogOpen(false);
    }
  };

  const handleActionClick = (val: RowItem) => {
    setItemToBeDeleted(val);
    setIsConfirmDialogOpen(true);
  };

  const onUserView = (name: string) => {
    setIsUserViewPanelOpen(true);
    setUserName(name);
  };

  const onDismiss = () => {
    setIsUserViewPanelOpen(false);
  };

  useEffect(() => {
    if (props?.items?.length > 0) {
      if (
        props?.mode === FormEngineModeTypeEnum.View &&
        props?.objectName === 'user__s'
      ) {
        setIsLoading(true);
        const userNameList = props?.items.map((item: any) => item.name);
        const params = {
          limit: userNameList.length,
          page: 1,
        };
        getObjects(
          props?.objectName,
          activeModule ? [activeModule?.systemName] : [],
          params,
          getUserFilter(userNameList),
          undefined,
          undefined,
          props?.filterByExactRoles
        )
          .then((res: any) => {
            const objects = res.objects as Datum[];
            const selectorItems: ReferenceSelectorItem[] =
              objects.map(transformToGridRow);
            setReferenceDocuments(selectorItems);
            setIsLoading(false);
            isReferenceDocsFetched.current = true;
          })
          .catch((error: any) => {
            if ((error as AxiosError<any>)?.name !== 'TypeError') {
              errorToast({
                message: `Could not fetch the items`,
              });
            }
          });
      }
      if (props?.objectName === ObjectEnum.CONTROLLED_DOCUMENT) {
        getReferenceDocumentItems();
      } else {
        setReferenceDocuments(props?.items);
        isReferenceDocsFetched.current = true;
      }
    } else {
      setReferenceDocuments([]);
    }
  }, [props?.items, props?.objectName, props?.mode]);

  const getReferenceDocumentItems = async () => {
    const selectedRefDocs = await getReferenceDocuments(
      props?.objectName,
      props?.items,
      setIsLoading,
      activeModule
    );
    selectedRefDocs && setReferenceDocuments(selectedRefDocs);
    isReferenceDocsFetched.current = true;
  };

  const onColumnClick: ColumnData['onColumnClick'] = (
    _ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column: ColumnData
  ) => {
    setColumnData((prev) =>
      prev.map((c) => {
        if (c.key === column.key) {
          return {
            ...c,
            isSortedAscending: getIsSortedAscending(
              c.isSorted,
              c.isSortedAscending
            ),
            isSorted: true,
          };
        }
        return {
          ...c,
          isSorted: false,
        };
      })
    );
    const sortedData = _.orderBy(
      referenceDocuments,
      [column.fieldName],
      [
        getSortOrder(column.isSorted, column.isSortedAscending, true) as
          | SortOrderEnum.ASC_LOWERCASE
          | SortOrderEnum.DESC_LOWERCASE,
      ]
    );
    setReferenceDocuments(sortedData);
  };

  return (
    <ReferenceSelectorPreviewView
      {...props}
      items={referenceDocuments}
      handleCancelClick={handleCancelClick}
      handleConfirmClick={handleConfirmClick}
      isConfirmDialogOpen={isConfirmDialogOpen}
      handleActionClick={handleActionClick}
      isLoading={isLoading}
      onDismiss={onDismiss}
      userName={userName}
      isUserViewPanelOpen={isUserViewPanelOpen}
      columnData={columnData}
    />
  );
};

export default ReferenceSelectorPreviewController;
