import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledTextFieldProps } from './controlled-textfield.model';
import { ControlledTextFieldView } from './controlled-textfield.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const ControlledTextFieldController = <T extends FieldValues>(
  props: ControlledTextFieldProps<T>
) => {
  return <ControlledTextFieldView {...props} />;
};
