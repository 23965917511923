import { LocalizationString } from '@celito.clients/assets';
import { AttributeTypeEnum, OperatorsEnum } from '@celito.clients/enums';

import { FilterConditionConfig } from '../types';

export const getConditionsForDataType = (
  dataType?: AttributeTypeEnum
): FilterConditionConfig[] => {
  return getAllowedOperatorsForDataType(dataType).map((operator) => ({
    key: operator,
    text: getUiTextFromOperatorEnum(operator),

    operator,
  }));
};

export const getAllowedOperatorsForDataType = (
  dataType?: AttributeTypeEnum
): OperatorsEnum[] => {
  switch (dataType) {
    case AttributeTypeEnum.Number:
      return [
        OperatorsEnum.EQUALS,
        OperatorsEnum.NOT_EQUALS,
        OperatorsEnum.GREATER_THAN,
        OperatorsEnum.GREATER_THAN_EQUALS,
        OperatorsEnum.LESS_THAN,
        OperatorsEnum.LESS_THAN_EQUALS,
        OperatorsEnum.RANGE,
        OperatorsEnum.IS_BLANK,
        OperatorsEnum.IS_NOT_BLANK,
      ];

    case AttributeTypeEnum.LongText:
    case AttributeTypeEnum.PlainText:
      return [
        OperatorsEnum.EQUALS,
        OperatorsEnum.NOT_EQUALS,
        OperatorsEnum.BEGINS_WITH,
        OperatorsEnum.NOT_BEGINS_WITH,
        OperatorsEnum.ENDS_WITH,
        OperatorsEnum.NOT_ENDS_WITH,
        OperatorsEnum.IS_BLANK,
        OperatorsEnum.IS_NOT_BLANK,
        OperatorsEnum.CONTAINS,
        OperatorsEnum.NOT_CONTAINS,
      ];

    case AttributeTypeEnum.Date:
    case AttributeTypeEnum.DateTime:
      return [
        OperatorsEnum.RANGE,
        OperatorsEnum.IN_LAST_DAYS,
        OperatorsEnum.IN_LAST_WEEKS,
        OperatorsEnum.IN_LAST_MONTHS,
        OperatorsEnum.IN_NEXT_DAYS,
        OperatorsEnum.IN_NEXT_WEEKS,
        OperatorsEnum.IN_NEXT_MONTHS,
      ];

    default:
      return [OperatorsEnum.EQUALS, OperatorsEnum.NOT_EQUALS];
  }
};

export const getUiTextFromOperatorEnum = (operator: OperatorsEnum): string => {
  switch (operator) {
    case OperatorsEnum.GREATER_THAN:
      return LocalizationString.GREATER_THAN;
    case OperatorsEnum.GREATER_THAN_EQUALS:
      return LocalizationString.GREATER_THAN_EQUALS;
    case OperatorsEnum.LESS_THAN:
      return LocalizationString.LESS_THAN;
    case OperatorsEnum.LESS_THAN_EQUALS:
      return LocalizationString.LESS_THAN_EQUALS;
    case OperatorsEnum.EQUALS:
      return LocalizationString.EQUALS;
    case OperatorsEnum.NOT_EQUALS:
      return LocalizationString.NOT_EQUALS;
    case OperatorsEnum.BEGINS_WITH:
      return LocalizationString.BEGINS_WITH;
    case OperatorsEnum.CONTAINS:
      return LocalizationString.CONTAINS;
    case OperatorsEnum.ENDS_WITH:
      return LocalizationString.ENDS_WITH;
    case OperatorsEnum.IN:
      return LocalizationString.IN;
    case OperatorsEnum.NOT_BEGINS_WITH:
      return LocalizationString.NOT_BEGINS_WITH;
    case OperatorsEnum.NOT_CONTAINS:
      return LocalizationString.NOT_CONTAINS;
    case OperatorsEnum.NOT_ENDS_WITH:
      return LocalizationString.NOT_ENDS_WITH;
    case OperatorsEnum.NOT_IN:
      return LocalizationString.NOT_IN;
    case OperatorsEnum.RANGE:
      return LocalizationString.RANGE;
    case OperatorsEnum.IS_BLANK:
      return LocalizationString.IS_BLANK;
    case OperatorsEnum.IS_NOT_BLANK:
      return LocalizationString.IS_NOT_BLANK;
    case OperatorsEnum.IN_LAST_DAYS:
      return LocalizationString.IN_LAST_DAYS;
    case OperatorsEnum.IN_LAST_MONTHS:
      return LocalizationString.IN_LAST_MONTHS;
    case OperatorsEnum.IN_LAST_WEEKS:
      return LocalizationString.IN_LAST_WEEKS;
    case OperatorsEnum.IN_NEXT_DAYS:
      return LocalizationString.IN_NEXT_DAYS;
    case OperatorsEnum.IN_NEXT_MONTHS:
      return LocalizationString.IN_NEXT_MONTHS;
    case OperatorsEnum.IN_NEXT_WEEKS:
      return LocalizationString.IN_NEXT_WEEKS;
    case OperatorsEnum.IS:
      return LocalizationString.IS;
    case OperatorsEnum.AFTER_ON_DAYS:
      return LocalizationString.AFTER_ON_DAYS;
    case OperatorsEnum.IN_PAST:
      return LocalizationString.IN_PAST;
    default:
      return '';
  }
};
