import { MenuItem, useIsOverflowItemVisible } from '@fluentui/react-components';

import { OverflowMenuItemProps } from '../search-result.model';

const OverflowMenuItem: React.FC<OverflowMenuItemProps> = (props) => {
  const { tab, onTabSelect } = props;
  const isVisible = useIsOverflowItemVisible(tab);

  if (isVisible) {
    return null;
  }

  return (
    <MenuItem key={tab} onClick={() => onTabSelect(tab)}>
      <div>{tab}</div>
    </MenuItem>
  );
};

export default OverflowMenuItem;
