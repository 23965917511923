const Endpoints = {
  LOGIN_URL: '/auth/login',
  AD_USERS: '/azure-ad/users',
  ALL_AD_USERS: '/azure-ad/users/getAllAdUsers',
  USER_URL: '/user',
  USER_AZURE_INFORMATION: '/user/userAzureInformation',
  USER_ROLES: '/role/all?limit=100&page=1',
  LOGOUT: '/auth/logout2',
  HEADER_CONFIG: '/metadata/tabs',
  OBJECT_DEFINITION_URL: '/metadata/object-definition/objects',
  ATTRIBUTE_DEFINITION_URL: '/metadata/object-definition/objects/attributes',
  GENERATE_NAME_URL: '/metadata/generate-name',
  NOTIFICATION_TEMPALTES_URL: '/notification-templates',
  DOCUMENT_DATA_URL: '/metadata/documents',
  DOCUMENT_CERTIFICATE_METADATA_DATA_URL: '/metadata/documents/certificate',
  GET_NOTIFICATION_TEMPLATE_URL: '/notification-templates',
  IN_APP_NOTIFICATIONS_URL: '/notifications/in-app',
  DELEGATION_URL: '/delegation',
  USERS_WITH_ROLES_URL: 'user/with-all-roles',
  VIEW_METADATA_URL: '/metadata/view',
  OBJECT_METADATA_URL: '/metadata/object-definition/objects',
  OBJECT_DATA_URL: '/metadata/object-data',
  OBJECT_DATA_URL_SAVE_PAGE_LAYOUT: '/metadata/object-data/view__s/save',
  OBJECT_DATA_URL_DELETE_PAGE_LAYOUT: '/metadata/object-data/view__s',
  PAGE_LAYOUT_PER_OBJECT: '/metadata/view/object',
  OBJECT_METADATA_VIEW: 'metadata/object-data/view__s',
  PERMISSION_URL: '/permission-group',
  WIZARD_CONFIG_URL: '/metadata/view',
  ATTRIBUTE_CONFIG_URL: 'metadata/object-definition/objects',
  TASK_URL: '/task',
  TASK_OBJECT_URL: '/task/object',
  PICKLIST_URL: '/picklist',
  PICKLIST_NAME: '/picklist/name',
  DEPARTMENT_URL: '/department',
  LOGIN_LOG: '/login-log',
  STATISTICS: 'assignment/statistics',
  ASSIGNMENTS_URL: '/assignment',
  ASSIGNMENT: '/assignment',
  MATERIAL: '/material',
  COMPLETION_STATUS: 'completion-status',
  ADMIN_USER_SETTINGS: 'admin/getUserAdminSettings',
  ALL_DEPARTMENTS: '/department',
  GET_CERTIFICATE_TEMPLATE_URL: 'assignment/certificate/template/data',
  SAVE_CERTIFICATE_TEMPALTE_URL: 'assignment/merge-body/certificate/save',
  PREVIEW_CERTIFICATE_TEMPALTE_URL: 'assignment/merge-body/certificate',
  GET_CERTIFICATE_DOCUMENT_URL: 'assignment/training-certificate-template/pdf',
  GET_TEMPLATES_URL: 'assignment/certificate/templates',
  GET_DEFAULT_TEMPLATE_URL: 'assignment/certificate/template/data/default',
  WORKFLOW_DEFINITION: 'workflow-definitions',
  CREATE_WORKFLOW_STEP_DEFINITION: 'workflow-step-definition',
  GET_ALL_WORKFLOW_STEPS_DEFINITION: 'workflow-step-definitions',
  ADVANCED_SEARCH: 'search/advancesearch',
  GET_FACETS: 'search/facets',
  SEARCH_SUGGESTIONS: 'search/suggestions',
  EXECUTE_ACTION: '/actions/execute',
  PICKLIST_ALL: '/picklist/all',
  GET_NOTIFICATION_TEMPLATE: '/metadata/object-data/notification_template__s',
  GET_MERGE_FIELD_DATA: '/metadata/object-definition/objects',
  GET_WORKFLOW_USER_DATA: '/metadata/object-data/user__s',
  GET_WORKFLOW_ROLES_DATA: '/role/all',
  GET_WORKFLOW_PERMISSION_GROUP_DATA:
    '/metadata/object-data/permission_group__s',
  GET_ALL_WORKFLOW_INSTANCES: 'workflow-instance/paginated',
  LIFECYCLE: 'lifecycle',
  LIFECYCLE_STAGE: 'lifecycle-stage',
  REPORT: 'report/resource',
  ADD_SUPERSET_USER: 'report/users',
  WOPI_URL: 'wopi',
  GENERATE_URL: 'generate-url',
  GET_TASK_NODE_ROLES: '/metadata/object-data/role__s',
  OBJECTS_URL: '/metadata/object-data/object_definition_object_name__s',
  GET_WORKFLOW_SMART_GROUP_DATA: '/metadata/object-data/smart_groups__a',
  COURSE_VIEW_METADATA_URL: '/metadata/course/course-view',
  NAVIGATION_TAB: '/metadata/object-data/celito_tab__s',
  PERMISSION_SET_ALL: '/permission-set/all',
  PERMISSION_SET: '/permission-set',
  ROLE: '/role',
  ASSIGNMENT_VIEW_METADAT_URL: 'metadata/object-data/training_assignment__a',
  MODULE_USER: '/user/all',
  WORKFLOW_DEFINITIONS: '/workflow-definitions',
  PICKLIST_SAVE: '/metadata/object-data/picklist__s',
};

export default Endpoints;
