import { makeStyles, shorthands } from '@fluentui/react-components';

export const FieldSelectoStyles = makeStyles({
  btnTrigger: {
    height: '2rem',
    maxHeight: '80%',
    ...shorthands.overflow('auto'),
  },

  search: {
    width: '100%',
  },

  menuList: {
    maxHeight: '40rem',

    '@media (max-width: 900px)': {
      maxHeight: '30rem',
    },

    '@media (max-width: 750px)': {
      maxHeight: '25rem',
    },
  },
});
