import { useState } from 'react';

import RolesContextManager from '../../singleton/roles-context-manager';
import { RoleType } from '../../types/permission-types';
import { useDeepCompareEffect } from '../useDeepCompare';

const rolesContextManager = RolesContextManager.getInstance();

export const useRoles = (roles: string[]) => {
  const [rolesData, setRolesData] = useState<Record<string, RoleType>>({});

  useDeepCompareEffect(() => {
    const rolesData = rolesContextManager.getRolesByNames(roles);
    setRolesData(rolesData);
  }, [roles]);

  return rolesData;
};
