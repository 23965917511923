import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import {
  useActiveModule,
  useLayout,
  useURLParams,
} from '@celito.clients/hooks';
import { getRecordDetailApi } from '@celito.clients/services';
import { raiseErrorToast } from '@celito.clients/utils';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { WizardStateStatus } from '../../../enums/wizard-enum';
import { getActiveLifeCycleStage } from '../../../utils/attribute-config';
import { getFormValues } from '../../../utils/helper';
import { WizardContainerComponentProps } from '../wizard-container.model';
import ViewScreenView from './view-screen.view';

export const ViewScreenController = (props: WizardContainerComponentProps) => {
  const {
    sections = [],
    objectName,
    step,
    recordName,
    attributeConfig,
    setWizardState,
    setRecordData: setFormWizardRecordData,
    recordData: formWizardRecordData,
  } = props;
  const [recordDetails, setRecordDetails] = useState<Record<string, unknown>>(
    {}
  );
  const navigate = useNavigate();
  const [isRecordDataLoading, setIsRecordDataLoading] = useState(false);
  const { configureLayout } = useLayout();
  const { urlParams } = useURLParams();
  const recordVersion = urlParams?.get('version');
  const { reset } = useFormContext();
  const activeModule = useActiveModule();

  const onRouteBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(activeModule?.link || '/');
    }
  };

  const updateWizardState = (response: Record<string, unknown>) => {
    if (Number(response?.version) < 1) {
      setWizardState(WizardStateStatus.DRAFT);
    }
    if (Number(response?.version) >= 1 && response?.isActive !== undefined) {
      response?.isActive === true
        ? setWizardState(WizardStateStatus.ACTIVE)
        : setWizardState(WizardStateStatus.INACTIVE);
    }
  };

  const callRecordDetailApi = async (
    objectName: string,
    recordName: string
  ) => {
    try {
      const response = await getRecordDetailApi(
        objectName,
        recordName,
        recordVersion
          ? {
              version: recordVersion,
              view_type: FormEngineModeTypeEnum.View,
            }
          : { view_type: FormEngineModeTypeEnum.View }
      );
      setFormWizardRecordData?.(response);

      setRecordDetails(response);
      reset(getFormValues(attributeConfig, response));

      const activeLifeCycleStage = getActiveLifeCycleStage(
        attributeConfig,
        response.lifecycleStage as string
      );
      setWizardState(activeLifeCycleStage as string);
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      setIsRecordDataLoading(false);
    }
  };

  useEffect(() => {
    setIsRecordDataLoading(true);
    if (objectName && recordName && !recordDetails?.title) {
      configureLayout({
        pageTitle: '',
        enablePadding: false,
        headerTitle: '',
        showHeadingLoader: true,
      });

      callRecordDetailApi(objectName, recordName);
    } else {
      setIsRecordDataLoading(false);
    }
  }, [recordDetails?.title]);

  useEffect(() => {
    if (!isEmpty(formWizardRecordData)) {
      setRecordDetails(formWizardRecordData);
    }
  }, [formWizardRecordData]);

  return (
    <ViewScreenView
      {...props}
      {...{
        onCancel: onRouteBack,
        step,
        sections,
        mode: props.mode,
        recordDetails,
        attributeConfig,
        isRecordDataLoading,
        callRecordDetailApi,
      }}
    />
  );
};
