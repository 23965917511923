export interface ThreeColSection {
  width: number;
  /** If no component is give, empty space of width 1 will be shown */
  Component?: React.FC;
}

export enum ObjectInitiationViewNameEnum {
  CourseInitiation = 'course_list_view_name__a',
  ControlledDocumentInitiation = 'controlled_document_view__a',
  CurriculumInitiation = 'curriculum_list_view_name__a',
}

export enum UpdateInitiationViewNameEnum {
  CourseInitiation = 'course_edit_view__a',
  ControlledDocumentInitiation = 'controlled_document_edit_view__a',
  CurriculumInitiation = 'curriculum_edit_view__a',
  QuizInitiation = 'quiz_update_view__a',
}

export enum CourseInitiationTypeEnum {
  NewCourse = 'New Course',
  UpdateCourse = 'Update Course',
  RetireCourse = 'Retire Course',
}

export enum CurriculumInitiationTypeEnum {
  NewCurriculum = 'New Curriculum',
  UpdateCurriculum = 'Update Curriculum',
  RetireCurriculum = 'Retire Curriculum',
}

export enum QuizInitiationTypeEnum {
  UpdateQuiz = 'Update Quiz',
}

export type InAppNotification = {
  id: number;
  subject: string;
  inappText: string;
  isActive: boolean;
  viewedOnUtc: string | null;
  createdAtUtc: string;
  isRemoved: boolean;
  urlPath: string;
};

export type DateType = string | Date | number;
