export enum ButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Ghost = 'ghost',
  Link = 'link',
  Blue = 'blue',
  Text = 'text',
  White = 'white',
  Transparent = 'transparent',
  Destructive = 'destructive',
}
