import { AttributeTypeEnum, OperatorsEnum } from '@celito.clients/enums';
import { type useQueryParams } from '@celito.clients/hooks';
import { isDateValid, isValidJsonString } from '@celito.clients/utils';
import {
  convertFilterRulesToQueryString,
  convertQueryStringToFilterRules,
} from 'libs/shared/src/lib/rules-component/types/compress-rules';
import {
  IFilterView,
  RulesComponentListViewFilter,
} from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import { ListViewQueryParams } from '../types';

export const parseFilterUrlValue = (
  columnType: AttributeTypeEnum,
  conditionOperator: OperatorsEnum,
  value: string
): string | Array<unknown> | Record<string, unknown> | boolean => {
  switch (conditionOperator) {
    case OperatorsEnum.RANGE: {
      const start = value.split('--')[0];
      const end = value.split('--')[1];

      if (isDateValid(start) && isDateValid(end)) {
        return {
          start: new Date(start),
          end: new Date(end),
        };
      }

      return {
        start: Number(start),
        end: Number(end),
      };
    }
    default:
      switch (columnType) {
        case AttributeTypeEnum.Reference: {
          const pickerJsonString = value.replace('json__', '');
          if (isValidJsonString(pickerJsonString)) {
            return JSON.parse(pickerJsonString);
          }
          return [];
        }
        case AttributeTypeEnum.YesNo:
        case AttributeTypeEnum.ActiveInactive:
          if (typeof value === 'boolean') {
            return value;
          }
          return value === 'true';

        default:
          return value;
      }
  }
};

export const setFiltersInQueryParams = (
  filters: RulesComponentListViewFilter,
  setQueryParams: ReturnType<typeof useQueryParams>['setUrlParams']
) => {
  if (!filters.conditions) return;

  const compressedFilters = convertFilterRulesToQueryString(filters.conditions);
  setQueryParams(ListViewQueryParams.Filters, compressedFilters);
};

export const getFiltersFromQueryParams = (
  getQueryParams: ReturnType<typeof useQueryParams>['getSearchParams']
): RulesComponentListViewFilter | undefined => {
  const params = getQueryParams();
  const compressedFilters = params[ListViewQueryParams.Filters];

  if (typeof compressedFilters !== 'string' || compressedFilters.length < 1)
    return undefined;

  const rules = convertQueryStringToFilterRules(compressedFilters);

  return {
    conditions: rules,
  };
};

const getDefaultFilterView = (availableFilterViews: IFilterView[]) => {
  return availableFilterViews.find((fw) => fw.isDefault);
};

export const setFilterViewInQueryParams = (
  filterView: IFilterView,
  availableFilterViews: IFilterView[] | undefined,
  setQueryParams: ReturnType<typeof useQueryParams>['setUrlParams'],
  clearUrlParams: ReturnType<typeof useQueryParams>['clearUrlParams']
) => {
  if (
    availableFilterViews &&
    getDefaultFilterView(availableFilterViews)?.label === filterView.label
  ) {
    clearUrlParams(ListViewQueryParams.FilterView);
  } else {
    setQueryParams(ListViewQueryParams.FilterView, filterView.label);
  }
};

export const getFilterViewFromQueryParams = (
  availableFilterViews: IFilterView[] | undefined,
  getQueryParams: ReturnType<typeof useQueryParams>['getSearchParams']
): IFilterView | undefined => {
  if (!availableFilterViews) return;

  // Find if any filter view was applied
  const params = getQueryParams();
  const queryParamVal = params[ListViewQueryParams.FilterView];

  if (typeof queryParamVal === 'string' && queryParamVal.length > 0) {
    const appliedFilterView = availableFilterViews.find(
      (fw) => fw.label === queryParamVal
    );

    if (appliedFilterView) return appliedFilterView;
  }

  // Default filter view
  return getDefaultFilterView(availableFilterViews);
};
