import type { FieldValues } from 'react-hook-form';

import { MultiFileUploadController } from './multi-file-upload.controller';
import { MultiFileUploadButtonProps } from './multi-file-upload.model';

const MultiFileUploadButton = <T extends FieldValues>(
  props: MultiFileUploadButtonProps<T>
): JSX.Element => {
  return <MultiFileUploadController {...props} />;
};
export default MultiFileUploadButton;
