import { useRef, useState } from 'react';

import { FilterableColumn } from '../../types';
import { ListViewActionsProps } from './list-view-actions.model';
import ListViewActionsView from './list-view-actions.view';

interface ListViewActionsControllerProps extends ListViewActionsProps {}

const ListViewActionsController = (
  props: ListViewActionsControllerProps
): JSX.Element => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const filterableColumns = useRef<FilterableColumn[] | undefined>(
    props.viewMetadata.viewDto.view?.[0]?.filterableColumns
  ).current;
  const areThereAnyFilterableColumns = useRef(
    !!filterableColumns?.length && filterableColumns.length > 0
  ).current;

  const openFilterPanel = () => {
    setIsFilterPanelOpen(true);
  };

  const closeFilterPanel = () => {
    setIsFilterPanelOpen(false);
  };

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };

  return (
    <ListViewActionsView
      isFilterPanelOpen={isFilterPanelOpen}
      openFilterPanel={openFilterPanel}
      closeFilterPanel={closeFilterPanel}
      toggleFilterPanel={toggleFilterPanel}
      filterableColumns={filterableColumns}
      areThereAnyFilterableColumns={areThereAnyFilterableColumns}
      {...props}
    />
  );
};

export default ListViewActionsController;
