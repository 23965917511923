import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { CustomButton } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';

import { useListViewContext } from '../../hooks';
import { FilterableColumn } from '../../types';
import { ExportButton, useExportBtnStyles } from '../export-btn';
import { FilterPanel } from '../filter-panel';
import { FilterViewsButton } from '../filter-views-btn';
import { ListViewActionsProps } from './list-view-actions.model';
import classes from './list-view-actions.styles.module.css';

interface ListViewActionsViewProps extends ListViewActionsProps {
  isFilterPanelOpen: boolean;
  openFilterPanel: () => void;
  closeFilterPanel: () => void;
  toggleFilterPanel: () => void;
  areThereAnyFilterableColumns: boolean;
  filterableColumns: FilterableColumn[] | undefined;
}

const ListViewActionsView = ({
  dataTestId,
  toggleFilterPanel,
  filterableColumns,
  areThereAnyFilterableColumns,
  applyFilters,
  leftActionNode,
  ...props
}: ListViewActionsViewProps): JSX.Element => {
  const { cssVariables } = useTheme();
  const exportBtnClasses = useExportBtnStyles();

  const { selectedFilterView, handleFilterViewChange } = useListViewContext();

  const { filterViews, allowExport } =
    props?.viewMetadata?.viewDto?.view?.[0] || {};

  return (
    <>
      <div className={classes.actionBtnsContainer} data-testid={dataTestId}>
        {filterViews &&
        filterViews?.length &&
        filterViews.length > 1 &&
        selectedFilterView ? (
          <FilterViewsButton
            options={filterViews}
            selected={selectedFilterView}
            onClick={handleFilterViewChange}
            cssObject={cssVariables}
          />
        ) : null}
        {leftActionNode}
        {allowExport && (
          <ExportButton onClick={props.exportFile} cssObject={cssVariables} />
        )}
        {areThereAnyFilterableColumns && (
          <div className={classes.filterBtnContainer}>
            <CustomButton
              dataTestId={dataTestId}
              buttonTitle={
                props.numberOfFilters
                  ? `${props.numberOfFilters} ${LocalizationString.FILTERS}`
                  : LocalizationString.FILTER
              }
              buttonType={ButtonTypes.Primary}
              leftIcon="Filter"
              onClick={toggleFilterPanel}
              customStyles={exportBtnClasses.root}
            />
          </div>
        )}
      </div>
      {areThereAnyFilterableColumns && filterableColumns && (
        <FilterPanel
          applyFilters={applyFilters}
          filterableColumns={filterableColumns}
          {...props}
        />
      )}
    </>
  );
};

export default ListViewActionsView;
