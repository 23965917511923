import { useQueryParams } from '@celito.clients/hooks';
import { Icon, Menu } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import {
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
} from '@fluentui/react-components';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { NavLinkProps } from './nav-link.model';
import classes from './nav-link.styles.module.css';
import { navLinkStyles } from './nav-link-styles';

interface NavLinkViewProps extends NavLinkProps {
  showContextualMenu: boolean;
  onShowContextualMenu: () => void;
}

/**
 * @param isActive if the link belongs to the active nav item
 * @returns false if the link is active and the source is email or tab is search
 */
const useIsActiveLink = (isActive: boolean) => {
  const { searchParams } = useQueryParams();
  const { tabName } = useParams();
  const srcEmail = searchParams.get('src') === 'email';
  const srcInAPP = searchParams.get('src') === 'in-app';
  const searchSubTab = tabName === 'search__s';
  return isActive && !srcEmail && !srcInAPP && !searchSubTab;
};

const NavLinkView = ({
  navLink,
  HeaderNavLinkComponent,
  activeDropdownItem,
  activeNavItem,
  handleNavLinkClick,
  showContextualMenu,
  onShowContextualMenu,
  hiddenButtonIndices,
  index,
}: NavLinkViewProps): JSX.Element => {
  const styles = navLinkStyles();

  const isActiveNavLink = useIsActiveLink(
    navLink.systemName === activeNavItem?.systemName
  );

  const [indices, setIndices] = useState<number[]>([-1]);
  useEffect(() => {
    hiddenButtonIndices && setIndices(hiddenButtonIndices);
  }, [hiddenButtonIndices]);

  if (HeaderNavLinkComponent) {
    return (
      <div>
        <HeaderNavLinkComponent
          activeItem={activeNavItem}
          allItems={activeDropdownItem?.navigationLinks}
        />
      </div>
    );
  }

  if (navLink.subLinks && navLink.subLinks.length > 0) {
    return (
      <div
        style={
          indices.some((item) => item === index)
            ? { visibility: 'hidden' }
            : { visibility: 'visible' }
        }
      >
        <Menu onOpenChange={onShowContextualMenu}>
          <MenuTrigger disableButtonEnhancement>
            <button
              className={mergeClasses(
                styles.customMenuButton,
                isActiveNavLink && styles.activeMenu
              )}
              data-testid={`button-tab-${createTestAttribute(navLink.name)}`}
              onClick={onShowContextualMenu}
            >
              <span title={navLink.name}>{navLink.name}</span>
              <Icon
                iconName="ChevronDown16Regular"
                className={classes.dropdownIcon}
                data-open={showContextualMenu}
              />
            </button>
          </MenuTrigger>

          <MenuPopover className={styles.menuPopover}>
            <MenuList>
              {navLink.subLinks.map((subLink) => {
                return (
                  <MenuItem key={subLink.name} className={styles.menuItem}>
                    <Link
                      data-testid={`dropdown-option-${createTestAttribute(
                        subLink.name
                      )}`}
                      to={subLink.link ?? ''}
                      className={cn(classes.navSubLink, {
                        [classes.activeSubMenuLink]: isActiveNavLink,
                      })}
                      onClick={() => handleNavLinkClick(subLink.link)}
                    >
                      {subLink.name}
                    </Link>
                  </MenuItem>
                );
              })}
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    );
  }

  return (
    <div
      style={
        indices.some((item) => item === index)
          ? { visibility: 'hidden' }
          : { visibility: 'visible' }
      }
    >
      <button className={styles.customMenuButton}>
        <Link
          data-testid={`link-tab-${createTestAttribute(navLink.name)}`}
          to={navLink.link ?? ''}
          title={navLink.name}
          className={cn(classes.navLink, {
            [classes.activeNavLink]: isActiveNavLink,
          })}
          onClick={() => handleNavLinkClick(navLink.link)}
        >
          {navLink.name}
        </Link>
      </button>
    </div>
  );
};

export default NavLinkView;
