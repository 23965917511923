import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledTextFieldAreaProps } from './controlled-textarea.model';
import { ControlledTextFieldAreaView } from './controlled-textarea.view';

export const ControlledTextFieldAreaController = <T extends FieldValues>(
  props: ControlledTextFieldAreaProps<T>
) => {
  return <ControlledTextFieldAreaView {...props} />;
};
