import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { courseObjectName } from '../config';
import { AssignmentData, CourseData } from '../types';

export const getCourseDetails = async (
  assignmentData: AssignmentData,
  signal?: AbortSignal
) => {
  const response = await performRequest<CourseData>(
    `${Endpoints.OBJECT_DATA_URL}/${courseObjectName}/${assignmentData.course.name}`,
    ApiMethodType.GET,
    undefined,
    {
      version: assignmentData.course.version,
    },
    undefined,
    signal
  );

  return response.data;
};
