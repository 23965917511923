import { ChangeEvent, useRef, useState } from 'react';

import { TextFieldMultipleProps } from './text-field.multiple.model';
import { TextFieldMultipleView } from './text-field.multiple.view';

export const TextFieldMultipleController = (props: TextFieldMultipleProps) => {
  const inputTextNoItemsRef = useRef<HTMLInputElement | null>(null);
  const inputTextRef = useRef<HTMLInputElement | null>(null);
  const prevWidthRef = useRef(0);
  const tagContainerRef = useRef<HTMLDivElement | null>(null);

  const [customSearch, setCustomSearch] = useState('');

  const handleCustomSearch = (customSearch: string) => {
    setCustomSearch(customSearch);
  };

  const onInput = () => {
    const input = inputTextRef.current;
    if (input && input.parentElement && tagContainerRef.current) {
      const parentWidth = tagContainerRef.current.getBoundingClientRect().width;
      const inputWidth = input.getBoundingClientRect().width;

      prevWidthRef.current = input.value.length * 8;

      if (inputWidth < parentWidth - 54) {
        input.style.width = input.value.length + 'ch';
      } else if (
        prevWidthRef.current < inputWidth &&
        prevWidthRef.current < parentWidth
      ) {
        input.style.width = input.value.length + 'ch';
      }
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setCustomSearch('');
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && inputTextRef.current?.value === '') {
      const lastIndex = props.value.length - 1;

      onChange(
        event as unknown as ChangeEvent<HTMLInputElement>,
        props.value?.[lastIndex],
        true
      );
    } else if (
      event.key === 'Enter' &&
      ((inputTextRef.current && inputTextRef?.current?.value?.length > 0) ||
        (inputTextNoItemsRef.current &&
          inputTextNoItemsRef?.current?.value?.length > 0))
    ) {
      event.stopPropagation();
      event.preventDefault();
      onChange(
        event as unknown as ChangeEvent<HTMLInputElement>,
        inputTextNoItemsRef.current?.value || inputTextRef.current?.value
      );

      inputTextRef.current?.focus();
    }
  };

  const onChange = (
    event: ChangeEvent<HTMLInputElement>,
    value?: string,
    isToRemove?: boolean
  ) => {
    if (value && props.onChange) {
      const isDuplicated = props.value.some((v) => v === value);

      const updatedValues = isToRemove
        ? props.value.filter((v) => v !== value)
        : !isDuplicated
        ? [...props.value, value]
        : props.value;

      props.onChange(event, updatedValues);

      handleCustomSearch('');
    }
  };

  return (
    <TextFieldMultipleView
      {...{
        ...props,
        inputTextRef,
        inputTextNoItemsRef,
        handleCustomSearch,
        customSearch,
        onInput,
        tagContainerRef,
        onBlur,
        onKeyDown,
        onChange,
      }}
    />
  );
};
