export enum ListViewModuleType {
  TRAINING_PLAN = 'training_plan__a',
  UPDATE_TRAINING_PLAN = 'tp_follow_up_update',
  LIBRARY_TRAINING_PLAN = 'library_training_plan_list_view_name__a',
  PENDING_TASK = 'pending_task__a',
  TAKING_TRAINING = 'training_assignment__a',
  TASKS = 'task__s',
  WORKFLOW = 'workflow_definition__s',
  LIBRARY_CONTROLLED_DOCUMENT = 'library_controlled_documents_list_view_name__a',
  LIBRARY_COURSE_VIEW = 'library_course_list_view_name__a',
  LIBRARY_CURRICULUM_VIEW = 'library_curriculum_list_view_name__a',
  LIBRARY_QUIZ_VIEW = 'library_quiz_list_view_name__a',
}
