import { LocalizationString, SaveIcon } from '@celito.clients/assets';
import { ButtonTypes, ObjectEnum, OperatorsEnum } from '@celito.clients/enums';
import {
  ConfirmDialog,
  CustomButton,
  InHouseInputSelect,
  ReferenceSelector,
} from '@celito.clients/shared';
import { getReferencePickerType } from '@celito.clients/utils';
import cn from 'classnames';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { ReferenceSelectorItem } from 'libs/shared/src/lib/reference-selector/src/reference-selector.model';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';
import { SelectorItem } from 'libs/shared/src/lib/selector/src/selector.model';

import {
  AddOneTimeAssignmentProps,
  ErrorConfig,
  User,
} from './add-one-time-assignment.model';
import classes from './add-one-time-assignment.module.css';
import { Header } from './components';
import { defaultReferencePickerProps } from './config';
import { TrainingPlanType } from './enums';

interface AddOneTimeAssignmentViewProps
  extends AddOneTimeAssignmentProps,
    FieldChildProps {
  trainingPlanType: string;
  onTrainingPlanTypeChange: (value: string) => void;
  onRemoveItem: (item: RowItem) => void;
  showAll: boolean;
  setShowAll: (value: boolean) => void;
  gridSelectedData: SelectorItem[];
  setGridSelectedData: (value: SelectorItem[]) => void;
  onSubmit: () => void;
  onConfirmClick: () => void;
  isConfirmDialogOpen: boolean;
  isSavingAssignment: boolean;
  newAssignedUsers: string[] | null;
  notAssignedUsers: string[] | null;
  handleChangeData: (item: SelectorItem[]) => void;
  selectedUser: any;
  handleUserChange: (item: User[]) => void;
  removeUser: (item: RowItem) => void;
}
interface FieldChildProps {
  trainingPlanType: string;
  selectedCurriculum: ReferenceSelectorItem | undefined;
  setSelectedCurriculum: React.Dispatch<
    React.SetStateAction<ReferenceSelectorItem | undefined>
  >;
  selectedCourse: ReferenceSelectorItem | undefined;
  setSelectedCourse: React.Dispatch<
    React.SetStateAction<ReferenceSelectorItem | undefined>
  >;
  errors: ErrorConfig;
}
const objects = [
  {
    label: 'Curriculum',
    name: 'Curriculum',
  },
  {
    label: 'Course',
    name: 'Course',
  },
];

const filters: RulesComponentListViewFilter = {
  conditions: {
    all: [
      {
        all: [
          {
            attribute: 'lifecycle_stage__s',
            operator: OperatorsEnum.EQUALS,
            value: 'Approved',
          },
          {
            attribute: 'version__s',
            operator: OperatorsEnum.ENDS_WITH,
            value: '.0',
          },
          {
            attribute: 'version__s',
            operator: OperatorsEnum.NOT_EQUALS,
            value: '0.0',
          },
          {
            attribute: 'isActive',
            operator: OperatorsEnum.EQUALS,
            value: true,
          },
        ],
      },
    ],
  },
};

const sortConfig = {
  attribute: 'modified_at_utc__s',
  order: 'DESC',
};

const FieldChild = ({
  trainingPlanType,
  setSelectedCourse,
  setSelectedCurriculum,
  errors,
  selectedCourse,
  selectedCurriculum,
}: FieldChildProps) => {
  const objectName =
    trainingPlanType === TrainingPlanType.Course
      ? ObjectEnum.COURSE
      : ObjectEnum.CURRICULUM;

  const selectedItems = [] as ReferenceSelectorItem[];

  if (trainingPlanType === TrainingPlanType.Course && selectedCourse)
    selectedItems.push(selectedCourse);
  else if (
    trainingPlanType === TrainingPlanType.Curriculum &&
    selectedCurriculum
  )
    selectedItems.push(selectedCurriculum);

  return (
    <ReferenceSelector
      objectName={objectName}
      label={
        trainingPlanType === TrainingPlanType.Course
          ? LocalizationString.COURSE
          : LocalizationString.CURRICULUM
      }
      referencePickerProps={{
        required: true,
        onOptionSelect: (_, data) => null,
        referencePicker: {
          objectName: objectName,
          filters,
          sortConfig,
          pickerType: getReferencePickerType(objectName),
        },
        multiselect: false,
        errorMessage:
          trainingPlanType === TrainingPlanType.Course
            ? errors.selectedCourse
            : errors.selectedCurriculum,
      }}
      onSelectorChange={
        trainingPlanType === TrainingPlanType.Course
          ? (data: ReferenceSelectorItem[]) => {
              setSelectedCourse(data[0]);
            }
          : (data: ReferenceSelectorItem[]) => {
              setSelectedCurriculum(data[0]);
            }
      }
      onRemoveItem={
        trainingPlanType === TrainingPlanType.Course
          ? () => {
              setSelectedCourse(undefined);
            }
          : () => {
              setSelectedCurriculum(undefined);
            }
      }
      selectedItems={selectedItems}
      getAllVersions={objectName === ObjectEnum.COURSE}
    />
  );
};

export const AddOneTimeAssignmentView = ({
  dataTestId,
  trainingPlanType,
  onTrainingPlanTypeChange,
  gridSelectedData,
  onRemoveItem,
  showAll,
  setShowAll,
  setGridSelectedData,
  errors,
  onSubmit,
  onConfirmClick,
  isConfirmDialogOpen,
  isSavingAssignment,
  newAssignedUsers,
  notAssignedUsers,
  handleChangeData,
  selectedUser,
  handleUserChange,
  removeUser,
  ...rest
}: AddOneTimeAssignmentViewProps): JSX.Element => {
  return (
    <div className={classes.rootContainer}>
      <div
        className={cn(classes.topContainer, classes.heading, {
          [classes.hideSection]: !showAll,
        })}
      >
        <Header title={LocalizationString.ADD_ONE_TIME_ASSIGNMENT} />
        <div data-testid={dataTestId} className={classes.container}>
          <div className={classes.trainingPlanSelector}>
            <div className={classes.dropdown}>
              <InHouseInputSelect
                required={true}
                label={LocalizationString.TRAINING_PLAN_TYPE}
                placeholder={LocalizationString.SELECT_PLACEHOLDER}
                errorMessage={errors.trainingPlanType}
                options={objects.map((obj) => ({
                  text: obj.label,
                  value: obj.name,
                }))}
                selectedOptions={{
                  value: trainingPlanType,
                  text:
                    objects.find((obj) => obj.name === trainingPlanType)
                      ?.label ?? '',
                }}
                onOptionSelect={(_e, data) => {
                  const optionText = data?.optionText ?? '';
                  onTrainingPlanTypeChange(optionText);
                }}
              />
            </div>
          </div>
          {trainingPlanType && (
            <div>
              <FieldChild
                trainingPlanType={trainingPlanType}
                errors={errors}
                {...rest}
              />
            </div>
          )}
          <div>
            <ReferenceSelector
              objectName={ObjectEnum.SMART_GROUP}
              label={'User Group(s)'}
              selectedItems={gridSelectedData}
              referencePickerProps={{
                referencePicker: {
                  pickerType: getReferencePickerType(ObjectEnum.SMART_GROUP),
                  ...defaultReferencePickerProps,
                },
                multiselect: true,
                onOptionSelect: (_, referredDocuments) => {
                  return null;
                },
              }}
              onSelectorChange={(data) =>
                handleChangeData(data as unknown as SelectorItem[])
              }
              onRemoveItem={onRemoveItem}
            />
          </div>
          <div>
            <ReferenceSelector
              objectName={'user__s'}
              label={LocalizationString.INDIVIDUAL_USERS}
              selectedItems={selectedUser}
              referencePickerProps={{
                referencePicker: {
                  pickerType: getReferencePickerType('user__s'),
                  ...defaultReferencePickerProps,
                },
                multiselect: true,
                onOptionSelect: (_, selectedUser) => {
                  return null;
                },
              }}
              onSelectorChange={(data) => handleUserChange(data as User[])}
              onRemoveItem={removeUser}
            />
          </div>
        </div>
      </div>
      <ConfirmDialog
        dataTestId={'training-matrix'}
        open={isConfirmDialogOpen}
        onConfirmClicked={onConfirmClick}
        title={LocalizationString.TRAINING_ASSINGMENT}
        primaryButtonText={LocalizationString.GREAT}
        description={LocalizationString.SUCESS_ASSIGN}
        iconSrc={SaveIcon}
      />
      <div className={classes.footer}>
        <CustomButton
          buttonType={ButtonTypes.Primary}
          buttonTitle={LocalizationString.SUBMIT}
          customStyles={classes.submitBtn}
          onClick={onSubmit}
          leftIcon="Submit"
          isLoading={isSavingAssignment}
        />
      </div>
    </div>
  );
};
