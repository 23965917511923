import { memo } from 'react';

import { FieldContainer } from './field-container';
import { FieldSection } from './field-section';
import type { FieldProps } from './types';

/**
 * Not to be confused with fluent UI's field
 *
 * This will render a field based on the provided attribute definition
 *
 * This is a memoized component and it will/should not consume any context directly
 * @todo Add usage example
 * @todo Remove Field Container and Field Section and use this component directly for rendering all types of field
 * @param props - Field configuration params {@link FieldProps}
 * @returns Form field container
 */
export const Field = memo<FieldProps>(function (props) {
  if (!props.fieldState?.isVisible) return null;

  if (props.readonly) return <FieldSection {...props} />;
  return <FieldContainer {...props} />;
});
