import { Stack } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import {
  Label as FluentLabel,
  makeStyles,
  mergeClasses,
} from '@fluentui/react-components';
import displayViewStyles from 'libs/form-engine/src/lib/components/view-components/display-view/display-view.module.css';
import { cloneElement, memo, ReactElement, ReactNode } from 'react';

const fieldStyles = makeStyles({
  section: {
    marginTop: 'unset !important',
  },
});

export const Label = ({
  label,
  dataTestId,
  required,
}: {
  label: string;
  dataTestId?: string;
  required?: boolean;
}) => {
  return (
    <FluentLabel
      size="large"
      data-testid={dataTestId ?? `label-${createTestAttribute(label)}`}
      className={displayViewStyles.label}
      required={required}
    >
      {label}
    </FluentLabel>
  );
};

export const FieldContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const styles = fieldStyles();
  return (
    <Stack
      className={mergeClasses(
        displayViewStyles.section,
        styles.section,
        className
      )}
    >
      {children}
    </Stack>
  );
};

export const Field = memo(
  ({
    label,
    visible = true,
    editable = true,
    editableField,
    value,
    valueDataTestId,
    labelDataTestId,
    className,
    required,
  }: {
    visible?: boolean;
    editableField?: ReactElement;
    editable?: boolean;
    label: string;
    value: ReactNode;
    valueDataTestId?: string;
    labelDataTestId?: string;
    className?: string;
    required?: boolean;
  }) => {
    const styles = fieldStyles();
    if (visible)
      if (editable && editableField)
        return cloneElement(editableField, { label, required });
      else
        return (
          <Stack
            className={mergeClasses(
              displayViewStyles.section,
              styles.section,
              className
            )}
          >
            <Label
              dataTestId={labelDataTestId}
              label={label}
              required={required}
            />
            <FluentLabel
              className={displayViewStyles.value}
              data-testid={
                !valueDataTestId
                  ? `label-${createTestAttribute(label)}-value`
                  : valueDataTestId
              }
            >
              {value ?? ''}
            </FluentLabel>
          </Stack>
        );
    return null;
  }
);
