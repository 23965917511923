import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const contextMenuStyles = makeStyles({
  menuButton: {
    backgroundColor: `${Color.context_button_color} !important`,
    ...shorthands.border('none'),
    width: '40px',
    height: '40px',
    color: `${Color.white} !important`,
  },
  actionMenuItem: {
    '&:hover > span > div': {
      fontFamily: ThemeFontFamily.bold,
    },
    ...shorthands.padding('14px', '8px'),
  },

  menuList: {
    '> div:hover': {
      backgroundColor: 'rgba(37, 72, 247, 0.05)',
    },
  },

  itemContent: {
    display: 'flex',
    ...shorthands.gap('8px'),
    alignItems: 'center',
    minWidth: '160px',
  },
});
