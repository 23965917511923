import { LocalizationString } from '@celito.clients/assets';
import {
  ObjectEnum,
  ROUTES,
  TrainingAssignmentObjectKeyEnum,
  TrainingAssignmentTypeEnum,
} from '@celito.clients/enums';
import {
  useLayout,
  useObjectAttributeDefinitions,
} from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import {
  downloadTrainingCertificate,
  getAllTaskData,
} from '@celito.clients/services';
import { CourseData, TrainingAssignmentData } from '@celito.clients/types';
import {
  errorToast,
  formatDateInTimezone,
  raiseErrorToast,
} from '@celito.clients/utils';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import {
  getAssignmentDetails,
  getCourseDetails,
  startTrainingAssignment,
  updateAssignment,
} from './services';
import { TakingAssingmentProps } from './taking-assingment.model';
import { TakingAssingmetView } from './taking-assingment.view';
import { AssignmentData, DocumentData } from './types';
import { getTaskPayload } from './utils';

export const TakingAssingmentController = (props: TakingAssingmentProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const { configureLayout } = useLayout();

  const { user, isBizAdmin } = useContext(UserContext);

  const [isEditing, setIsEditing] = useState(false);
  const [assignmentData, setAssignmentData] = useState<AssignmentData | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [dueDate, setDueDate] = useState<Date | string>(new Date());
  const [retrainDate, setRetrainDate] = useState<Date | string | undefined>(
    undefined
  );
  const [trainingAssignmentType, setTrainingAssignmentType] =
    useState<TrainingAssignmentTypeEnum | null>(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [courseData, setCourseData] = useState<CourseData | null>(null);
  const [assignmentDoc, setAssignmentDoc] = useState<DocumentData | undefined>(
    undefined
  );
  const [isSaving, setIsSaving] = useState(false);

  const { data: attributes } =
    useObjectAttributeDefinitions<TrainingAssignmentObjectKeyEnum>({
      objectName: ObjectEnum.TRAINING_ASSIGNMENT,
    });

  const abortController = useRef(new AbortController());

  const navigateBack = () => {
    navigate(-1);
  };

  const getAssignmentDetailsData = useCallback(async () => {
    setIsLoading(true);
    configureLayout({
      pageTitle: '',
      showHeadingLoader: true,
      headerTitle: '',
      enablePadding: false,
    });
    try {
      const assignmentRecordData = await getAssignmentDetails(
        params.name ?? '',
        abortController.current.signal
      );

      getCourseDetailsData(assignmentRecordData);

      if (user?.name === assignmentRecordData.user.name) {
        setTrainingAssignmentType(TrainingAssignmentTypeEnum.MyAssignments);
      }

      getTaskDetailsData(assignmentRecordData);

      setAssignmentData(assignmentRecordData);
      setDueDate(assignmentRecordData.dueDate);
      setRetrainDate(
        assignmentRecordData.retrainDate
          ? assignmentRecordData.retrainDate
          : undefined
      );
      configureLayout({
        pageTitle: '',
        headerTitle: assignmentRecordData.label,
        breadcrumbTitle: assignmentRecordData.label,
        enablePadding: false,
      });
    } catch (_error) {
      if (!abortController.current.signal.aborted) {
        raiseErrorToast(_error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [params.name]);

  const getCourseDetailsData = async (
    assignmentRecordData: TrainingAssignmentData
  ) => {
    getCourseDetails(assignmentRecordData, abortController.current.signal).then(
      (courseRecordData) => setCourseData(courseRecordData)
    );
  };

  const getTaskDetailsData = async (
    assignmentRecordData: TrainingAssignmentData
  ) => {
    const taskPayload = getTaskPayload(assignmentRecordData?.name ?? '');
    getAllTaskData(taskPayload).then((taskInfo) => {
      const docData =
        taskInfo?.data?.find((task) => !!task?.document)?.document ?? undefined;
      setAssignmentDoc(docData);
    });
  };

  const startTraining = (
    navigateToMaterials?: boolean,
    updateAssignmentCheck?: boolean
  ) => {
    if (assignmentData) {
      if (
        user?.name === assignmentData?.user.name ||
        (user?.name !== assignmentData?.user.name && updateAssignmentCheck)
      ) {
        startTrainingAssignment(assignmentData.name)
          .then(() => {
            if (!navigateToMaterials) {
              getAssignmentDetailsData();
            }
          })
          .catch((_error) => {
            raiseErrorToast(_error);
          });
      }

      if (navigateToMaterials && courseData?.document) {
        navigate(
          `../${ROUTES.DOCUMENT_VIEW}?training_assignment_name=${assignmentData.name}`
        );
      }
    }
  };

  const viewCertificate = () => {
    if (assignmentData?.name) {
      setShowExportModal(true);
      downloadTrainingCertificate(assignmentData?.name)
        .catch((_error) => {
          raiseErrorToast(_error);
        })
        .finally(() => {
          setShowExportModal(false);
        });
    } else {
      errorToast({
        message: LocalizationString.ASSIGNMENT_DATA_NOT_FOUND,
      });
    }
  };

  const handleSave = () => {
    if (isEditing) {
      if (assignmentData) {
        if (dueDate) {
          setIsSaving(true);
          updateAssignment({
            name: assignmentData.name,
            isActive: assignmentData.isActive,
            dueDate: formatDateInTimezone(dueDate),
            retrainDate: retrainDate
              ? formatDateInTimezone(retrainDate)
              : undefined,
          }).then(() => {
            setIsEditing(false);
            setIsSaving(false);
            getAssignmentDetailsData();
          });
        } else {
          setIsEditing(true);
          errorToast({
            message: LocalizationString.DATE_FIELD_REQUIRED,
          });
        }
      } else {
        setIsEditing(false);
      }
    } else {
      setIsEditing(true);
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    getAssignmentDetailsData();
    const controller = abortController.current;
    return () => {
      controller.abort();
    };
  }, [getAssignmentDetailsData]);

  return (
    <TakingAssingmetView
      {...{
        ...props,
        isBizAdmin,
        isEditing,
        isLoading,
        assignmentData,
        dueDate,
        retrainDate,
        setDueDate,
        setRetrainDate,
        handleSave,
        startTraining,
        trainingAssignmentType,
        cancelEdit,
        viewCertificate,
        setShowExportModal,
        showExportModal,
        navigateBack,
        user,
        courseData,
        assignmentDoc,
        isSaving,
        attributes,
      }}
    />
  );
};
