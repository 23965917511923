import { LocalizationString } from '@celito.clients/assets';
import { useDidUpdate } from '@celito.clients/hooks';
import { Icon, Loader } from '@celito.clients/shared';
import { makeStyles } from '@fluentui/react-components';
import { useLocation } from 'react-router';

const getStyles = makeStyles({
  page: {
    height: '100%',
    width: '100%',
    display: 'grid',
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3rem',
    fontSize: '1.2rem',
  },
  icon: {
    scale: 5,
  },
});

export function ErrorOrUnauthorized({
  error,
  resetErrorBoundary,
}: {
  error?: Error;
  resetErrorBoundary?: () => void;
}) {
  const location = useLocation();
  const styles = getStyles();
  useDidUpdate(() => {
    resetErrorBoundary?.();
  }, [location.pathname]);
  return (
    <div className={styles.page} style={{ placeItems: 'center' }}>
      {error?.message?.includes(
        LocalizationString.ERROR_OCCURRED_ON_DYNAMIC_IMPORT
      ) ? (
        <Loader />
      ) : (
        <div className={styles.pageContent}>
          <Icon className={styles.icon} iconName="Warning20Filled" />
          <div>{LocalizationString.ERROR_OCCURRED_OR_UNAUTHORIZED}</div>
        </div>
      )}
    </div>
  );
}
