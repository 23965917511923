import { LocalizationString } from '@celito.clients/assets';
import {
  FormEngineModeTypeEnum,
  QuizObjectAttributeNameEnum,
} from '@celito.clients/enums';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { QuizEnumTypes } from '../../enums/quiz-enum';
import { CustomPageProps } from '../../types/common-types';
import {
  IChoice,
  IQuestion,
  IQuizFormData,
  QuestionTypes,
} from './quiz-questions-page.model';
import { QuizQuestionsView } from './quiz-questions-page.view';

const stringValidation = yup.string().required(LocalizationString.REQUIRED_MSG);

const validationSchema = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      questionText: stringValidation,
      questionType: stringValidation,
      choices: yup.array().when('questionType', ([questionType], schema) => {
        const minChoices = questionType !== QuestionTypes.BOOLEAN ? 2 : 0;

        return schema
          .min(minChoices, LocalizationString.ENTER_TWO_CHOICES)
          .of(
            yup.object().shape({
              choiceText: stringValidation,
              isCorrect: yup.boolean(),
            })
          )
          .test(
            'atLeastOneCorrect',
            questionType === QuestionTypes.MULTIPLE_RESPONSE
              ? LocalizationString.ENTER_ATLEAST_ONE_CHOICE
              : LocalizationString.ENTER_ONE_CHOICE_CORRECT,
            (choices) => {
              if (questionType === QuestionTypes.MULTIPLE_RESPONSE) {
                const correctChoicesCount =
                  choices?.filter((choice) => choice.isCorrect).length ?? 0;
                return correctChoicesCount > 1;
              }

              return choices?.some((choice) => choice.isCorrect);
            }
          );
      }),
    })
  ),
}) as yup.ObjectSchema<IQuizFormData>;

export const QuizQuestionsController = (props: CustomPageProps) => {
  const {
    goToNextStep,
    goToPreviousStep,
    mode,
    onSave,
    step,
    totalSteps,
    onCancel,
    recordDetails,
    editRoute,
    recordData,
    isLoading,
  } = props;

  const {
    setValue: setQuizValue,
    watch: watchQuizValue,
    trigger: triggerQuizValue,
  } = useFormContext();

  const navigate = useNavigate();

  const currentContextQuestionStrigfied = watchQuizValue('currentQuestion');
  const ContextQuestionsStrigfied = watchQuizValue(QuizEnumTypes.QUESTIONS);
  const watchedMinQuestions = watchQuizValue(
    QuizObjectAttributeNameEnum.QUESTIONS_TO_BE_DISPLAYED
  );

  const contextQuestions: IQuestion[] | undefined =
    mode === FormEngineModeTypeEnum.View
      ? recordData?.questions
      : ContextQuestionsStrigfied && JSON.parse(ContextQuestionsStrigfied);

  const currentContextQuestion: IQuestion | undefined =
    currentContextQuestionStrigfied &&
    JSON.parse(currentContextQuestionStrigfied);

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(
    currentContextQuestion ? currentContextQuestion.order : 1
  );
  const defineDefaultValues = (): IQuizFormData => {
    if (contextQuestions)
      return {
        questions: contextQuestions.map((contextQuestion) => {
          return {
            questionText: contextQuestion.questionText,
            choices: contextQuestion.choices.map((contextChoice: IChoice) => {
              return {
                choiceText: contextChoice.choiceText,
                isCorrect: contextChoice.isCorrect,
                order: contextChoice.order,
              };
            }),
            feedback: contextQuestion.feedback,
            isMandatory: contextQuestion.isMandatory,
            isRandom: contextQuestion.isRandom,
            order: contextQuestion.order,
            questionType: contextQuestion.questionType,
          };
        }),
      };

    return {
      questions: [
        {
          questionText: '',
          choices: [],
          feedback: '',
          isMandatory: false,
          isRandom: false,
          order: 1,
          questionType: '',
        },
      ],
    };
  };

  const methods = useForm<IQuizFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: defineDefaultValues(),
  });

  const {
    watch,
    control,
    setValue,
    trigger,
    clearErrors,
    formState: { errors, isDirty },
  } = methods;

  const watchedQuestions = watch('questions', []);

  const { fields: questions, append } = useFieldArray({
    control,
    name: 'questions',
  });

  const onAddQuestion = () => {
    append({
      questionText: '',
      choices: [],
      feedback: '',
      isMandatory: false,
      isRandom: false,
      order: questions.length + 1,
      questionType: '',
    });
  };

  const onSubmit = (data: IQuizFormData) => {
    setQuizValue(QuizEnumTypes.QUESTIONS, JSON.stringify(data.questions), {
      shouldDirty: true,
    });

    setQuizValue(
      'currentQuestion',
      JSON.stringify(data.questions[currentQuestionNumber - 1]),
      {
        shouldDirty: true,
      }
    );
  };

  const onRemoveQuestion = (order: number) => {
    setCurrentQuestionNumber(
      order === watchedQuestions.length ? order - 1 : order
    );

    const updatedQuestions: IQuestion[] = watchedQuestions
      .filter((question) => question.order !== order)
      .map((question, index) => {
        return {
          ...question,
          order: index + 1,
        };
      });

    setValue('questions', updatedQuestions, {
      shouldDirty: true,
    });

    onSubmit({ questions: updatedQuestions });

    trigger('questions');
  };

  useEffect(() => {
    setCurrentQuestionNumber(1);
  }, []);

  const questionTypeOptions = [
    { value: QuestionTypes.MULTIPLE_CHOICE, text: 'Multiple Choice' },
    { value: QuestionTypes.MULTIPLE_RESPONSE, text: 'Multiple Response' },
    { value: QuestionTypes.BOOLEAN, text: 'True Or False' },
    { value: QuestionTypes.SEQUENCE, text: 'Sequence' },
  ];

  const handleSetCurrentQuestionNumber = (order: number) => {
    if (!errors.questions?.[currentQuestionNumber - 1]) {
      setQuizValue(
        'currentQuestion',
        JSON.stringify(watchedQuestions[order - 1]),
        {
          shouldDirty: true,
        }
      );

      setCurrentQuestionNumber(order);
    }
  };

  const onSaveAndAdd = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!errors.questions?.[currentQuestionNumber - 1]) {
      onAddQuestion();
      onSubmit({ questions: watchedQuestions } as IQuizFormData);
      handleSetCurrentQuestionNumber(watchedQuestions.length + 1);
    }
  };

  const onFormEngineAction = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: string
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (errors?.questions && errors.questions !== null) {
      const entries = Object.entries(errors.questions);

      const [firstKey] = entries[0];

      handleSetCurrentQuestionNumber(parseInt(firstKey + 1));

      return;
    }

    switch (action) {
      case 'previous': {
        onSubmit({ questions: watchedQuestions } as IQuizFormData);
        goToPreviousStep(event);
        break;
      }

      case 'saveDraft': {
        onSubmit({ questions: watchedQuestions } as IQuizFormData);
        onSave?.(false);
        break;
      }

      case 'edit': {
        const isRedirectToSubmit = recordDetails?.version
          ? parseInt(recordDetails?.version as string) < 1
          : false;
        if (isRedirectToSubmit) {
          navigate(`../submit/${editRoute}/${recordDetails?.name}`);
        } else {
          navigate(
            `../edit/${editRoute}/${props?.recordName}?version=${recordDetails?.version}`
          );
        }
        break;
      }

      default: {
        onSubmit({ questions: watchedQuestions } as IQuizFormData);
        goToNextStep(event);
      }
    }
  };

  const missingQuestionToContext = useCallback(() => {
    const hasUnsaved =
      JSON.stringify(contextQuestions) !== JSON.stringify(watchedQuestions);

    return hasUnsaved && isDirty;
  }, [contextQuestions, watchedQuestions, isDirty]);

  const submitToContext = useCallback(() => {
    onSubmit({ questions: watchedQuestions } as IQuizFormData);
  }, [watchedQuestions, contextQuestions, missingQuestionToContext]);

  useEffect(() => {
    if (missingQuestionToContext()) submitToContext();
  }, [missingQuestionToContext, watchedQuestions, isDirty, submitToContext]);

  // handles the wizard step error state UI when the step looses focus (needed for custom pages)
  useEffect(() => {
    if (props.mode === FormEngineModeTypeEnum.View) {
      return;
    }

    if (!props.wizardStepsState[props.step].hasNavigated) {
      props.setWizardStepsState((prev) =>
        prev.map((step) =>
          step.number === props.step ? { ...step, hasNavigated: true } : step
        )
      );
    }

    const unMountFunction = async () => {
      await triggerQuizValue().then(async (formRes) => {
        await trigger()
          .then((res) => {
            props.setWizardStepsState((prev) =>
              prev.map((step) =>
                step.number === props.step
                  ? {
                      ...step,
                      isValid:
                        formRes &&
                        res &&
                        (contextQuestions
                          ? contextQuestions.length >= watchedMinQuestions
                          : true),
                    }
                  : step
              )
            );
          })
          .finally(() => clearErrors());
      });
    };

    return () => {
      unMountFunction();
    };
  }, [contextQuestions?.length]);

  return (
    <QuizQuestionsView
      {...{
        onAddQuestion,
        methods,
        onRemoveQuestion,
        onSubmit,
        questionTypeOptions,
        mode,
        handleSetCurrentQuestionNumber,
        currentQuestionNumber,
        onSaveAndAdd,
        onCancel,
        step,
        totalSteps,
        onFormEngineAction,
        watchedMinQuestions,
        isLoading,
      }}
    />
  );
};
