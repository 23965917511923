import {
  LocalizationString,
  SaveIcon,
  WarningIcon,
} from '@celito.clients/assets';
import {
  ButtonTypes,
  FormEngineModeTypeEnum,
  ObjectEnum,
} from '@celito.clients/enums';
import {
  ConfirmDialog,
  CustomButton,
  Field,
  FieldSelector,
  FileUploadButton,
  GridView,
  InHouseInputSelect,
  Loader,
  TextField,
  TextFieldArea as Textarea,
} from '@celito.clients/shared';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import { createTestAttribute } from '@celito.clients/utils';
import { FileDataProps } from 'libs/shared/src/lib/file-upload/file-upload.model';
import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';

import { documentTemplateStyles } from '../../styles';
import { DocumentTemplateViewProps, FormMode } from '../../types';
import classes from './document-templates.module.css';

const DocumentTemplatesView = ({
  onSubmit,
  control,
  handleSubmit,
  setValue,
  isLoading,
  mode,
  showPrompt,
  confirmNavigation,
  cancelNavigation,
  isDirty,
  isCancelModalOpen,
  toggleCancelModalBtnClick,
  onCancel,
  toggleSaveModalBtnClick,
  isSaveModalOpen,
  copiedValueAction,
  copiedValues,
  isSaving,
  onCopy,
  attributes,
}: DocumentTemplateViewProps) => {
  const styles = documentTemplateStyles();

  const onFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit);
  };

  if (isLoading) {
    return (
      <div className={styles.loaderContainer} style={{ placeItems: 'center' }}>
        <Loader fullPage />
      </div>
    );
  }

  return (
    <Fragment>
      <form onSubmit={onFormSubmit} noValidate className={styles.formContainer}>
        {mode === FormMode.EDIT && (
          <div className={styles.smallSizeContainer}>
            <Controller
              name={'label'}
              control={control}
              render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    disabled
                    className={styles.field}
                    data-testid="input-field-template-name"
                    name={fieldName}
                    label="Template ID"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                    required
                  />
                );
              }}
            />
          </div>
        )}

        <div className={styles.smallSizeContainer}>
          <Controller
            name={'title'}
            control={control}
            render={({
              field: { onChange, onBlur, name: fieldName, value },
              fieldState: { error },
            }) => {
              return (
                <TextField
                  className={styles.field}
                  data-testid="input-field-template-name"
                  name={fieldName}
                  label={attributes.title?.label}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  helperLabelText={attributes.title?.helpText}
                  required
                />
              );
            }}
          />
        </div>
        <div className={styles.mediumSizeContainer}>
          <Controller
            name={'description'}
            control={control}
            render={({
              field: { onChange, onBlur, name: fieldName, value },
              fieldState: { error },
            }) => (
              <Textarea
                data-testid="input-field-description"
                name={fieldName}
                className={styles.field}
                resize="vertical"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label={attributes.description?.label}
                helperLabelText={attributes.description?.helpText}
                errorMessage={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.smallSizeContainer}>
          <Controller
            name={'fieldType'}
            control={control}
            render={({
              field: { onBlur, value, onChange },
              fieldState: { error },
            }) => {
              return (
                <div className={styles.row}>
                  <InHouseInputSelect
                    label={attributes.mergeFeildValues?.label}
                    helperLabelText={attributes.mergeFeildValues?.helpText}
                    data-testid="select-group-status-add-group"
                    options={[
                      {
                        text: 'Controlled Document',
                        value: ObjectEnum.CONTROLLED_DOCUMENT,
                      },
                    ]}
                    disabled
                    selectedOptions={value}
                    onOptionSelect={(_e, option) => {
                      onChange(option?.selectedOptions);
                    }}
                    errorMessage={error?.message}
                  />

                  <FieldSelector
                    onCopy={onCopy}
                    objectName={ObjectEnum.CONTROLLED_DOCUMENT}
                    marginTop={28}
                  />
                </div>
              );
            }}
          />
        </div>
        {!isEmpty(copiedValues) && (
          <GridView
            disableDefaultSorting
            disableSelection
            columns={[
              {
                key: '1',
                name: 'Merge Field Value',
                fieldName: 'value',
                minWidth: 500,
                flexGrow: 1,
              },
              {
                key: '2',
                name: 'Action',
                minWidth: 200,
                isResizable: true,
                onRender: (_item, index, _column) => {
                  if (typeof index === 'number')
                    return (
                      <div className={styles.actionsContainer}>
                        <CustomButton
                          onClick={() => copiedValueAction('copy', index)}
                          buttonType={ButtonTypes.Destructive}
                          leftIcon="Copy"
                        />
                        <CustomButton
                          onClick={() => copiedValueAction('remove', index)}
                          buttonType={ButtonTypes.Destructive}
                          leftIcon={'Delete'}
                        />
                      </div>
                    );
                },
              },
            ]}
            items={copiedValues.map((value, i) => ({
              key: i,
              value,
            }))}
          />
        )}
        <div className={styles.mediumSizeContainer}>
          <Controller
            name={'file__a'}
            control={control}
            render={({
              field: { onBlur, value, onChange },
              fieldState: { error },
            }) => {
              const handleFileSelect = (file: File | null) => {
                onChange({
                  target: {
                    value: file,
                    name: file?.name,
                  },
                });
              };

              return (
                <Field
                  className={styles.documentUploadContainer}
                  label={attributes.document?.label}
                  size="large"
                  helperTextLabel={attributes.document?.helpText}
                  required={
                    !attributes.document?.helpText &&
                    attributes.document?.isMandatory
                  }
                  validationMessage={error?.message}
                  validationState={error?.message ? 'error' : 'none'}
                >
                  <FileUploadButton
                    onFileSelect={handleFileSelect}
                    fileName={
                      (value as File)?.name ??
                      (value as FileDataProps)?.nameWithExtension
                    }
                    error={error}
                    documentId={(value as FileDataProps)?.documentId ?? ''}
                    // FXME: decouple this component from react-hook-form lib
                    attribute={
                      { name: 'document__s' } as ObjectAttributeDefinition
                    }
                    objectName={'document_template__a'}
                    allowedExtensions={[
                      '.docx',
                      '.doc',
                      '.xlsx',
                      '.pptx',
                      '.xls',
                    ]}
                    mode={FormEngineModeTypeEnum.Create}
                    setValue={setValue}
                  />
                </Field>
              );
            }}
          />
        </div>
      </form>
      <div className={styles.footer}>
        <CustomButton
          data-testid="button-cancel-document-template"
          customStyles={classes.btn_container}
          buttonType={ButtonTypes.Ghost}
          buttonTitle="Cancel"
          onClick={onCancel}
        />
        <CustomButton
          data-testid="button-save-document-template"
          customStyles={classes.btn_container}
          leftIcon={mode === FormMode.CREATE ? 'Submit' : 'Save'}
          buttonTitle={mode === FormMode.CREATE ? 'Create' : 'Save'}
          buttonType={ButtonTypes.Primary}
          disabled={isSaving}
          onSubmit={onSubmit}
          isLoading={isSaving}
        />
      </div>
      <ConfirmDialog
        open={isCancelModalOpen}
        onCancelClicked={() => toggleCancelModalBtnClick()}
        onConfirmClicked={() => toggleCancelModalBtnClick(true)}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL}
        iconSrc={WarningIcon}
        description={LocalizationString.CANCEL_POPUP_MSG}
      />
      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
      <ConfirmDialog
        dataTestId={`save-modal-${createTestAttribute(
          LocalizationString.SAVE_REQUEST
        )}`}
        open={isSaveModalOpen}
        onCancelClicked={toggleSaveModalBtnClick}
        onConfirmClicked={toggleSaveModalBtnClick}
        primaryButtonText={LocalizationString.GREAT}
        title={
          mode === FormMode.CREATE
            ? LocalizationString.CREATE_REQUEST
            : LocalizationString.SAVE_REQUEST
        }
        iconSrc={SaveIcon}
        dontShowCloseIcon
        description={
          mode === FormMode.CREATE
            ? LocalizationString.CREATED_DESCRIPTION
            : LocalizationString.SAVED_DESCRIPTION
        }
      />
    </Fragment>
  );
};

export default DocumentTemplatesView;
