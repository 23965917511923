import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import {
  AssignmentPayload,
  IAssingedUser,
  SubmitAssigmentResponse,
} from '../add-one-time-assignment.model';

export const submitAssignment = async (
  payload: AssignmentPayload
): Promise<IAssingedUser> => {
  const requestBody = {
    ...payload,
  };

  const { data } = await performRequest<SubmitAssigmentResponse>(
    Endpoints.ASSIGNMENT,
    ApiMethodType.POST,
    requestBody
  );

  const newAssingnedUsersLabel = data?.newTrainingAssignments.map(
    (trainingAssingment) => trainingAssingment.user.label
  );

  const existingUsersLabel = data?.existingTrainingAssignments.map(
    (trainingAssingment) => trainingAssingment.user.label
  );

  return {
    assignedUsersLabel: newAssingnedUsersLabel,
    notAssignedUsersLabel: existingUsersLabel,
  };
};
