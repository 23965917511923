import {
  LocalizationString,
  SaveIcon,
  WarningIcon,
} from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import {
  ConfirmDialog,
  CustomButton,
  FormActionButtonsContainer,
  Loader,
  Stack,
} from '@celito.clients/shared';
import { Field, Section } from '@celito.clients/types';
import { useFormEngineContext } from 'libs/form-engine/src/lib/hooks';
import { getFieldContainer } from 'libs/form-engine/src/lib/utils/attribute-config';
import React, { useEffect } from 'react';
import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form';

import { SectionView } from '../../section/section';
import { EditLayoutProps } from './edit.model';
import classes from './edit.module.css';

interface EditLayoutViewProps extends EditLayoutProps {
  methods: UseFormReturn<FieldValues>;
  isCancelModalOpen: boolean;
  toggleCancelModalBtnClick: (navigateBack?: boolean) => void;
  showSaveBtn?: boolean;
  isRecordDataLoading: boolean;
  showPrompt: boolean;
  confirmNavigation: () => void;
  cancelNavigation: () => void;
}

export const EditLayoutView: React.FC<EditLayoutViewProps> = ({
  sections = [],
  attributeConfig,
  onCancel,
  onSave,
  handleSubmit,
  control,
  watch,
  setValue,
  trigger,
  onFormSubmit,
  isSaveModalOpen,
  isWarningModalOpen,
  toggleWarningModalBtnClick,
  toggleSaveModalBtnClick,
  dataTestIdForSaveModal,
  dataTestIdForWarningModal,
  mode,
  viewDto,
  objectName = '',
  methods,
  isCancelModalOpen,
  toggleCancelModalBtnClick,
  isLoading,
  showSaveBtn: isDirty,
  isRecordDataLoading,
  showPrompt,
  confirmNavigation,
  cancelNavigation,
  ...rest
}) => {
  const [isShow, setIsShow] = React.useState(false);

  const { unregister } = useFormContext();
  const { fieldsState } = useFormEngineContext();

  const fieldsStateStringified = JSON.stringify(fieldsState);

  // Unregister fields when they become hidden
  useEffect(() => {
    const fieldsStateParsed = JSON.parse(fieldsStateStringified);

    (sections[0]?.fields || []).forEach((field: Field) => {
      if (
        !fieldsStateParsed[field.columnName]?.isVisible &&
        field.columnName !== 'space'
      ) {
        unregister(field.columnName);
      }
    });
  }, [fieldsStateStringified, unregister, watch]);

  const sectionTypeLayout = (sections: Section[]) => {
    if (sections[0]?.fields.length > 0) {
      return (
        <>
          <Stack className={classes.rowContainer}>
            <div className={classes.container}>
              {(sections[0]?.fields || []).map((field: Field, idx) => {
                if (!fieldsState[field.columnName]?.isVisible) {
                  return null;
                }

                return (
                  <SectionView
                    width={field.width}
                    key={field.columnName + idx}
                    style={{ display: isShow ? 'none' : '' }}
                    className={
                      field.columnName === 'users__s'
                        ? classes.userContainer
                        : ''
                    }
                  >
                    {getFieldContainer(
                      objectName,
                      attributeConfig,
                      field.columnName,
                      control,
                      watch,
                      setValue,
                      trigger,
                      mode,
                      undefined,
                      undefined,
                      { ...field, ...fieldsState[field.columnName] }
                    )}
                  </SectionView>
                );
              })}
            </div>
          </Stack>
          <FormActionButtonsContainer style={{ display: isShow ? 'none' : '' }}>
            <CustomButton
              buttonTitle="Cancel"
              buttonType={ButtonTypes.Ghost}
              onButtonClicked={onCancel}
              appearance={ButtonTypes.Transparent}
              disabled={isLoading}
            />
            <CustomButton
              buttonTitle="Save"
              leftIcon="Save"
              buttonType={ButtonTypes.Primary}
              isLoading={isLoading}
              onSubmit={!isLoading ? () => onSave() : undefined}
              disabled={!isDirty || isLoading}
            />
          </FormActionButtonsContainer>
        </>
      );
    }
  };

  useEffect(() => {
    window.addEventListener('TOGGLE_ON', () => {
      setIsShow(true);
    });
    window.addEventListener('TOGGLE_OFF', () => {
      setIsShow(false);
    });
    return () => {
      window.removeEventListener('TOGGLE_ON', () => {
        setIsShow(true);
      });
      window.removeEventListener('TOGGLE_OFF', () => {
        setIsShow(false);
      });
    };
  }, []);

  return (
    <form
      onSubmit={onFormSubmit}
      noValidate
      encType="multipart/form-data"
      className={classes.form}
    >
      {isRecordDataLoading ? (
        <Stack className={classes.ht}>
          <Loader fullPage />
        </Stack>
      ) : (
        <Stack className={classes.formContainer} {...rest}>
          {sectionTypeLayout(sections)}
        </Stack>
      )}
      <ConfirmDialog
        dataTestId={dataTestIdForWarningModal}
        open={isCancelModalOpen}
        onCancelClicked={() => toggleCancelModalBtnClick()}
        onConfirmClicked={() => toggleCancelModalBtnClick(true)}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL}
        iconSrc={WarningIcon}
        description={LocalizationString.CANCEL_POPUP_MSG}
      />
      <ConfirmDialog
        dataTestId={dataTestIdForWarningModal}
        open={isWarningModalOpen}
        onCancelClicked={toggleWarningModalBtnClick}
        onConfirmClicked={toggleWarningModalBtnClick}
        primaryButtonText={LocalizationString.GOT_IT}
        title={LocalizationString.ERROR}
        iconSrc={WarningIcon}
        description={LocalizationString.VALIDATION_DESCRIPTION}
      />
      <ConfirmDialog
        dataTestId={dataTestIdForSaveModal}
        open={isSaveModalOpen}
        onCancelClicked={toggleSaveModalBtnClick}
        onConfirmClicked={toggleSaveModalBtnClick}
        primaryButtonText={LocalizationString.GREAT}
        title={LocalizationString.UPDATE_REQUEST}
        iconSrc={SaveIcon}
        description={LocalizationString.UPDATED_DESCRIPTION}
        dontShowCloseIcon
      />
      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
    </form>
  );
};
