import React from 'react';

import InAppNotificationsController from './in-app-notifications.controller';
import { InAppNotificationsProps } from './in-app-notifications.model';

export const InAppNotificationsComponent: React.FC<InAppNotificationsProps> = (
  props
) => {
  return <InAppNotificationsController {...props} />;
};
