import React from 'react';

export interface ActionContextProps {
  onClickCheckInAction: (latestVersion?: string) => void;
  onClickCheckOutAction: (latestVersion?: string) => void;
}
const ActionContext = React.createContext<ActionContextProps>({
  onClickCheckInAction: () => {},
  onClickCheckOutAction: () => {},
});

export default ActionContext;
