import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const messageDialogStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    width: '400px',
    ...shorthands.borderRadius('6px'),
  },

  modalHeader: {
    display: 'flex',
    width: '100%',
    ...shorthands.padding('0.75rem'),
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    backgroundColor: Color.drawer_header_color,
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 700,
    justifyContent: 'space-between',
  },

  modalBody: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...shorthands.overflow('hidden'),
    ...shorthands.gap('1.5rem'),
    ...shorthands.padding('1.5rem'),
  },

  modalBodyWithChildren: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'center',
    ...shorthands.overflow('hidden'),
    ...shorthands.gap('1.5rem'),
    ...shorthands.padding('1.5rem'),
  },

  modalFooter: {
    ...shorthands.gap('1rem'),
    ...shorthands.padding('0.75rem'),
    width: '100%',
    backgroundColor: Color.drawer_header_color,
    justifyContent: 'center',
    boxShadow: 'inset 0px 1px 0px var(--gray-200)',
  },

  description: {
    textAlign: 'center',
    fontSize: '1rem',
  },
});
