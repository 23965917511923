import { TimePickerComponentProps } from './time-picker.model';
import TimePickerView from './time-picker.view';

interface TimePickerControllerProps extends TimePickerComponentProps {}

const TimePickerController = (
  props: TimePickerControllerProps
): JSX.Element => {
  return <TimePickerView {...props} />;
};

export default TimePickerController;
