import { makeStyles, shorthands } from '@fluentui/react-components';

export const addUserStyles = makeStyles({
  addUserContainer: {
    width: '100%',
    ...shorthands.gap('8px'),
    display: 'flex',
    alignItems: 'center',
  },

  peoplePickerContainer: { width: '350px', height: '96px' },

  button: {
    height: '40px',
  },
});
