import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes, ObjectEnum, OperatorsEnum } from '@celito.clients/enums';
import { CustomButton, Icon, InHouseInputSelect } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { ControlledDocumentRequestTypeEnum } from '@celito.clients/types';
import {
  createTestAttribute,
  findDataTestIdFromField,
  getReferencePickerType,
} from '@celito.clients/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from '@fluentui/react-components';
import { IChangeData } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';
import { components, OptionProps } from 'react-select';

import { ControlledDocumentPickerModalProps } from './controlled-document-picker-modal.model';
import { controlledDocumentPickerModalStyles } from './controlled-document-picker-modal.styles';

const filters: RulesComponentListViewFilter = {
  conditions: {
    all: [
      {
        all: [
          {
            attribute: 'version',
            operator: OperatorsEnum.ENDS_WITH,
            value: '.0',
          },
          {
            attribute: 'isActive',
            operator: OperatorsEnum.EQUALS,
            value: true,
          },
          {
            attribute: 'activeFlowName',
            operator: OperatorsEnum.IS_BLANK,
            value: null,
          },
          {
            attribute: 'documentStatus',
            operator: OperatorsEnum.EQUALS,
            value: 'Effective',
          },
        ],
      },
    ],
  },
};

export const Option = (props: OptionProps) => {
  const dataTestId = findDataTestIdFromField(props.label);

  return (
    <components.Option {...props}>
      <span data-testid={`dropdown-option-${createTestAttribute(dataTestId)}`}>
        {props.label}
      </span>
    </components.Option>
  );
};
interface ControlledDocumentPickerModalViewProps
  extends ControlledDocumentPickerModalProps {
  selectedValue: IChangeData | undefined;
  onPickerValChange: (val: IChangeData) => void;
  version: string | undefined;
  setVersion: (val: string | undefined) => void;
  onClose: () => void;
  setSelectedValue: React.Dispatch<
    React.SetStateAction<IChangeData | undefined>
  >;
}

export const ControlledDocumentPickerModalView: React.FC<
  ControlledDocumentPickerModalViewProps
> = ({
  controlledDocumentPickerModalOpen,
  onCloseControlledDocumentPickerModal,
  selectedValue,
  onPickerValChange,
  version,
  setVersion,
  onUpdateInitiate,
  onClose,
  setSelectedValue,
}) => {
  const { cssVariables } = useTheme();

  const styles = controlledDocumentPickerModalStyles();

  return (
    <Dialog open={controlledDocumentPickerModalOpen}>
      <DialogSurface
        className={styles.container}
        style={{ padding: 0, ...cssVariables }}
      >
        <DialogBody className={styles.dialogBody}>
          <DialogTitle className={styles.modalHeader}>
            {LocalizationString.UPDATE_DOCUMENT}

            <Button
              appearance="subtle"
              icon={<Icon iconName="Dismiss24Regular" />}
              onClick={onClose}
            />
          </DialogTitle>

          <DialogContent className={styles.modalBody}>
            <InHouseInputSelect
              label={LocalizationString.UPDATE_DOCUMENT}
              referencePicker={{
                objectName: 'controlled_document__a',
                filters,
                pickerType: getReferencePickerType(
                  ObjectEnum.CONTROLLED_DOCUMENT
                ),
              }}
              onOptionSelect={(event, data) => {
                const version = data?.data?.version;
                if (version) setVersion(version as string);
                onPickerValChange(data);
              }}
              options={[]}
              selectedOptions={selectedValue?.selectedOptions}
              fieldClassName={styles.fieldContainer}
            />
          </DialogContent>

          <DialogActions className={styles.modalFooter}>
            <CustomButton
              buttonType={ButtonTypes.Ghost}
              buttonTitle={LocalizationString.CANCEL}
              onClick={onClose}
            />

            <CustomButton
              buttonType={ButtonTypes.Primary}
              buttonTitle={'Update'}
              isDisabled={selectedValue === undefined}
              onClick={() => {
                onUpdateInitiate({
                  ...selectedValue?.data,
                  version: version!,
                  objectDefinitionName: 'controlled_document__a',
                  requestType: ControlledDocumentRequestTypeEnum.UpdateDocument,
                  editViewName: 'controlled_document_edit_view__a',
                });
                setSelectedValue(undefined);
              }}
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
