import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes, FormEngineModeTypeEnum } from '@celito.clients/enums';
import {
  CustomButton,
  Field,
  FormActionButtonsContainer,
  Icon,
  IconButton,
  InHouseInputSelect,
  TextField,
  TextFieldArea,
} from '@celito.clients/shared';
import {
  Divider,
  InfoLabel,
  Subtitle1,
  Switch,
  Text,
} from '@fluentui/react-components';
import { useRef } from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';

import { Choices } from './components/choices/choices.component';
import { IQuizFormData, QuestionTypes } from './quiz-questions-page.model';
import { QuizQuestionPageStyles } from './quiz-questions-page.styles';

interface QuizQuestionViewProps {
  onAddQuestion: () => void;
  onRemoveQuestion: (index: number) => void;
  methods: UseFormReturn<IQuizFormData>;
  questionTypeOptions: { text: string; value: string }[];
  mode: FormEngineModeTypeEnum;
  handleSetCurrentQuestionNumber: (order: number) => void;
  currentQuestionNumber: number;
  onSaveAndAdd: (e: React.BaseSyntheticEvent) => void;
  onCancel:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  step: number;
  totalSteps: number;
  onFormEngineAction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: string
  ) => void;
  watchedMinQuestions: number;
  isLoading?: boolean;
}

export const QuizQuestionsView = ({
  methods,
  onAddQuestion,
  onRemoveQuestion,
  questionTypeOptions,
  mode,
  handleSetCurrentQuestionNumber,
  currentQuestionNumber,
  onSaveAndAdd,
  onCancel,
  step,
  totalSteps,
  onFormEngineAction,
  watchedMinQuestions,
  isLoading,
}: QuizQuestionViewProps) => {
  const styles = QuizQuestionPageStyles();

  const { watch, control, trigger, setValue, clearErrors } = methods;

  const {
    formState: { isDirty },
  } = useFormContext();

  const watchedQuestions = watch('questions', []);

  const isViewMode = mode === FormEngineModeTypeEnum.View;

  const formRef = useRef<HTMLFormElement | null>(null);

  return (
    <>
      <div className={styles.root}>
        <form ref={formRef} onSubmit={(e) => e.preventDefault()} noValidate>
          <div className={styles.btnActionContainer}>
            <div className={styles.titleContainer}>
              <Subtitle1>{LocalizationString.QUESTIONS}</Subtitle1>

              <span className={styles.questionRequiredError}>
                {watchedQuestions.length < watchedMinQuestions &&
                  `Add at least ${watchedMinQuestions} questions`}
              </span>
            </div>

            <div className={styles.btnInnerContainer}>
              {!isViewMode && (
                <CustomButton
                  buttonTitle="Add Question"
                  buttonType={ButtonTypes.Ghost}
                  onClick={(e) => {
                    trigger(`questions.${currentQuestionNumber - 1}`).then(
                      (_) => onSaveAndAdd(e)
                    );
                  }}
                />
              )}
            </div>
          </div>

          <div className={styles.questionContainer}>
            {watchedQuestions.map((question, questionIndex) => {
              return (
                question.order === currentQuestionNumber && (
                  <div
                    className={styles.formContainer}
                    key={`formContainer-${question.order} `}
                  >
                    <div className={styles.questionActions}>
                      <Text className={styles.questionNumberText}>
                        <Field
                          required={watch(
                            `questions.${questionIndex}.isMandatory`
                          )}
                          label={`${LocalizationString.QUESTION} ${
                            questionIndex + 1
                          }`}
                        />
                      </Text>

                      <div className={styles.questionPageControl}>
                        <IconButton
                          icon={<Icon iconName="ChevronLeft20Regular" />}
                          disabled={question.order === 1}
                          onClick={() =>
                            trigger('questions').then((res) =>
                              handleSetCurrentQuestionNumber(question.order - 1)
                            )
                          }
                        />

                        <TextField
                          size="large"
                          onChange={(_ev, data) => {
                            if (data.value) {
                              const totalPages = watchedQuestions.length;

                              if (parseInt(data.value) > totalPages) {
                                handleSetCurrentQuestionNumber(totalPages);
                              } else {
                                handleSetCurrentQuestionNumber(
                                  parseInt(data.value)
                                );
                              }
                            } else {
                              handleSetCurrentQuestionNumber(1);
                            }
                          }}
                          value={currentQuestionNumber.toString()}
                          customStyles={styles.questionNumberField}
                        />

                        <span className={styles.totalQuestions}>
                          / {watchedQuestions.length}
                        </span>

                        <IconButton
                          icon={<Icon iconName="ChevronRight20Regular" />}
                          disabled={question.order === watchedQuestions.length}
                          onClick={() =>
                            trigger('questions').then((res) =>
                              handleSetCurrentQuestionNumber(question.order + 1)
                            )
                          }
                        />

                        {!isViewMode && watchedQuestions.length > 1 && (
                          <IconButton
                            onClick={() => onRemoveQuestion(question.order)}
                            icon={
                              <Icon
                                iconName="Delete24Regular"
                                className={styles.deleteIcon}
                              />
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className={styles.settings}>
                      <Controller
                        name={`questions.${questionIndex}.questionType`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <InHouseInputSelect
                            required
                            label={LocalizationString.QUESTION_TYPE}
                            options={questionTypeOptions}
                            onOptionSelect={(_ev, option) => {
                              field.onChange(option.optionValue);

                              setValue(
                                `questions.${questionIndex}.choices`,
                                [],
                                { shouldTouch: true }
                              );

                              clearErrors(`questions.${questionIndex}.choices`);
                            }}
                            placeholder={
                              LocalizationString.SELECT_QUESTION_TYPE
                            }
                            selectedOptions={{
                              text:
                                questionTypeOptions.find(
                                  (opt) =>
                                    opt.value ===
                                    watch(
                                      `questions.${questionIndex}.questionType`
                                    )
                                )?.text ?? '',
                              value: watch(
                                `questions.${questionIndex}.questionType`
                              ),
                            }}
                            disabled={isViewMode}
                            errorMessage={error?.message}
                          />
                        )}
                      />

                      <div className={styles.endSettings}>
                        <Controller
                          name={`questions.${questionIndex}.isMandatory`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Switch
                              label={
                                <InfoLabel
                                  size="large"
                                  className={styles.helper}
                                  info={
                                    LocalizationString.MANDATORY_HELPER_TEXT
                                  }
                                >
                                  {LocalizationString.MANDATORY}
                                </InfoLabel>
                              }
                              data-testid={`radio-button-mandatory`}
                              labelPosition="above"
                              onChange={(_ev, data) =>
                                field.onChange(data.checked)
                              }
                              disabled={isViewMode}
                              checked={watch(
                                `questions.${questionIndex}.isMandatory`
                              )}
                            />
                          )}
                        />

                        {watch(`questions.${questionIndex}.questionType`) !==
                          QuestionTypes.BOOLEAN && (
                          <Controller
                            name={`questions.${questionIndex}.isRandom`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <Switch
                                label={
                                  <InfoLabel
                                    size="large"
                                    className={styles.helper}
                                    info={
                                      LocalizationString.RANDOMIZE_HELPER_TEXT
                                    }
                                  >
                                    {LocalizationString.RANDOMIZE}
                                  </InfoLabel>
                                }
                                labelPosition="above"
                                onChange={(_ev, data) =>
                                  field.onChange(data.checked)
                                }
                                disabled={isViewMode}
                                checked={watch(
                                  `questions.${questionIndex}.isRandom`
                                )}
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>

                    <Divider appearance="subtle" style={{ flexGrow: 0 }} />

                    <div className={styles.questionContent}>
                      <Controller
                        name={`questions.${questionIndex}.questionText`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextFieldArea
                            size="large"
                            required
                            label={LocalizationString.QUESTION_TEXT}
                            value={watch(
                              `questions.${questionIndex}.questionText`
                            )}
                            disabled={isViewMode}
                            onChange={(_ev, data) => field.onChange(data.value)}
                            placeholder={LocalizationString.ENTER_QUESTION_TEXT}
                            errorMessage={error?.message}
                          />
                        )}
                      />

                      {watch(`questions.${questionIndex}.questionType`) && (
                        <div className={styles.choices}>
                          <Field required size="large" label={'Choices'} />

                          <Choices
                            methods={methods}
                            questionType={watch(
                              `questions.${questionIndex}.questionType`
                            )}
                            question={question}
                            questionIndex={questionIndex}
                            isViewMode={isViewMode}
                          />
                        </div>
                      )}
                    </div>

                    <Divider
                      appearance="subtle"
                      alignContent="start"
                      style={{ flexGrow: 0 }}
                    ></Divider>

                    <div
                      className={styles.questionContent}
                      style={{ paddingBottom: '1.5rem' }}
                    >
                      <Controller
                        name={`questions.${questionIndex}.feedback`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextFieldArea
                            size="large"
                            value={watch(`questions.${questionIndex}.feedback`)}
                            onChange={(_ev, data) => field.onChange(data.value)}
                            label={LocalizationString.FEEDBACK}
                            disabled={isViewMode}
                            placeholder={LocalizationString.ENTER_FEEDBACK_TEXT}
                          />
                        )}
                      />
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </form>
      </div>

      <FormActionButtonsContainer>
        <CustomButton
          buttonTitle={LocalizationString.CANCEL}
          buttonType={ButtonTypes.Ghost}
          onClick={onCancel}
        />
        <div className={styles.footerBtnContainer}>
          {step > 0 && (
            <CustomButton
              buttonTitle="Previous"
              leftIcon="Previous"
              buttonType={ButtonTypes.Ghost}
              onClick={(_) => {
                trigger('questions').then((res) =>
                  onFormEngineAction(_, 'previous')
                );
              }}
            />
          )}
          {step < totalSteps - 1 && (
            <CustomButton
              buttonTitle="Next"
              leftIcon="Next"
              buttonType={ButtonTypes.Ghost}
              onClick={(_) => {
                trigger('questions').then((res) =>
                  onFormEngineAction(_, 'next')
                );
              }}
            />
          )}

          {(mode === FormEngineModeTypeEnum.Edit ||
            mode === FormEngineModeTypeEnum.Submit ||
            mode === FormEngineModeTypeEnum.Create) && (
            <CustomButton
              buttonTitle={LocalizationString.SAVE}
              isLoading={isLoading}
              disabled={isLoading || !isDirty}
              leftIcon="Save"
              buttonType={ButtonTypes.Secondary}
              onClick={(_) => {
                onFormEngineAction(_, 'saveDraft');
              }}
            />
          )}
          {mode === FormEngineModeTypeEnum.Submit &&
            step === totalSteps - 1 && (
              <CustomButton
                buttonTitle="Submit"
                leftIcon="Submit"
                buttonType={ButtonTypes.Primary}
                onClick={(_) => onFormEngineAction(_, 'saveDraft')}
              />
            )}
        </div>
      </FormActionButtonsContainer>
    </>
  );
};
