import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { useQueryParams } from '@celito.clients/hooks';
import { getEditDocumentUrl } from '@celito.clients/services';
import { ConfirmDialog, Loader, StringList } from '@celito.clients/shared';
import { APIError } from '@celito.clients/types';
import { createTestAttribute } from '@celito.clients/utils';
import { AxiosError, isAxiosError } from 'axios';
import { useGetAccessToken } from 'libs/core/src/hooks/useAccessToken';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import classes from './wopi-editor.module.css';
import { WopiEditorView } from './wopi-editor.view';

export const WopiEditorController: React.FC = () => {
  const [editUrl, setEditUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState<string | JSX.Element>('');
  const { getAccessToken } = useGetAccessToken();
  const { getSearchParams } = useQueryParams();
  const params = useParams();
  const searchParams = getSearchParams();

  useEffect(() => {
    fetchUrl();
  }, []);

  const fetchUrl = async () => {
    setIsLoading(true);
    const accessToken = await getAccessToken();
    setAccessToken(accessToken);
    getEditDocumentUrl(
      params.documentId!,
      accessToken,
      searchParams.version,
      searchParams.taskname,
      searchParams.viewType
    )
      .then((res) => {
        setEditUrl(res.hostEditUrl);
        setShowPopup(false);
        setError('');
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (isAxiosError(err) || err instanceof AxiosError) {
          const error = err as AxiosError<APIError>;
          const { errorMessage } = error?.response?.data ?? {};
          if (errorMessage && Array.isArray(errorMessage)) {
            const message = <StringList as="ul" content={errorMessage} />;
            setError(message);
          } else {
            errorMessage && setError(errorMessage);
          }
          setShowPopup(true);
        }
      });
  };

  const onConfirm = () => {
    window.close();
  };

  const renderLoaderOrPopupOrEditor = () => {
    if (isLoading) {
      return <Loader className={classes.loader} />;
    }
    if (showPopup) {
      return (
        <ConfirmDialog
          dataTestId={`save-modal-${createTestAttribute(
            LocalizationString.SAVE_REQUEST
          )}`}
          open={true}
          onConfirmClicked={onConfirm}
          primaryButtonText={LocalizationString.GO_BACK_TO_APP}
          title={LocalizationString.ERROR}
          iconSrc={WarningIcon}
          dontShowCloseIcon
          description={error}
        />
      );
    }
    return <WopiEditorView url={editUrl} accessToken={accessToken} />;
  };

  return renderLoaderOrPopupOrEditor();
};
