import { AttributeTypeEnum } from '@celito.clients/enums';
import { CheckBox } from '@celito.clients/shared';

import { CheckboxFacetViewProps } from './checkbox-facet.model';
import classes from './checkbox-facet.module.css';

const CheckboxFacetView: React.FC<CheckboxFacetViewProps> = (props) => {
  const {
    visibleCheckboxes,
    onToggelCheckboxHandler,
    setShowMorehandler,
    showMoreClicked,
    isShowMoreOptionNeeded,
    disabled,
  } = props;

  return (
    <div>
      <div className={classes.checkbox_list}>
        {visibleCheckboxes.map((item) => (
          <CheckBox
            label={`${item.option} (${item.docCount})`}
            onChanged={() =>
              onToggelCheckboxHandler(
                item.option,
                item.dataType as AttributeTypeEnum
              )
            }
            checked={item.checked}
            disabled={disabled}
            className={classes.checkbox_item}
          />
        ))}
        {isShowMoreOptionNeeded && (
          <div
            className={classes.show_more_option}
            role="button"
            onClick={setShowMorehandler}
          >
            {showMoreClicked ? 'Show Less' : 'Show More'}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckboxFacetView;
