import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { FormProvider, useForm } from 'react-hook-form';

import { CustomPageProps } from '../../types/common-types';
import { QuizQuestionsController } from './quiz-questions-page.controller';

export const QuizQuestionsPage = (props: CustomPageProps): JSX.Element => {
  const methods = useForm();
  if (props.mode !== FormEngineModeTypeEnum.View) {
    return <QuizQuestionsController {...props} />;
  } else {
    return (
      <FormProvider {...methods}>
        <QuizQuestionsController {...props} />
      </FormProvider>
    );
  }
};
