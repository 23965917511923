import { LocalizationString } from '@celito.clients/assets';
import { Relationship } from '@celito.clients/types';
import {
  Button,
  Input,
  Menu,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
  Tooltip,
} from '@fluentui/react-components';
import { ObjectDefinitionDto } from 'apps/web-client/src/types/object-definition-dto';
import { useMemo } from 'react';

import { createTestAttribute } from '../../../../core/src/utils/e2e-test.util';
import { Icon } from '../shared';
import { NestedSelector } from './components/nested-selector/nested-selector.component';
import { FieldSelectorProps } from './field-selector.model';
import { FieldSelectoStyles } from './field-selector.styles';

interface FieldSelectorViewProps extends FieldSelectorProps {
  fields?: ObjectDefinitionDto;
  isLoading: boolean;
  onFieldClick: (
    fieldName: string,
    relationship?: Relationship,
    appendInitalObject?: boolean,
    objectName?: string,
    firstLevel?: string
  ) => void;
  onCustomSearch: (search: string) => void;
  customSearch: string;
}

export const FieldSelectorView = ({
  fields,
  customSearch,
  onCustomSearch,
  isLoading,
  marginTop,
  appendInitalObject = false,
  objectName,
  onFieldClick,
}: FieldSelectorViewProps) => {
  const styles = FieldSelectoStyles();

  const filteredFieds = useMemo(() => {
    return fields?.objectAttributeDefinitions?.filter(
      ({ label, shouldNotDisplayAsMergeField }) =>
        label.toLowerCase().includes(customSearch.toLowerCase()) &&
        !shouldNotDisplayAsMergeField
    );
  }, [customSearch, fields?.objectAttributeDefinitions]);

  return (
    <Menu positioning={'before'}>
      <MenuTrigger disableButtonEnhancement>
        <Tooltip
          relationship="label"
          content={LocalizationString.FIELD_SELECTOR}
        >
          <Button
            data-testId="button-field-selector"
            className={styles.btnTrigger}
            style={{ marginTop: `${marginTop}px` }}
            disabled={!fields?.objectActionDefinitions || isLoading}
            icon={
              isLoading ? (
                <Spinner size="tiny" />
              ) : (
                <Icon iconName="Merge16Filled" />
              )
            }
          ></Button>
        </Tooltip>
      </MenuTrigger>

      <MenuPopover>
        <Input
          placeholder="Search field"
          className={styles.search}
          value={customSearch}
          onChange={(_, data) => {
            onCustomSearch(data.value);
          }}
          contentBefore={<Icon iconName="Search12Regular" />}
        />

        <MenuGroup>
          <MenuGroupHeader>{fields?.label}</MenuGroupHeader>

          <MenuList className={styles.menuList}>
            {filteredFieds?.map((field) =>
              field.relationship ? (
                <NestedSelector
                  key={`selector-a-${field.name}`}
                  relationship={field.relationship}
                  field={field}
                  appendInitalObject={appendInitalObject}
                  parentObjectName={objectName}
                  onFieldClick={onFieldClick}
                />
              ) : (
                <MenuItem
                  data-testId={`menu-item-${createTestAttribute(field.label)}`}
                  onClick={() =>
                    onFieldClick(
                      field.name,
                      undefined,
                      appendInitalObject,
                      objectName
                    )
                  }
                  key={field.name}
                >
                  {field.label}
                </MenuItem>
              )
            )}
          </MenuList>
        </MenuGroup>
      </MenuPopover>
    </Menu>
  );
};
