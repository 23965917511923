import Cookies from 'js-cookie';

import { Constants } from '../enums/constants';

class IdManager {
  private static instance: IdManager;
  public static getInstance(): IdManager {
    if (!this.instance) {
      this.instance = new IdManager();
    }
    return IdManager.instance;
  }

  getId() {
    return Cookies.get(Constants.USER_ID);
  }

  setId(id: string) {
    Cookies.set(Constants.USER_ID, id);
  }

  removeId() {
    Cookies.remove(Constants.USER_ID);
  }
}
export default IdManager;
