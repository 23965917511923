import { LocalizationString } from '@celito.clients/assets';
import { useCallbackPrompt } from '@celito.clients/hooks';
import { infoToast, raiseErrorToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { notificationService } from '../../../settings/notifications/hooks/notificationService';
import { ObjectDefinitionType } from '../../../settings/notifications/notification-form/notification-form.model';
import {
  getDocumentData,
  updateDocumentData,
} from '../../services/document-data';
import AdminSettingsDocumentDataView from './edit-document-data.view';

const MAX_CHARACTERS = 512;

const schema = yup.object().shape({
  expirationInDays: yup
    .number()
    .typeError(LocalizationString.REQUIRED_MSG)
    .required(LocalizationString.REQUIRED_MSG)
    .min(1, 'Expiration Days must be greater than or equal to 1"'),
  effectiveHeaderMessage: yup
    .string()
    .max(MAX_CHARACTERS, `Max ${MAX_CHARACTERS} characters`),
  effectiveFooterMessage: yup
    .string()
    .max(MAX_CHARACTERS, `Max ${MAX_CHARACTERS} characters`),
}) as yup.ObjectSchema<FieldValues>;

export interface ICopyLabel {
  id: number;
  label: string;
}

export type ObjectItem = {
  value: string;
  text: string;
};

const AdminSettingsDocumentDataController = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: { isDirty },
  } = useForm<FieldValues>({
    defaultValues: {
      expirationInDays: '',
      documentWatermark: '',
      effectiveHeaderMessage: '',
      effectiveFooterMessage: '',
      fieldType: { text: '', value: '' },
      field: '',
    },
    resolver: yupResolver(schema),
    mode: 'all',
    shouldUnregister: false,
    reValidateMode: 'onChange',
  });
  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(isDirty);

  const fieldType = watch('fieldType');

  const { getObjects, getObjectsByName } = notificationService();

  const [copyButtonLabel, setCopyButtonLabel] = useState<ICopyLabel[]>([
    { id: 1, label: 'Copy' },
    { id: 2, label: 'Copy' },
  ]);
  const [objects, setObjects] = useState<Partial<ObjectDefinitionType>[]>([]);
  const [mergeFieldValues, setMergeFieldValues] = useState<ObjectItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const onCopyClicked = useCallback((value: string, copyLabelId: number) => {
    const updatedButtons = copyButtonLabel.map((button) => {
      if (button.id === copyLabelId) {
        return { ...button, label: 'Copied' };
      }
      return button;
    });

    setCopyButtonLabel(updatedButtons);

    const clipboardValue = '${' + value + '}';
    if (navigator.clipboard) {
      navigator.clipboard.writeText(clipboardValue);
      infoToast({
        message: 'Copied to clipboard',
      });
    }

    setTimeout(() => {
      const revertedButtons = copyButtonLabel.map((button) => {
        if (button.id === copyLabelId) {
          return { ...button, label: 'Copy' };
        }
        return button;
      });
      setCopyButtonLabel(revertedButtons);
    }, 3000); // Revert after 3 seconds
  }, []);

  useEffect(() => {
    getObjects()
      .then((resp: ObjectDefinitionType[]) => {
        if (resp) {
          const data: Partial<ObjectDefinitionType>[] = resp.map((item) => ({
            name: item.name,
            label: item.label,
          }));
          data.push({
            name: 'allObjects',
            label: 'For all objects',
          });
          setObjects([...data]);
        }
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  }, []);

  // useeffect for fetching merge field values based on object name
  useEffect(() => {
    if (fieldType?.value) {
      getObjectsByName(fieldType.value)
        .then(
          (resp: { objectAttributeDefinitions: ObjectDefinitionType[] }) => {
            const { objectAttributeDefinitions } = resp;
            let data: ObjectItem[] = [];
            for (const item of objectAttributeDefinitions) {
              data = [...data, ...handleNestedFields(item)];
            }
            setMergeFieldValues(data);
          }
        )
        .catch((_error) => {
          raiseErrorToast(_error);
        });
    }
  }, [fieldType]);
  const toggleCancelModalBtnClick = (navigateBack?: boolean) => {
    setIsCancelModalOpen((prev) => !prev);
    if (navigateBack) {
      reset({ ...getValues() });
      confirmNavigation();
      navigate(-1);
    }
  };
  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isDirty) {
      setIsCancelModalOpen(true);
    } else {
      navigate(-1);
    }
  };
  const handleNestedFields = (
    objectDefinition: ObjectDefinitionType,
    prefix = ''
  ): ObjectItem[] => {
    const { objectAttributes } = objectDefinition;
    let data: ObjectItem[] = [
      {
        value:
          prefix !== ''
            ? `${prefix}.${objectDefinition.name}`
            : objectDefinition.name,
        text:
          prefix !== ''
            ? `${prefix}.${objectDefinition.name}`
            : objectDefinition.name,
      },
    ];

    if (objectAttributes) {
      for (const attr of objectAttributes) {
        const getRelationshipNamePrefix = (prefix: string) =>
          prefix !== ''
            ? `${prefix}.${objectDefinition.relationshipName}`
            : `${objectDefinition.relationshipName}`;
        data = [
          ...data,
          ...handleNestedFields(
            attr,
            objectDefinition.relationshipName
              ? getRelationshipNamePrefix(prefix)
              : prefix
          ),
        ];
      }
    }
    return data;
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const toggleSaveModalBtnClick = () => {
    setIsSaveModalOpen(!isSaveModalOpen);

    if (isSaveModalOpen) navigateBack();
  };

  const onSubmit = async () => {
    await handleSubmit((data) => {
      const formData = new FormData();
      formData.append('expirationInDays', data.expirationInDays);
      formData.append('documentWaterMark', data.documentWatermark);
      formData.append('effectiveHeaderMessage', data.effectiveHeaderMessage);
      formData.append('effectiveFooterMessage', data.effectiveFooterMessage);
      setIsSaving(true);
      updateDocumentData(formData)
        .then(() => {
          reset({ ...getValues() });
          toggleSaveModalBtnClick();
        })
        .catch((e) => {
          raiseErrorToast(e);
        })
        .finally(() => setIsSaving(false));
    })();
  };

  useEffect(() => {
    setLoading(true);
    getDocumentData()
      .then((res: any) => {
        if (res?.data?.[0]) {
          const documentData = res?.data?.[0];

          const formData = {
            expirationInDays: documentData?.expirationInDays ?? '',
            documentWatermark: documentData?.documentWaterMark ?? '',
            effectiveHeaderMessage: documentData?.effectiveHeaderMessage ?? '',
            effectiveFooterMessage: documentData?.effectiveFooterMessage ?? '',
          };

          reset(formData);
        }
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <AdminSettingsDocumentDataView
      {...{ onSubmit, control, handleSubmit, navigateBack, watch }}
      objects={objects}
      onCopyClicked={onCopyClicked}
      mergeFieldValues={mergeFieldValues}
      isLoading={loading}
      isSaveModalOpen={isSaveModalOpen}
      toggleSaveModalBtnClick={toggleSaveModalBtnClick}
      isSaving={isSaving}
      cancelNavigation={cancelNavigation}
      confirmNavigation={confirmNavigation}
      showPrompt={showPrompt}
      isCancelModalOpen={isCancelModalOpen}
      toggleCancelModalBtnClick={toggleCancelModalBtnClick}
      onCancel={onCancel}
      isDirty={isDirty}
    />
  );
};

export default AdminSettingsDocumentDataController;
