import { logger, postload } from '@celito.clients/services';
import { createFluentIcon } from '@fluentui/react-icons';
import { stubObject, times } from 'lodash';

import { IconComponent } from './icon.component';
import commonIconNames from './names/common.json';
import commonPaths from './paths/common.json';

postload.register(() => {
  times(26, (i) => {
    const letter = String.fromCharCode(i + 65).toLowerCase();

    import(`./paths/${letter}.json`).catch(stubObject);
  });
});

const getDefaultIcons = () => {
  const defaultIcons = new Array<[string, IconComponent]>();
  try {
    commonIconNames.forEach((iconName) => {
      const iconArgs = commonPaths[iconName as keyof typeof commonPaths];
      defaultIcons.push([
        iconName,
        createFluentIcon(iconName, iconArgs.size, iconArgs.paths),
      ]);
    });
  } catch (error) {
    logger.error('Error loading default icons');
  }
  return defaultIcons;
};

/**
 * Icons cache
 */
export const icons: Map<string, IconComponent> = new Map(getDefaultIcons());

export const resolveIcon = async (
  iconName: string,
  onLoaded: (icon: IconComponent) => void,
  onError?: (error: unknown) => void
) => {
  try {
    const json = await import(
      `./paths/${iconName.charAt(0).toLowerCase()}.json`
    );

    const iconComponent = createFluentIcon(
      iconName,
      json[iconName].size,
      json[iconName].paths
    );

    if (!iconComponent) throw new Error(`Icon ${iconName} not found`);

    icons.set(iconName, iconComponent);

    onLoaded(iconComponent);
  } catch (error) {
    onError?.(error);
  }
};
