import { OperatorsEnum } from '@celito.clients/enums';

export type RuleComponentType = 'singleRule' | 'multipleRules';

export enum RuleComponentEnum {
  SingleRule = 'singleRule',
  MultipleRules = 'multipleRules',
}

export interface IFilterView {
  label: string;
  isDefault: boolean;
  filtersToBeApplied: RulesComponentListViewFilter[];
}

export interface RulesComponentListViewFilter {
  conditions?: IFiltersToBeApplied;
}

export interface ISimplifedFilter {
  attribute: string | undefined;
  operator?: OperatorsEnum;
  value?: any;
  referenceFilters?: any;
  moduleFilterForObjectDefinition?: string[];
}

export interface IFiltersToBeApplied {
  [conditionType: string]: {
    [conditionType: string]: ISimplifedFilter[];
  }[];
}

export interface IAllOrAnyFilter {
  [conditionType: string]: ISimplifedFilter[];
}

// Compressed filters
export interface ICompressedSimplifedFilter {
  a: ISimplifedFilter['attribute'];
  o?: ISimplifedFilter['operator'];
  v?: ISimplifedFilter['value'];
  r?: ICompressedReferenceFilter[];
}

export interface ICompressedFiltersToBeApplied {
  [conditionType: string]: {
    [conditionType: string]: ICompressedSimplifedFilter[];
  }[];
}

export interface IReferenceFilter {
  [conditionType: string]: {
    [conditionType: string]: IFiltersToBeApplied[];
  };
}

export interface ICompressedReferenceFilter {
  [conditionType: string]: {
    [conditionType: string]: ICompressedFiltersToBeApplied[];
  };
}
