import { FieldValues } from 'react-hook-form';

import { ControlledRulesPickerController } from './controlled-rules-picker.controller';
import { ControlledRulesPickerProps } from './controlled-rules-picker.model';

export const ControlledRulesPicker = <T extends FieldValues>(
  props: ControlledRulesPickerProps<T>
) => {
  return <ControlledRulesPickerController {...props} />;
};
