export enum ControlledDocumentObjectAttributeNameEnum {
  INITIATION_TYPE = 'initiation_type__a',
  INITIATION_DATE = 'initiation_date__a',
  IS_GXP = 'is_gxp__a',
  GXP_TYPES = 'gxp_types__a',
  TITLE = 'title__a',
  DEPARTMENT = 'department__s',
  DOCUMENT_TYPE = 'document_type__a',
  DOCUMENT_STATUS = 'document_status__a',
  DOCUMENT_SUB_TYPE = 'document_sub_type__a',
  IS_TRAINABLE = 'is_trainable__a',
  OWNER = 'owner__a',
  COLLABORATORS = 'collaborators__a',
  COLLABORATION_DUE_DATE = 'collaboration_due_date__a',
  REQUIRED_APPROVERS = 'required_approvers__a',
  QUALITY_APPROVERS = 'quality_approvers__a',
  DOCUMENT = 'file__a',
  REFERENCE_DOCUMENTS = 'reference_documents__a',
  ATTACHMENTS = 'attachments__a',
  REASON_FOR_CHANGES = 'reason_for_changes__a',
  SUMMARY_FOR_CHANGES = 'summary_for_changes__a',
  IS_BIZ_ADMIN_APPROVAL_REQUIRED = 'is_biz_admin_approval_required__a',
  APPROVED_DATE = 'approved_date__a',
  PROPOSED_EFFECTIVE_DATE = 'proposed_effective_date__a',
  EFFECTIVE_DATE = 'effective_date__a',
  FOLLOW_UP_REQUIRED = 'follow_up_required__a',
  FOLLOW_UP_FREQUENCY_IN_MONTHS = 'follow_up_frequency_in_months__a',
  FOLLOW_UP_DECISION_DATE = 'follow_up_decision_date__a',
  FOLLOW_UP_DUE_DATE = 'follow_up_due_date__a',
  FOLLOW_UP_TRIGGER_DATE = 'follow_up_trigger_date__a',
  FOLLOW_UP_TRIGGER_PERIOD_IN_DAYS = 'follow_up_trigger_period_in_days__a',
  FOLLOW_UP_ACTION = 'follow_up_action__a',
  FOLLOW_UP_COMMENT = 'follow_up_comment__a',
  IS_OBSOLETE_APPROVED = 'is_obsolete_approved__a',
  PROPOSED_OBSOLETION_DATE = 'proposed_obsoletion_date__a',
  OBSOLETE_JUSTIFICATION = 'obsolete_justification__a',
  CANCEL_JUSTIFICATION = 'cancel_justification__a',
  IS_UPLOADED_DOCUMENT_ELECTRONIC_COPY = 'is_uploaded_document_electronic_copy__a',
  OBSOLETION_DATE = 'obsoletion_date__a',
  SUPERSEDED_DATE = 'superseded_date__a',
  REQUESTOR = 'requestor__a',
  VERSION = 'version__s',
  IS_ACTIVE = 'is_active__s',
  IS_SUBMITTED = 'is_submitted__s',
  IS_APPROVED = 'is_approved__a',
  IS_ORIGINAL_DOCUMENT = 'is_original__a',
  IS_LOCKED = 'is_locked__a',
  APPROVAL_DUE_DATE = 'approval_due_date__a',
  CERTIFICATE_ID = 'certificate_id__a',
  FOLLOW_UP_APPROVAL_DUE_DATE = 'follow_up_approval_due_date__a',
  FOLLOW_UP_APPROVAL_DATE = 'follow_up_approval_date__a',
  IS_REVISION_OF_PRE_APPROVED_DOCUMENT = 'is_revision_of_pre_approved_document__a',
  SHOULD_REPLACE_HEADER_FOOTER = 'should_replace_header_footer__a',
  CHECK_IN_COMMENT = 'check_in_comment__a',
  LAST_CHECKED_IN_BY_USER = 'last_checked_in_by_user__a',
  LAST_CHECKED_OUT_BY_USER = 'last_checked_out_by_user__a',
  IS_RAW_FILE_DOWNLOAD_ALLOWED = 'is_raw_file_download_allowed__a',
}

export enum ControlledDocumentKeyEnum {
  INITIATION_TYPE = 'initiationType',
  INITIATION_DATE = 'initiationDate',
  IS_GXP = 'isGxp',
  GXP_TYPES = 'gxpTypes',
  TITLE = 'title',
  DEPARTMENT = 'department',
  DOCUMENT_TYPE = 'documentType',
  DOCUMENT_STATUS = 'documentStatus',
  DOCUMENT_SUB_TYPE = 'documentSubType',
  IS_TRAINABLE = 'isTrainable',
  OWNER = 'owner',
  COLLABORATORS = 'collaborators',
  COLLABORATION_DUE_DATE = 'collaborationDueDate',
  REQUIRED_APPROVERS = 'requiredApprovers',
  QUALITY_APPROVERS = 'qualityApprovers',
  DOCUMENT = 'document',
  REFERENCE_DOCUMENTS = 'referenceDocuments',
  ATTACHMENTS = 'attachments',
  REASON_FOR_CHANGES = 'reasonForChanges',
  SUMMARY_FOR_CHANGES = 'summaryForChanges',
  IS_BIZ_ADMIN_APPROVAL_REQUIRED = 'isBizAdminApprovalRequired',
  APPROVED_DATE = 'approvedDate',
  PROPOSED_EFFECTIVE_DATE = 'proposedEffectiveDate',
  OBSOLETE_JUSTIFICATION = 'obsoleteJustification',
  CANCEL_JUSTIFICATION = 'cancelJustification',
  IS_UPLOADED_DOCUMENT_ELECTRONIC_COPY = 'isUploadedDocumentElectronicCopy',
  EFFECTIVE_DATE = 'effectiveDate',
  FOLLOW_UP_REQUIRED = 'followUpRequired',
  FOLLOW_UP_FREQUENCY_IN_MONTHS = 'followUpFrequencyInMonths',
  FOLLOW_UP_DECISION_DATE = 'followUpDecisionDate',
  FOLLOW_UP_DUE_DATE = 'followUpDueDate',
  FOLLOW_UP_TRIGGER_DATE = 'followUpTriggerDate',
  FOLLOW_UP_TRIGGER_PERIOD_IN_DAYS = 'followUpTriggerPeriodInDays',
  FOLLOW_UP_ACTION = 'followUpAction',
  FOLLOW_UP_COMMENT = 'followUpComment',
  IS_OBSOLETE_APPROVED = 'isObsoleteApproved',
  PROPOSED_OBSOLETION_DATE = 'proposedObsoletionDate',
  OBSOLETION_DATE = 'obsoletionDate',
  SUPERSEDED_DATE = 'supersededDate',
  REQUESTOR = 'requestor',
  VERSION = 'version',
  IS_SUBMITTED = 'isSubmitted',
  IS_APPROVED = 'isApproved',
  IS_ORIGINAL_DOCUMENT = 'isOriginal',
  IS_LOCKED = 'isLocked',
  APPROVAL_DUE_DATE = 'approvalDueDate',
  CERTIFICATE_ID = 'certificateId',
  FOLLOW_UP_APPROVAL_DUE_DATE = 'followUpApprovalDueDate',
  FOLLOW_UP_APPROVAL_DATE = 'followUpApprovalDate',
  IS_REVISION_OF_PRE_APPROVED_DOCUMENT = 'isRevisionOfPreApprovedDocument',
  SHOULD_REPLACE_HEADER_FOOTER = 'shouldReplaceHeaderFooter',
  CHECK_IN_COMMENT = 'checkInComment',
  LAST_CHECKED_IN_BY_USER = 'lastCheckedInByUser',
  LAST_CHECKED_OUT_BY_USER = 'lastCheckedOutByUser',
  IS_RAW_FILE_DOWNLOAD_ALLOWED = 'isRawFileDownloadAllowed',
}

export enum ControlledDocumentColumnNameEnum {
  INITIATION_TYPE = 'initiationType',
  INITIATION_DATE = 'initiationDate',
  DOCUMENT_STATUS = 'documentStatus',
  IS_GXP = 'isGxp',
  GXP_TYPES = 'gxpTypes',
  TITLE = 'title',
  DEPARTMENT = 'department',
  DOCUMENT_TYPE = 'documentType',
  DOCUMENT_SUB_TYPE = 'documentSubType',
  IS_TRAINABLE = 'isTrainable',
  OWNER = 'owner',
  COLLABORATORS = 'collaborators',
  COLLABORATION_DUE_DATE = 'collaborationDueDate',
  REQUIRED_APPROVERS = 'requiredApprovers',
  QUALITY_APPROVERS = 'qualityApprovers',
  DOCUMENT = 'document',
  REFERENCE_DOCUMENTS = 'referenceDocuments',
  ATTACHMENTS = 'attachments',
  REASON_FOR_CHANGES = 'reasonForChanges',
  SUMMARY_FOR_CHANGES = 'summaryForChanges',
  IS_BIZ_ADMIN_APPROVAL_REQUIRED = 'isBizAdminApprovalRequired',
  APPROVED_DATE = 'approvedDate',
  PROPOSED_EFFECTIVE_DATE = 'proposedEffectiveDate',
  OBSOLETE_JUSTIFICATION = 'obsoletionJustification',
  CANCEL_JUSTIFICATION = 'cancelJustification',
  IS_UPLOADED_DOCUMENT_ELECTRONIC_COPY = 'isUploadedDocumentElectronicCopy',
  EFFECTIVE_DATE = 'effectiveDate',
  FOLLOW_UP_REQUIRED = 'followUpRequired',
  FOLLOW_UP_FREQUENCY_IN_MONTHS = 'followUpFrequencyInMonths',
  FOLLOW_UP_DECISION_DATE = 'followUpDecisionDate',
  FOLLOW_UP_DUE_DATE = 'followUpDueDate',
  FOLLOW_UP_TRIGGER_DATE = 'followUpTriggerDate',
  FOLLOW_UP_TRIGGER_PERIOD_IN_DAYS = 'followUpTriggerPeriodInDays',
  FOLLOW_UP_ACTION = 'followUpAction',
  FOLLOW_UP_COMMENT = 'followUpComment',
  IS_OBSOLETE_APPROVED = 'isObsoleteApproved',
  PROPOSED_OBSOLETION_DATE = 'proposedObsoletionDate',
  OBSOLETION_DATE = 'obsoletionDate',
  SUPERSEDED_DATE = 'supersededDate',
  REQUESTOR = 'requestor',
  VERSION = 'version',
  IS_SUBMITTED = 'isSubmitted',
  IS_APPROVED = 'isApproved',
  IS_ORIGINAL_DOCUMENT = 'isOriginal',
  IS_LOCKED = 'isLocked',
  APPROVAL_DUE_DATE = 'approvalDueDate',
  FOLLOW_UP_APPROVAL_DUE_DATE = 'followUpApprovalDueDate',
  FOLLOW_UP_APPROVAL_DATE = 'followUpApprovalDate',
  CERTIFICATE_ID = 'certificateId',
  IS_REVISION_OF_PRE_APPROVED_DOCUMENT = 'isRevisionOfPreApprovedDocument',
  SHOULD_REPLACE_HEADER_FOOTER = 'shouldReplaceHeaderFooter',
  CHECK_IN_COMMENT = 'checkInComment',
  LAST_CHECKED_IN_BY_USER = 'lastCheckedInByUser',
  LAST_CHECKED_OUT_BY_USER = 'lastCheckedOutByUser',
  IS_RAW_FILE_DOWNLOAD_ALLOWED = 'isRawFileDownloadAllowed',
}
