import { AttributeTypeEnum } from '@celito.clients/enums';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';

import { UserAssignmentCell } from '../components/user-assignment-cell/user-assignment-cell';
import { IColumns } from '../training-matrix.model';

export const getCourseViewColumns = (
  onColumnClick: IColumns['onColumnClick'],
  onUserView: (name: string) => void
): IColumns[] => {
  const columnConfig: IColumns[] = [
    {
      fieldName: 'label',
      isResizable: true,
      key: 'id',
      maxWidth: 250,
      minWidth: 150,
      name: 'Course ID',
      isFilterable: true,
      dataType: AttributeTypeEnum.PlainText,
      showTooltip: true,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      data: {
        name: 'id',
      },
    },
    {
      name: 'Course Name',
      isResizable: true,
      key: 'name',
      maxWidth: 350,
      minWidth: 150,
      fieldName: 'title',
      isFilterable: true,
      dataType: AttributeTypeEnum.PlainText,
      showTooltip: true,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      data: {
        name: 'title',
      },
    },
    {
      name: 'Course Version',
      isResizable: true,
      key: 'version',
      maxWidth: 100,
      minWidth: 80,
      fieldName: 'version',
      isFilterable: true,
      showTooltip: true,
      showSortIconWhenUnsorted: true,
      dataType: AttributeTypeEnum.PlainText,
      onColumnClick,
      data: {
        name: 'version',
      },
    },
    {
      name: 'Associated Curricula',
      isResizable: true,
      key: 'curriculum',
      maxWidth: 350,
      minWidth: 150,
      fieldName: 'curriculum',
      isFilterable: false,
      showTooltip: true,
    },
    {
      name: 'User Assignment',
      isResizable: true,
      key: 'userAssignment',
      maxWidth: 350,
      minWidth: 150,
      fieldName: 'userAssignment',
      onRender: (item, index) => {
        if ((item as RowItem).isSubRow) {
          return UserAssignmentCell({ item, index, onUserView });
        }
        return null;
      },
      isFilterable: false,
      showTooltip: true,
    },
  ];

  return columnConfig;
};
