import { performRequest } from '@celito.clients/network';

import Endpoints from '../config/endpoint';
import { ApiMethodType } from '../enums/api-constants';
import { DocumentDownloadActionEnum } from '../enums/download-document-action.enum';
import {
  DocCertificateData,
  DocData,
  EditDocumentData,
} from '../types/document-type';

const CONTROLLED_DOCS_OBJECT_NAME = 'controlled_document__a';

export const getDocumentData = async (
  docId: string,
  objectName: string,
  recordName?: string,
  version?: string,
  downloaAction = DocumentDownloadActionEnum.Download,
  recordVersion?: string
) => {
  const response = await performRequest<DocData>(
    `${Endpoints.DOCUMENT_DATA_URL}/${docId}`,
    ApiMethodType.GET,
    null,
    {
      objectName,
      action: downloaAction,
      recordName: recordName,
      versionNumber: version,
      recordVersion: recordVersion,
    }
  );
  return response.data;
};

export const deleteDocuments = async (
  documentIds: string[],
  objectName: string,
  attributeName: string,
  recordName: string
) => {
  const response = await performRequest<DocData>(
    Endpoints.DOCUMENT_DATA_URL,
    ApiMethodType.DELETE,
    {
      documentIds,
      objectName,
      attributeName,
      recordName,
    }
  );
  return response.data;
};

export const deleteDocument = async (
  documentId: string,
  objectName: string,
  attributeName: string,
  recordName: string,
  version?: string
) => {
  const response = await performRequest<DocData>(
    `${Endpoints.DOCUMENT_DATA_URL}/${documentId}`,
    ApiMethodType.DELETE,
    {
      objectName,
      attributeName,
      recordName,
      documentVersion: version,
    }
  );
  return response.data;
};

export const getEditDocumentUrl = async (
  id: string,
  accessToken: string,
  version: string,
  taskName: string | undefined,
  viewType: string
) => {
  const response = await performRequest<EditDocumentData>(
    `${Endpoints.WOPI_URL}/${Endpoints.GENERATE_URL}/${id}`,
    ApiMethodType.GET,
    null,
    {
      access_token: accessToken,
      version,
      ...(taskName ? { taskName } : {}),
      viewType,
    }
  );
  return response.data;
};

export const getDocumentCertificateMetadata = async (recordName: string) => {
  const response = await performRequest<DocCertificateData>(
    `${Endpoints.DOCUMENT_CERTIFICATE_METADATA_DATA_URL}/${recordName}`,
    ApiMethodType.GET,
    null,
    {
      objectName: CONTROLLED_DOCS_OBJECT_NAME,
    }
  );

  return response.data;
};
