import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledNumberRangeProps } from './controlled-numberrange-picker.model';
import { ControlledNumberRangePickerView } from './controlled-numberrange-picker.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const ControlledNumberRangePickerController = <T extends FieldValues>(
  props: ControlledNumberRangeProps<T>
) => {
  return <ControlledNumberRangePickerView {...props} />;
};
