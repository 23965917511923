import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { CourseData, TrainingAssignmentData } from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';

import { courseObjectName } from '../config';

export const getCourseDetails = async (
  assignmentData: TrainingAssignmentData
) => {
  const response = await performRequest<CourseData>(
    `${Endpoints.OBJECT_DATA_URL}/${courseObjectName}/${assignmentData.course.name}`,
    ApiMethodType.GET,
    undefined,
    {
      version: assignmentData.course.version,
    }
  );

  return response.data;
};
