import { getConvertedDate } from '@celito.clients/utils';
import { TimeOfDayPrefenceEnum } from 'libs/core/src/enums/date-formats';

import { DatePickerProps } from './date-picker.model';
import DatePickerView from './date-picker.view';

const DatePickerController = (props: DatePickerProps) => {
  const onSelectDateHandler = (date: Date | null | undefined): void => {
    if (date) {
      date = getConvertedDate(
        date,
        props?.timeOfDayPreference ?? TimeOfDayPrefenceEnum.START
      );
    } else {
      return;
    }

    props.onDateChange(date);
  };

  return (
    <DatePickerView onSelectDateHandler={onSelectDateHandler} {...props} />
  );
};

export default DatePickerController;
