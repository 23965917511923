import withLayout from 'apps/web-client/src/hoc/with-layout';

import QuickSearchController from './quick-search.controller';
import { QuickSearchProps } from './quick-search.model';

const QuickSearch: React.FC<QuickSearchProps> = (props) => {
  return <QuickSearchController {...props} />;
};

export default withLayout(QuickSearch);
