import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ROUTES } from '@celito.clients/enums';
import { ConfirmDialog } from '@celito.clients/shared';
import {
  PermissionGroupType,
  PermissionResponseType,
  PermissionRoleType,
  RolesType,
  RoleType,
  UserTypes,
} from '@celito.clients/types';
import { raiseErrorToast, successToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import {
  useCallbackPrompt,
  usePermission,
  useUser,
} from 'libs/core/src/hooks/';
import { InHouseInputSelectProps } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';

import { customRoleType, FormValues, Item } from './edit-group.model';
import EditGroupView from './edit-group.view';

const schema = yup.object().shape({
  label: yup.string().required(LocalStrings.requiredLabel),
  roleNames: yup
    .array()
    .min(1, LocalStrings.requiredLabel)
    .required(LocalStrings.requiredLabel),
  isActive: yup.string().required(LocalStrings.requiredLabel),
}) as yup.ObjectSchema<FormValues>;

const EditGroupController: React.FC = () => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [selectedList, setSelectedList] = React.useState<(string | number)[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPermissionGrpUpdated, setIsPermissionGrpUpdated] =
    useState<boolean>(false);
  const [permissionGrpData, setPermissionGrpData] =
    useState<PermissionGroupType>();
  const [list, setList] = React.useState<[] | Item[]>([]);
  const [rightItemList, setRightItemList] = useState<[] | Item[]>([]);
  const [roles, setRoles] = useState<customRoleType[]>([]);
  const navigate = useNavigate();
  const { name = '' } = useParams();
  const { fetchAllUsers } = useUser();
  const { fetchAllRoles, updatePermissionGroup, fetchPermissionGroupInfo } =
    usePermission();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<FormValues>({
    defaultValues: {
      label: '',
      name: name,
      description: '',
      roleNames: selectedKeys,
      isActive: 'yes',
      userNames: [],
    },
    resolver: yupResolver(schema),
    mode: 'all',
    shouldUnregister: false,
    reValidateMode: 'onChange',
  });

  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(isDirty);

  const navigateBackRoute = `../${ROUTES.LIST}/permission_group_view__s`;
  const navigateToList = () => {
    navigate(navigateBackRoute);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const editPermissionGroup = (payloads: FormValues, name: string) => {
    const updatedPayloads = {
      ...payloads,
      isActive: payloads.isActive === 'yes',
    };
    setIsPermissionGrpUpdated(true);
    updatePermissionGroup(updatedPayloads, name)
      .then(() => {
        successToast({
          message: 'Permission Group edited successfully',
        });
        confirmNavigation();
        navigateToList();
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsPermissionGrpUpdated(false);
      });
  };

  const onSubmit = async () => {
    await handleSubmit((data) => {
      const payloads = {
        label: data.label,
        name: name,
        description: data?.description,
        roleNames: selectedKeys,
        isActive: data.isActive,
        userNames: selectedList,
      };
      editPermissionGroup(payloads, name);
    })();
  };

  const onOptionSelect: InHouseInputSelectProps['onOptionSelect'] = (
    ev,
    { selectedOptions }
  ) => {
    let selectedValues: string[] = [];

    if (!Array.isArray(selectedOptions))
      selectedValues = [selectedOptions?.value];

    setValue('roleNames', selectedValues);

    setSelectedKeys(selectedValues);
  };

  const getAllRoles = () => {
    fetchAllRoles()
      .then((resp: RolesType) => {
        const roles = resp.data;
        setRoles(
          roles.map((role: RoleType) => {
            return { value: role.name, text: role.label };
          })
        );
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  };

  const getPermissionGroupData = useCallback(() => {
    setIsLoading(true);
    fetchPermissionGroupInfo(name)
      .then((resp: PermissionResponseType) => {
        const data = resp.permissionGroup;
        setPermissionGrpData(data);
        setRightItemList(
          data.users.map((user: UserTypes) => {
            return { id: user.name, name: user.label };
          })
        );
        const filteredUserList = data.users.map((user: UserTypes) => user.name);
        const roleList = data.roles.map(
          (role: PermissionRoleType) => role.name
        );
        setSelectedList(filteredUserList);
        setSelectedKeys(roleList);
        getAllUsers(filteredUserList);
        reset({
          label: data.label,
          name: data.name,
          description: data.description,
          isActive: data.isActive ? 'yes' : 'no',
          roleNames: roleList,
        });
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [name, reset]);

  const getAllUsers = (selectedUsers: string[]) => {
    setIsLoading(true);
    fetchAllUsers()
      .then((users: UserTypes[] | []) => {
        const userList = users
          .filter((user: UserTypes) => !selectedUsers.includes(user.name))
          .map((user) => ({
            id: user.name,
            name: user.label,
          }));
        setList(
          userList.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          }) as Item[]
        );
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllRoles();
    getPermissionGroupData();
  }, [getPermissionGroupData]);

  return (
    <>
      <EditGroupView
        {...{
          onSubmit,
          control,
          handleSubmit,
          permissionGrpData,
          isPermissionGrpUpdated,
          rightItemList,
          onOptionSelect,
          selectedKeys,
          options: roles,
          isLoading,
          navigateBack,
          list,
          selectedList,
          setList,
          setRightItemList,
          setSelectedList,
          navigateBackRoute,
        }}
      />

      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
    </>
  );
};

export default EditGroupController;
