import { IChangeData } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import { useState } from 'react';

import { ControlledDocumentPickerModalProps } from './controlled-document-picker-modal.model';
import { ControlledDocumentPickerModalView } from './controlled-document-picker-modal.view';

export const ControlledDocumentPickerModalController = (
  props: ControlledDocumentPickerModalProps
) => {
  const [selectedValue, setSelectedValue] = useState<IChangeData>();
  const [version, setVersion] = useState<string>();

  const onPickerValChange = (val: IChangeData) => {
    setSelectedValue(val);
  };

  const onClose = () => {
    setSelectedValue(undefined);
    props.onCloseControlledDocumentPickerModal();
  };

  return (
    <ControlledDocumentPickerModalView
      {...props}
      {...{
        selectedValue,
        onPickerValChange,
        version,
        setVersion,
        onClose,
        setSelectedValue,
      }}
    />
  );
};
