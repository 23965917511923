import { Curriculum } from '@celito.clients/types';
import {
  getCurriculumPageUrl,
  getRedirectionBaseUrl,
} from '@celito.clients/utils';
import { Link } from '@fluentui/react-components';
import { Fragment } from 'react';

import classes from '../../training-matrix.module.css';

interface CurriculaCellProps {
  items: Curriculum[];
  customStartValue: string | undefined;
}

export const CurriculaCell = ({
  items,
  customStartValue,
}: CurriculaCellProps) => {
  const getUrl = (item: Curriculum) => {
    const baseUrl = getRedirectionBaseUrl();
    const curriculumViewUrl = getCurriculumPageUrl(item.name, item.version);
    return `${baseUrl}${curriculumViewUrl}`;
  };

  return (
    <div className={classes.cellWrapping}>
      {customStartValue}
      {customStartValue && items.length > 0 && ', '}
      {items.map((item, index) => {
        return (
          <Fragment key={item.name}>
            <Link href={getUrl(item)} target="_blank">
              {item.label}
            </Link>
            {index !== items.length - 1 && ', '}
          </Fragment>
        );
      })}
      {items.length === 0 && !customStartValue && <div>{'-'}</div>}
    </div>
  );
};
