import {
  CheckBox,
  Field,
  Icon,
  IconButton,
  TextField,
} from '@celito.clients/shared';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  IChoice,
  IQuestion,
  QuestionTypes,
} from '../../quiz-questions-page.model';
import { ChoiceProps } from './choices.model';
import { ChoicesStyles } from './choices.styles';

interface ChoicesViewProps extends ChoiceProps {
  onAddChoiceToQuestion: (question: IQuestion, questionIndex: number) => void;
  onRemoveChoiceFromAQuestion: (
    question: IQuestion,
    questionIndex: number,
    choiceOrder: number
  ) => void;
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    choice: IChoice
  ) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (
    event: React.DragEvent<HTMLDivElement>,
    choiceIndex: number
  ) => void;
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  choiceTextRef: React.MutableRefObject<HTMLInputElement | null>;
}

export const ChoicesView = ({
  questionType,
  methods,
  questionIndex,
  question,
  onAddChoiceToQuestion,
  onRemoveChoiceFromAQuestion,
  isViewMode,
  handleDragOver,
  handleDragStart,
  handleDrop,
  handleKeyPress,
  choiceTextRef,
}: ChoicesViewProps) => {
  const { setValue, control, trigger } = methods;

  const [receivedQuestion, setReceivedQuestion] = useState(question);

  useEffect(() => {
    setReceivedQuestion(question);
  }, [question]);

  const styles = ChoicesStyles();

  switch (questionType) {
    case QuestionTypes.MULTIPLE_CHOICE:
    case QuestionTypes.SEQUENCE:
    case QuestionTypes.MULTIPLE_RESPONSE: {
      if (question.choices.length === 0) {
        const choice: IChoice[] = [
          {
            choiceText: '',
            order: 1,
            isCorrect: questionType === QuestionTypes.SEQUENCE,
          },
        ];

        setValue(`questions.${questionIndex}.choices`, choice);
      }

      return (
        <div className={styles.root}>
          {receivedQuestion.choices.map((choice, choiceIndex) => (
            <div
              className={styles.choicesContainer}
              key={`choice-${choiceIndex}`}
            >
              <div className={styles.choicesInnerContainer}>
                <tr
                  className={styles.draggableContainer}
                  draggable={
                    !isViewMode && questionType === QuestionTypes.SEQUENCE
                  }
                  onDragStart={(_ev) => handleDragStart(_ev, choice)}
                  onDragOver={handleDragOver}
                  onDrop={(_) => handleDrop(_, choiceIndex)}
                >
                  {questionType === QuestionTypes.SEQUENCE ? (
                    <span className={styles.draggableIconContainer}>
                      <Icon
                        iconName="ReOrderDotsVertical20Regular"
                        className={styles.draggableIcon}
                      />
                    </span>
                  ) : (
                    <Controller
                      name={`questions.${questionIndex}.choices.${choiceIndex}.isCorrect`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CheckBox
                          onChanged={(_, data) => {
                            field.onChange(data?.checked);
                            trigger();
                          }}
                          checked={choice.isCorrect}
                          disabled={
                            isViewMode ||
                            (questionType === QuestionTypes.MULTIPLE_CHOICE &&
                              question.choices.some(
                                ({ isCorrect }) =>
                                  isCorrect && !choice.isCorrect
                              ))
                          }
                        />
                      )}
                    />
                  )}

                  <Controller
                    name={`questions.${questionIndex}.choices.${choiceIndex}.choiceText`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Field
                        validationState={error?.message ? 'error' : 'none'}
                        validationMessage={error?.message}
                      >
                        <TextField
                          dataTestId="input-field-choice"
                          onKeyDown={handleKeyPress}
                          disabled={isViewMode}
                          onChange={(_ev) => field.onChange(_ev.target.value)}
                          value={choice.choiceText}
                          placeholder={'Choice'}
                          inputRef={
                            question.choices.length === choiceIndex + 1
                              ? choiceTextRef
                              : undefined
                          }
                        />
                      </Field>
                    )}
                  />
                </tr>

                {!isViewMode && question.choices.length > 1 && (
                  <div className={styles.choicesAction}>
                    <IconButton
                      icon={
                        <Icon
                          iconName="Delete24Regular"
                          className={styles.deleteIcon}
                        />
                      }
                      onClick={() =>
                        onRemoveChoiceFromAQuestion(
                          question,
                          questionIndex,
                          choice.order ?? choiceIndex + 1
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          ))}

          {!isViewMode && (
            <div className={styles.addChoiceContainer}>
              <div
                role="button"
                onClick={() => onAddChoiceToQuestion(question, questionIndex)}
                className={styles.btnAddChoice}
                data-testid={`button-add-choice`}
              >
                Add Choice
              </div>

              <span className={styles.customErrorLabel}>
                {
                  methods.formState.errors?.questions?.[questionIndex]?.choices
                    ?.message
                }
              </span>
            </div>
          )}
        </div>
      );
    }

    case QuestionTypes.BOOLEAN: {
      if (question.choices.length === 0) {
        const choice: IChoice[] = [
          { choiceText: 'True', order: 1, isCorrect: false },
          { choiceText: 'False', order: 2, isCorrect: false },
        ];

        setValue(`questions.${questionIndex}.choices`, choice);
      }

      return (
        <div className={styles.root}>
          {question.choices.map((choice, choiceIndex) => (
            <div
              className={styles.choicesContainer}
              key={`choice-${choiceIndex}`}
            >
              <div className={styles.choicesInnerContainer}>
                <div className={styles.booleanContainer}>
                  <Controller
                    name={`questions.${questionIndex}.choices.${choiceIndex}.isCorrect`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CheckBox
                        onChanged={(_, data) => {
                          field.onChange(data?.checked);
                          trigger(`questions.${questionIndex}.choices`);
                        }}
                        checked={choice.isCorrect}
                        disabled={
                          isViewMode ||
                          question.choices.some(
                            ({ isCorrect }) => isCorrect && !choice.isCorrect
                          )
                        }
                      />
                    )}
                  />

                  <Controller
                    name={`questions.${questionIndex}.choices.${choiceIndex}.choiceText`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        size="large"
                        label={''}
                        value={choice.choiceText}
                        disabled
                        onChange={(_ev, data) => field.onChange(data.value)}
                        placeholder={'Choice'}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          ))}

          <span className={styles.customErrorLabel}>
            {
              methods.formState.errors?.questions?.[questionIndex]?.choices
                ?.message
            }
          </span>
        </div>
      );
    }

    default:
      return null;
  }
};
