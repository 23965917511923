import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import {
  CustomButton,
  FormActionButtonsContainer,
  GridView,
  PreviewPanel,
  Stack,
} from '@celito.clients/shared';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { getFieldSection } from '../../components/helper-components';
import { ConfirmButton } from '../../components/wizard-container/confirm-button/confirm-button.component';
import { generalPageStepNumber, questionsPageStepNumber } from './config';
import { QuizPreviewPageProps } from './quiz-preview-page.model';
import classes from './quiz-preview-page.styles.module.css';
import {
  getQuizQuestionsGridViewColumnConfig,
  getQuizQuestionsGridViewItems,
} from './utils';

interface QuizPreviewPageViewProps extends QuizPreviewPageProps {
  changePage: (page: number) => void;
  onPreviousClicked: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}

export const QuizPreviewPageView = (
  props: QuizPreviewPageViewProps
): JSX.Element => {
  const {
    dataTestId,
    changePage,
    onPreviousClicked,
    step,
    onCancel,
    onSave,
    isWizardStateVisible,
    viewDto,
  } = props;

  const {
    formState: { isDirty },
  } = useFormContext();

  const isSubmitDisabled =
    props.isLoading ||
    props.isSubmitting ||
    props.wizardStepsState.some(
      (wstep) => wstep.number !== step && !wstep.isValid
    );

  const showSubmitConfirmationPopup = Boolean(
    viewDto?.view?.[0]?.showSubmitConfirmationPopup
  );

  const submitConfirmationPopupMessage =
    viewDto?.view?.[0]?.submitConfirmationPopupMessage;

  return (
    <div className={classes.container}>
      <div
        data-testid="quiz-preview-page_container"
        className={cn(classes.previewFormContainer, {
          [classes.rowContainerWithWizardState]: isWizardStateVisible,
        })}
      >
        {/* General Section */}
        <PreviewPanel
          title={LocalizationString.GENERAL}
          onEditPress={() => changePage(generalPageStepNumber)}
        >
          <Stack
            data-testid={dataTestId}
            className={classes.generalSection}
            {...props}
          >
            {props.sections?.[generalPageStepNumber]?.fields.map(
              (field, index) =>
                getFieldSection(
                  field.columnName,
                  field.width,
                  props.recordDetails!,
                  props.attributeConfig,
                  props.objectName!,
                  index
                )
            )}
          </Stack>
        </PreviewPanel>
        {/* Questions Section */}
        <PreviewPanel
          title={LocalizationString.QUESTIONS}
          onEditPress={() => changePage(questionsPageStepNumber)}
        >
          <div className={classes.questionsSection}>
            <GridView
              columns={getQuizQuestionsGridViewColumnConfig(props)}
              items={getQuizQuestionsGridViewItems(props.recordDetails)}
              disableSelection
            />
          </div>
        </PreviewPanel>
      </div>

      <FormActionButtonsContainer>
        <CustomButton
          buttonTitle={LocalizationString.CANCEL}
          buttonType={ButtonTypes.Ghost}
          onClick={onCancel}
          disabled={props.isLoading || props.isSubmitting}
        />

        <div className={classes.formActionBtns}>
          {step > 0 && (
            <CustomButton
              buttonTitle={LocalizationString.PREVIOUS}
              leftIcon="Previous"
              buttonType={ButtonTypes.Ghost}
              onClick={onPreviousClicked}
              disabled={props.isLoading || props.isSubmitting}
            />
          )}

          <CustomButton
            buttonTitle={LocalizationString.SAVE}
            buttonType={ButtonTypes.Ghost}
            leftIcon="Save"
            onClick={() => onSave?.(false)}
            isDisabled={props.isLoading || props.isSubmitting || !isDirty}
          />
          <ConfirmButton
            buttonTitle={LocalizationString.SUBMIT}
            isLoading={props.isSubmitting}
            buttonType={ButtonTypes.Primary}
            leftIcon="Submit"
            showSubmitConfirmationPopup={showSubmitConfirmationPopup}
            dialogProps={{
              primaryButtonText: LocalizationString.SUBMIT,
              title: LocalizationString.SUBMIT,
              description: submitConfirmationPopupMessage,
              secondaryButtonText: LocalizationString.CANCEL,
              iconSrc: WarningIcon,
            }}
            onSubmit={onSave ? () => onSave?.(true) : undefined}
            isDisabled={isSubmitDisabled}
          />
        </div>
      </FormActionButtonsContainer>
    </div>
  );
};
