import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

interface IStage {
  name: string;
  label: string;
}

interface LifecycleData {
  name: string;
  label: string;
  stages: IStage[];
}

export const getLifecycleData = async (objectName: string) => {
  const response = await performRequest<LifecycleData>(
    `${Endpoints.LIFECYCLE}/${objectName}`,
    ApiMethodType.GET
  );
  return response.data;
};
