import { useLayout } from '@celito.clients/hooks';

export const withLayoutMountNode = <T,>(
  Component: React.FC<T>
): React.FC<T> => {
  return (props) => {
    const { layoutRef } = useLayout();
    return (
      <Component mountNode={layoutRef?.current?.previousSibling} {...props} />
    );
  };
};
