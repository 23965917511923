import { HTTPStatusCode, ROUTES } from '@celito.clients/enums';
import {
  useConfigureLayout,
  useGetUserAdminSettings,
} from '@celito.clients/hooks';
import {
  errorToast,
  raiseErrorToast,
  successToast,
} from '@celito.clients/utils';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import { useUser } from 'libs/core/src/hooks/useUser';
import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { AdUserTypes, Error } from './add-user.model';
import AddNewView from './add-user.view';

const AddNewController: React.FC = () => {
  const [users, setUsers] = useState<AdUserTypes[] | []>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>({ msg: '' });
  const [selectedId, setSelectedId] = useState<IOption | undefined>();
  const [options, setOptions] = useState<IOption[]>([]);
  const { fetchAllAdUsersNotInvited } = useUser();

  const [isPreAddUserScreenOpen, setIsPreAddUserScreenOpen] = useState(false);

  const { data: userAdminSettings, isLoading: isAdminSettingLoading } =
    useGetUserAdminSettings();

  const [isUserAddLoading, setIsUserAddLoading] = useState<boolean>(false);

  const { addUser } = useUser();

  const navigate = useNavigate();

  useConfigureLayout({
    pageTitle: '',
    headerTitle: LocalStrings.newUser,
  });

  useConfigureLayout({
    pageTitle: '',
    headerTitle: LocalStrings.newUser,
  });

  const togglePreAddUserScreen = () => {
    setIsPreAddUserScreenOpen((prev) => !prev);
  };

  const onAddBtnHandler = () => {
    if (selectedId?.value) {
      if (
        !userAdminSettings?.departmentIsSynced &&
        !userAdminSettings?.managerIsSynced
      ) {
        setIsUserAddLoading(true);

        addUser(selectedId.value)
          .then((resp) => {
            successToast({
              message: 'User added successfully',
            });
            const name = resp.name;
            if (name) {
              navigate(`../${ROUTES.USER.replace(':name', name)}`);
            }
          })
          .catch((_error) => {
            if (_error.response.status === HTTPStatusCode.BAD_REQUEST) {
              errorToast({
                message: 'User email not found, please contact your Admin',
              });
            } else {
              raiseErrorToast(_error);
            }
          })
          .finally(() => {
            setIsUserAddLoading(false);
          });
      } else togglePreAddUserScreen();
    } else {
      setError({ msg: LocalStrings.userRequired });
    }
  };

  const onChange = (value: IOption) => {
    if (!value) setError({ msg: LocalStrings.userRequired });
    else {
      setError({ msg: '' });
      setSelectedId(value);
    }
  };

  const getAllUsers = () => {
    setIsLoading(true);
    fetchAllAdUsersNotInvited()
      .then((users: AdUserTypes[] | []) => {
        setUsers(users);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (users) {
      setOptions(
        users.map((user) => {
          return {
            value: user.id,
            text: user.displayName,
            secondaryText: user.mail ?? '',
          };
        })
      );
    }
  }, [users]);

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <AddNewView
      {...{
        isLoading,
        error,
        options,
        onChange,
        onAddBtnHandler,
        selectedId,
        isPreAddUserScreenOpen,
        togglePreAddUserScreen,
        departmentIsSynced: userAdminSettings?.departmentIsSynced,
        managerIsSynced: userAdminSettings?.managerIsSynced,
        isAdminSettingLoading,
        isUserAddLoading,
      }}
    />
  );
};

export default AddNewController;
