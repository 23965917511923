export const BREAKPOINTS = {
  DESKTOP: {
    CHROME: {
      MIN_WIDTH: 1024,
      MIN_HEIGHT: 768,
    },
    FIREFOX: {
      MIN_WIDTH: 1280,
      MIN_HEIGHT: 800,
    },
    SAFARI: {
      MIN_WIDTH: 1440,
      MIN_HEIGHT: 900,
    },
  },
  TABLET: {
    SAFARI: {
      MIN_WIDTH: 768,
      MIN_HEIGHT: 1024,
    },
  },
  MOBILE: {
    SAFARI: {
      MIN_WIDTH: 414,
      MIN_HEIGHT: 896,
    },
  },
};
