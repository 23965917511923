import { LocalizationString } from '@celito.clients/assets';
import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { IFilterPanelFormData } from '@celito.clients/list-view-engine';
import { UseFormReturn } from 'react-hook-form';

import { UserViewPanelWrapper } from '../../reference-selector/src/components/user-view-panel.component';
import { IOption, IRule } from '../../rules-component/rules-component.model';
import { RulesComponentListViewFilter } from '../../rules-component/types/rules-component.types';
import { Field, GridView, Loader, RulesComponent } from '../../shared';
import { FiltersFormData, ObjectData, ObjectMetadata, User } from './types';
import { UserRulesPickerProps } from './user-rules-picker.model';
import classes from './user-rules-picker.styles.module.css';
import {
  getUsersListViewColumnConfig,
  getUsersListViewRowData,
} from './utils/users-list-view-utils';

interface UserRulesPickerViewProps
  extends Omit<UserRulesPickerProps, 'onChange' | 'value' | 'objectName'> {
  objectData?: ObjectData<User>;
  initialValue?: FiltersFormData[];
  loading?: boolean;
  filters: RulesComponentListViewFilter[];
  objectMetadata: ObjectMetadata | null;
  methods: UseFormReturn<IFilterPanelFormData>;
  conditionFieldsData: IOption[];
  onAddRule: () => void;
  onRemoveRule: (index: number) => void;
  rules: IRule[];
  onApplyFilter: (data: IFilterPanelFormData) => void;
  userName?: string;
  isUserViewPanelOpen: boolean;
  onDismiss: () => void;
  onUserView: (name: string) => void;
}

export const UserRulesPickerView = ({
  dataTestId,
  objectData,
  loading,
  filters,
  mode,
  objectMetadata,
  methods,
  conditionFieldsData,
  onAddRule,
  onRemoveRule,
  rules,
  onApplyFilter,
  isRequired,
  userName,
  isUserViewPanelOpen,
  onDismiss,
  onUserView,
}: UserRulesPickerViewProps): JSX.Element => {
  const { handleSubmit } = methods;

  return (
    <div className={classes.container}>
      {mode !== FormEngineModeTypeEnum.View && (
        <Field
          label={LocalizationString.SET_RULE}
          required={isRequired}
          size="large"
        ></Field>
      )}
      <div className={classes.contentContainer}>
        <div className={classes.filtersContainer}>
          {objectMetadata && (
            <RulesComponent
              methods={methods}
              fields={conditionFieldsData}
              onAddRule={onAddRule}
              onRemoveRule={onRemoveRule}
              ruleLayout={'singleRule'}
              rules={rules}
              submitButtonTitle={LocalizationString.PREVIEW_RULE_RESULTS}
              submitButtonHandler={handleSubmit(onApplyFilter)}
              hideActionButtons={mode === FormEngineModeTypeEnum.View}
              disableFields={mode === FormEngineModeTypeEnum.View}
              isCompact
            />
          )}
        </div>

        {loading && (
          <>
            <hr />
            <Loader />
          </>
        )}
        {objectData && !loading && (
          <>
            {filters.length > 0 && <hr />}
            <div>
              <h3>{`${LocalizationString.RULE_RESULTS_GROUPS} ${
                objectData?.total
                  ? `(${objectData.total} ${LocalizationString.USERS})`
                  : ''
              }`}</h3>
              <GridView
                columns={getUsersListViewColumnConfig(onUserView)}
                items={objectData ? getUsersListViewRowData(objectData) : []}
                disableSelection
              />
            </div>
          </>
        )}

        {isUserViewPanelOpen && userName && (
          <UserViewPanelWrapper
            isOpen={isUserViewPanelOpen}
            label={LocalizationString.INDIVIDUAL_USERS}
            name={userName}
            onDismiss={onDismiss}
          />
        )}
      </div>
    </div>
  );
};
