import { LocalizationString } from '@celito.clients/assets';
import {
  AssignmentStatusEnum,
  CompletionTypeEnum,
  ROUTES,
  TaskStatusEnum,
  TrainingAssignmentTypeEnum,
} from '@celito.clients/enums';
import { useQueryParams } from '@celito.clients/hooks';
import { ModalContext, UserContext } from '@celito.clients/provider';
import {
  downloadTrainingCertificate,
  fetchObjectTaskInfo,
} from '@celito.clients/services';
import { APIError, CourseData, Task } from '@celito.clients/types';
import {
  errorToast,
  getULHomePageUrl,
  raiseErrorToast,
} from '@celito.clients/utils';
import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { CompleteTrainingButtonProps } from './complete-training-button.model';
import { CompleteTrainingButtonView } from './complete-training-button.view';
import { trainingAssignmentObjectName } from './config';
import { getCourseDetails } from './services/get-course-data';
import { getTrainingAssignmentDetails } from './services/get-training-assignment-data';

interface CompleteTrainingButtonControllerProps
  extends CompleteTrainingButtonProps {}

export const CompleteTrainingButtonController = (
  props: CompleteTrainingButtonControllerProps
): JSX.Element | null => {
  const {
    trainingAssignmentData,
    trainingAssignmentType,
    trainingPlanName,
    openCompleteFlow,
    completedInCurrentSession = false,
  } = props;
  const { getSearchParams, clearUrlParams } = useQueryParams();
  const params = useParams();
  const queryParams = getSearchParams();
  const navigate = useNavigate();
  const { openModal } = useContext(ModalContext);
  const { user, isBizAdmin } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isValidatingIfActive, setIsValidatingIfActive] = useState(false);
  const [taskData, setTaskData] = useState<Task | null>(null);
  const [courseData, setCourseData] = useState<CourseData | null>(null);
  const [showSignOffPanel, setShowSignOffPanel] = useState({
    isOpen: false,
    isAdmin: false,
  });
  const [showExportModal, setShowExportModal] = useState(false);
  const [
    isLoadingDocumentCompletionStatus,
    setIsLoadingDocumentCompletionStatus,
  ] = useState(true);

  const [isAllDocumentsCompleted, setIsAllDocumentsCompleted] = useState(false);

  const trainingAssignmentName = trainingAssignmentData.name;
  const assignmentVersion = trainingAssignmentData.version;
  const completionType = trainingAssignmentData.course.completionType;

  const isUserTask =
    user?.name === trainingAssignmentData.user.name &&
    taskData?.tag === 'ta_take_training';
  const delegatedUserNames = JSON.parse(taskData?.delegatedUserNames ?? `[]`);
  const isTrainerTask =
    user?.name === courseData?.trainer?.name ||
    (taskData?.tag === 'ta_trainer_sign_off' &&
      delegatedUserNames.includes(user?.name));
  const isBizAdminTrainerTask =
    isBizAdmin && queryParams['taskName'] && isTrainerTask;

  const openSignOffPanel = (isAdmin?: boolean) => {
    setShowSignOffPanel((prev) => ({
      ...prev,
      isOpen: true,
      isAdmin: isAdmin ?? prev.isAdmin,
    }));
  };

  const closeSignOffPanel = () => {
    setShowSignOffPanel((prev) => ({ ...prev, isOpen: false }));
    const searchParams = getSearchParams();

    if (searchParams.openCompleteModal) {
      clearUrlParams('openCompleteModal');
    }
  };

  const openEsignModal = (taskPayload?: Record<string, unknown>) => {
    const redirectUrl = getULHomePageUrl();

    if (taskData?.name) {
      openModal(
        taskData.name,
        taskPayload,
        redirectUrl,
        trainingAssignmentData
      );
    } else {
      errorToast({
        message: LocalizationString.ASSIGNMENT_TASK_PENDING,
      });
    }
  };

  const validateIfAssignmentActive = async (): Promise<{
    isActive: boolean;
    errorMessage?: string | string[];
  }> => {
    try {
      setIsValidatingIfActive(true);
      const response = await getTrainingAssignmentDetails(
        trainingAssignmentName
      );

      if (response.status === AssignmentStatusEnum.UnAssigned) {
        return {
          isActive: false,
        };
      }
    } catch (e) {
      const error = e as AxiosError<APIError>;
      return {
        isActive: false,
        errorMessage: error?.response?.data?.errorMessage || error.message,
      };
    } finally {
      setIsValidatingIfActive(false);
    }
    return {
      isActive: true,
    };
  };

  const handleComplete = () => {
    if (!taskData?.name) {
      errorToast({
        message: LocalizationString.ASSIGNMENT_TASK_PENDING,
      });
      return;
    }

    validateIfAssignmentActive().then((assignmentStatus) => {
      if (!assignmentStatus?.isActive) {
        errorToast({
          message: `${
            assignmentStatus?.errorMessage ||
            LocalizationString.ASSIGNMENT_UPDATE_ERROR_MESSAGE
          }`,
        });
        return;
      } else {
        if (
          isBizAdmin &&
          (trainingAssignmentType !==
            TrainingAssignmentTypeEnum.MyAssignments ||
            taskData.ownerName !== user?.name) &&
          (!isTrainerTask || !isBizAdminTrainerTask)
        ) {
          openSignOffPanel(true);
          return;
        }

        switch (completionType) {
          case CompletionTypeEnum.Quiz:
            navigate(
              `../${ROUTES.TAKING_QUIZ.replace(
                ':name',
                courseData?.quiz?.name as string
              )}?assignment_name=${trainingAssignmentName}&training_plan_name=${trainingPlanName}&version=${assignmentVersion}`
            );
            return;

          case CompletionTypeEnum.DocumentUpload:
            openSignOffPanel();
            return;

          case CompletionTypeEnum.TrainerSignOff:
            if (isUserTask) {
              openEsignModal();
            } else if (isTrainerTask) {
              openSignOffPanel();
            }
            return;

          case CompletionTypeEnum.ReadAndUnderstood:
            openEsignModal();
            return;
        }
      }
    });
  };

  const viewCertificate = () => {
    if (trainingAssignmentData?.name) {
      setShowExportModal(true);
      downloadTrainingCertificate(trainingAssignmentData?.name)
        .catch((_error) => {
          raiseErrorToast(_error);
        })
        .finally(() => {
          setShowExportModal(false);
        });
    } else {
      errorToast({
        message: LocalizationString.ASSIGNMENT_DATA_NOT_FOUND,
      });
    }
  };

  const handleFetchDocumentCompletionStatus = async () => {
    const trainingAssignmentName = queryParams['training_assignment_name']
      ? queryParams['training_assignment_name']
      : params.name ?? '';

    try {
      setIsLoadingDocumentCompletionStatus(true);
      const trainingAssignment = await getTrainingAssignmentDetails(
        trainingAssignmentName
      );
      const isDocumentComplete = trainingAssignment.isDocumentComplete;
      setIsAllDocumentsCompleted(isDocumentComplete);
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      setIsLoadingDocumentCompletionStatus(false);
    }
  };

  useEffect(() => {
    Promise.all([
      fetchObjectTaskInfo(
        trainingAssignmentObjectName,
        trainingAssignmentName,
        trainingAssignmentData?.status === AssignmentStatusEnum.Completed
          ? {
              status: TaskStatusEnum.Done,
            }
          : undefined
      ),
      getCourseDetails(trainingAssignmentData),
    ])
      .then((r) => {
        setTaskData(r[0].task);
        setCourseData(r[1]);
      })
      .catch((_error) => {
        // Do nothing
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [trainingAssignmentData]);

  useEffect(() => {
    if (!isLoading && openCompleteFlow) {
      handleComplete();
    }
  }, [openCompleteFlow, isLoading]);

  useEffect(() => {
    handleFetchDocumentCompletionStatus();
  }, []);

  if (isLoadingDocumentCompletionStatus) {
    return null;
  }

  if (isUserTask && !(isAllDocumentsCompleted || completedInCurrentSession)) {
    return null;
  }

  if (isLoading && !taskData) {
    return null;
  }

  if (
    trainingAssignmentData?.status ===
      AssignmentStatusEnum.EvaluatingTraining ||
    (trainingAssignmentData?.status === AssignmentStatusEnum.PendingSignOff &&
      !isTrainerTask)
  ) {
    return null;
  }

  if (!taskData) {
    return null;
  }

  return (
    <CompleteTrainingButtonView
      {...{
        ...props,
        isLoading: isLoading || isValidatingIfActive,
        handleComplete,
        completionType,
        showSignOffPanel,
        closeSignOffPanel,
        openEsignModal,
        showExportModal,
        setShowExportModal,
        viewCertificate,
      }}
    />
  );
};
