import { LayoutURLParamsEnum, ROUTES } from '@celito.clients/enums';
import { Params } from 'react-router-dom';

export const getValueFromParams = (
  valueToGet: LayoutURLParamsEnum,
  params: Readonly<Params<string>>
) => {
  const layoutURL: string = ROUTES.LAYOUT;
  const valueIndex = layoutURL.indexOf(`:${valueToGet}`);
  const url = params['*'];

  if (valueIndex === -1 || !url) return undefined;

  const start = url.indexOf('/', valueIndex - 2) + 1;
  const end = url.indexOf('/', start);

  return url.substring(start, end);
};
