import { LocalizationString } from '@celito.clients/assets';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  isAxiosError,
} from 'axios';
import { isEqual, upperCase } from 'lodash';
import { match } from 'ts-pattern';

import { environment } from '../config/environment';
import { ApiMethodType } from '../enums/api-constants';
import {
  HTTPContentType,
  HTTPHeader,
  HTTPStatusCode,
} from '../enums/http-constants';
import { logger } from '../services/logger';
import { MsalInstanceManager } from '../singleton';
import { Environment } from '../singleton/environment-manager';

Environment.getInstance().setEnvironment(environment);
/** * Create an Axios Client with defaults */
const axiosInstance = axios.create({
  baseURL: Environment.getInstance().getParam('NX_API_BASE_URL') as string,
});

export const initAxios = () => {
  setupAxiosHeader();
  setAxiosResponseInterceptor();
};

const setupAxiosHeader = () => {
  axiosInstance.defaults.headers.common[HTTPHeader.ACCEPT] =
    HTTPContentType.APPLICATION_JSON;
  axiosInstance.defaults.headers.common[HTTPHeader.CONTENT_TYPE] =
    HTTPContentType.APPLICATION_JSON;
};

const shouldLog = (error: AxiosError) => {
  return (
    !isEqual(error.code, 'ERR_CANCELED') &&
    (!error.status || /^5\d{2}$/.test(error.status.toString()))
  );
};

const setAxiosResponseInterceptor = () => {
  const handleErr = (error: Error) => {
    if (!isAxiosError(error)) {
      logger.error(error, {
        origin: 'AxiosResponseInterceptor',
      });
      return Promise.reject(error);
    }
    if (shouldLog(error))
      logger.error(error, {
        origin: 'AxiosResponseInterceptor',
        ...error.toJSON(),
      });

    const res = error.response;
    if (res?.data.statusCode === HTTPStatusCode.UNAUTHORIZED) {
      match(res.data.errorMessage)
        .with(LocalizationString.USER_NOT_INVITED, () => {
          window.location.replace(
            Environment.getInstance().getParam('NX_NOT_INVITED_URL') as string
          );
        })
        .with(LocalizationString.USER_IS_DEACTIVATED, () => {
          window.location.replace(
            Environment.getInstance().getParam(
              'NX_NOT_ACTIVE_USER_URL'
            ) as string
          );
        })
        .with(LocalizationString.USER_DOES_NOT_HAVE_ACCESS, () => {})
        .otherwise(() => {
          MsalInstanceManager.getInstance().getMsalInstance().loginRedirect();
        });
    }

    return Promise.reject(error);
  };

  axiosInstance.interceptors.response.use((response) => response, handleErr);
};

export const setAxiosAuthToken = (token: string) => {
  axiosInstance.defaults.headers.common[
    HTTPHeader.AUTHORIZATION
  ] = `Bearer ${token}`;
};

export const removeAxiosAuthToken = () => {
  axiosInstance.interceptors.request.clear();
};

export const performRequest = async <T>(
  url: string,
  method: ApiMethodType,
  data: object | null = null,
  params?: object,
  config?: AxiosRequestConfig,
  signal?: AbortSignal
): Promise<AxiosResponse<T>> => {
  logger.info(`${upperCase(method)} Request`, { type: 'http', url, method });

  await MsalInstanceManager.getInstance()
    .getTokenSilently()
    .then((token) => setAxiosAuthToken(token));

  switch (method) {
    case ApiMethodType.GET:
      return getApi(url, params, config, signal);
    case ApiMethodType.POST:
      return postApi(url, data, params, config, signal);
    case ApiMethodType.PUT:
      return putApi(url, data, params, config);
    case ApiMethodType.DELETE:
      return deleteApi(url, data, params, config);
    case ApiMethodType.PATCH:
      return patchApi(url, data, params, config);
  }
};

const getApi = async (
  url: string,
  params?: object,
  config?: AxiosRequestConfig,
  signal?: AbortSignal
) => {
  return axiosInstance.get(url, { params, ...config, signal });
};

const postApi = async (
  url: string,
  data: object | null,
  params?: object,
  config?: AxiosRequestConfig,
  signal?: AbortSignal
) => {
  // data is optional for post requests. It is not used for put and delete
  return axiosInstance.post(url, data, { params, ...config, signal });
};

const putApi = async (
  url: string,
  data: object | null,
  params?: object,
  config?: AxiosRequestConfig
) => {
  return axiosInstance.put(url, data, { params, ...config }); // data is optional for post requests.  It is not used for put and delete;
};

const deleteApi = async (
  url: string,
  data?: object | null,
  params?: object,
  config?: AxiosRequestConfig
) => {
  // data is optional for put and delete
  return axiosInstance.delete(url, { params, ...config, data });
};

const patchApi = async (
  url: string,
  data: object | null,
  params?: object,
  config?: AxiosRequestConfig
) => {
  return axiosInstance.patch(url, data, { params, ...config });
};

export const performFormRequest = async (
  url: string,
  method: ApiMethodType,
  data: object | null,
  params: object = {}
) => {
  logger.info(`${upperCase(method)} Request`, { type: 'http', method, url });
  // data is optional for post requests.  It is not used for put and delete
  return performRequest(
    url,
    method,
    data,
    {
      ...params,
      headers: { ContentType: 'multipart/form-data' },
    },
    data instanceof FormData
      ? { headers: { 'Content-Type': 'multipart/form-data' } }
      : undefined
  ); // data is optional for post requests.  It is not used for put and delete;
};
export default axiosInstance;
