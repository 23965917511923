import { createTestAttribute } from '@celito.clients/utils';
import cn from 'classnames';
import { forwardRef } from 'react';

import { Icon } from '../shared';
import { PreviewPanelProps } from './preview-panel.model';
import classes from './preview-panel.styles.module.css';

interface PreviewPanelViewProps extends PreviewPanelProps {
  isPanelOpen: boolean;
  closePanel: () => void;
  openPanel: () => void;
}

export const PreviewPanelView = forwardRef<
  HTMLDivElement,
  PreviewPanelViewProps
>((props, ref) => {
  const {
    dataTestId,
    isPanelOpen,
    closePanel,
    openPanel,
    children,
    title,
    onEditPress,
    showEditIcon = true,
  } = props;
  return (
    <div ref={ref} data-testid={dataTestId} className={classes.container}>
      <div
        className={cn(classes.header, { [classes.headerOpen]: isPanelOpen })}
      >
        <h3 data-testid={`form-section-${createTestAttribute(title)}`}>
          {title}
        </h3>
        <div className={classes.actionsContainer}>
          {showEditIcon && (
            <button
              data-testid={`button-edit-${createTestAttribute(title)}`}
              className={classes.actionBtn}
              onClick={onEditPress}
            >
              <Icon iconName="Edit16Regular" />
            </button>
          )}
          <button
            data-testid={`button-collapse-${createTestAttribute(title)}`}
            className={classes.actionBtn}
            onClick={isPanelOpen ? closePanel : openPanel}
          >
            {isPanelOpen ? (
              <Icon iconName="ChevronUp16Regular" />
            ) : (
              <Icon iconName="ChevronDown16Regular" />
            )}
          </button>
        </div>
      </div>

      {isPanelOpen && <div>{children}</div>}
    </div>
  );
});
