import {
  mergeClasses,
  Tree,
  TreeItem,
  TreeItemLayout,
} from '@fluentui/react-components';
import { Icon } from 'libs/shared/src/lib/icon';
import { useNavigate } from 'react-router';

import { INavLink } from '../../header.model';
import { HeaderSidebarProps } from './header-sidebar.component';
import { headerSideBarStyles } from './header-sidebar.styles';
import classes from './header-sidebar.styles.module.css';

interface HeaderSidebarViewProps extends HeaderSidebarProps {}

interface TreeMenuProps {
  handleNavLinkClick: (selectedLinkKey?: string | undefined) => void;
  links: INavLink[];
  activeMenu?: string;
}

const TreeMenu = ({ links, handleNavLinkClick, activeMenu }: TreeMenuProps) => {
  const navigate = useNavigate();

  const styles = headerSideBarStyles();

  return (
    <>
      {links.map((link) => {
        if (!link.links || link.links?.length === 0) {
          return (
            <TreeItem key={link.name} itemType="leaf" value={link.url}>
              <TreeItemLayout
                className={mergeClasses(activeMenu === link.url && styles.root)}
                onClick={() => {
                  handleNavLinkClick();
                  navigate(link.url);
                }}
              >
                {link.name}
              </TreeItemLayout>
            </TreeItem>
          );
        } else
          return (
            <TreeItem key={link.name} itemType="branch" value={link.url}>
              <TreeItemLayout
                className={mergeClasses(activeMenu === link.url && styles.root)}
              >
                {link.name}
              </TreeItemLayout>
              <Tree>
                <TreeMenu
                  handleNavLinkClick={() => handleNavLinkClick(link.url)}
                  links={link.links}
                />
              </Tree>
            </TreeItem>
          );
      })}
    </>
  );
};

const HeaderSidebarView = ({
  activeNavItem,
  getNavLinkGroups,
  handleNavLinkClick,
  toggleSidebar,
}: HeaderSidebarViewProps): JSX.Element => {
  const navLinks = getNavLinkGroups();

  return (
    <div className={classes.container}>
      <button
        type="button"
        className={classes.closeIconBtn}
        onClick={() => toggleSidebar(false)}
      >
        <Icon iconName="Dismiss16Regular" className={classes.closeIcon} />
      </button>
      <div className={classes.navigationContainer}>
        <Tree
          aria-label="Default"
          appearance="subtle-alpha"
          defaultOpenItems={[activeNavItem?.link ?? '']}
        >
          {navLinks.map((link, i) => {
            if (link.links)
              return (
                <TreeMenu
                  key={i}
                  activeMenu={activeNavItem?.link}
                  handleNavLinkClick={handleNavLinkClick}
                  links={link.links}
                />
              );
            return null;
          })}
        </Tree>
      </div>
    </div>
  );
};

export default HeaderSidebarView;
