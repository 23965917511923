import { LocalizationString } from '@celito.clients/assets';
import { ObjectEnum, ROUTES } from '@celito.clients/enums';
import { useLayout, useObjectDefinition } from '@celito.clients/hooks';
import { editFormSubmitApi } from '@celito.clients/services';
import {
  ControlledDocumentRequestTypeEnum,
  ObjectAttributeType,
} from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';

import { ControlledDocumentPickerModal } from './components/controlled-doc-picker-modal/controlled-document-picker-modal.component';
import { CoursePickerModal } from './components/course-picker-modal/course-picker-modal.component';
import { CurriculumPickerModal } from './components/curriculum-picker-modal/curriculum-picker-modal.component';
import {
  ControlledDocumentInitiationType,
  CourseInitiationType,
  CourseInitiationTypeEnum,
  CurriculumInitiationType,
  CurriculumInitiationTypeEnum,
  InitiationType,
  ObjectInitiationProps,
  ObjectInitiationViewNameEnum,
} from './object-initiation-screen.model';
import { ObjectInitiationView } from './object-initiation-screen.view';
import { getObjectNameByView, transformToPayload } from './utils/helpers';

export const ObjectInitiationController: React.FC<ObjectInitiationProps> = (
  props
) => {
  const [initiationType, setInitiationType] = useState<
    InitiationType | undefined
  >();
  const [openUpdatePickerModal, setOpenUpdatePickerModal] = useState(false);
  const [
    showUpdateInititationConfirmationModal,
    setShowUpdateInititationConfirmationModal,
  ] = useState(false);
  const [selectedRecordDetails, setSelectedRecordDetails] =
    useState<Record<string, any>>();
  const [loading, setLoading] = useState(false);

  const { viewName = '' } = useParams();
  const navigate = useNavigate();
  const { configureLayout } = useLayout();
  const { data: objectDefinition } = useObjectDefinition({
    objectName: getObjectNameByView(
      viewName as ObjectInitiationViewNameEnum
    ) as ObjectEnum,
  });

  const handleInitiateBtnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (
      [
        CourseInitiationTypeEnum.UpdateCourse,
        ControlledDocumentRequestTypeEnum.UpdateDocument,
        CurriculumInitiationTypeEnum.UpdateCurriculum,
      ].includes(initiationType!)
    ) {
      e.preventDefault();
      setOpenUpdatePickerModal(true);
    }
  };

  const handleChange = (value: string) => {
    setInitiationType(value as InitiationType);
  };

  useEffect(() => {
    let headerTitle = '';
    switch (viewName) {
      case ObjectInitiationViewNameEnum.ControlledDocumentInitiation:
        setInitiationType(ControlledDocumentRequestTypeEnum.NewDocument);
        headerTitle = LocalizationString.CONTROLLED_DOCUMENT_INITIATION;

        break;
      case ObjectInitiationViewNameEnum.CourseInitiation:
        setInitiationType(CourseInitiationTypeEnum.NewCourse);
        headerTitle = LocalizationString.COURSE_INITIATION;
        break;
      default:
        setInitiationType(CurriculumInitiationTypeEnum.NewCurriculum);
        headerTitle = LocalizationString.CURRICULUM_INITIATION;

        break;
    }

    configureLayout({
      pageTitle: '',
      enablePadding: false,
      headerTitle: headerTitle,
    });
  }, [viewName]);

  const options = useMemo(() => {
    switch (viewName) {
      case ObjectInitiationViewNameEnum.ControlledDocumentInitiation:
        return ControlledDocumentInitiationType.map(({ label, value }) => ({
          label,
          value,
        }));
      case ObjectInitiationViewNameEnum.CourseInitiation:
        return CourseInitiationType.map((type) => ({
          label: type,
          value: type,
        }));
      default:
        return CurriculumInitiationType.map((type) => ({
          label: type,
          value: type,
        }));
    }
  }, [viewName]);

  const handleOnUpdateInitiate = (selectedRecord: Record<string, unknown>) => {
    setSelectedRecordDetails(selectedRecord);
    setOpenUpdatePickerModal(false);
    setShowUpdateInititationConfirmationModal(true);
  };

  const handleOnUpdateInitiationConfirmation = async () => {
    setLoading(true);

    const payload = transformToPayload(
      {
        ...selectedRecordDetails,
        initiationType: selectedRecordDetails?.requestType, // initiationType should always be Update type
      },
      objectDefinition as ObjectAttributeType
    );

    try {
      const response = await editFormSubmitApi(
        selectedRecordDetails?.objectDefinitionName as string,
        selectedRecordDetails?.name as string,
        payload,
        {
          skipVersionUpgrade: true,
          action: 'update',
          version: selectedRecordDetails?.version as string,
        }
      );

      navigate(
        `../edit/${selectedRecordDetails?.editViewName}/${response?.record?.name}?requestType=${selectedRecordDetails?.requestType}&version=${response?.record?.version}`
      );
    } catch (_error) {
      raiseErrorToast(_error);
      setLoading(false);
      setShowUpdateInititationConfirmationModal(false);
    }
  };

  const renderUpdatePickerModal = () => {
    switch (viewName) {
      case ObjectInitiationViewNameEnum.ControlledDocumentInitiation:
        return (
          <ControlledDocumentPickerModal
            selectedKey={initiationType as ControlledDocumentRequestTypeEnum}
            controlledDocumentPickerModalOpen={openUpdatePickerModal}
            onCloseControlledDocumentPickerModal={() =>
              setOpenUpdatePickerModal(false)
            }
            onUpdateInitiate={handleOnUpdateInitiate}
          />
        );
      case ObjectInitiationViewNameEnum.CourseInitiation:
        return (
          <CoursePickerModal
            selectedKey={initiationType as CourseInitiationTypeEnum}
            courseUpdatePickerModalOpen={openUpdatePickerModal}
            onCloseCourseUpdatePickerModal={() =>
              setOpenUpdatePickerModal(false)
            }
            onUpdateInitiate={handleOnUpdateInitiate}
          />
        );
      default:
        return (
          <CurriculumPickerModal
            selectedKey={initiationType as CurriculumInitiationTypeEnum}
            curriculumUpdatePickerModalOpen={openUpdatePickerModal}
            onCloseCurriculumUpdatePickerModal={() =>
              setOpenUpdatePickerModal(false)
            }
            onUpdateInitiate={handleOnUpdateInitiate}
          />
        );
    }
  };

  if (
    ![
      ObjectInitiationViewNameEnum.CourseInitiation,
      ObjectInitiationViewNameEnum.ControlledDocumentInitiation,
      ObjectInitiationViewNameEnum.CurriculumInitiation,
    ].includes(viewName as ObjectInitiationViewNameEnum)
  ) {
    return <Navigate to={`../${ROUTES.PAGE_NOT_FOUND}`} />;
  }

  return (
    <ObjectInitiationView
      {...props}
      options={options}
      initiationType={initiationType!}
      openUpdatePickerModal={openUpdatePickerModal}
      handleInitiateBtnClick={handleInitiateBtnClick}
      handleChange={handleChange}
      renderUpdatePickerModal={renderUpdatePickerModal}
      showUpdateInititationConfirmationModal={
        showUpdateInititationConfirmationModal
      }
      setShowUpdateInititationConfirmationModal={
        setShowUpdateInititationConfirmationModal
      }
      onUpdateInitiationConfirmation={handleOnUpdateInitiationConfirmation}
      loading={loading}
      objectLabel={objectDefinition?.label ?? ''}
    />
  );
};
