import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { ViewMetadata } from '../types/view-metadata.type';

export const triggerWorkflow = async (
  actionName: string,
  recordName: string,
  version: string
): Promise<ViewMetadata> => {
  const response = await performRequest<ViewMetadata>(
    `${Endpoints.EXECUTE_ACTION}/${actionName}`,
    ApiMethodType.POST,
    {},
    {
      record_name: recordName,
      version,
    }
  );
  return response.data;
};
