import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import {
  ObjectAttributeDefinition,
  ObjectAttributeType,
} from '@celito.clients/types';
import { getAttributeConfig } from 'libs/form-engine/src/lib/utils/attribute-config';
import { useFormContext } from 'react-hook-form';

export const Field = ({
  attribute,
}: {
  attribute: ObjectAttributeDefinition;
}) => {
  const form = useFormContext();
  return getAttributeConfig(
    '',
    attribute,
    form.control,
    FormEngineModeTypeEnum.Create,
    {} as ObjectAttributeType,
    form.watch,
    form.setValue,
    form.trigger
  );
};
