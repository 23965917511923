import { LocalizationString } from '@celito.clients/assets';
import { BREAKPOINTS, ButtonTypes } from '@celito.clients/enums';
import {
  CustomButton,
  GridView,
  GridViewProps,
  Icon,
  Panel,
  SearchInput,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { OnChangeFn, RowSelectionState } from '@tanstack/react-table';
import { keys } from 'lodash';
import ReactPaginate from 'react-paginate';

import { ReferenceSelectorItem } from '../../reference-selector.model';
import { UserView } from '../user-view/user-view.component';
import {
  Pagination,
  ShowAllResultPanelProps,
} from './show-all-results-panel.model';
import classes from './show-all-results-panel.module.css';

interface ShowAllResultPanelViewProps extends ShowAllResultPanelProps {
  isLoadingAvailableItems: boolean;
  availableItems: ReferenceSelectorItem[];
  rowSelection: RowSelectionState;
  paginationProps: Pagination;
  onRowSelection: OnChangeFn<RowSelectionState>;
  columnData: GridViewProps['columns'];
  isUserViewPanelOpen: boolean;
  userName?: string;
  onUserViewDismiss: () => void;
  onAddClick: () => void;
}

export const ShowAllResultPanelView = ({
  isOpen,
  onDismiss,
  onAddClick,
  searchKey,
  setSearchKey,
  isLoadingAvailableItems,
  availableItems,
  paginationProps,
  rowSelection,
  objectName,
  label,
  columnData,
  multiSelect,
  onRowSelection,
  isUserViewPanelOpen,
  userName,
  onUserViewDismiss,
}: ShowAllResultPanelViewProps) => {
  const { cssVariables } = useTheme();

  const renderPanel = () => {
    if (isUserViewPanelOpen && userName) return <UserView name={userName} />;

    return (
      <div className={classes.selectorContainer}>
        <div className={classes.grid_view_wrapper}>
          <div className={classes.search_box}>
            <SearchInput
              value={searchKey}
              placeholder="Search Key"
              onChange={setSearchKey}
            />
          </div>
          <GridView
            onRowSelection={onRowSelection}
            items={availableItems}
            columns={columnData}
            enableShimmer={isLoadingAvailableItems}
            enableMultiRowSelection={multiSelect}
            disableDefaultSorting
            rowSelection={rowSelection}
          />
          {paginationProps.totalPageCount > 1 && (
            <div className={classes.bottomContainer}>
              <div className={classes.pagination}>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <Icon
                      iconName="ChevronRight16Regular"
                      data-testid="icon-pagination-right"
                    />
                  }
                  forcePage={paginationProps.currentPage - 1}
                  onPageChange={({ selected }) => {
                    paginationProps.handlePageUpdate(selected + 1);
                  }}
                  previousLabel={
                    <Icon
                      iconName="ChevronLeft16Regular"
                      data-testid="icon-pagination-left"
                    />
                  }
                  pageCount={paginationProps.totalPageCount}
                  marginPagesDisplayed={1}
                  activeClassName={classes.active}
                  disabledClassName={classes.disabled}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const onCancelHandler = () => {
    if (isUserViewPanelOpen) {
      onUserViewDismiss();
    } else {
      onDismiss();
    }
  };

  return (
    <Panel
      open={isOpen}
      onClose={onDismiss}
      position="end"
      headerText={label}
      style={{
        width:
          window.innerWidth <= BREAKPOINTS.TABLET.SAFARI.MIN_WIDTH
            ? '100vw'
            : '75vw',
        ...cssVariables,
      }}
    >
      {renderPanel()}
      <div className={classes.addBtn}>
        <div className={classes.btnContainer}>
          <CustomButton
            buttonTitle={
              isUserViewPanelOpen
                ? LocalizationString.BACK
                : LocalizationString.CANCEL
            }
            buttonType={ButtonTypes.Ghost}
            onClick={onCancelHandler}
          />
          {!isUserViewPanelOpen && (
            <CustomButton
              buttonTitle={LocalizationString.ADD}
              leftIcon="Add"
              buttonType={ButtonTypes.Primary}
              onClick={onAddClick}
              isDisabled={keys(rowSelection).length < 1}
            />
          )}
        </div>
      </div>
    </Panel>
  );
};
