import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { AdUserTypes } from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';
import { useQuery } from 'react-query';

export interface IUserAzureInformationResponse {
  label: 'Roberto Filho';
  idpOid: string;
  department: string;
  manager: string;
}

export function useGetUserAzureInformationByIdpOid(idpOid: string) {
  async function fetchUserInformation() {
    if (!idpOid) return {} as IUserAzureInformationResponse;

    const response = await performRequest<IUserAzureInformationResponse>(
      `${Endpoints.USER_AZURE_INFORMATION}/${idpOid}`,
      ApiMethodType.GET
    );

    return response.data;
  }

  return {
    userAzureInfomation: useQuery(
      ['user-azure-information', idpOid],
      fetchUserInformation
    ),
  };
}

export function useGetAllAdUsers() {
  async function fetchUserInformation() {
    const response = await performRequest<AdUserTypes[]>(
      `${Endpoints.ALL_AD_USERS}`,
      ApiMethodType.GET
    );

    return response.data;
  }

  return {
    userAzureInfomation: useQuery(['all-ad-users'], fetchUserInformation),
  };
}
