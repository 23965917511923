import { ObjectEnum } from '@celito.clients/enums';
import { getObjectData } from '@celito.clients/list-view-engine';
import { getModuleUsers } from '@celito.clients/services';

import { RulesComponentListViewFilter } from '../../../rules-component/types/rules-component.types';
import { User } from '../types';

export const previewRuleResults = async <T = User>(
  objectName: string,
  filters: RulesComponentListViewFilter[],
  modules: string[]
) => {
  if (objectName === ObjectEnum.USER) {
    const response = await getModuleUsers<T>({ filters }, { modules });
    return response.data;
  }

  return getObjectData<T>(
    objectName,
    { limit: 100, page: 1 },
    {
      filters: filters[0],
    }
  );
};
