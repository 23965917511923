export enum FileMimeType {
  TEXT = 'text/plain',
  CSV = 'text/csv',
  WORD = 'application/msword',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP = 'application/zip',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument',
}
