import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledRadioGroupController } from './controlled-radio-group.controller';
import { ControlledRadioGroupProps } from './controlled-radio-group.model';

const ControlledRadioGroup = <T extends FieldValues>(
  props: ControlledRadioGroupProps<T>
) => {
  return <ControlledRadioGroupController {...props} />;
};

export default ControlledRadioGroup;
