import { useState } from 'react';

import { NavLinkMultipleProps } from './nav-link-multiple.model';
import { NavLinkMultipleView } from './nav-link-multiple.view';

export const NavLinkMultipleController = (props: NavLinkMultipleProps) => {
  const [showContextualMenu, setShowContextualMenu] = useState(false);

  const onShowContextualMenu = () => {
    setShowContextualMenu(!showContextualMenu);
  };

  return (
    <NavLinkMultipleView
      {...{ onShowContextualMenu, showContextualMenu, ...props }}
    />
  );
};
