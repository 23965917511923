import { useEffect, useState } from 'react';

import { RowItem } from '../../grid-view-new/src/types';
import { ReferenceDocumentsPreviewProps } from './reference-documents-preview.model';
import ReferenceDocumentsPreviewView from './reference-documents-preview.view';

const ReferenceDocumentsPreviewController = (
  props: ReferenceDocumentsPreviewProps
): JSX.Element => {
  const [referenceDocuments, setReferenceDocuments] = useState<RowItem[]>([]);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState<RowItem | null>(null);

  const handleCancelClick = () => {
    setIsConfirmDialogOpen(false);
    setItemToBeDeleted(null);
  };

  const handleConfirmClick = () => {
    if (props.removeItem) {
      props.removeItem(itemToBeDeleted as RowItem);
      setItemToBeDeleted(null);
      setIsConfirmDialogOpen(false);
    }
  };

  const handleActionClick = (val: RowItem) => {
    setItemToBeDeleted(val);
    setIsConfirmDialogOpen(true);
  };

  useEffect(() => {
    if (props?.items?.length > 0) {
      setReferenceDocuments(props?.items);
    } else {
      setReferenceDocuments([]);
    }
  }, [props]);

  return (
    <ReferenceDocumentsPreviewView
      {...props}
      items={referenceDocuments}
      handleCancelClick={handleCancelClick}
      handleConfirmClick={handleConfirmClick}
      isConfirmDialogOpen={isConfirmDialogOpen}
      handleActionClick={handleActionClick}
    />
  );
};

export default ReferenceDocumentsPreviewController;
