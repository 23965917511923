import { getCourseData } from 'libs/form-engine/src/lib/custom-pages/related-course/services';

export const quizHasRelatedCourse = async (
  quizName: string,
  objectName: string
) => {
  if (objectName !== 'quiz__a' || !quizName) return false;

  return await getCourseData(quizName, objectName, 1).then(
    (courseDataResponse) => {
      return courseDataResponse.total > 0;
    }
  );
};
