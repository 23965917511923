import { NumberRangePicker, Stack } from '@celito.clients/shared';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { ControlledNumberRangeProps } from './controlled-numberrange-picker.model';
import classes from './controlled-numberrange-picker.styles.module.css';

export const ControlledNumberRangePickerView = <T extends FieldValues>(
  props: ControlledNumberRangeProps<T>
) => {
  const { name } = props.attribute;
  return (
    <Controller
      name={name as Path<T>}
      control={props.control}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error },
      }) => (
        <Stack
          data-testid="numberrange-picker-container"
          className={classes.section}
        >
          <NumberRangePicker
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Stack>
      )}
    />
  );
};
