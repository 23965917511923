import { isNonEmptyArray, NonEmptyArray } from '@celito.clients/types';

export const insertAtIndex = <T = unknown>(
  arr: Array<T>,
  index: number,
  newItem: T
) => {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];
};

export const changeAtIndex = <T = unknown>(
  arr: Array<T>,
  index: number,
  newItem: T
) => {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index + 1),
  ];
};

export const removeAtIndex = <T = unknown>(arr: Array<T>, index: number) => {
  if (index < 0) {
    return arr;
  }
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // part of the array after the specified index
    ...arr.slice(index + 1),
  ];
};

export const removeEmptyValues = <T>(
  arr: Array<T | null | undefined>
): Array<T> => arr.filter((el): el is T => el !== null && el !== undefined);

export const removeEmptyArrays = <T>(
  arr: Array<T[]>
): Array<NonEmptyArray<T>> =>
  arr.filter((el) => isNonEmptyArray(el)) as Array<NonEmptyArray<T>>;
