import { GridViewProps } from '@celito.clients/shared';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';

// When any row is set to null, that row will be shown as loading
const getLoadingRowData = (totalItemsInRow: number) => {
  const loadingRowData = [];
  for (let i = 0; i < totalItemsInRow; i++) {
    loadingRowData.push(null);
  }
  return loadingRowData as unknown as GridViewProps['items'];
};

// Remove null rows, ie. ones that are loading
// only loaded rows will be shown
export const getLoadedRowData = (rowData: RowItem[]) => {
  return rowData.filter((v) => v !== null);
};

const uiConfig = {
  itemsPerRow: 10,
  // Need to type alias here since fluent ui grid-vew will automatically handle null rows
  // So specifically setting the type as null[] will just cause redundant if checks
  getLoadingRowData,
  getLoadedRowData,
};

export default uiConfig;
