import { ObjectAttributeDefinition } from '@celito.clients/types';

import { FilterableColumn } from '../../../types';

export const getAttributeDefinationsOfColumns = (
  filterableColumns: FilterableColumn[],
  objectAttributeDefinitions: ObjectAttributeDefinition[]
): ObjectAttributeDefinition[] => {
  const definitaions = filterableColumns
    .map(
      ({ name }) =>
        objectAttributeDefinitions.find((item) => item.name === name) ?? null
    )
    .filter((field): field is ObjectAttributeDefinition => field !== null);

  return [...new Set(definitaions)];
};
