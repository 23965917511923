import { LocalizationString } from '@celito.clients/assets';
import {
  useCallbackPrompt,
  useDeepCompareEffect,
  useLayout,
  useURLParams,
} from '@celito.clients/hooks';
import {
  editFormSubmitApi,
  getRecordDetailApi,
} from '@celito.clients/services';
import { Field, TrainingPlanRequestTypeEnum } from '@celito.clients/types';
import {
  errorToast,
  formatDateInTimezone,
  raiseErrorToast,
} from '@celito.clients/utils';
import { FileDataProps } from 'libs/shared/src/lib/multi-file-upload/multi-file-upload.model';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

import {
  smartGroupPreviewPropName,
  trainessPreviewPropName,
  trainingRolesPreviewPropName,
} from '../../../custom-pages/assingment-page/config';
import { useFormEngineContext } from '../../../hooks';
import { RecorLevelUserContextFields } from '../../../types/common-types';
import { getActiveLifeCycleStage } from '../../../utils/attribute-config';
import {
  atLeastOneValueExists,
  getFormValues,
  refactorFormData,
} from '../../../utils/helper';
import { WizardContainerComponentProps } from '../wizard-container.model';
import { EditScreenProps } from './edit-screen.model';
import EditScreenView from './edit-screen.view';

const attachmentsPropName = 'attachments__a';
// for system objects the attachment key name will be attachments__s
const attachmentSystemPropName = 'attachments__s';
const filePropName = 'file__a';

const isMajorVersion = (version: string) => {
  return !isNaN(Number(version)) && Number(version) % 1 === 0;
};

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const EditScreenController = (props: WizardContainerComponentProps) => {
  const {
    sections = [],
    attributeConfig: objectDefinition,
    objectName,
    recordName,
    step = 0,
    submitUrl = '',
    setWizardState,
    labelFormat,
    setRecordData,
  } = props;

  const [recordVersion, setRecordVersion] = useState<number>(0);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);

  const { urlParams } = useURLParams();
  const versionNumber = urlParams?.get('version');
  const requestType = urlParams.get('requestType');
  const isUpdateRequest = requestType?.includes('Update');
  const isControlledDocsModule =
    window.location.pathname.includes('controlled_docs__a');

  const { fetchAttributeConfig } = useFormEngineContext();
  const navigate = useNavigate();

  const isTrainingPlanRequest =
    requestType === TrainingPlanRequestTypeEnum.NewTrainingPlan;

  const methods = useFormContext();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    reset,
    formState: { dirtyFields, isDirty },
    getValues,
  } = methods;

  const allSectionsFields = useMemo(() => {
    return sections.reduce((acc, section) => {
      return acc.concat(section.fields);
    }, [] as Field[]);
  }, [sections]);

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    !!props.showCancelPopup && isDirty
  );

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [recordDetails, setRecordDetails] = useState<Record<string, unknown>>(
    {}
  );
  const [isRecordDataLoading, setIsRecordDataLoading] = useState(false);
  const { configureLayout } = useLayout();

  const toggleWarningModalBtnClick = () => {
    setIsWarningModalOpen(!isWarningModalOpen);
  };

  const toggleSaveModalBtnClick = () => {
    setIsSaveModalOpen(!isSaveModalOpen);
    reset({ ...getValues(), isDirty: false });
  };

  const toggleSubmitModalBtnClick = () => {
    reset({ ...getValues(), isDirty: false });

    setIsSubmitModalOpen(!isSubmitModalOpen);
    if (isSubmitModalOpen) {
      if (submitUrl) {
        navigate(submitUrl);
      } else {
        navigate(-1);
      }
    }
  };

  const toggleCancelModalBtnClick = (navigateBack?: boolean) => {
    setIsCancelModalOpen((prev) => !prev);
    if (navigateBack) {
      reset({ isDirty: false });
      confirmNavigation();
      navigate(-1);
    }
  };

  const onCancel: EditScreenProps['onCancel'] = (e) => {
    e.preventDefault();

    if (props.showCancelPopup && isDirty) {
      setIsCancelModalOpen(true);
    } else {
      onRouteBack();
    }
  };

  const onRouteBack = () => {
    navigate(-1);
  };

  const onFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    handleSubmit(() => onSave(false));
  };

  const callEditFormApi = async (
    payload: FormData,
    isSubmit: boolean,
    extraQueryString: Record<string, unknown> = {},
    fromNextOrPrevious = false
  ) => {
    isSubmit ? setIsDataSubmitted(true) : setIsDataSaved(true);
    try {
      const submitFlag = extraQueryString?.isSubmit;

      if (submitFlag) {
        delete extraQueryString.isSubmit;
      }
      const params: Record<string, unknown> = {
        ...(isSubmit && { isSubmit: true }),
        ...(labelFormat && { recordName: payload?.get('name__s') }),

        ...extraQueryString,
      };

      if (
        objectDefinition?.isVersioningEnabled &&
        !extraQueryString.skipVersionUpgrade
      ) {
        params.skipVersionUpgrade =
          (Object.keys(dirtyFields).length >= 1 &&
            recordDetails?.isActive &&
            !isSubmit) ||
          false;
      }

      if (
        (params.skipVersionUpgrade || isUpdateRequest) &&
        recordDetails.version
      ) {
        params.version = recordDetails.version;
      }

      if (labelFormat) {
        payload.delete('name__s');
        payload.delete('label__s');
      }

      const response = await editFormSubmitApi(
        objectName,
        recordName,
        payload,
        params
      );

      setRecordDetails({ ...response.record });
      setRecordData?.({ ...response.record });

      setValue(`deleted_${attachmentsPropName}`, []);
      setValue(`deleted_${attachmentSystemPropName}`, []);
      setValue(`deleted_${filePropName}`, []);

      confirmNavigation();

      if (isSubmit) {
        toggleSubmitModalBtnClick();
      } else if (!fromNextOrPrevious) {
        toggleSaveModalBtnClick();
      }
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      isSubmit ? setIsDataSubmitted(false) : setIsDataSaved(false);
    }
  };

  const callRecordDetailApi = async (
    objectName: string,
    recordName: string
  ) => {
    try {
      const response = await getRecordDetailApi(
        objectName,
        recordName,
        versionNumber
          ? {
              version: versionNumber,
            }
          : {}
      );
      setRecordDetails(response);
      setRecordData?.(response);

      setRecordVersion(Number(response?.version));

      if (response?.version)
        urlParams.set('version', response?.version as string);

      const navigateTo = {
        pathname: window.location.pathname,
        search: `?${urlParams.toString()}`,
      };
      navigate(navigateTo, { replace: true });

      const activeLifeCycleStage = getActiveLifeCycleStage(
        objectDefinition,
        response.lifecycleStage as string
      );
      setWizardState(activeLifeCycleStage as string);

      reset(getFormValues(objectDefinition, response));

      if (requestType) setValue('initiation_type__a', requestType);
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      setIsRecordDataLoading(false);
    }
  };

  const submitForm = async (
    rawData: FieldValues,
    isSubmit: boolean,
    extraQueryString: Record<string, unknown> = {},
    formDataObject: Record<string, unknown> = {},
    fromNextOrPrevious = false
  ) => {
    let data = refactorFormData(objectDefinition, rawData);
    if (Object.keys(formDataObject).length > 0) {
      data = { ...data, ...formDataObject };
    }

    if (!data?.file__a?.documentId) {
      data.file__a = watch('file__a');
    }

    // for control docs
    const attachments =
      watch(attachmentsPropName) || watch(attachmentSystemPropName);
    if (attachments?.length > 0) {
      data.secondary_file__a = attachments.filter(
        // only File type should be passed to secondary_file__a and will act as append in the DB
        (attachment: File | FileDataProps) => attachment instanceof File
      );
    }

    const markedForDeleteAttachments =
      watch(`deleted_${attachmentsPropName}`) ??
      watch(`deleted_${attachmentSystemPropName}`) ??
      [];
    let markedForDeleteFile = watch(`deleted_${filePropName}`) ?? [];

    if (
      markedForDeleteFile?.length &&
      isControlledDocsModule &&
      isMajorVersion((recordDetails?.document as { version: string })?.version)
    ) {
      setValue(`deleted_${filePropName}`, []);
      markedForDeleteFile = [];
    }

    delete data?.[attachmentsPropName];
    delete data?.[`deleted_${attachmentsPropName}`];
    delete data?.[attachmentSystemPropName];
    delete data?.[`deleted_${attachmentSystemPropName}`];
    delete data?.[`deleted_${filePropName}`];
    delete data?.[attachmentsPropName];
    delete data?.[trainessPreviewPropName];
    delete data?.[smartGroupPreviewPropName];
    delete data?.[trainingRolesPreviewPropName];
    delete data?.['isDirty'];
    delete data?.['is_trainable__a'];
    delete data?.['lifecycle_stage__s'];
    delete data?.['document_status__a'];
    delete data?.['currentQuestion'];
    delete data?.['isEditMode'];
    delete data?.['modified_by_user__s'];
    delete data?.['created_by_user__s'];
    delete data?.['created_at_utc__s'];
    delete data?.['modified_at_utc__s'];
    delete data?.['requestor__a'];
    delete data?.['version__s'];
    delete data?.[RecorLevelUserContextFields.RECORD_AND_SYSTEM_ROLES];

    if (objectDefinition?.name === 'quiz__a') {
      delete data?.['initiation_type__a'];
    }

    const formData = new FormData();

    if (markedForDeleteFile?.length) {
      // tells BE that the file has been deleted
      formData.append(`documentDeleted`, 'true');
    }

    if (markedForDeleteAttachments.length > 0) {
      formData.append(
        'deletedDocumentIds',
        JSON.stringify(markedForDeleteAttachments)
      );
    }

    Object.keys(data).forEach((key) => {
      if (
        data[key] !== undefined &&
        !(data[key] instanceof Date) &&
        data[key] !== '' &&
        !Number.isNaN(data[key])
      ) {
        // for multi file uploads
        if (Array.isArray(data[key]) && key === 'secondary_file__a') {
          for (const element of data[key]) {
            formData.append(key, element);
          }
          return;
        }

        const handleArrayData = () =>
          data[key].length > 0 ? JSON.stringify(data[key]) : null;

        formData.append(
          key,
          Array.isArray(data[key]) ? handleArrayData() : data[key]
        );
      }
      if (data[key] instanceof Date) {
        formData.append(key, formatDateInTimezone(data[key]));
      }
    });
    callEditFormApi(
      formData,
      isSubmit || (extraQueryString?.isSubmit as boolean),
      {
        ...extraQueryString,
      },
      fromNextOrPrevious
    );
  };

  const onValidSubmitForm = (
    rawData: FieldValues,
    isSubmit: boolean,
    extraQueryString: Record<string, unknown> = {},
    formDataObject: Record<string, unknown> = {},
    fromNextOrPrevious = false
  ) => {
    const oneValueExists = atLeastOneValueExists(rawData, allSectionsFields);

    if (!fromNextOrPrevious && !oneValueExists) {
      errorToast({
        message: LocalizationString.VALIDATION_DESCRIPTION,
      });
      return;
    }
    if (oneValueExists) {
      return submitForm(
        rawData,
        isSubmit,
        extraQueryString,
        formDataObject,
        fromNextOrPrevious
      );
    }
  };

  const onInvalidSubmitForm = (
    isSubmit: boolean,
    extraQueryString: Record<string, unknown> = {},
    formDataObject: Record<string, unknown> = {},
    fromNextOrPrevious = false
  ) => {
    const values = getValues();
    const oneValueExists = atLeastOneValueExists(values, allSectionsFields);

    if (isSubmit || (!fromNextOrPrevious && !oneValueExists)) {
      errorToast({
        message: LocalizationString.VALIDATION_DESCRIPTION,
      });
      return;
    }

    if (oneValueExists) {
      return submitForm(
        values,
        isSubmit,
        extraQueryString,
        formDataObject,
        fromNextOrPrevious
      );
    }
  };

  const onSave = async (
    isSubmit = false,
    extraQueryString: Record<string, unknown> = {},
    formDataObject: Record<string, unknown> = {},
    fromNextOrPrevious = false
  ) => {
    if (!isSubmit) {
      return onValidSubmitForm(
        getValues(),
        isSubmit,
        extraQueryString,
        formDataObject,
        fromNextOrPrevious
      );
    }

    await handleSubmit(
      (rawData) =>
        onValidSubmitForm(
          rawData,
          isSubmit,
          extraQueryString,
          formDataObject,
          fromNextOrPrevious
        ),
      (_err) =>
        onInvalidSubmitForm(
          isSubmit,
          extraQueryString,
          formDataObject,
          fromNextOrPrevious
        )
    )();
  };

  const handleGoToNextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (sections?.[step]?.pageComponent) {
      props.goToNextStep(e);
      return;
    }

    if (step <= props.totalSteps - 1) {
      // validate the current step before advancing
      // for custom screens, validation is done at the custom screen level
      const currentStepFields = (sections?.[step]?.fields ?? []).map(
        (field) => field.columnName
      );

      // validate only current section fields
      const isValid = await trigger(currentStepFields);

      props.setWizardStepsState((prev) =>
        prev.map((prevStep) =>
          prevStep.number === step ? { ...prevStep, isValid } : prevStep
        )
      );

      if (!isValid) {
        return;
      }

      await onSave(
        false,
        {
          ...(isTrainingPlanRequest && {
            isSubmit: false,
          }),
        },
        {
          ...(isTrainingPlanRequest
            ? {
                request_type__s: requestType,
              }
            : {}),
        },
        true
      );

      props.goToNextStep(e);
    }
  };

  useEffect(() => {
    setIsRecordDataLoading(true);

    if (objectName && recordName && !recordDetails?.title) {
      configureLayout({
        pageTitle: '',
        enablePadding: false,
        headerTitle: '',
        showHeadingLoader: true,
      });

      callRecordDetailApi(objectName, recordName);
    } else {
      setIsRecordDataLoading(false);
    }
  }, [recordDetails?.title]);

  useEffect(() => {
    if (recordDetails?.lifecycleStage && objectName) {
      fetchAttributeConfig?.(objectName);
    }
  }, [recordDetails?.lifecycleStage]);

  useDeepCompareEffect(() => {
    if (recordDetails) {
      const responseValues = getFormValues(objectDefinition, recordDetails);
      const currentFormState = getValues();

      Object.keys(currentFormState).forEach((fieldName) => {
        currentFormState[fieldName] =
          currentFormState[fieldName] !== null
            ? currentFormState[fieldName]
            : responseValues[fieldName];
      });

      reset({
        ...responseValues,
        ...currentFormState,
        file__a:
          responseValues.file__a ?? currentFormState.file__a ?? undefined,
        attachments__a:
          responseValues.attachments__a ??
          currentFormState.attachments__a ??
          undefined,
      });
    }
  }, [getValues, recordDetails]);

  return (
    <EditScreenView
      {...props}
      {...{
        onCancel,
        attributeConfig: objectDefinition,
        control,
        setValue,
        onSave,
        handleSubmit,
        onFormSubmit,
        watch,
        trigger,
        step,
        sections,
        isSaveModalOpen,
        isWarningModalOpen,
        isSubmitModalOpen,
        toggleWarningModalBtnClick,
        toggleSaveModalBtnClick,
        recordDetails,
        toggleSubmitModalBtnClick,
        isCancelModalOpen,
        toggleCancelModalBtnClick,
        methods,
        recordeDetails: recordDetails ?? {},
        version: recordVersion,
        isLoading: isDataSaved,
        isSubmitting: isDataSubmitted,
        isDataSaved: isDataSaved,
        isRecordDataLoading: isRecordDataLoading,
        showPrompt,
        confirmNavigation,
        cancelNavigation,
        goToNextStep: handleGoToNextStep,
      }}
    />
  );
};
