import { ROUTES } from '@celito.clients/enums';
import { checkIfPublicRoute } from '@celito.clients/utils';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Main from '../screens/main/main.screen';
import ProtectedRoutes from './protected-routes';
import PublicRoutes from './public-routes';

const RouteConfig: React.FC = () => {
  const isPublicRoute = checkIfPublicRoute();

  return (
    <Routes>
      <Route index element={<Main />} />
      <Route
        path={`${ROUTES.MAIN}*`}
        element={isPublicRoute ? <PublicRoutes /> : <ProtectedRoutes />}
      />
    </Routes>
  );
};

export default RouteConfig;
