import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import {
  actionColumnListViewStyles,
  ExportButton,
  FilterPanel,
  useExportBtnStyles,
} from '@celito.clients/list-view-engine';
import {
  CustomButton,
  GridView,
  HeaderActionButtonsContainer,
  Icon,
  Menu,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import {
  MenuButton,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
  Text,
} from '@fluentui/react-components';
import { UserViewPanelWrapper } from 'libs/shared/src/lib/reference-selector/src/components/user-view-panel.component';
import ReactPaginate from 'react-paginate';

import { TrainingMatrixViewProps } from './training-matrix.model';
import classes from './training-matrix.module.css';
import { TrainingMatrixStyles } from './training-matrix.styles';
import { getViewSelectorMenu } from './utils';

export const TrainingMatrixVew = ({
  selectedView,
  updateSelectedView,
  onOneTimeAssignmentButtonClick,
  exportFile,
  isFilterPanelOpen,
  handleFilterButtonClick,
  applyFilters,
  customObjectAttributeDefinitions,
  closeFilterPanel,
  filterableColumns,
  numberOfFilters,
  rowData,
  isLoading,
  currentPageNumber,
  totalItems,
  pageLimit,
  handlePageChange,
  columns,
  groupNames,
  menuOpen,
  setMenuOpen,
  isUserViewPanelOpen,
  userName,
  onDismiss,
  resetFilterPanel,
  setResetFilterPanel,
  resetColumnSizes,
  setResetColumnSizes,
}: TrainingMatrixViewProps) => {
  const { cssVariables } = useTheme();
  const exportBtnClasses = useExportBtnStyles();
  const actionColumnStyles = actionColumnListViewStyles();

  const styles = TrainingMatrixStyles();

  return (
    <div>
      <HeaderActionButtonsContainer>
        <CustomButton
          buttonTitle="Add One Time Assignment"
          onButtonClicked={onOneTimeAssignmentButtonClick}
          buttonType={ButtonTypes.Transparent}
          customStyles={classes.button}
        />
      </HeaderActionButtonsContainer>
      <div className={mergeClasses(styles.headerContainer)}>
        <Text className={styles.headerTitle}>
          {LocalizationString.TRAINING_MATRIX}
        </Text>

        <div className={styles.actionsContainer}>
          <Menu
            closeOnScroll
            open={menuOpen}
            onOpenChange={(_, data) => setMenuOpen(data.open)}
          >
            <MenuTrigger disableButtonEnhancement>
              <MenuButton
                className={mergeClasses(exportBtnClasses.root, classes.menuBtn)}
                data-testid="button-views"
              >
                {selectedView.text}
              </MenuButton>
            </MenuTrigger>
            <MenuPopover
              className={mergeClasses(
                actionColumnStyles.menuPopover,
                classes.menuList
              )}
            >
              <MenuList className={actionColumnStyles.menuList}>
                {getViewSelectorMenu(cssVariables, updateSelectedView)}
              </MenuList>
            </MenuPopover>
          </Menu>
          <ExportButton
            cssObject={cssVariables}
            onClick={exportFile}
            customStyles={exportBtnClasses.root}
          />
          <CustomButton
            leftIcon="Filter"
            buttonType={ButtonTypes.Primary}
            buttonTitle={
              numberOfFilters > 0
                ? `${numberOfFilters} ${LocalizationString.FILTER}`
                : LocalizationString.FILTER
            }
            onButtonClicked={handleFilterButtonClick}
            customStyles={exportBtnClasses.root}
          />
        </div>
      </div>

      <FilterPanel
        isFilterPanelOpen={isFilterPanelOpen}
        closeFilterPanel={closeFilterPanel}
        objectName={''}
        applyFilters={applyFilters}
        filterableColumns={filterableColumns}
        objectMetadata={customObjectAttributeDefinitions}
        resetFilterPanel={resetFilterPanel}
        setResetFilterPanel={setResetFilterPanel}
      />

      <div>
        <GridView
          columns={columns}
          items={rowData}
          disableSelection={true}
          disableDefaultSorting={true}
          enableShimmer={isLoading}
          groupNames={groupNames}
          resetColumnSizes={resetColumnSizes}
          setResetColumnSizes={setResetColumnSizes}
        />
        {totalItems > pageLimit && (
          <div className={classes.bottomContainer}>
            <div className={classes.pagination} style={{ ...cssVariables }}>
              <ReactPaginate
                breakLabel="..."
                nextLabel={<Icon iconName="ChevronRight16Regular" />}
                previousLabel={<Icon iconName="ChevronLeft16Regular" />}
                forcePage={currentPageNumber - 1}
                pageCount={Math.ceil(totalItems / pageLimit)}
                pageRangeDisplayed={pageLimit}
                activeClassName={classes.active}
                disabledClassName={classes.disabled}
                onPageChange={({ selected }) => {
                  handlePageChange(selected);
                }}
              />
            </div>
          </div>
        )}
      </div>

      {isUserViewPanelOpen && userName && (
        <UserViewPanelWrapper
          isOpen={isUserViewPanelOpen}
          label={LocalizationString.INDIVIDUAL_USERS}
          name={userName}
          onDismiss={onDismiss}
        />
      )}
    </div>
  );
};
