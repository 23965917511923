import { ButtonTypes } from '@celito.clients/enums';
import { Container, CustomButton, Stack } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { Text } from '@fluentui/react-components';
import cn from 'classnames';
import { styles } from 'libs/core/src/providers/toast-provider/styles';
import { Loader } from 'libs/shared/src/lib/loader/src';
import React from 'react';

import { userCreateType, userTransformType } from './user.model';
import classes from './user.module.css';
import { userModuleStyles } from './user.module.styles';

const GridLayout: React.FC<{
  formDataList: Array<userTransformType>;
}> = ({ formDataList }) => {
  return (
    <div className={classes.cards}>
      {formDataList.map((item: userTransformType) => {
        return (
          <Stack className={classes.card}>
            <Text className={classes.label}>{item.label}</Text>
            {item.customElem ? (
              item.customElem
            ) : (
              <Text className={classes.value}>{item?.value || ''}</Text>
            )}
          </Stack>
        );
      })}
    </div>
  );
};

const UserView: React.FC<userCreateType> = ({
  userData,
  transformFormData,
  isUserDataLoading,
  isDataUpdating,
  navigateBackRoute,
  onBtnClick,
  navigateBack,
  canUserEditData,
  isDepartmentLoading,
  isManagerLoading,
}) => {
  const { cssVariables } = useTheme();

  const styles = userModuleStyles();

  return (
    <Container
      showBackBtn
      navigateBackRoute={navigateBackRoute}
      name={userData?.label}
      style={{ ...cssVariables }}
      className={classes.container}
    >
      {!isUserDataLoading && !isDepartmentLoading && !isManagerLoading ? (
        <>
          <Stack>
            <GridLayout
              formDataList={userData ? transformFormData(userData) : []}
            />
          </Stack>

          <Stack className={classes.footer}>
            <CustomButton
              dataTestId="button-cancel-user-list"
              customStyles={classes.btn_container}
              buttonType={ButtonTypes.Ghost}
              buttonTitle="Cancel"
              onButtonClicked={navigateBack}
            />
            <CustomButton
              customStyles={cn(classes.btn_container, {
                [classes.btn_disabled]: !canUserEditData,
              })}
              leftIcon="Save"
              disabled={!canUserEditData}
              buttonType={ButtonTypes.Primary}
              buttonTitle={isDataUpdating ? 'Saving...' : 'Save'}
              onButtonClicked={onBtnClick}
            />
          </Stack>
        </>
      ) : (
        <Stack className={styles.loader}>
          <Loader />
        </Stack>
      )}
    </Container>
  );
};

export default UserView;
