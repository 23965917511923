import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export type TAction = {
  name: string;
  label: string;
  onClick: () => void;
  iconName?: string;
};

export type TLayoutContext = {
  contextMenuActions: Array<TAction>;
  setContextMenuActions: Dispatch<SetStateAction<Array<TAction>>>;
};

const DocumentTypeLayoutContext = createContext<TLayoutContext>({
  contextMenuActions: [],
  setContextMenuActions: () => null,
});

export const DocumentTypeLayoutProvider = DocumentTypeLayoutContext.Provider;

export const useDocumentTypeLayoutContext = () => {
  const context = useContext(DocumentTypeLayoutContext);
  if (!context) throw new Error('Not Used Within the Provider');
  return context;
};
