import { useEffect, useState } from 'react';

import { QuickFilterProps } from './quick-filter.model';
import QuickFilterView from './quick-filter.view';

const QuickFilterController: React.FC<QuickFilterProps> = (props) => {
  const { filters, selectedFilter, setSelectedFilterHandler } = props;
  const [selectedTabId, setSelectedTabId] = useState<string | null>(
    selectedFilter
  );

  useEffect(() => {
    setSelectedTabId(selectedFilter);
  }, [selectedFilter]);

  const onTabSelect = (tabId: string) => {
    setSelectedTabId(tabId);
    setSelectedFilterHandler(tabId);
  };

  return (
    <QuickFilterView
      filters={filters}
      selectedTabId={selectedTabId}
      onTabSelect={onTabSelect}
    />
  );
};

export default QuickFilterController;
