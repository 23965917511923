import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { errorToast } from '@celito.clients/utils';
import { useEffect, useRef, useState } from 'react';
import type { FieldValues, Path, PathValue } from 'react-hook-form';

import { FileUploadButtonProps } from './file-upload.model';
import FileUploadView from './file-upload.view';

export const FileUploadController = <T extends FieldValues>(
  props: FileUploadButtonProps<T>
) => {
  const { setValue, attribute, fileName: file = '', watch } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileRef = useRef<File>();
  const [fileName, setFileName] = useState<string>(props?.fileName ?? '');
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const allowedFileExtensions = props?.allowedExtensions?.join(',') ?? '*';

  const notAllowedFileExtensions = (
    (props?.notAllowedExtensions as string[]) ?? []
  ).join(',');

  const handleDragEnter = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isValidFileUploaded = (file: File) => {
    const fileExtension = file.name.split('.')?.pop() ?? '';
    if (notAllowedFileExtensions.includes(`.${fileExtension}`)) return false;
    if (allowedFileExtensions === '*') {
      return true;
    }
    return allowedFileExtensions.includes(`.${fileExtension}`);
  };

  const onFileUpload = (file: File) => {
    if (isValidFileUploaded(file)) {
      if (props.maxFileSizeInBytes && file.size > props.maxFileSizeInBytes) {
        errorToast({
          message: `File size exceed the maximum limit of ${
            props.maxFileSizeInBytes / 1024 / 1024 / 1024
          } GB`,
        });
        return;
      }

      props.onFileSelect(file);
      setFileName(file.name);
      setIsDragging(false);
      fileRef.current = file;
    } else {
      errorToast({ message: 'Invalid file type selected' });
      setIsDragging(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: React.DragEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e?.dataTransfer?.files?.[0]) {
      const draggedFile = e.dataTransfer.files[0];
      if (draggedFile) {
        onFileUpload(draggedFile);
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      onFileUpload(selectedFile);
    }
  };

  const handleButtonClick = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.value = '';
    fileInputRef.current?.click();
  };

  // Function to remove the selected file
  const removeFile = () => {
    if (setValue) {
      setValue(
        `${attribute.name}` as Path<T>,
        undefined as PathValue<T, Path<T>>
      ); // Reset the value of the file input
      if (
        props.mode === FormEngineModeTypeEnum.Edit ||
        props.mode === FormEngineModeTypeEnum.Create
      ) {
        if (props.documentId) {
          setValue(
            `deleted_${attribute.name}` as Path<T>,
            [props.documentId] as PathValue<T, Path<T>>
          );
        }
      }
    }
    setFileName('');
    props.onFileSelect(null);
  };

  const closeDeleteAlert = (deleteFile = false) => {
    if (deleteFile) {
      removeFile();
    }
    setShowDeleteAlert(false);
  };

  const handleFileView = () => {
    try {
      if (fileRef.current) {
        const imageOrPDFRegex = /^(image\/|application\/pdf)/;
        const url = URL.createObjectURL(fileRef.current);
        if (imageOrPDFRegex.test(fileRef.current.type)) {
          window.open(url, '_blank');
        } else {
          const anchor = document?.createElement('a');
          anchor.href = url;
          anchor.download = props.fileName ?? 'file';
          anchor.click();
        }
      }
    } catch (error) {
      errorToast({ message: `${error}` });
    }
  };

  useEffect(() => {
    if (file && setValue && props.watch?.(props.attribute.name as Path<T>)) {
      setFileName(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <FileUploadView<T>
      {...{
        ...props,
        fileName: fileName,
        fileObj: watch?.(props.attribute.name as Path<T>),
        handleFileChange,
        handleButtonClick,
        handleDrop,
        isDragging,
        handleDragEnter,
        handleDragLeave,
        handleDragOver,
        removeFile,
        handleFileView,
        allowedFileExtensions,
        showDeleteAlert,
        closeDeleteAlert,
        setShowDeleteAlert,
      }}
      ref={fileInputRef as React.ForwardedRef<HTMLUListElement>}
    />
  );
};
