import { Icon, InHouseInputSelect } from '@celito.clients/shared';
import { ToggleButton } from '@fluentui/react-components';

import { SortfacetViewProps } from './sort-facet.model';
import classes from './sort-facet.module.css';

const SortFacetView: React.FC<SortfacetViewProps> = (props) => {
  const {
    sortOptions,
    selectedSortOptions,
    selectedSortList,
    onSortUpdationHandler,
    onSortChange,
  } = props;

  return (
    <div className={classes.sort_section}>
      <InHouseInputSelect
        selectedOptions={selectedSortOptions}
        onOptionSelect={(ev, data) => {
          onSortUpdationHandler(
            data.optionValue === 'Relevancy' ? '' : data.optionValue,
            selectedSortList?.[0]?.isAscending
          );
          onSortChange(data.optionValue, data.optionText);
        }}
        isDropdownOnly
        dontSortOptions
        label={''}
        options={sortOptions}
      ></InHouseInputSelect>
      {selectedSortOptions.text !== 'Relevancy' && (
        <ToggleButton
          checked={selectedSortList?.[0]?.isAscending}
          icon={
            selectedSortList?.[0]?.isAscending ? (
              <Icon iconName="ArrowSortDownLines20Filled" />
            ) : (
              <Icon iconName="ArrowSortDownLines20Regular" />
            )
          }
          appearance="subtle"
          onClick={() =>
            onSortUpdationHandler(
              selectedSortOptions.value,
              !selectedSortList?.[0].isAscending
            )
          }
          className={classes.sort_button}
        />
      )}
    </div>
  );
};

export default SortFacetView;
