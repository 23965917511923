import React from 'react';

import { Notification } from '../../in-app-notifications.model';
import InAppNotificationsListController from './in-app-notifcations-list.controller';

interface InAppNotificationsListComponentProps {
  notifications: Notification[];
  readNotification: (id: number) => Promise<void>;
  removeNotification: (id: number) => Promise<void>;
  handleRemove: (id: number) => void;
  hasMoreNotifications: boolean;
  loadMoreNotifications: () => void;
  onDismissInAppNotifPanel: () => void;
  isNotificationsLoading: boolean;
  isRemoveAllNotifsLoading: boolean;
  refreshNotifications: () => Promise<void>;
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
}

export const InAppNotificationsListComponent: React.FC<
  InAppNotificationsListComponentProps
> = ({
  notifications,
  readNotification,
  removeNotification,
  handleRemove,
  loadMoreNotifications,
  hasMoreNotifications,
  onDismissInAppNotifPanel,
  isNotificationsLoading,
  isRemoveAllNotifsLoading,
  refreshNotifications,
  scrollableContainerRef,
}) => {
  return (
    <InAppNotificationsListController
      notifications={notifications}
      readNotification={readNotification}
      removeNotification={removeNotification}
      handleRemove={handleRemove}
      loadMoreNotifications={loadMoreNotifications}
      hasMoreNotifications={hasMoreNotifications}
      onDismissInAppNotifPanel={onDismissInAppNotifPanel}
      isNotificationsLoading={isNotificationsLoading}
      isRemoveAllNotifsLoading={isRemoveAllNotifsLoading}
      refreshNotifications={refreshNotifications}
      scrollableContainerRef={scrollableContainerRef}
    />
  );
};
