import { InferValueFromFieldsSchema } from '@celito.clients/types';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldValues, Path, PathValue } from 'react-hook-form/dist/types';

import { ControlledRadioGroupProps } from './controlled-radio-group.model';
import { ControlledRadioGroupView } from './controlled-radio-group.view';

const sortInferFields = (fields?: InferValueFromFieldsSchema[]) => {
  if (!fields) {
    return [];
  }
  return fields.sort((a, b) => a.orderOfConsideration - b.orderOfConsideration);
};
// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const ControlledRadioGroupController = <T extends FieldValues>(
  props: ControlledRadioGroupProps<T>
) => {
  const { watch, attribute } = props;

  const [inferredValue, setInferredValue] = useState<boolean | null>(null);
  const {
    formState: { dirtyFields },
  } = useFormContext();

  const fieldsToInfer = useMemo(() => {
    return attribute?.inferValueFromFields
      ? sortInferFields(attribute.inferValueFromFields)
      : [];
  }, [attribute?.inferValueFromFields]);

  const fiedsToInferValuesString = JSON.stringify(
    watch?.(fieldsToInfer?.map?.((field) => field.fieldName) as Path<T>[]) ?? []
  );

  useEffect(() => {
    // Infer values whenever dependencies change
    const fetchInferredValue = async () => {
      if (!fieldsToInfer?.length) {
        return;
      }

      let inferredValue = null;
      for (const inferField of fieldsToInfer) {
        const fieldValue = watch?.(inferField.fieldName as Path<T>);

        if (!fieldValue) {
          continue;
        }

        inferredValue = fieldValue?.[inferField.lookUpColumnName];
        break;
      }

      const fallbackValue = (() => {
        if (inferredValue === null) {
          const areAnyInferFieldsDirty = Object.keys(dirtyFields).some(
            (field: string) =>
              fieldsToInfer.map((field) => field.fieldName).includes(field)
          );

          if (areAnyInferFieldsDirty) {
            const allFieldsNull = fieldsToInfer.every(
              (field) => watch?.(field.fieldName as Path<T>) === null
            );

            return allFieldsNull ? null : false;
          }
        }
        return inferredValue;
      })();

      setInferredValue(fallbackValue);
      props?.setValue?.(
        attribute.name as Path<T>,
        fallbackValue as PathValue<T, Path<T>>
      );
    };

    fetchInferredValue();
  }, [fieldsToInfer, fiedsToInferValuesString, dirtyFields, watch]);

  return <ControlledRadioGroupView {...props} inferredValue={inferredValue} />;
};
