import { DateFormat } from '@celito.clients/enums';
import { SortConfig } from '@celito.clients/list-view-engine';
import { formatDate } from '@celito.clients/utils';
import {
  ColumnData,
  RowItem,
} from 'libs/shared/src/lib/grid-view-new/src/types';

import { DelegateTypes } from '../delegation-list.model';
import ActionMenu from './action-menu';
import styles from './list-util.module.css';

const checkPrimeNumber = (num: number) => {
  for (let i = 2; i < num; i++) {
    if (num % i === 0) {
      return false;
    }
  }
  return num > 1;
};

export const getColumns = (options?: {
  addSortingIcons?: boolean;
  addLinkRow?: boolean;
  addActionsRow?: boolean;
  hasSubrows?: boolean;
  onDeleteButtonClicked: (item: RowItem) => void;
  onColumnClick: ColumnData['onColumnClick'];
}): ColumnData[] => {
  let defaultColumns: ColumnData[] = [
    {
      key: 'label',
      name: 'Record ID',
      fieldName: 'label',
      minWidth: 100,
      maxWidth: 220,
      isResizable: true,
      onColumnClick: options?.onColumnClick,
    },
    {
      key: 'primaryUserLabel',
      name: 'Delegated From',
      fieldName: 'from',
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
      onColumnClick: options?.onColumnClick,
    },
    {
      key: 'delegatedUserLabel',
      name: 'Delegated To',
      fieldName: 'to',
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
      onColumnClick: options?.onColumnClick,
    },
    {
      key: 'startAtUtc',
      name: 'Start Date',
      fieldName: 'startdate',
      minWidth: 100,
      maxWidth: 220,
      isResizable: true,
      disableAlphanumericSorting: true,
      onColumnClick: options?.onColumnClick,
    },
    {
      key: 'endAtUtc',
      name: 'End Date',
      fieldName: 'enddate',
      minWidth: 100,
      maxWidth: 220,
      isResizable: true,
      disableAlphanumericSorting: true,
      onColumnClick: options?.onColumnClick,
    },
    {
      key: 'justification',
      name: 'Justification',
      fieldName: 'justification',
      minWidth: 100,
      maxWidth: 220,
      isResizable: true,
      showTooltip: true,
      onColumnClick: options?.onColumnClick,
    },
  ];

  if (options?.addActionsRow) {
    defaultColumns.push({
      key: 'actions',
      name: 'Actions',
      fieldName: 'actions',
      minWidth: 100,
      maxWidth: 250,
      isResizable: false,
      onRender: (item) => (
        <div className={styles.actionsContainer}>
          <ActionMenu
            item={item}
            onDeleteClick={() => options.onDeleteButtonClicked(item)}
          />
        </div>
      ),
    });
  }

  if (options?.addSortingIcons) {
    defaultColumns = defaultColumns.map((column) => {
      if (column.fieldName === 'id') {
        return { ...column, isSorted: true, showSortIconWhenUnsorted: true };
      }
      return {
        ...column,
        showSortIconWhenUnsorted: true,
      };
    });
  }

  return defaultColumns;
};

export const getItems = (
  delegations: DelegateTypes[],
  options?: {
    addLinkRow?: boolean;
    addActionsRow?: boolean;
    addSubrows?: boolean;
  }
) => {
  const items: RowItem[] = [];

  for (let i = 0; i < delegations.length; i++) {
    const item: RowItem = {
      key: delegations[i].name,
      from: delegations[i].primaryUserLabel,
      to: delegations[i].delegatedUserLabel,
      startdate: formatDate(delegations[i].startAtUtc, DateFormat.Date),
      enddate:
        delegations[i].endAtUtc === null
          ? null
          : formatDate(delegations[i].endAtUtc, DateFormat.Date),
      startAtUtcdate: delegations[i].startAtUtc,
      endAtUtcdate: delegations[i].endAtUtc,
      categoryName: checkPrimeNumber(i) ? 'Prime' : 'Non Prime',
      primaryUserName: delegations[i].primaryUserName,
      delegatedUserName: delegations[i].delegatedUserName,
      isActive: delegations[i].isActive,
      justification: delegations[i].justification,
      label: delegations[i].label,
    };

    if (options?.addActionsRow) {
      item.actions = 'Actions';
    }

    items.push(item);
  }
  return items;
};

export const defaultSortConfig: SortConfig = {
  attribute: 'createdAtUtc',
  order: 'DESC',
};
