import React from 'react';

export interface WorkflowActionContextProps {
  onActionClick: (updatedVersion: string) => void;
  onTaskActionClick: (updatedVersion: string) => void;
}
const WorkflowActionContext = React.createContext<WorkflowActionContextProps>({
  onActionClick: (updatedVersion: string) => {},
  onTaskActionClick: (updatedVersion: string) => {},
});

export default WorkflowActionContext;
