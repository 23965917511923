import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledDatePickerProps } from './controlled-datepicker.model';
import { ControlledDatePickerView } from './controlled-datepicker.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const ControlledDatePickerController = <T extends FieldValues>(
  props: ControlledDatePickerProps<T>
) => {
  const transformMaxDate = () => {
    const maxDate = props.attribute.rules?.find(
      (data) => data.type === 'max'
    )?.value;

    if (!maxDate) return undefined;

    if (maxDate === '@today') return new Date(Date.now());

    return new Date(maxDate as string);
  };

  const tranformMinDate = () => {
    const minDate = props.attribute.rules?.find(
      (data) => data.type === 'min'
    )?.value;

    if (!minDate || minDate === '@today') return new Date(Date.now());

    const a = new Date(minDate as string);

    return a;
  };

  return (
    <ControlledDatePickerView
      {...{ ...props, transformMaxDate, tranformMinDate }}
    />
  );
};
