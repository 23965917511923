import { ZIndex } from '@celito.clients/theme';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const documentTemplateStyles = makeStyles({
  loaderContainer: {
    height: '100%',
    width: '100%',
    display: 'grid',
  },
  stackContainer: {
    ...shorthands.gap('50px'),
    width: '100%',
  },
  actionsContainer: {
    display: 'flex',
    ...shorthands.gap('8px'),
  },
  textField: {
    ...shorthands.flex(1),
    backgroundColor: Color.white,
    ...shorthands.borderRadius('5px'),
    height: '40px',
    width: '100%',
  },

  textFieldError: {
    ...shorthands.border('1px', 'solid', Color.error_color),

    '&:after': {
      ...shorthands.border('1px', 'solid', Color.error_color),
    },
  },

  field: {
    // fontSize: '1rem',
    width: '100%',
  },

  mergeField: {
    ...shorthands.flex(1),
    '& > div:nth-child(1)': {
      marginRight: '16px',
    },
  },

  copyButton: {
    marginBottom: '4px',
    marginLeft: '12px',
  },

  documentUploadContainer: {
    width: '66%',
    marginBlockStart: '20px',
  },

  displayView: {
    display: 'flex',
    flexDirection: 'column',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },

  row: {
    display: 'flex',
    width: '100%',
    ...shorthands.gap('8px'),
  },

  smallSizeContainer: {
    width: '33%',
  },

  mediumSizeContainer: {
    width: '66%',
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('1rem'),
    ...shorthands.padding('1.625rem'),
  },
  footer: {
    display: 'flex',
    alignItems: 'end',
    marginTop: 'auto',
    width: '100%',
    alignSelf: 'end',
    justifyContent: 'space-between',
    position: 'sticky',
    bottom: 0,
    left: 0,
    backgroundColor: 'var(--gray-1300)',
    boxShadow: '0px 1px 0px 0px var(--gray-200) inset',
    zIndex: ZIndex.Docked,
    ...shorthands.padding('1rem', '1.625rem'),
  },
});
