export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum HTTPHeader {
  ACCEPT = 'Accept',
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization',
  ALLOW_ORIGIN = 'Access-Control-Allow-Origin',
}

export enum HTTPContentType {
  APPLICATION_JSON = 'application/json',
  TEXT_HTML = 'text/html',
  MULTIPART = 'multipart/form-data',
  ALLOW_ORIGIN = '*',
}

export enum HTTPStatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  REQUEST_TIMEOUT = 408,
  UNSUPPORTED_MEDIA_TYPE = 415,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}
