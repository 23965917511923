import {
  DeleteIcon,
  LocalizationString,
  WarningIcon,
} from '@celito.clients/assets';
import {
  ActionTypeEnum,
  CustomViewTypeEnum,
  ObjectEnum,
  ROUTES,
} from '@celito.clients/enums';
import {
  useActiveModule,
  useUpdateInitiationConfirmationModal,
  useURLParams,
} from '@celito.clients/hooks';
import {
  DelegationModal,
  deleteObject,
  LifeCycleStageUserActionsPermissionEnum,
  RecordAction,
  triggerWorkflow,
} from '@celito.clients/list-view-engine';
import { ModalContext } from '@celito.clients/provider';
import {
  ConfirmDialog,
  FluentIconNames,
  Icon,
  Menu,
} from '@celito.clients/shared';
import { ObjectActionDefinition } from '@celito.clients/types';
import {
  createTestAttribute,
  infoToast,
  isExistInUrl,
  raiseErrorToast,
  successToast,
} from '@celito.clients/utils';
import {
  Button,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { contextMenuStyles } from './context-menu-actions-component.styles';

interface ContextMenuProps {
  recordName: string;
  actions: ObjectActionDefinition[];
  version: string;
  objectName: string;
  response: any;
  refetchRecordData?: () => Promise<void>;
  objectLabel?: string;
}

export const ContextMenu = (props: ContextMenuProps): JSX.Element | null => {
  const { recordName, objectName, response, refetchRecordData, objectLabel } =
    props;
  const [openDelegateModal, setOpenDelegateModal] = useState(false);
  const newStyles = contextMenuStyles();
  const navigate = useNavigate();
  const { urlParams } = useURLParams();
  const taskName = urlParams.get('taskName');
  const activeModule = useActiveModule();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const isUnsubmittedControlledDocsListView =
    isExistInUrl(CustomViewTypeEnum.UNSUBMITTED_CONTROL_DOCS_LIST_VIEW) ||
    isExistInUrl('cd_unsubmitted_controlled_documents__a');

  const isULModule = window.location.pathname.includes('lms__a');
  const { openModal } = useContext(ModalContext);
  const updateInitiationConfirmationProps =
    useUpdateInitiationConfirmationModal({
      objectName: objectName as ObjectEnum,
    });

  const {
    showUpdateInititationConfirmationModal,
    setShowUpdateInititationConfirmationModal,
    handleOnUpdateInitiationConfirmation,
    handleOnUpdateInitiate,
    loading,
  } = updateInitiationConfirmationProps;

  const openDeleteAlert = () => {
    setShowDeleteAlert(true);
  };
  const openEsignModal = (
    recordData?: Record<string, unknown>,
    contextMenuAction?: Record<string, unknown>,
    taskPayload?: Record<string, unknown>
  ) => {
    openModal(
      '',
      taskPayload,
      activeModule?.link,
      recordData,
      contextMenuAction
    );
  };
  const callUserActions = async (action: RecordAction) => {
    if (action.actionType?.toLowerCase() === 'delete') {
      openDeleteAlert();
      return;
    }

    if (action.actionType?.toLowerCase() === 'delegate') {
      setOpenDelegateModal(true);
      return;
    }

    const objectname = action?.objectName;
    const version = response?.version;

    if (action.actionType === ActionTypeEnum.ACTIVATE && recordName) {
      infoToast({ message: `Activating ${response?.title ?? recordName}` });
      await callTriggerWorkflow(
        action.name,
        recordName,
        version,
        action?.actionType,
        true,
        response?.title
      );

      return;
    }

    if (action.actionType === ActionTypeEnum.DEACTIVATE && recordName) {
      infoToast({
        message: `Deactivating ${response?.title ?? recordName}`,
      });
      await callTriggerWorkflow(
        action.name,
        recordName,
        version,
        action?.actionType,
        true,
        response?.title
      );

      return;
    }

    if (
      [ActionTypeEnum.TRIGGER_WORKFLOW].includes(
        action.actionType as ActionTypeEnum
      ) &&
      recordName &&
      version
    ) {
      callTriggerWorkflow(action.name, recordName, version, action.actionType!);
      openEsignModal(response, action as unknown as Record<string, unknown>);
      return;
    }

    if (
      action.actionType === ActionTypeEnum.LIST &&
      action.label?.toLowerCase()?.includes('audit')
    ) {
      navigate(
        `../${action.actionType?.toLowerCase()}/${
          action.viewName
        }?recordname=${recordName}&objectname=${objectName}`
      );
      return;
    }

    if (
      action.actionType?.toLowerCase() === 'list' &&
      recordName &&
      objectname
    ) {
      navigate(
        `../${action.actionType?.toLowerCase()}/${
          action.viewName
        }?recordname=${recordName}&objectname=${objectname}`
      );
      return;
    }

    if (action.actionType?.toLowerCase() === 'custom') {
      if (action.name === 'complete_course_action__a') {
        navigate(
          `../${ROUTES.DOCUMENT_VIEW}?training_assignment_name=${recordName}&openCompleteModal=true`
        );
        return;
      }
    }

    if (action.actionType?.toLowerCase() === 'customview') {
      if (response?.detailViewName?.includes?.('custom/')) {
        navigate(
          `../view/${response?.detailViewName}/${recordName}?taskName=${taskName}`
        );
        return;
      }
      navigate(
        `../customview/${response?.detailViewName}/${recordName}?taskName=${taskName}`
      );
      return;
    }

    if (action.actionType === ActionTypeEnum.UPDATE) {
      handleOnUpdateInitiate(response ?? {}, action.viewName!);
      setShowUpdateInititationConfirmationModal(true);

      return;
    }

    if (isULModule && response?.version) {
      navigate(
        `../${action.actionType?.toLowerCase()}/${
          action.viewName
        }/${recordName}${
          response?.version ? `?version=${response?.version}` : ''
        }`
      );
      return;
    }

    if (
      action.actionType === ActionTypeEnum.VIEW ||
      action.actionType === ActionTypeEnum.EDIT
    ) {
      if (taskName) {
        navigate(
          `../${action.actionType?.toLowerCase()}/${
            action.viewName
          }/${recordName}?taskName=${taskName}${
            response?.initiationType
              ? `&requestType=${response?.initiationType}&version=${response?.version}`
              : ''
          }`
        );
        return;
      }
      navigate(
        `../${action.actionType?.toLowerCase()}/${
          action.viewName
        }/${recordName}${
          response?.initiationType
            ? `?requestType=${response?.initiationType}&version=${response?.version}`
            : ''
        }`
      );
      return;
    }

    navigate(
      `../${action.actionType?.toLowerCase()}/${action.viewName}/${recordName}${
        response?.initiationType
          ? `?requestType=${response?.initiationType}`
          : ''
      }`
    );
  };

  const callTriggerWorkflow = async (
    actionName: string,
    recordName: string,
    version: string,
    actionType: string,
    shouldRefetch = false,
    title = ''
  ) => {
    try {
      await triggerWorkflow(actionName, recordName, version);
      if (actionType === ActionTypeEnum.DEACTIVATE) {
        successToast({
          message: `Deactivated ${title ?? recordName}`,
        });
      }
      if (actionType === ActionTypeEnum.ACTIVATE) {
        successToast({
          message: `Activated ${title ?? recordName}`,
        });
      }
      if (shouldRefetch) {
        refetchRecordData?.();
      }
    } catch (_error) {
      raiseErrorToast(_error);
    }
  };
  const closeDelegateModal = () => {
    setOpenDelegateModal(false);
  };

  const deleteRecord = () => {
    deleteObject(props.objectName, recordName)
      .then(() => {
        successToast({
          message: isUnsubmittedControlledDocsListView
            ? LocalizationString.DOCUMENT_INITIATION_DELETED
            : LocalizationString.DELETED_SUCCESSFULLY,
        });
        navigate(-1);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  };

  const closeDeleteAlert = (deleteRec = false) => {
    if (deleteRec) {
      deleteRecord();
    }
    setShowDeleteAlert(false);
  };

  const delegationModalRowItem = useMemo(
    () => ({
      ...props?.response,
      name: taskName,
    }),
    [props?.response, taskName]
  );

  return (
    <>
      <ConfirmDialog
        open={showDeleteAlert}
        onCancelClicked={(e) => {
          e.stopPropagation();
          closeDeleteAlert();
        }}
        onConfirmClicked={(e) => {
          e.stopPropagation();
          closeDeleteAlert(true);
        }}
        primaryButtonText={LocalizationString.DELETE}
        title={LocalizationString.DELETE_INITIATION}
        iconSrc={DeleteIcon}
        description={
          isUnsubmittedControlledDocsListView
            ? LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_DELETE_DOC_INITIATION
            : LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_DELETE
        }
        secondaryButtonText={LocalizationString.CANCEL}
        isBlocking
      />
      <ConfirmDialog
        dataTestId={`${createTestAttribute(
          LocalizationString.UPDATE_INITIATION_CONFIRMATION
        )}-modal`}
        open={showUpdateInititationConfirmationModal}
        onCancelClicked={() => setShowUpdateInititationConfirmationModal(false)}
        onConfirmClicked={handleOnUpdateInitiationConfirmation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UPDATE_INITIATION_CONFIRMATION}
        iconSrc={WarningIcon}
        description={LocalizationString.UPDATE_INITIATION_CONFIRMATION_MSG.replace(
          '{objectName}',
          objectLabel ?? 'record'
        )}
        isPrimaryButtonLoading={loading}
      />
      <Menu>
        <MenuTrigger>
          <Button
            className={newStyles.menuButton}
            data-testid={`button-more-action`}
          >
            <Icon iconName="MoreHorizontal24Regular" />
          </Button>
        </MenuTrigger>
        <MenuPopover>
          <MenuList className={newStyles.menuList}>
            {props?.actions.map((action, index) => (
              <MenuItem
                key={index}
                disabled={
                  action?.permission ===
                  LifeCycleStageUserActionsPermissionEnum.VIEW
                }
                onClick={() => callUserActions(action)}
                data-testid={`context-menu-option-${createTestAttribute(
                  action.label
                )}`}
                className={newStyles.actionMenuItem}
              >
                <div className={newStyles.itemContent}>
                  <Icon iconName={action.iconName as FluentIconNames} />
                  {action.label}
                </div>
              </MenuItem>
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
      <DelegationModal
        isOpen={openDelegateModal}
        onClose={closeDelegateModal}
        rowItem={delegationModalRowItem}
        objectName={props?.objectName}
      />
    </>
  );
};
