import { raiseErrorToast } from '@celito.clients/utils';
import { PopoverProps } from '@fluentui/react-components';
import { useEffect, useState } from 'react';

import { getWorkflowHistory } from '../workflow-history/workflow-actions/services';
import { WizardStateComponentProps } from './wizard-state.model';
import WizardStateView from './wizard-state.view';

export const WizardStateController = (props: WizardStateComponentProps) => {
  const [open, setOpen] = useState(false);
  const [taskGroupNames, setTaskGroupNames] = useState<string[]>([]);
  const handleOpenChange: PopoverProps['onOpenChange'] = (e, data) => {
    // check if the id's dropdown options and no result options are in view port then keep open the workflow history
    // else let event handle the selection
    const target = e.currentTarget as EventTarget & (HTMLElement | Document);

    if (target instanceof HTMLElement || target instanceof Document) {
      const dropdownWithOptions = target.querySelector(
        '#dropdown-with-options'
      );
      const dropdownWithoutOptions = target.querySelector(
        '#dropdown-with-no-results'
      );
      if (dropdownWithOptions || dropdownWithoutOptions) {
        setOpen(true);
      } else {
        setOpen(data.open || false);
      }
    } else {
      setOpen(data.open || false);
    }
  };
  useEffect(() => {
    getTasks();
  }, [props?.recordData]);

  const getTasks = async () => {
    const groupNames: string[] = [];
    try {
      if (props?.objectName && props?.recordName) {
        const taskList = await getWorkflowHistory(
          props.objectName,
          props.recordName,
          props?.objectDefinitions?.isVersioningEnabled
            ? (props?.recordData?.version as string)
            : ''
        );
        if (taskList?.tasks?.length) {
          taskList?.tasks?.forEach((task) => {
            groupNames.push(task?.objectRecordLifecycleGroup);
          });
          setTaskGroupNames(groupNames);
        }
      }
    } catch (error) {
      raiseErrorToast(error);
    }
  };

  return (
    <WizardStateView
      {...props}
      loading={false}
      handleOpenChange={handleOpenChange}
      open={open}
      taskGroupNames={taskGroupNames}
    />
  );
};
