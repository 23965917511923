import { LocalizationString } from '@celito.clients/assets';
import { raiseErrorToast } from '@celito.clients/utils';
import { OptionProps } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { HookFormProps } from '../../../types';
import ControlledDropdown from '../controlled-dropdown/controlled-dropdown.component';
import { getAllObjectsData } from './services';

type ObjectDefinitionsDropdownProps<T extends FieldValues = FieldValues> =
  HookFormProps<T>;

export const ObjectDefinitionsDropdown = <T extends FieldValues>({
  attribute,
  ...props
}: ObjectDefinitionsDropdownProps<T>): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<OptionProps[]>([]);

  useEffect(() => {
    getAllObjectsData(attribute?.modulesToRestrict)
      .then((r) => {
        setOptions(
          r.map((o) => ({
            value: o.name,
            text: o.label,
          }))
        );
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <ControlledDropdown
      {...{
        ...props,
        attribute: {
          ...attribute,
          placeholder: loading
            ? `${LocalizationString.LOADING}...`
            : attribute.placeholder,
        },
        options: options,
      }}
    />
  );
};
