import React from 'react';

import { TextFieldAreaController } from './text-field-area.controller';
import { TextFieldAreaModel } from './text-field-area.model';

const TextFieldArea: React.FC<TextFieldAreaModel> = (props) => {
  return <TextFieldAreaController {...props} />;
};

export default TextFieldArea;
