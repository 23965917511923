import { DateFormat, ROUTES } from '@celito.clients/enums';
import { formatDate } from '@celito.clients/utils';
import {
  AssignmentData,
  AssignmentDetail,
} from 'libs/shared/src/lib/assignments-list/src/types';
import { getUserNameForUser } from 'libs/shared/src/lib/assignments-list/src/utils';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { Color } from 'libs/theme/src/lib/colors';

export const getUserViewRow = (assignmentData: AssignmentData) => {
  const rowData: RowItem[] = [];

  Object.keys(assignmentData).forEach((user: string) => {
    const userName = getUserNameForUser(assignmentData[user], user);
    assignmentData[user].forEach((assignment: AssignmentDetail) => {
      const courseRowData = getCourseRowConfig(assignment);
      courseRowData.categoryName = userName;
      rowData.push(courseRowData);
    });
  });

  return rowData;
};

export const getUserViewGroupNames = (
  assignmentData: AssignmentData
): string[] => {
  const groupNames: string[] = [];

  Object.keys(assignmentData).forEach((user) => {
    const userName = getUserNameForUser(assignmentData[user], user);
    groupNames.push(userName);
  });

  return groupNames;
};

const getCourseRowConfig = (assignment: AssignmentDetail): RowItem => {
  const course = assignment.course;
  return {
    key: assignment.name,
    course: course.label,
    version: course.version,
    title: course.title,
    status: assignment.status,
    dueDate: assignment.dueDate
      ? formatDate(assignment.dueDate, DateFormat.Date)
      : '-',
    completionDate: assignment.completionDate
      ? formatDate(assignment.completionDate, DateFormat.Date)
      : '-',
    rowBackgroundColor: Color.blue_2100,
    clickable: true,
    onClickNavigateRoute: `../${ROUTES.TAKING_ASSINGMENTS.replace(
      ':name',
      assignment.name
    )}`,
  };
};
