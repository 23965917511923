import { FormEngineModeTypeEnum } from '@celito.clients/enums';

import AddScreen from './add-screen/add-screen.component';
import EditScreen from './edit-screen/edit-screen.component';
import ViewScreen from './view-screen/view-screen.component';
import { WizardContainerComponentProps } from './wizard-container.model';

const WizardContainerView = (props: WizardContainerComponentProps) => {
  const getLayoutonModeBasis = (mode: FormEngineModeTypeEnum) => {
    switch (mode) {
      case FormEngineModeTypeEnum.Create:
        return <AddScreen {...props} showCancelPopup />;
      case FormEngineModeTypeEnum.Edit:
        return <EditScreen {...props} showCancelPopup />;
      case FormEngineModeTypeEnum.Submit:
        return <EditScreen {...props} showCancelPopup />;
      case FormEngineModeTypeEnum.View:
      case FormEngineModeTypeEnum.CustomView:
        return <ViewScreen {...props} />;
      default:
        return <></>;
    }
  };
  return getLayoutonModeBasis(props.mode);
};

export default WizardContainerView;
