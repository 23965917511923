import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { ActionContext } from '@celito.clients/provider';
import { Loader, SplitView } from '@celito.clients/shared';
import { PropsWithChildren } from 'react';

import { CompleteBar } from './components/complete-bar';
import { ContextMenu } from './components/context-menu-actions';
import { FormWizard } from './components/form-wizard/component';
import { LeftPane } from './components/left/component';
import { Lifecycle } from './components/life-cycle/component';
import { ScreenContextProvider } from './context';
import { useController } from './controller';
import { getStyles } from './styles';

interface IDocumentScreenProviderProps extends PropsWithChildren {
  mode: FormEngineModeTypeEnum;
}

export function ControlledDocumentScreenProvider({
  mode,
  children,
}: Readonly<IDocumentScreenProviderProps>) {
  const { checkActions, ...values } = useController(mode);
  const styles = getStyles();

  return (
    <ScreenContextProvider value={values}>
      <ActionContext.Provider value={checkActions}>
        {values.isTaskResponseLoading ? (
          <Loader className={styles.fullPageLoader} />
        ) : (
          children
        )}
      </ActionContext.Provider>
    </ScreenContextProvider>
  );
}

export default function ({ mode }: { mode: FormEngineModeTypeEnum }) {
  const styles = getStyles();
  return (
    <ControlledDocumentScreenProvider mode={mode}>
      <ContextMenu />
      <div className={styles.pageContainer}>
        <CompleteBar />
        <Lifecycle />
        <SplitView
          className={styles.splitView}
          left={<LeftPane />}
          right={<FormWizard />}
        />
      </div>
    </ControlledDocumentScreenProvider>
  );
}
