import { returnSelectedOptionFromOptions } from 'libs/core/src/utils/getSelectedOptionFromOptions';

import { IOption } from '../../rules-component/rules-component.model';
import { InHouseInputSelect } from '../../shared';
import { PicklistProps } from './picklist.model';

interface PicklistViewProps extends PicklistProps {
  options: IOption[];
  isLoading: boolean;
  errorMessage?: string;
}

export const PicklistView = ({
  dataTestId,
  label = '',
  selectedKey,
  options,
  errorMessage,
  isRequired = false,
  size = 'large',
  isLoading,
  multiselect,
  helperLabelText,
  ...props
}: PicklistViewProps): JSX.Element => {
  return (
    <InHouseInputSelect
      virtualized={options?.length > 30}
      dontSortOptions
      label={label}
      errorMessage={errorMessage}
      required={isRequired}
      options={options}
      data-testid={dataTestId}
      isLoadingOptions={isLoading}
      selectedOptions={returnSelectedOptionFromOptions(options, selectedKey)}
      multiselect={multiselect}
      onOptionSelect={(_, data) => {
        let selectedOpt = [];

        if (Array.isArray(data.selectedOptions))
          selectedOpt = data.selectedOptions.map((selected) => selected.value);
        else selectedOpt = [data.selectedOptions.value];

        props.onOptionSelect &&
          props.onOptionSelect(_, { ...data, selectedOptions: selectedOpt });
      }}
      disabled={props.disabled}
      helperLabelText={helperLabelText}
    />
  );
};
