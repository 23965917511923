import {
  CourseObjectAttributeNameEnum,
  OperatorsEnum,
  SmartGroupsObjectAttributeNameEnum,
} from '@celito.clients/enums';
import { FilterAsConditions } from '@celito.clients/types';

export const getFiltersByObject = (
  objectName: string,
  searchKey: string
): FilterAsConditions[] => {
  switch (objectName) {
    case 'controlled_document__a':
      return [
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    attribute: 'title__a',
                    operator: OperatorsEnum.CONTAINS,
                    value: searchKey,
                  },
                  {
                    attribute: 'label__s',
                    operator: OperatorsEnum.CONTAINS,
                    value: searchKey,
                  },
                ],
              },
            ],
          },
        },
      ];
    case 'course__a':
      return [
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    attribute: CourseObjectAttributeNameEnum.TITLE,
                    operator: OperatorsEnum.CONTAINS,
                    value: searchKey,
                  },
                  {
                    attribute: 'label__s',
                    operator: OperatorsEnum.CONTAINS,
                    value: searchKey,
                  },
                  {
                    attribute:
                      CourseObjectAttributeNameEnum.CONTROLLED_DOCUMENT_LABEL,
                    operator: OperatorsEnum.CONTAINS,
                    value: searchKey,
                  },
                ],
              },
              {
                all: [
                  {
                    attribute: 'version',
                    operator: OperatorsEnum.ENDS_WITH,
                    value: '.0',
                  },
                  {
                    attribute: 'isActive',
                    operator: OperatorsEnum.EQUALS,
                    value: true,
                  },
                  {
                    attribute: 'status',
                    operator: OperatorsEnum.EQUALS,
                    value: 'Approved',
                  },
                ],
              },
            ],
          },
        },
      ];
    case 'curriculum__a':
      return [
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    attribute: 'title__a',
                    operator: OperatorsEnum.CONTAINS,
                    value: searchKey,
                  },
                  {
                    attribute: 'label__s',
                    operator: OperatorsEnum.CONTAINS,
                    value: searchKey,
                  },
                ],
              },
              {
                all: [
                  {
                    attribute: 'version',
                    operator: OperatorsEnum.ENDS_WITH,
                    value: '.0',
                  },
                  {
                    attribute: 'isActive',
                    operator: OperatorsEnum.EQUALS,
                    value: true,
                  },
                  {
                    attribute: 'status',
                    operator: OperatorsEnum.EQUALS,
                    value: 'Approved',
                  },
                ],
              },
            ],
          },
        },
      ];
    case 'smart_groups__a':
      return [
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    attribute: SmartGroupsObjectAttributeNameEnum.TITLE,
                    operator: OperatorsEnum.CONTAINS,
                    value: searchKey,
                  },
                  {
                    attribute: 'label__s',
                    operator: OperatorsEnum.CONTAINS,
                    value: searchKey,
                  },
                  {
                    attribute: SmartGroupsObjectAttributeNameEnum.DESCRIPTION,
                    operator: OperatorsEnum.CONTAINS,
                    value: searchKey,
                  },
                ],
              },
              {
                all: [
                  {
                    attribute: 'isActive',
                    operator: OperatorsEnum.EQUALS,
                    value: true,
                  },
                ],
              },
            ],
          },
        },
      ];
    case 'user__s':
      return [
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    any: [
                      {
                        attribute: 'department',
                        operator: OperatorsEnum.EQUALS,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        referenceFilters: [
                          {
                            conditions: {
                              all: [
                                {
                                  all: [
                                    {
                                      all: [
                                        {
                                          attribute: 'label',
                                          operator: OperatorsEnum.CONTAINS,
                                          value: searchKey,
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        attribute: 'label__s',
                        operator: OperatorsEnum.CONTAINS,
                        value: searchKey,
                      },
                    ],
                  },
                  {
                    all: [
                      {
                        attribute: 'is_active__s',
                        operator: OperatorsEnum.EQUALS,
                        value: 'true',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      ];
    default:
      return [];
  }
};

export const getUserFilter = (userNameList: string[]): FilterAsConditions[] => {
  return [
    {
      conditions: {
        all: [
          {
            all: [
              {
                attribute: 'name__s',
                operator: OperatorsEnum.IN,
                value: userNameList,
              },
            ],
          },
        ],
      },
    },
  ];
};

export const getReferenceDocumentsFilter = (
  documents: Record<string, any>[]
): FilterAsConditions[] => {
  return [
    {
      conditions: {
        all: [
          {
            any: documents.map((doc) => ({
              all: [
                {
                  attribute: 'name',
                  operator: OperatorsEnum.EQUALS,
                  value: doc.name,
                },
                {
                  attribute: 'version',
                  operator: OperatorsEnum.EQUALS,
                  value: doc.version,
                },
              ],
            })),
          },
        ],
      },
    },
  ];
};
