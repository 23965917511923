import { DocumentTemplateObjectKeyEnum } from '@celito.clients/enums';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import { FileDataProps } from 'libs/shared/src/lib/file-upload/file-upload.model';
import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';

export type FormValues = {
  label?: string;
  title: string;
  description?: string;
  fieldType: IOption;
  field: string;
  file__a: File | FileDataProps | null;
};

export interface ICopyLabel {
  id: number;
  label: string;
}

export type ObjectItem = {
  value: string;
  text: string;
};

export enum FormMode {
  CREATE = 'create',
  EDIT = 'edit',
}
export type DocumentTemplateScreenProps = {
  mode: FormMode;
};

export interface DocumentTemplateViewProps<T extends FieldValues = FormValues>
  extends DocumentTemplateScreenProps {
  onSubmit: () => Promise<void>;
  handleSubmit: (onSubmit: () => Promise<void>) => void;
  control: Control<FormValues>;
  setValue?: UseFormSetValue<T>;
  isSubmitting: boolean;
  isLoading: boolean;
  showPrompt: boolean;
  confirmNavigation: () => void;
  cancelNavigation: () => void;
  isCancelModalOpen: boolean;
  toggleCancelModalBtnClick: (navigateBack?: boolean) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  toggleSaveModalBtnClick: () => void;
  isSaveModalOpen: boolean;
  isSaving: boolean;
  isDirty: boolean;
  copiedValues: string[];
  copiedValueAction: (action: 'copy' | 'remove', idx: number) => void;
  onCopy: (value: string, copyLabelId: number) => void;
  attributes: Record<DocumentTemplateObjectKeyEnum, ObjectAttributeDefinition>;
}
