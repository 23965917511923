import { LocalizationString } from '@celito.clients/assets';
import { AttributeTypeEnum, ExportFileType } from '@celito.clients/enums';
import { Icon } from '@celito.clients/shared';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import { createTestAttribute } from '@celito.clients/utils';
import {
  MenuItem,
  MenuItemRadio,
  mergeClasses,
} from '@fluentui/react-components';
import { MappingTypeEnum } from 'libs/form-engine/src/lib/components/form-components/controlled-picker/controlled-picker.model';
import { userObjectName } from 'libs/form-engine/src/lib/config';
import { IFilterView } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import { actionColumnListViewStyles } from '../components';
import actionClasses from '../components/action-column/action-column.styles.module.css';
import { ExportItem, ObjectMetadata } from '../types';

const items: ExportItem[] = [
  {
    key: ExportFileType.CSV,
    text: LocalizationString.CSV,
    data: {
      fileType: ExportFileType.CSV,
    },
    iconProps: { iconName: 'DocumentTextExtract20Regular' },
  },
  {
    key: ExportFileType.EXCEL,
    text: LocalizationString.EXCEL,
    data: {
      fileType: ExportFileType.EXCEL,
    },
    iconProps: { iconName: 'DocumentBulletList20Regular' },
  },
  {
    key: ExportFileType.PDF,
    text: LocalizationString.PDF,
    data: {
      fileType: ExportFileType.PDF,
    },
    iconProps: { iconName: 'DocumentBulletList20Regular' },
  },
];

export const getExportBtnMenuConfig = (
  cssVariables: object,
  onClick: (item: ExportItem) => void
) => {
  const styles = actionColumnListViewStyles();

  return items.map((item) => {
    return (
      <MenuItem
        data-testid={`dropdown-${createTestAttribute(item.text)}`}
        className={styles.actionMenuItem}
        style={{ ...cssVariables }}
        onClick={() => onClick(item)}
        key={item.key}
      >
        <div className={styles.itemContent}>
          {item.iconProps && <Icon iconName={item.iconProps.iconName} />}
          {item.text}
        </div>
      </MenuItem>
    );
  });
};

export const getFilterViewsBtnMenuConfig = (
  inputName: string,
  options: IFilterView[],
  cssVariables: object
) => {
  const styles = actionColumnListViewStyles();

  return options.map((item) => {
    return (
      <MenuItemRadio
        key={item.label}
        data-testid={`dropdown-${createTestAttribute(item.label)}`}
        className={mergeClasses(
          actionClasses.btnContainer,
          styles.viewMenuItem
        )}
        style={{ ...cssVariables }}
        name={inputName}
        value={item.label}
      >
        {item.label}
      </MenuItemRadio>
    );
  });
};

export const transformObjectMetadata = (
  objectMetadata: ObjectMetadata
): ObjectMetadata => {
  return {
    ...objectMetadata,
    objectAttributeDefinitions: objectMetadata.objectAttributeDefinitions.map(
      (o) => transformObjectAttributeConfig(o)
    ),
  };
};

const transformObjectAttributeConfig = (
  attributeConfig: ObjectAttributeDefinition
): ObjectAttributeDefinition => {
  const copiedConfig = { ...attributeConfig };

  if (copiedConfig.peoplePickerDropdown) {
    copiedConfig.dataType = AttributeTypeEnum.Reference;
    copiedConfig.relationship = {
      objectName: userObjectName,
      objectMappingType: MappingTypeEnum.OneToMany,
    } as ObjectAttributeDefinition['relationship'];
  }

  return copiedConfig;
};
