import { LocalizationString } from '@celito.clients/assets';
import { DateFormat, FormEngineModeTypeEnum } from '@celito.clients/enums';
import {
  formatDate,
  getDocumentViewUrl,
  isDateValid,
} from '@celito.clients/utils';
import { Link } from 'react-router-dom';

import { ColumnData, RowItem } from '../../../grid-view-new/src/types';
import ActionComponent from '../components/ActionComponent';
import classes from '../reference-documents-preview.module.css';

export const getReferenceDocumentsPreviewGridColumns = (
  onItemDelete: (val: RowItem) => void,
  mode: FormEngineModeTypeEnum,
  isPreviewTab = false
): ColumnData[] => {
  const referenceDocsGridViewColumns: ColumnData[] = [
    {
      name: 'Document ID',
      isResizable: true,
      key: 'column1',
      maxWidth: 200,
      minWidth: 200,
      fieldName: 'label',
      onRender: (item) => (
        <Link
          to={getDocumentViewUrl(item.name, item.version)}
          className={classes.hyperlink}
          target="_blank"
        >
          {item.label}
        </Link>
      ),
    },
    {
      name: 'Title',
      isResizable: true,
      key: 'column2',
      maxWidth: 250,
      minWidth: 150,
      fieldName: 'title',
    },
    {
      name: 'Status',
      isResizable: true,
      key: 'column3',
      maxWidth: 100,
      minWidth: 50,
      fieldName: 'documentStatus',
    },
    {
      name: 'Version',
      isResizable: true,
      key: 'column4',
      maxWidth: 100,
      minWidth: 100,
      fieldName: 'version',
    },
    {
      name: 'Effective Date',
      isResizable: true,
      key: 'column5',
      maxWidth: 250,
      minWidth: 150,
      fieldName: 'effectiveDate',
      onRender: (item) =>
        isDateValid(item?.effectiveDate as string)
          ? formatDate((item?.effectiveDate as string) || '', DateFormat.Date)
          : '-',
      disableAlphanumericSorting: true,
    },
    {
      name: 'Follow Up Due Date',
      isResizable: true,
      key: 'column6',
      maxWidth: 250,
      minWidth: 150,
      fieldName: 'followUpDueDate',
      onRender: (item) =>
        isDateValid(item?.followUpDueDate as string)
          ? formatDate((item?.followUpDueDate as string) || '', DateFormat.Date)
          : '-',
      disableAlphanumericSorting: true,
    },
    {
      name: 'Owner',
      isResizable: true,
      key: 'column7',
      maxWidth: 250,
      minWidth: 150,
      fieldName: 'owner.label',
    },
  ];

  if (mode !== FormEngineModeTypeEnum.View) {
    referenceDocsGridViewColumns.push({
      key: 'actions',
      minWidth: 80,
      maxWidth: 80,
      name: LocalizationString.ACTIONS,
      fieldName: 'actions',
      isResizable: true,
      onRender: (item?: RowItem, index?: number, column?: ColumnData) =>
        ActionComponent({
          item,
          column,
          index,
          onItemDelete,
          isPreviewTab,
        }),
    });
  }

  return referenceDocsGridViewColumns;
};
