import { makeStyles } from '@fluentui/react-components';

export const viewGroupStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
});
