import React from 'react';

import withLayout from '../../../hoc/with-layout';
import AddNewController from './add-user.controller';

const AddNewScreen: React.FC = () => {
  return <AddNewController />;
};

export default withLayout(AddNewScreen);
