import { WizardStepType } from '../enums/wizard-enum';
import { PageComponentEnum } from './../../../form-engine/src/lib/components/wizard-container/page-component/page-component.enum';
import { FilterCriteria } from './filter-criteria-type';
import {
  InferValueFromFieldsSchema,
  LayoutRulesConfigurationSchema,
  LayoutRulesDataSchema,
} from './layout-rules-types';

export interface WizardSectionType {
  viewDto: ViewDto;
}

export interface ViewDto {
  modifiedAtUtc: string;
  createdAtUtc: string;
  isActive: boolean;
  name: string;
  label: string;
  view: View[];
  roles: string[];
  viewType: 'Create' | 'Edit';
}

export enum LayoutType {
  ONE_LAYOUT = 'ONE_LAYOUT',
  TWO_LAYOUT = 'TWO_LAYOUT',
}
export interface View {
  objectName: string;
  sections: Section[];
  actions: string[];
  editViewName: string;
  name: string;
  label: string;
  ownerType: string;
  pageLayout: LayoutType;
  listViewName?: string;
  submitRedirectUrl?: string;
  showCancelPopup?: boolean;
  showSubmitConfirmationPopup: boolean;
  submitConfirmationPopupMessage: string;
}

export interface Section {
  label: string;
  fields: Field[];
  pageComponent: PageComponentEnum | null;
  name: string;
  viewType: string;
  layoutConfiguration?: LayoutRulesConfigurationSchema;
}

export interface SectionState extends Section {
  currentState: WizardStepType;
}

export interface Field {
  columnName: string;
  width: number;
  row?: number;
  layoutConfiguration?: LayoutRulesConfigurationSchema;
  fieldFilterConditions?: {
    filters: FilterCriteria[];
    validationsWhenResponsePayloadIsEmpty: LayoutRulesDataSchema;
    validationsWhenResponsePayloadIsNotEmpty: LayoutRulesDataSchema;
  };
  inferValueFromFields?: InferValueFromFieldsSchema[];
}
