import { DocumentDownloadActionEnum, ObjectEnum } from '@celito.clients/enums';
import { files } from '@celito.clients/services';
import {
  errorToast,
  raiseErrorToast,
  requiresVersionParam,
} from '@celito.clients/utils';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { useScreenContext } from '../../context';
import { useRecordData } from '../../hooks/use-record-data';
import { getDocumentBasedOnType, getDocumentData } from '../../services';
import { DocumentFile } from '../../types';
import { TLeftPaneProps } from './component';

export const useController = (): TLeftPaneProps => {
  const { recordName, version } = useScreenContext();
  const [noPreviewText, setNoPreviewText] = useState(false);

  const { data: record } = useRecordData();

  const {
    data: documentDocs,
    isLoading,
    isSuccess,
  } = useQuery(
    [
      ObjectEnum.CONTROLLED_DOCUMENT,
      recordName,
      version,
      record?.document?.documentId,
      record?.version,
      'document',
    ],
    {
      queryFn: async () => {
        const documentDocs: DocumentFile = await getDocumentData(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          record?.document?.documentId,
          recordName,
          requiresVersionParam(
            record?.version ?? '',
            record?.documentStatus ?? ''
          )
            ? record?.version
            : '',
          DocumentDownloadActionEnum.View
        );
        if (!documentDocs) throw new Error('No Document Found');
        return documentDocs;
      },
      enabled: Boolean(record?.document?.documentId),
      staleTime: Infinity,
      onError: (_error) => {
        setNoPreviewText(true);
        raiseErrorToast(_error);
      },
    }
  );

  const documentURL = useMemo(() => {
    if (documentDocs?.label?.endsWith('.pdf')) {
      setNoPreviewText(false);
      const uint8Array = new Uint8Array(documentDocs?.fileBuffer?.data);
      const blob = new Blob([uint8Array], {
        type: 'application/pdf',
      });
      const createUrl = URL.createObjectURL(blob);
      const url = createUrl?.concat('#toolbar=0&navpanes=0&zoom=80%');
      return url;
    } else {
      setNoPreviewText(true);
      return '';
    }
  }, [documentDocs]);

  const downloadRawDocument = useCallback(async () => {
    const documentId = record?.document?.documentId;
    if (!documentId) {
      errorToast({ message: 'Document not found' });
      return;
    }
    if (documentId) {
      files.downloadFile(
        getDocumentBasedOnType(
          documentId,
          'controlled_document__a',
          record?.name,
          DocumentDownloadActionEnum.DownloadRaw
        ),
        (fileDocument) => {
          if (!fileDocument) throw new Error('Document not found');
          files.downloadFileBuffer({
            data: fileDocument.fileBuffer.data,
            label: fileDocument.label,
            mimeType: fileDocument.mimeType,
          });
        }
      );
    }
  }, [record?.name, record?.document?.documentId]);
  return {
    isLoading: isLoading || !isSuccess,
    noPreviewText: noPreviewText && isSuccess,
    documentURL,
    documentData: documentDocs,
    downloadRawDocument,
  };
};
