import { GridView } from '@celito.clients/shared';
import { Label } from '@fluentui/react-components';
import { isArray, isString } from 'lodash';

import { documentTemplateStyles } from '../../../styles';
import { DocCellActions, MFVCellActions } from './cell-actions';

export default function CustomFields({
  name,
  value,
}: Readonly<{
  name: string;
  value: string | Record<string, unknown>;
}>) {
  const styles = documentTemplateStyles();
  switch (name) {
    case 'mergeFeildValues':
      value = isString(value) ? JSON.parse(value) : value;
      return (
        <div>
          <Label size="large">Merge Field Values</Label>
          <GridView
            disableDefaultSorting
            disableSelection
            columns={[
              {
                key: '1',
                name: 'Merge Field Value',
                fieldName: 'value',
                minWidth: 500,
                flexGrow: 1,
              },
              {
                key: '2',
                name: 'Action',
                minWidth: 200,
                isResizable: true,
                onRender: (item, index, _column) => {
                  if (isArray(value))
                    return (
                      <MFVCellActions
                        styles={styles}
                        index={index ?? 0}
                        value={value}
                      />
                    );
                },
              },
            ]}
            items={
              isArray(value)
                ? value.map((value: string, i: number) => ({
                    key: i,
                    value: String(`\${${value}}`),
                  }))
                : []
            }
          />
        </div>
      );
    case 'document':
      return (
        <div>
          <Label size="large">Document</Label>
          <GridView
            disableDefaultSorting
            disableSelection
            columns={[
              {
                key: '1',
                name: 'Title',
                minWidth: 100,
                maxWidth: 400,
                fieldName: 'title',
                isResizable: true,
                showTooltip: true,
              },
              {
                key: '3',
                name: 'Action',
                minWidth: 80,
                maxWidth: 80,
                fieldName: 'action',
                isResizable: true,
                onRender: (_item, _index, _column) => {
                  if (!isString(value)) return <DocCellActions value={value} />;
                },
              },
            ]}
            items={
              !isString(value)
                ? [
                    {
                      key: '1',
                      title: value.nameWithExtension,
                      action: 'action',
                      ...value,
                    },
                  ]
                : []
            }
          />
        </div>
      );
    default:
      return null;
  }
}
