import { Text } from '@fluentui/react-components';

import { TypographyProps } from './typography.model';

type TypographyViewProps = TypographyProps;

const TypographyView = (props: TypographyViewProps): JSX.Element => {
  return <Text {...props} />;
};

export default TypographyView;
