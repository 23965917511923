import { FormEngineModeTypeEnum } from '@celito.clients/enums';

import { AddLayout } from './add/add.component';
import { EditLayout } from './edit/edit.component';
import { SingleLayoutContainerComponentProps } from './single-layout-container.model';
import { ViewLayout } from './view/view.component';

export interface SingleLayoutContainerViewProps
  extends SingleLayoutContainerComponentProps {}
export const SingleLayoutContainerView = (
  props: SingleLayoutContainerComponentProps
) => {
  const getLayoutonModeBasis = (mode: FormEngineModeTypeEnum) => {
    switch (mode) {
      case FormEngineModeTypeEnum.Create:
        return <AddLayout {...props} />;
      case FormEngineModeTypeEnum.Edit:
        return <EditLayout {...props} />;
      case FormEngineModeTypeEnum.View:
      case FormEngineModeTypeEnum.CustomView:
        return <ViewLayout {...props} />;
      default:
        return null;
    }
  };
  return getLayoutonModeBasis(props.mode);
};
