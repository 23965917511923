import { FormEngineModeTypeEnum, ObjectEnum } from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import { SelectionEvents } from '@fluentui/react-combobox';
import { useEffect, useState } from 'react';

import { RowItem } from '../../grid-view-new/src/types';
import { IOption } from '../../in-house-input-select/in-house-input-select.model';
import { ICommonData } from '../../in-house-input-select/services';
import {
  ReferenceSelectorItem,
  ReferenceSelectorProps,
} from './reference-selector.model';
import ReferenceSelectorView from './reference-selector.view';
import { getReferenceDocuments } from './utils/getReferenceDocumentData';

interface ReferenceSelectorControllerProps extends ReferenceSelectorProps {}

export const ReferenceSelectorController = (
  props: ReferenceSelectorControllerProps
): JSX.Element => {
  const [isShowAllResultsViewOpen, setIsShowAllResultsViewOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [searchKey, setSearchKey] = useState('');
  const activeModule = useActiveModule();
  const onRemoveItem = (item: RowItem) => {
    props.onRemoveItem(item);
    props.onSelectorChange(
      props.selectedItems.filter((itm) => {
        if (itm?.version && item?.version) {
          return (
            `${itm.name}-${itm.version}` !== `${item.name}-${item.version}`
          );
        }
        return itm.name !== item.name;
      })
    );
  };

  const handleOnAddClick = (items: ReferenceSelectorItem[]) => {
    const newItems = items;

    const updatedSelectedItems = props.referencePickerProps.multiselect
      ? [
          ...(Array.isArray(props.selectedItems) ? props.selectedItems : []),
          ...newItems,
        ]
      : newItems;

    props.onSelectorChange(updatedSelectedItems);

    onShowAllResult();
  };

  const onOptionSelect = (
    _ev: SelectionEvents,
    optionText: string,
    optionValue: string,
    selectedOptions: IOption[] | IOption,
    data?: ICommonData
  ) => {
    const isRemoval = props?.selectedItems?.some(
      (selected) => selected.name === optionValue
    );

    const selectedItem: ReferenceSelectorItem = {
      key: optionValue,
      label: optionText,
      name: optionValue,
      ...data,
    };

    const updatedSelectedItems = props.referencePickerProps.multiselect
      ? [
          ...(Array.isArray(props.selectedItems) ? props.selectedItems : []),
          selectedItem,
        ]
      : [selectedItem];

    const newSelectedOptions = isRemoval
      ? props.selectedItems?.filter((selected) => selected.name !== optionValue)
      : updatedSelectedItems;

    props.onSelectorChange(newSelectedOptions);
  };

  const onShowAllResult = () => {
    setIsShowAllResultsViewOpen((prev) => !prev);
  };

  useEffect(() => {
    if (
      props?.mode &&
      props?.mode === FormEngineModeTypeEnum.Edit &&
      props?.objectName === ObjectEnum.CONTROLLED_DOCUMENT &&
      props?.selectedItems?.length > 0
    ) {
      getSelectedReferenceDocuments();
    }
  }, []);

  const getSelectedReferenceDocuments = async () => {
    const selectedRefDocs = await getReferenceDocuments(
      props?.objectName,
      props?.selectedItems,
      setIsLoading,
      activeModule
    );
    selectedRefDocs && props?.onSelectorChange(selectedRefDocs);
  };

  return (
    <ReferenceSelectorView
      {...props}
      selectedItems={props?.selectedItems}
      onRemoveItem={onRemoveItem}
      showDownloadButton={props?.showDownloadButton}
      onOptionSelect={onOptionSelect}
      searchKey={searchKey}
      onShowAllResult={onShowAllResult}
      setSearchKey={setSearchKey}
      isShowAllResultsViewOpen={isShowAllResultsViewOpen}
      handleOnAddClick={handleOnAddClick}
      setIsLoading={setIsLoading}
      isLoading={isLoading}
    />
  );
};
