import {
  DeleteIcon,
  LocalizationString,
  WarningIcon,
} from '@celito.clients/assets';
import { CustomViewTypeEnum } from '@celito.clients/enums';
import { useUpdateInitiationConfirmationModal } from '@celito.clients/hooks';
import {
  ConfirmDialog,
  FluentIconNames,
  Icon,
  Menu,
} from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import {
  Button,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
} from '@fluentui/react-components';
import { Dispatch, SetStateAction, useMemo } from 'react';

import { ClaimModal } from '../claim-modal';
import { DelegationModal } from '../delegation-modal/delegation-modal.component';
import {
  ActionColumnProps,
  IActionItem,
  LifeCycleStageUserActionsPermissionEnum,
} from './action-column.model';
import { actionColumnListViewStyles } from './action-column.styles';

interface ActionColumnViewProps extends ActionColumnProps {
  menuProps: IActionItem[];
  showDeleteAlert: boolean;
  closeDeleteAlert: (deleteRec?: boolean) => void;
  closeClaimAlert: () => void;
  openDelegateModal: boolean;
  closeDelegateModal: () => void;
  showClaimAlert: boolean;
  objectName: string;
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  fetchUserActions: () => void;
  isUserActionsFetched: boolean;
  isUserActionsLoading: boolean;
  updateInitiationConfirmationProps: ReturnType<
    typeof useUpdateInitiationConfirmationModal
  >;
}
const ActionColumnView = ({
  dataTestId,
  menuProps,
  showDeleteAlert,
  closeDeleteAlert,
  closeClaimAlert,
  rowItem,
  openDelegateModal,
  closeDelegateModal,
  showClaimAlert,
  objectName = '',
  viewMetadata,
  menuOpen,
  setMenuOpen,
  fetchUserActions,
  isUserActionsFetched,
  isUserActionsLoading,
  updateInitiationConfirmationProps,
  objectMetadata,
}: ActionColumnViewProps): JSX.Element => {
  const styles = actionColumnListViewStyles();

  const {
    showUpdateInititationConfirmationModal,
    setShowUpdateInititationConfirmationModal,
    handleOnUpdateInitiationConfirmation,
    loading,
  } = updateInitiationConfirmationProps;

  const isUnsubmittedControlledDocsListView =
    viewMetadata?.viewDto?.name ===
      CustomViewTypeEnum.UNSUBMITTED_CONTROL_DOCS_LIST_VIEW ||
    viewMetadata?.viewDto?.name === 'cd_unsubmitted_controlled_documents__a';

  const sortedMenuProps = useMemo(
    () =>
      [...menuProps].sort((a, b) => {
        if (a.label && b.label) {
          return a.label.localeCompare(b.label);
        }
        return -1;
      }),
    [menuProps]
  );

  const hasNoOptions =
    !isUserActionsLoading && isUserActionsFetched && sortedMenuProps.length < 1;

  return (
    <div>
      {isUserActionsLoading ? (
        <Spinner
          data-testid="action-loader"
          className={styles.spinner}
          size="tiny"
        />
      ) : (
        <Menu
          open={menuOpen}
          onOpenChange={(_, data) => setMenuOpen(data.open)}
          closeOnScroll
        >
          <MenuTrigger disableButtonEnhancement>
            <Button
              disabled={hasNoOptions}
              appearance="subtle"
              icon={<Icon iconName="MoreHorizontal24Regular" />}
              onClick={fetchUserActions}
            />
          </MenuTrigger>

          {!hasNoOptions ? (
            <MenuPopover className={styles.menuPopover}>
              <MenuList className={styles.menuList}>
                {sortedMenuProps.map((menuItem, index) => {
                  const menuItemProps = {
                    className: styles.actionMenuItem,
                    disabled:
                      menuItem?.permission ===
                      LifeCycleStageUserActionsPermissionEnum.VIEW,
                    onClick: menuItem.onActionClick,
                  };
                  return (
                    <MenuItem key={menuItem.label} {...menuItemProps}>
                      <div className={styles.itemContent}>
                        <Icon iconName={menuItem.iconName as FluentIconNames} />
                        {menuItem.label}
                      </div>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </MenuPopover>
          ) : (
            <span />
          )}
        </Menu>
      )}

      <ConfirmDialog
        dataTestId={dataTestId}
        open={showDeleteAlert}
        onCancelClicked={(e) => {
          e.stopPropagation();
          closeDeleteAlert();
        }}
        onConfirmClicked={(e) => {
          e.stopPropagation();
          closeDeleteAlert(true);
        }}
        primaryButtonText={LocalizationString.DELETE}
        title={LocalizationString.DELETE_INITIATION}
        iconSrc={DeleteIcon}
        description={
          isUnsubmittedControlledDocsListView
            ? LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_DELETE_DOC_INITIATION
            : LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_DELETE
        }
        secondaryButtonText={LocalizationString.CANCEL}
        isBlocking
      />
      <ClaimModal onClose={closeClaimAlert} isOpen={showClaimAlert} />
      <DelegationModal
        isOpen={openDelegateModal}
        onClose={closeDelegateModal}
        rowItem={{ ...(rowItem?.rowData ?? rowItem), key: rowItem?.key }}
        objectName={objectName}
      />

      <ConfirmDialog
        dataTestId={`${createTestAttribute(
          LocalizationString.UPDATE_INITIATION_CONFIRMATION
        )}-modal`}
        open={showUpdateInititationConfirmationModal}
        onCancelClicked={() => setShowUpdateInititationConfirmationModal(false)}
        onConfirmClicked={handleOnUpdateInitiationConfirmation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UPDATE_INITIATION_CONFIRMATION}
        iconSrc={WarningIcon}
        description={LocalizationString.UPDATE_INITIATION_CONFIRMATION_MSG.replace(
          '{objectName}',
          objectMetadata?.label ?? 'record'
        )}
        isPrimaryButtonLoading={loading}
      />
    </div>
  );
};

export default ActionColumnView;
