import { useEffect, useRef } from 'react';

import { IChoice, IQuestion } from '../../quiz-questions-page.model';
import { ChoiceProps } from './choices.model';
import { ChoicesView } from './choices.view';

export const ChoicesController = (props: ChoiceProps) => {
  const { setValue, clearErrors } = props.methods;

  const choiceTextRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (choiceTextRef.current) choiceTextRef.current.focus();
  }, [props.question.choices]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onAddChoiceToQuestion(props.question, props.questionIndex);
      event.preventDefault();
    }
  };

  const onAddChoiceToQuestion = (
    question: IQuestion,
    questionIndex: number
  ) => {
    const updatedChoices: IChoice[] = [
      ...question.choices,
      {
        choiceText: '',
        isCorrect: false,
        order: question.choices.length + 1,
      },
    ];

    setValue(`questions.${questionIndex}.choices`, updatedChoices, {
      shouldDirty: true,
    });

    clearErrors(`questions.${questionIndex}.choices`);
  };

  const onRemoveChoiceFromAQuestion = (
    question: IQuestion,
    questionIndex: number,
    choiceOrder: number
  ) => {
    const updatedChoices: IChoice[] = question.choices
      .filter((choice) => choice.order !== choiceOrder)
      .map((choice, index) => {
        return {
          ...choice,
          order: index + 1,
        };
      });

    setValue(`questions.${questionIndex}.choices`, updatedChoices, {
      shouldDirty: true,
    });
  };

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    choice: IChoice
  ) => {
    event.dataTransfer.setData('text/plain', JSON.stringify(choice));
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    choiceIndex: number
  ) => {
    event.preventDefault();

    const droppedItem: IChoice = JSON.parse(
      event.dataTransfer.getData('text/plain')
    );
    const updatedChoices = props.question.choices.filter(
      (item: IChoice) => item.order !== droppedItem.order
    );

    // Insert the dropped item at the new position
    updatedChoices.splice(choiceIndex, 0, droppedItem);

    const orderedItems: IChoice[] = updatedChoices.map((item, ind) => {
      return {
        ...item,
        order: ind + 1,
      };
    });

    setValue(`questions.${props.questionIndex}.choices`, orderedItems, {
      shouldDirty: true,
    });
  };

  return (
    <ChoicesView
      {...{
        ...props,
        onAddChoiceToQuestion,
        onRemoveChoiceFromAQuestion,
        handleDragOver,
        handleDragStart,
        handleDrop,
        handleKeyPress,
        choiceTextRef,
      }}
    />
  );
};
