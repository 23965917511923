import { ActionRule, PropertiesType } from './object-attribute-types';

export type WorkflowDefinition = {
  label: string;
  ownerType: string;
  name: string;
  createdAtUtc: string;
  modifiedAtUtc: string;
  deletedAtUtc: string;
  isActive: boolean;
  properties: PropertiesType;
  objectName: string;
  viewName: string;
  iconName: string;
  actionRule: ActionRule[] | null;
  permission?: string;
  umberOfActiveWorkflows: 0;
  workflowId: string;
  version: number;
  description: string;
  status: string;
  executionType: string;
  executionEvent: string;
  executionSchedule: null;
  executionConditions: null;
  executeOnlyOnRecordLatestVersion: boolean;
  publishedAtUtc: Date;
  inActivatedAtUtc: Date;
  noActiveWorkflowCheck: boolean;
  isCancelable: boolean;
  workflowControlsDefinition: WorkflowControlsDefinition[];
};

export type WorkflowControlsDefinition = {
  label: string;
  ownerType: string;
  name: string;
  createdAtUtc: string | null;
  modifiedAtUtc: string | null;
  deletedAtUtc: string | null;
  isActive: boolean;
  numberOfActiveWorkflows: number;
  description: string | null;
  controlType: string;
  permission: string;
  isDisabled: boolean;
  controlConfig: {
    [key: string]:
      | string
      | string[]
      | WorkflowControlVariableDataTypeEnum
      | boolean;
  };
  defaultRule: string;
  displayOrder: number;
};

export enum WorkflowControlVariableDataTypeEnum {
  DATE_ON_INITIATION = 'DATE-ON-INITIATION',
  PLAIN_TEXT = 'PLAIN-TEXT',
  LONG_TEXT = 'LONG-TEXT',
  YES_OR_NO = 'YES-OR-NO',
  PICKLIST = 'PICKLIST',
  USER_ON_INITIATION = 'USER-ON-INITIATION',
  USER_OBJECT_ROLES = 'USER-OBJECT-ROLES',
}
