export enum ROUTES {
  // Public Routes
  MAIN = '/',
  NOT_INVITED = '/not-invited',
  NOT_ACTIVE_USER = '/not-active-user',
  PAGE_NOT_FOUND = '*',
  // Private Routes
  LAYOUT = '/md/:moduleName/tb/:tabName/stb/:subTabName',
  // inner routes of the LAYOUT route cannot start with '/' (Absolute routes are not allowed in nested route)
  HOME = 'home',
  PROFILE = 'profile',
  DASHBOARD = 'dashboard',
  NOTIFICATIONS = 'notifications',
  DELEGATION = 'delegation',
  CREATE_NOTIFICATION = 'create/custom/notification_template__s',
  EDIT_NOTIFICATION = 'edit/custom/notification_template__s/:name',
  VIEW_NOTIFICATION = 'view/custom/notification_template__s/:name',
  USER = 'edit/custom/user__s/:name',
  ADD_USER = 'create/custom/user__s',
  VIEW_USER = 'view/custom/user__s/:name',
  LIST = 'list',
  LIST_VIEW = 'list/:viewName',
  REPORTING_VIEW = 'reporting/:viewName',
  VERSIONHISTORY = 'version-history/:documentId',
  ADD_PERMISSION_GROUP = 'create/custom/permission_group__s',
  VIEW_PERMISSION_GROUP = 'view/custom/permission_group__s/:name',
  EDIT_PERMISSION_GROUP = 'edit/custom/permission_group__s/:name',
  MATERIAL_LIST_VIEW = 'list/training_material_view__a',
  USER_LEARNING = ':mode/:viewName/:recordName?',
  TAKING_QUIZ = 'view/custom/taking_quiz/:name',
  TAKING_ASSINGMENTS = 'view/custom/taking_assignment__a/:name',
  DOCUMENT_VIEW = 'view/custom/taking_training/document_view__a',
  ADD_ONE_TIME_ASSIGNMENT = 'create/custom/one_time_assignment__a',
  HOME_VIEW = 'view/custom/home__a',
  CD_HOME_VIEW = 'view/custom/cdhome__a',
  MY_TEAMS_ASSIGNMENTS_LIST_VIEW = 'list/custom/my_teams_assignments_view__a',
  COMPANY_ASSIGNMENTS_LIST_VIEW = 'list/custom/company_assignments_view__a',
  ILC_ASSIGNMENTS_LIST_VIEW = 'list/custom/ilc_assignments_view__a',
  OVERDUE_ASSIGNMENTS_LIST_VIEW = 'list/custom/overdue_assignments_view__a',
  WORKFLOW = 'workflows',
  CUSTOM_LIST_VIEW = 'list/custom/:viewName',
  CONTROLLED_DOCS_CUSTOM_VIEW = 'view/custom/controlled_document_detail_view__a/:name',
  VIEW_CERTIFICATE = 'view/custom/certificate_template__a',
  EDIT_CERTIFICATE = 'edit/custom/certificate_template__a',
  PREVIEW_CERTIFICATE = 'preview/custom/certificate_template__a',
  CONTROLLED_DOCS_CUSTOM_EDIT = 'edit/custom/controlled_document_edit_view__a/:name',
  WORKFLOW_NODE_EDITOR = 'custom/workflows/node_editor/:name/:version',
  CONFIGURATION = 'custom/configuration_view__s',
  SEARCH = 'custom/search',
  CREATE_ADMIN_DOCUMENT_TEMPLATE = 'create/custom/document_templates_create_view__a',
  EDIT_ADMIN_DOCUMENT_TEMPLATE = 'edit/custom/document_templates_edit_view__a/:name',
  VIEW_ADMIN_DOCUMENT_TEMPLATE = 'view/custom/document_template__a/:name',
  VIEW_ADMIN_DOCUMENT_DATA = 'view/custom/document_data_detail_view__a',
  EDIT_ADMIN_DOCUMENT_DATA = 'edit/custom/document_data_edit_view__a',
  VIEW_ADMIN_DOCUMENT_ID = 'view/custom/document_id_details_view__a',
  EDIT_ADMIN_sDOCUMENT_ID = 'edit/custom/document_id_edit_view__a',
  CHART_LIST_VIEW = 'custom/chart_view__s',
  DASHBOARD_LIST_VIEW = 'custom/dashboard_view__s',
  CONTROLLED_DOCS_LAYOUT = ':mode/custom/*',
  WOPI_EDITOR = 'editor/:documentId',
  EDIT_ADMIN_DOCUMENT_TYPE = 'document_type_edit_view__a/:documentType',
  CREATE_ADMIN_DOCUMENT_TYPE = 'document_type_create_view__a',
  LIST_ADMIN_DOCUMENT_TYPE = 'document_type_list_view__a',
  VIEW_ADMIN_DOCUMENT_TYPE = 'document_type_view__a/:documentType',
  TRAINING_MATRIX = 'custom/training_matrix_list_view_name__a',
  CONFIGURATION_OBJECTS = 'custom/metadata/landing_page/objects',
  CONFIGURATION_NAVIGATION = 'custom/metadata/landing_page/navigation',
  CONFIGURATION_PICKLIST = 'custom/metadata/landing_page/picklist',
  PERMISSION_SET = 'custom/permission_set',
  ROLES = 'custom/roles',
  MODULES_LIST_VIEW = 'list/module_list_view__a',
  EMAIL_EXPORT_REDIRECT = 'custom/export',
  CREATE_DELEGATION = 'create/custom/delegation_detail_view__a',
  VIEW_DELEGATION = 'view/delegation_detail_view__a/:name',
  EDIT_DELEGATION = 'edit/custom/delegation_detail_view__a/:name',
  CREATE = 'create',
}
