import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';
import { Variables } from 'libs/theme/src/lib/variables';

export const actionPopupStyles = makeStyles({
  modalHeader: {
    display: 'flex',
    width: '100%',
    ...shorthands.padding('0.75rem'),
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    backgroundColor: Color.drawer_header_color,
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 700,
    justifyContent: 'space-between',
  },
  modalFooter: {
    ...shorthands.gap('1rem'),
    ...shorthands.padding('0.75rem'),
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    backgroundColor: Color.drawer_header_color,
    justifyContent: 'center',
    boxShadow: 'inset 0px 1px 0px var(--gray-200)',
  },
  modalSurface: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    width: '25rem',
    ...shorthands.borderRadius('6px'),
    ...shorthands.padding('unset'),
  },
  modalBody: {
    ...shorthands.padding('1.5rem'),
  },

  modalBodyHeight: {
    height: `calc(100vh - ${Variables.scrollHeight}px)`,
    maxHeight: '400px',
  },
  group: {
    marginBottom: '1.5rem',
  },
  fieldLabel: {
    fontFamily: ThemeFontFamily.bold,
    fontSize: '16px',
    fontWeight: '600',
  },
  fieldValue: {
    fontFamily: ThemeFontFamily.regular,
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
