import {
  Overflow,
  OverflowItem,
  Tab,
  TabList,
} from '@fluentui/react-components';

import OverflowMenu from '../components/OverflowMenu';
import { QuickFilterViewProps } from './quick-filter.model';
import classes from './quick-filter.module.css';

const QuickFilterView: React.FC<QuickFilterViewProps> = (props) => {
  const { filters, selectedTabId, onTabSelect } = props;

  return (
    <div className={classes.tab_list}>
      <Overflow minimumVisible={1}>
        <TabList
          selectedValue={selectedTabId}
          appearance="subtle"
          size="large"
          onTabSelect={(_, data) => onTabSelect(data.value as string)}
        >
          {filters.map((tab) => {
            return (
              <OverflowItem
                key={tab}
                id={tab}
                priority={tab === selectedTabId ? 2 : 1}
              >
                <Tab value={tab}>{tab}</Tab>
              </OverflowItem>
            );
          })}
          <OverflowMenu filters={filters} onTabSelect={onTabSelect} />
        </TabList>
      </Overflow>
    </div>
  );
};

export default QuickFilterView;
