import { useTheme } from '@celito.clients/theme';
import { Text } from '@fluentui/react-components';
import { Stack } from 'libs/shared/src/lib/shared';

import { userTransformType, UserViewViewProps } from './user-view.model';
import classes from './user-view.module.css';

const GridLayout: React.FC<{
  formDataList: Array<userTransformType>;
}> = ({ formDataList }) => {
  const { cssVariables } = useTheme();

  return (
    <div className={classes.cards} style={{ ...cssVariables }}>
      {formDataList.map((item: userTransformType) => {
        return (
          <Stack className={classes.card} key={item.label}>
            <Text className={classes.label}>{item.label}</Text>
            <Text className={classes.value}>{item?.value || ''}</Text>
          </Stack>
        );
      })}
    </div>
  );
};

export const UserViewView = ({
  userData,
  transformFormData,
}: UserViewViewProps) => {
  return (
    <Stack className={classes.topMargin}>
      <GridLayout formDataList={userData ? transformFormData(userData) : []} />
    </Stack>
  );
};
