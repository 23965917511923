import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { startTrainingObjectName } from '../config';

export const startTrainingAssignment = async (
  trainingAssignmentName: string
) => {
  const response = await performRequest(
    `${Endpoints.EXECUTE_ACTION}/${startTrainingObjectName}?record_name=${trainingAssignmentName}`,
    ApiMethodType.POST,
    {}
  );
  return response.data;
};
