import { LabelProps } from './label.model';
import LabelView from './label.view';

interface LabelControllerProps extends LabelProps {}

const LabelController = (props: LabelControllerProps): JSX.Element => {
  return <LabelView {...props} />;
};

export default LabelController;
