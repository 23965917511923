import { makeStyles, shorthands } from '@fluentui/react-components';
import { tokens } from '@fluentui/react-theme';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const TextFieldMultipleStyles = makeStyles({
  inputContainer: {
    ...shorthands.border('1px', 'solid', '#d9d9d9'),
    ...shorthands.overflow('hidden'),
    ...shorthands.padding('6px', '12px'),
    display: 'flex',
    ...shorthands.gap('10px'),
    alignItems: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
    width: '100%',
    ...shorthands.borderRadius('5px'),
    justifyContent: 'space-between',
    fontFamily: ThemeFontFamily.sans,
    backgroundColor: Color.white,
    fontSize: '14px',
    minHeight: '40px',
    boxSizing: 'border-box',

    ...shorthands.borderBottom(
      '1px',
      'solid',
      'var(--colorNeutralStrokeAccessible)'
    ),

    ...shorthands.transition('border-color', '0.3s', 'ease'),
  },

  focusedInputContainer: {
    ...shorthands.borderBottom(
      '2px',
      'solid',
      'var(--colorCompoundBrandStroke)'
    ),
  },

  btnTriggerDisabled: {
    cursor: 'not-allowed',
    color: '#bdbdbd',
    ...shorthands.border('1px', 'solid', 'var(--colorNeutralStrokeDisabled)'),
  },

  error: { ...shorthands.border('1px', 'solid', Color.avatar_lightRed) },

  inputTextCustomized: {
    fontFamily: ThemeFontFamily.sans,
    fontSize: '1rem',
    ...shorthands.border(0),
    cursor: 'text',
    backgroundColor: 'transparent',

    ':disabled': {
      backgroundColor: 'transparent',
      cursor: 'not-allowed',
    },
  },

  inputTextAutoWidth: {
    minWidth: '1px',
    width: '1px',
    maxWidth: 'calc(100% - 60px)',
  },

  tagInputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    ...shorthands.gap('8px'),
    boxSizing: 'border-box',
  },

  inputDisabled: {
    color: 'var(--colorNeutralForegroundDisabled)',

    '::placeholder': {
      color: 'var(--colorNeutralForegroundDisabled)',
    },
  },

  tagDisabled: {
    backgroundColor: tokens.colorNeutralBackgroundDisabled,
  },

  dismissContainerDisabled: {
    ':hover > svg': {
      color: 'inherit',
    },

    ':hover': {
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
    },
  },

  selectContainer: { position: 'relative' },

  field: {
    boxSizing: 'border-box',
  },

  tagContainer: {
    width: 'calc(100% - 60px)',
    display: 'flex',
    flexWrap: 'wrap',
    ...shorthands.gap('8px'),
    fontFamily: ThemeFontFamily.sans,
    fontSize: '1rem',
    maxWidth: '100%',
    boxSizing: 'border-box',
  },

  tagOption: {
    fontSize: '14px',
    fontFamily: ThemeFontFamily.sans,
    color: '#333333',
    zIndex: '1',
    maxWidth: '9rem',
    ...shorthands.overflow('hidden'),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  tagInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'hsl(0, 0%, 90%)',
    ...shorthands.padding('0px', '0px', 0, '4px'),
  },

  singleSelectTagInnerContainer: {
    backgroundColor: 'transparent',

    '> span': {
      fontSize: '1rem',
    },
  },

  singleSelectTagInnerContainerDisabled: {
    '> span': {
      color: '#adadad',
    },
  },

  dismissContainer: {
    ...shorthands.padding('3px', '4px'),

    '> svg': {
      width: '10px',
    },

    ':hover > svg': {
      color: 'red',
    },

    ':hover': {
      cursor: 'pointer',
      backgroundColor: Color.error_border,
    },
  },

  noOptionsAvailable: {
    display: 'flex',
    width: '100%',
    ...shorthands.padding('12px'),
    justifyContent: 'center',
    fontSize: '1rem',
    fontFamily: ThemeFontFamily.sans,
  },

  infoLabel: {
    '> div': {
      ...shorthands.padding('8px'),
      ...shorthands.margin(0),
    },
  },
});
