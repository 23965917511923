type EnvProps = Record<string, unknown>;

export class Environment {
  private static instance: Environment;
  private state: EnvProps = {};

  private constructor() {}

  public setEnvironment(env: EnvProps): void {
    this.state = env;
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation lets you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): Environment {
    if (!Environment.instance) {
      Environment.instance = new Environment();
    }
    return Environment.instance;
  }

  getParam = <T extends keyof EnvProps>(key: T): EnvProps[T] => {
    return this.state[key];
  };
}
