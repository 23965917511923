type objType = {
  [key: string]: string | unknown;
};
// this utility function is used to generate colors to use in css file
export const cssGenerator = (cssObj = {}) => {
  const obj: objType = {};
  for (const [key, value] of Object.entries(cssObj)) {
    const transformKey = key.toLocaleLowerCase().replace('_', '-');
    obj[`--${transformKey}`] = value;
  }
  return obj;
};
