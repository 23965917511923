import { setUrlHashParams } from '@celito.clients/utils';

export function formatSize(sizeInBytes: number | null): string {
  if (!sizeInBytes || isNaN(sizeInBytes)) return '0 B';

  if (sizeInBytes < 1024) {
    return `${sizeInBytes} B`;
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(1)} KB`;
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return `${(sizeInBytes / (1024 * 1024)).toFixed(1)} MB`;
  } else {
    return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(1)} GB`;
  }
}

export const generatePdfUrl = (
  url: string,
  directives: {
    hideNavigation?: boolean;
    hideToolbar?: boolean;
  }
): string => {
  try {
    let newUrl = url;

    if (directives.hideNavigation !== undefined) {
      newUrl = setUrlHashParams(url, {
        navpanes: directives.hideNavigation ? '0' : '1',
      });
    }

    if (directives.hideToolbar !== undefined) {
      newUrl = setUrlHashParams(url, {
        toolbar: directives.hideToolbar ? '0' : '1',
      });
    }

    return newUrl;
  } catch (e) {
    return '';
  }
};
