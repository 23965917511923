import { Environment as ENV } from '@celito.clients/singleton';
import * as Sentry from '@sentry/react';
import { isString } from 'lodash';

import {
  LogLevel,
  logLevels,
  TAttributes,
  TMessage,
  Transport,
  User,
} from '../types';

export class SentryTransport implements Transport {
  constructor() {
    Sentry.init({
      dsn: String(ENV.getInstance().getParam('SENTRY_DSN')),
      environment: String(ENV.getInstance().getParam('NX_ENV_NAME')),
      release: String(ENV.getInstance().getParam('SENTRY_RELEASE_NAME')),
      dist: String(ENV.getInstance().getParam('SENTRY_DIST')),
      sampleRate: 1,
      enableTracing: true,
      tracesSampleRate: 1,
      defaultIntegrations: false,
      integrations: [
        Sentry.dedupeIntegration(),
        Sentry.functionToStringIntegration(),
        Sentry.browserTracingIntegration(),
      ],
    });
  }

  handle(message: TMessage, level: LogLevel, attributes?: TAttributes): void {
    if ([LogLevel.DEBUG, LogLevel.INFO].includes(level)) {
      if (!isString(message)) return;
      const { type, ...data } = attributes ?? {};
      Sentry.addBreadcrumb({
        message,
        type,
        category: type,
        data,
        level: logLevels[level],
      });
    } else {
      message = isString(message) ? new Error(message) : message;
      Sentry.captureException(message, {
        extra: {
          attributes,
          level,
        },
      });
    }
  }

  identity(user: User) {
    const sentryUser: Sentry.User = {
      id: user?.name,
      email: user?.email,
      username: user?.username,
      ip_address: '{{auto}}',
    };
    Sentry.setUser(sentryUser);
  }
}
