import { brandLogoWhite2x } from '@celito.clients/assets';
import { Icon } from '@celito.clients/shared';
import { getHomeUrl } from '@celito.clients/utils';
import { Link } from 'react-router-dom';

import SuggestionMenu from '../suggestion-menu/suggestion-menu.component';
import { QuickSearchViewProps } from './quick-search.model';
import classes from './quick-search.module.css';

const QuickSearchView: React.FC<QuickSearchViewProps> = (props) => {
  const {
    isOpen,
    onCloseQuickSearchHandler,
    quickSearchInput,
    onInputChangeHandler,
    onQuickSearch,
    onTopResultClickHandler,
    outSideClickRef,
  } = props;

  return isOpen ? (
    <div className={classes.navigation_header_overlay}>
      <Link to={getHomeUrl()}>
        <img
          src={brandLogoWhite2x}
          data-testid="img-logo"
          alt="logo"
          className={classes.logo}
        />
      </Link>
      <div className={classes.quick_search_section}>
        <div className={classes.quick_search_bar} ref={outSideClickRef}>
          <SuggestionMenu
            placeholder="Enter keyword"
            searchInput={quickSearchInput}
            onChangeHandler={onInputChangeHandler}
            onKeydownHandler={onQuickSearch}
            isIcon={true}
            onTopResultClickHandler={onTopResultClickHandler}
          />
        </div>
        <Icon
          iconName="Dismiss24Filled"
          className={classes.dismiss_button}
          onClick={onCloseQuickSearchHandler}
        />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default QuickSearchView;
