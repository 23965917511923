import { ApiMethodType } from '@celito.clients/enums';
import { performFormRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

const objectName = 'document_data__a';

export const getDocumentData = async () => {
  const response = await performFormRequest(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}`,
    ApiMethodType.POST,
    {},
    { page: 1, limit: 1 }
  );
  return response.data;
};

export const updateDocumentData = async (
  payload: FormData,
  params: object = {}
) => {
  const CONSTANT_RECORD_NAME = 'dd00000000001__c';
  payload.append('is_active__s', 'true');

  const response = await performFormRequest(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}/${CONSTANT_RECORD_NAME}`,
    ApiMethodType.PUT,
    payload,
    params
  );
  return response.data as Record<'record', Record<string, unknown>>;
};
