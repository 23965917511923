import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const delegationFormStyles = makeStyles({
  item: {
    minWidth: '50%',
    paddingRight: '1rem',
  },

  formView: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '1rem',
  },

  formHeading: {
    '> button': {
      ...shorthands.border('none'),
      backgroundColor: 'transparent',
      marginRight: '15px',
      cursor: 'pointer',
    },

    '> b': {
      fontSize: '20px',
      fontFamily: 'var(--bold)',
    },
  },

  footerContent: {
    position: 'absolute',
    width: 'calc(100% - 6rem)',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    ...shorthands.borderTop('1px', 'solid', Color.dividerColor),
    ...shorthands.padding('1rem'),
  },

  textArea: {
    fontSize: '16px !important',
  },
  fieldMinWdith: {
    minWidth: '100%',
  },
  fieldWidth: {
    width: '49%',
  },
  loaderWrapper: {
    height: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
});
