import { performRequest } from '@celito.clients/network';

import Endpoints from '../config/endpoint';
import { ApiMethodType } from '../enums/api-constants';
import { HeaderConfig } from '../types/header-config';

export const fetchHeaderConfig = async () => {
  const response = await performRequest<HeaderConfig>(
    `${Endpoints.HEADER_CONFIG}`,
    ApiMethodType.GET,
    null
  );
  return response.data;
};
