export enum ViewTypes {
  ASSIGNMENT_VIEW = 'assignment_view',
  USER_VIEW = 'user_view',
  COURSE_VIEW = 'course_view',
}

export enum UserAssignmentType {
  SMART_GROUP = 'smartGroup',
  TRANIEES = 'traniees',
}
