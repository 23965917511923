import { defaultUrlConfig } from '@celito.clients/endpoints';
import { NotificationContext } from 'libs/core/src/providers/in-app-notification-provider';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { HeaderProps, INavLinkGroup } from './header.model';
import HeaderView from './header.view';
import { NavigationConfigItem, NavigationLink } from './types';

const TABLET_VIEW_WIDTH = 1000;
const MOBILE_VIEW_WIDTH = 600;

interface HeaderControllerProps extends HeaderProps {}

const HeaderController = (props: HeaderControllerProps): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const { hasUnreadNotifications } = useContext(NotificationContext);

  const isInitialTabSet = useRef(false);
  const isInitialSubtabSet = useRef(false);

  const [showHeaderTooltip, setShowHeaderTooltip] = useState(false);
  const [showPrimaryDropdown, setShowPrimaryDropdown] = useState(false);
  const [showSecondaryDropdown, setShowSecondaryDropdown] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeDropdownItem, setActiveDropdownItem] = useState<
    NavigationConfigItem | undefined
  >();

  const [activeNavItem, setActiveNavItem] = useState<
    NavigationLink | undefined
  >();

  // value null indicates the initial value
  // when it becomes undefined we can be sure that the activeSubNavItem is checked
  // and set to undefined because the current selected tab doesn't have a sub tab
  const [activeSubNavItem, setActiveSubNavItem] = useState<
    NavigationLink | undefined | null
  >(null);

  // useEffect(() => {
  //   try {
  //     setInterval(async () => {
  //       const response = await notificationService().getInAppNotifications(
  //         1,
  //         10
  //       );
  //       setUnreadNotifications(
  //         response?.data?.filter((d: Notification) => d.viewedOnUtc === null)
  //       );
  //     }, 10000);
  //   } catch (error) {
  //     errorToast({ message: 'Unable to fetch notifications' });
  //   }
  // }, []);

  useEffect(() => {
    if (window.innerWidth <= TABLET_VIEW_WIDTH) {
      setIsTabletView(true);
    }
  }, []);

  useEffect(() => {
    const handleWidthChange = () => {
      if (!isTabletView && window.innerWidth <= TABLET_VIEW_WIDTH) {
        setIsTabletView(true);
      } else if (isTabletView && window.innerWidth > TABLET_VIEW_WIDTH) {
        setIsTabletView(false);
      }
      if (!isMobileView && window.innerWidth <= MOBILE_VIEW_WIDTH) {
        setIsMobileView(true);
      } else if (isMobileView && window.innerWidth > MOBILE_VIEW_WIDTH) {
        setIsMobileView(false);
      }
    };
    window.addEventListener('resize', handleWidthChange);

    return () => {
      window.removeEventListener('resize', handleWidthChange);
    };
  }, [isTabletView, isMobileView]);

  // listen to moduleName changes in the URL
  useEffect(() => {
    if (params.moduleName === defaultUrlConfig.defaultModuleName) {
      const firstModule = props.navigationConfig?.[0].modules?.[0];
      setActiveDropdownItem(firstModule);
    } else {
      let foundModuleName: NavigationConfigItem | undefined;
      props.navigationConfig.forEach((application) => {
        application.modules.forEach((module) => {
          if (module.systemName === params.moduleName) {
            foundModuleName = module;
          }
        });
      });

      if (foundModuleName) {
        setActiveDropdownItem(foundModuleName);
      }
    }
  }, [params.moduleName, props.navigationConfig, navigate]);

  // listen to tabName changes in the URL
  useEffect(() => {
    if (
      params.tabName === defaultUrlConfig.defaultTabName ||
      params.tabName === defaultUrlConfig.homeTabName
    ) {
      setActiveNavItem(undefined);
    } else {
      isInitialTabSet.current = true;
      const foundTabName =
        activeDropdownItem?.navigationLinks.find(
          (tab) => tab.systemName === params.tabName
        ) ||
        (activeDropdownItem?.settingLinks?.systemName === params.tabName
          ? activeDropdownItem?.settingLinks
          : undefined);

      if (foundTabName) {
        setActiveNavItem(foundTabName);
      }
    }
  }, [params.tabName, activeDropdownItem]);

  // listen to subTabName changes in the URL
  useEffect(() => {
    if (params.subTabName === defaultUrlConfig.defaultSubTabName) {
      setActiveSubNavItem(undefined);
      isInitialSubtabSet.current = true;
    } else {
      if (activeNavItem) {
        isInitialSubtabSet.current = true;
        const foundSubTabName = activeNavItem?.subLinks?.find(
          (subTab) => subTab.systemName === params.subTabName
        );
        setActiveSubNavItem(foundSubTabName);
      }
    }
  }, [params.subTabName, activeNavItem]);

  const handleDropdownLinkClick = (_selectedPage: NavigationConfigItem) => {
    setShowPrimaryDropdown(false);
  };

  const handleNavLinkClick = (selectedLinkKey?: string) => {
    if (selectedLinkKey) {
      const foundKey = activeDropdownItem?.navigationLinks.find(
        (item) => item.link === selectedLinkKey
      );
      if (foundKey) {
        if (isSidebarOpen) {
          toggleSidebar(false);
        }
      }
    } else if (isSidebarOpen) {
      toggleSidebar(false);
    }
  };

  const togglePrimaryDropdown = () => {
    setShowPrimaryDropdown((prev) => !prev);
  };

  const toggleSecondaryDropdown = () => {
    setShowSecondaryDropdown((prev) => !prev);
  };

  const toggleSidebar = (value?: boolean) => {
    setIsSidebarOpen((prev) => value ?? !prev);
  };

  const getNavLinkGroups = useCallback(() => {
    const navLinkConfig: INavLinkGroup[] = [{ links: [] }];
    activeDropdownItem?.navigationLinks.forEach((item) => {
      navLinkConfig[0].links.push({
        key: item.link,
        name: item.name,
        url: item.link || '',
        links: item.subLinks?.map((sublink, i) => ({
          key: String(i),
          name: sublink.name,
          url: sublink.link || '',
        })),
      });
    });
    return navLinkConfig;
  }, [activeDropdownItem]);

  return (
    <HeaderView
      activeDropdownItem={activeDropdownItem}
      activeNavItem={activeNavItem}
      activeSubNavItem={activeSubNavItem}
      handleDropdownLinkClick={handleDropdownLinkClick}
      handleNavLinkClick={handleNavLinkClick}
      showPrimaryDropdown={showPrimaryDropdown}
      showSecondaryDropdown={showSecondaryDropdown}
      togglePrimaryDropdown={togglePrimaryDropdown}
      toggleSecondaryDropdown={toggleSecondaryDropdown}
      getNavLinkGroups={getNavLinkGroups}
      isTabletView={isTabletView}
      isMobileView={isMobileView}
      isSidebarOpen={isSidebarOpen}
      toggleSidebar={toggleSidebar}
      showHomeTitle={params.tabName === defaultUrlConfig.defaultTabName}
      showHeaderTooltip={showHeaderTooltip}
      setShowHeaderTooltip={setShowHeaderTooltip}
      isUnreadMessageAvailable={hasUnreadNotifications}
      {...props}
    />
  );
};

export default HeaderController;
