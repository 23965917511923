import { DateFormat, ROUTES } from '@celito.clients/enums';
import { UserTypes } from '@celito.clients/types';
import { formatDate, raiseErrorToast } from '@celito.clients/utils';
import { useUser } from 'libs/core/src/hooks/useUser';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import ViewUser from './view-user.view';

const transformFormData = (userData?: UserTypes) => {
  const formData = [
    {
      label: 'Name',
      value: userData?.label,
    },
    {
      label: 'Email',
      value: userData?.email,
    },
    {
      label: 'Department',
      value: userData?.department,
    },
    {
      label: 'Title',
      value: userData?.jobTitle,
    },
    {
      label: 'Phone',
      value: userData?.mobileNumber,
    },
    {
      label: 'Manager',
      value: userData?.manager,
    },
    {
      label: 'Country',
      value: userData?.country,
    },
    {
      label: 'Timezone',
      value: userData?.timezone,
    },

    {
      label: 'Status',
      value: userData?.isActive ? 'Active' : 'Inactive',
    },
    {
      label: 'Activation Date',
      value: userData?.createdAtUtc
        ? formatDate(userData.createdAtUtc, DateFormat.DateTime)
        : '',
    },
    {
      label: 'Activation By',
      value: userData?.createdBy.label,
    },
    {
      label: 'Modified By',
      value: userData?.modifiedBy.label,
    },
    {
      label: 'Last Modified Date',
      value: userData?.modifiedAtUtc
        ? formatDate(userData.modifiedAtUtc, DateFormat.DateTime)
        : '',
    },
  ];
  return formData;
};

const ViewUserController: React.FC = () => {
  const [isUserDataLoading, setIsUserDataLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserTypes | undefined>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const { name = '' } = useParams();
  const { fetchUserInfo } = useUser();

  const onEditBtnClick = () => {
    navigate(`../${ROUTES.USER.replace(':name', name)}`);
  };

  const navigateBackLink = `../${ROUTES.LIST}/user_list_view__s`;

  const navigateBack = () => {
    navigate(-1);
  };

  const getUserData = useCallback(() => {
    setIsUserDataLoading(true);
    fetchUserInfo(name)
      .then((resp: UserTypes) => {
        setUserData(resp);
        setIsActive(resp.isActive || false);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsUserDataLoading(false);
      });
  }, [name]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <ViewUser
      {...{
        isActive,
        userData,
        transformFormData,
        isUserDataLoading,
        onEditBtnClick,
        navigateBackRoute: navigateBackLink,
        navigateBack,
      }}
    />
  );
};

export default ViewUserController;
