import { useEffect, useRef, useState } from 'react';

interface DragItem {
  type: string;
  id: string;
  [key: string]: any;
}

interface UseDragResult {
  isDragging: boolean;
  dragRef: React.RefObject<HTMLDivElement>;
  previewRef: React.RefObject<HTMLDivElement>;
}

interface UseDropParams<T> {
  accept: string;
  drop: (item: T) => void;
}

interface UseDropResult {
  dropRef: React.RefObject<HTMLDivElement>;
}

export const useDrag = <T extends DragItem>(item: T): UseDragResult => {
  const [isDragging, setIsDragging] = useState(false);
  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dragStartHandler = (e: DragEvent) => {
      setIsDragging(true);
      e.dataTransfer?.setData('application/json', JSON.stringify(item));
      e.dataTransfer!.effectAllowed = 'move';

      if (previewRef.current) {
        const rect = previewRef.current.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;
        e.dataTransfer!.setDragImage(previewRef.current, offsetX, offsetY);
      }
    };

    const dragEndHandler = () => {
      setIsDragging(false);
    };

    const elem = dragRef.current;
    if (elem) {
      elem.setAttribute('draggable', 'true');
      elem.addEventListener('dragstart', dragStartHandler);
      elem.addEventListener('dragend', dragEndHandler);
    }

    return () => {
      if (elem) {
        elem.removeEventListener('dragstart', dragStartHandler);
        elem.removeEventListener('dragend', dragEndHandler);
      }
    };
  }, [item]);

  return { isDragging, dragRef, previewRef };
};

export const useDrop: <T extends DragItem>(
  params: UseDropParams<T>
) => UseDropResult = <T extends DragItem>({
  accept,
  drop,
}: UseDropParams<T>): UseDropResult => {
  const dropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.dataTransfer!.dropEffect = 'move';
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      const item = e.dataTransfer?.getData('application/json');
      if (item) {
        const parsedItem: T = JSON.parse(item);
        if (parsedItem.type === accept) {
          drop(parsedItem);
        }
      }
    };

    const elem = dropRef.current;
    if (elem) {
      elem.addEventListener('dragover', handleDragOver);
      elem.addEventListener('drop', handleDrop);
    }

    return () => {
      if (elem) {
        elem.removeEventListener('dragover', handleDragOver);
        elem.removeEventListener('drop', handleDrop);
      }
    };
  }, [accept, drop]);

  return { dropRef };
};
