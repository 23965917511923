import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { ViewMetadata } from '../types/view-metadata.type';

export const deleteObject = async (
  objectName: string,
  recordName: string
): Promise<ViewMetadata> => {
  const response = await performRequest<ViewMetadata>(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}/${recordName}`,
    ApiMethodType.DELETE,
    {
      objectName,
      recordName,
    }
  );
  return response.data;
};

export const deleteWorkflowObject = async (
  workflowDefinitionName: string,
  workflowDefinitionVersion: string
): Promise<ViewMetadata> => {
  const response = await performRequest<ViewMetadata>(
    `workflow-definitions/${workflowDefinitionName}/${workflowDefinitionVersion}`,
    ApiMethodType.DELETE,
    {}
  );
  return response.data;
};
