import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const iconButtonStlyes = makeStyles({
  btn: {
    height: '33px',
    ...shorthands.borderRadius('50%'),
    backgroundColor: 'transparent',
    ...shorthands.border(0),
    display: 'flex',
    alignItems: 'center',

    ':hover': {
      backgroundColor: Color.icon_button_hover,
    },
  },

  disabled: {
    ':hover': {
      backgroundColor: 'transparent',
    },
    '> span svg': {
      color: `var(--colorNeutralForegroundDisabled)`,
    },
  },
});
