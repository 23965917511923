import { OperatorsEnum } from '@celito.clients/enums';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

export const assignmentViewDefaultFilter: RulesComponentListViewFilter = {
  conditions: {
    all: [
      {
        all: [
          {
            attribute: 'isActive',
            operator: OperatorsEnum.EQUALS,
            value: true,
          },
        ],
      },
    ],
  },
};
