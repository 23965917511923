export enum CurriculumAttributeNameEnum {
  TITLE = 'title__a',
  IS_GXP = 'is_gxp__a',
  SEQUENCE = 'is_sequence__a',
  OWNER = 'owner__a',
  IS_APPROVAL_REQUIRED = 'is_approval_required__a',
  CONTENT_APPROVERS = 'content_approvers__a',
  STATUS = 'status__a',
  TRAINEES = 'trainees__a',
  SMART_GROUPS = 'smart_groups__a',
  COURSES = 'courses__a',
  VERSION = 'version__s',
  INITIATION_TYPE = 'initiation_type__a',
  IS_OBSOLETE_APPROVED = 'is_obsolete_approved__a',
  PROPOSED_OBSOLETION_DATE = 'proposed_obsoletion_date__a',
  OBSOLETE_JUSTIFICATION = 'obsolete_justification__a',
  OBSOLETION_DATE = 'obsoletion_date__a',
  APPROVAL_DUE_DATE = 'approval_due_date__a',
  APPROVED_DATE = 'approved_date__a',
  SUPERSEDED_DATE = 'superseded_date__a',
}

export enum CurriculumObjectKeyEnum {
  TITLE = 'title',
  IS_GXP = 'isGxp',
  SEQUENCE = 'sequence',
  OWNER = 'owner',
  IS_APPROVAL_REQUIRED = 'isApprovalRequired',
  CONTENT_APPROVERS = 'contentApprovers',
  STATUS = 'status',
  TRAINEES = 'trainees',
  SMART_GROUPS = 'smartGroups',
  COURSES = 'courses',
  VERSION = 'version',
  INITIATION_TYPE = 'initiationType',
  IS_OBSOLETE_APPROVED = 'isObsoleteApproved',
  PROPOSED_OBSOLETION_DATE = 'proposedObsoletionDate',
  OBSOLETION_DATE = 'obsoletionDate',
  OBSOLETE_JUSTIFICATION = 'obsoletionJustification',
  APPROVAL_DUE_DATE = 'approvalDueDate',
  APPROVED_DATE = 'approvedDate',
  SUPERSEDED_DATE = 'supersededDate',
}

export enum CurriculumObjectColumnNameEnum {
  TITLE = 'title',
  IS_GXP = 'isGxp',
  SEQUENCE = 'sequence',
  OWNER = 'owner',
  IS_APPROVAL_REQUIRED = 'isApprovalRequired',
  CONTENT_APPROVERS = 'contentApprovers',
  STATUS = 'status',
  TRAINEES = 'trainees',
  SMART_GROUPS = 'smartGroups',
  COURSES = 'courses',
  VERSION = 'version',
  INITIATION_TYPE = 'initiationType',
  IS_OBSOLETE_APPROVED = 'isObsoleteApproved',
  PROPOSED_OBSOLETION_DATE = 'proposedObsoletionDate',
  OBSOLETION_DATE = 'obsoletionDate',
  OBSOLETE_JUSTIFICATION = 'obsoletionJustification',
  APPROVAL_DUE_DATE = 'approvalDueDate',
  APPROVED_DATE = 'approvedDate',
  SUPERSEDED_DATE = 'supersededDate',
}
