export enum SmartGroupsObjectAttributeNameEnum {
  GROUP_TYPE = 'group_type__a',
  OWNER = 'owner__a',
  DESCRIPTION = 'description__a',
  RULES = 'rules__a',
  USERS = 'users__a',
  TITLE = 'title__a',
}

export enum SmartGroupsObjectKeyEnum {
  GROUP_TYPE = 'groupType',
  OWNER = 'owner',
  DESCRIPTION = 'description',
  RULES = 'rules',
  USERS = 'users',
  TITLE = 'title',
}

export enum SmartGroupsObjectColumnNameEnum {
  GROUP_TYPE = 'groupType',
  OWNER = 'owner',
  DESCRIPTION = 'description',
  RULES = 'rules',
  USERS = 'users',
  TITLE = 'title',
}
