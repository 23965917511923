import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledDropdownController } from './controlled-dropdown.controller';
import { ControlledDropdownProps } from './controlled-dropdown.model';

const ControlledDropdown = <T extends FieldValues>(
  props: ControlledDropdownProps<T>
) => {
  return <ControlledDropdownController {...props} />;
};

export default ControlledDropdown;
