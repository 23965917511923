import { LocalizationString } from '@celito.clients/assets';
import { ObjectEnum } from '@celito.clients/enums';
import {
  useCallbackPrompt,
  useLayout,
  useObjectDefinition,
  useView,
} from '@celito.clients/hooks';
import { deleteDocuments } from '@celito.clients/services';
import {
  Field,
  LayoutRulesDataSchema,
  ObjectAttributeDefinition,
} from '@celito.clients/types';
import {
  formatDateInTimezone,
  raiseErrorToast,
  requiresVersionParam,
  successToast,
} from '@celito.clients/utils';
import { SelectTabData, SelectTabEvent } from '@fluentui/react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLayoutRules } from 'libs/form-engine/src/lib/hooks/useLayoutRules';
import { RecorLevelUserContextFields } from 'libs/form-engine/src/lib/types/common-types';
import {
  getFormValues,
  refactorFormData,
} from 'libs/form-engine/src/lib/utils/helper';
import { generateYupSchemaFromLayoutRules } from 'libs/form-engine/src/lib/utils/validator-generator';
import { FileDataProps } from 'libs/shared/src/lib/multi-file-upload/multi-file-upload.model';
import {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { FieldEnum } from '../../config/field-enum';
import { sections } from '../../config/section';
import { useScreenContext } from '../../context';
import {
  getRecordDataQueryKey,
  useRecordData,
} from '../../hooks/use-record-data';
import { saveDetailsAPI } from '../../services';
import { ControlledDocs } from '../../types';
import {
  getDefaultValues,
  mergeSections,
  overrideIsRequired,
} from '../../utils';

export const useController = () => {
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<yup.AnyObject>
  >({} as yup.ObjectSchema<yup.AnyObject>);

  const { data: record, isLoading: recordDataIsLoading } = useRecordData();

  const { mode, recordName, taskName, version, userActions } =
    useScreenContext();

  const { configureLayout } = useLayout();

  const { data: objectDefinition } = useObjectDefinition({
    objectName: ObjectEnum.CONTROLLED_DOCUMENT,
    recordName,
    version,
    queryOptions: {
      enabled: false,
    },
  });

  const attributeDefinitions = useMemo(() => {
    return objectDefinition?.objectAttributeDefinitions?.reduce<
      Record<string, ObjectAttributeDefinition>
    >((acc, attribute) => {
      return {
        ...acc,
        [attribute.name]: attribute,
      };
    }, {});
  }, [objectDefinition]);

  const { data: viewData } = useView({
    view: 'controlled_document_custom_edit_view__a',
  });

  const mergedSections = useMemo(() => {
    const { viewDto } = viewData ?? {};
    const mergedSections = mergeSections(viewDto?.view[0]?.sections ?? []);
    return overrideIsRequired(mergedSections, attributeDefinitions);
  }, [viewData, attributeDefinitions]);

  const fieldsMap = useMemo(() => {
    return mergedSections.fields.reduce<Record<string, Field>>(
      (acc, field: Field) => {
        return { ...acc, [field.columnName]: field };
      },
      {}
    );
  }, [mergedSections]);

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: getDefaultValues(fieldsMap, attributeDefinitions),
  });

  const navigate = useNavigate();

  const formData = form.watch();

  const { handleSubmit, reset } = form;

  const formDataStringified = JSON.stringify(formData);

  const { fieldsState, setFieldsState } = useLayoutRules(
    formData,
    mergedSections
  );

  const fieldsStateStringified = JSON.stringify(fieldsState);

  const isDirty = form.formState.isDirty;

  const [inSubmission, setInSubmission] = useState(false);

  const isResettable = (!isDirty && mode === 'edit') || mode === 'view';

  useEffect(() => {
    if (!record || !objectDefinition) return;
    if (isResettable) {
      form.reset(getFormValues(objectDefinition, record));
    }
  }, [record, form, objectDefinition, mode]);

  useEffect(() => {
    const fieldsStateParsed = JSON.parse(fieldsStateStringified) as Record<
      string,
      LayoutRulesDataSchema
    >;
    const newSchema = generateYupSchemaFromLayoutRules(
      form.watch(),
      mergedSections.fields,
      objectDefinition?.objectValidationRules ?? [],
      objectDefinition?.objectAttributeDefinitions ?? [],
      fieldsStateParsed
    );

    if (
      newSchema &&
      typeof newSchema === 'object' &&
      Object.keys(newSchema).length > 0
    ) {
      setValidationSchema(newSchema);
    }
  }, [
    formDataStringified,
    fieldsStateStringified,
    form,
    mergedSections,
    objectDefinition,
  ]);

  const hiddenFieldsInFieldsState = useMemo(() => {
    const fieldsState = JSON.parse(fieldsStateStringified);

    return Object.keys(fieldsState).filter(
      (field) => !fieldsState[field]?.isVisible
    );
  }, [fieldsStateStringified]);

  const queryClient = useQueryClient();

  const sectionRefs = useRef<
    Array<{ ref: RefObject<HTMLDivElement>; tabName: string }>
  >(
    sections.map(({ name: tabName }) => ({
      ref: createRef(),
      tabName,
    }))
  );

  const mcContainerRef = useRef<HTMLDivElement>(null);

  const scrollToSelectedPanel = useCallback(
    (_e: SelectTabEvent, d: SelectTabData) => {
      const currentRef = sectionRefs.current.find(
        ({ tabName }) => tabName === d.value
      )?.ref;
      mcContainerRef.current?.scrollTo({
        top: (currentRef?.current?.offsetTop ?? 0) - 124,
        behavior: 'smooth',
      });
    },
    [sectionRefs]
  );

  const hideKeyDates = !!useMemo(() => {
    const keyDatesFields = [
      FieldEnum.APPROVED_DATE,
      FieldEnum.PROPOSED_EFFECTIVE_DATE,
      FieldEnum.EFFECTIVE_DATE,
      FieldEnum.SUPERSEDED_DATE,
      FieldEnum.OBSOLETION_DATE,
    ] as string[];

    const isHidden = objectDefinition?.objectAttributeDefinitions
      ?.filter?.((obj) => keyDatesFields.includes(obj?.name))
      .every((obj) => obj?.isHidden);

    return isHidden;
  }, [objectDefinition]);

  const routeBackToViewPage = useCallback(() => {
    navigate(
      window.location.pathname.replace(
        'edit/custom/controlled_document_edit_view__a',
        'view/custom/controlled_document_detail_view__a'
      ) +
        '?' +
        createSearchParams({
          ...(taskName ? { taskName } : {}),
          ...(requiresVersionParam(
            record?.version ?? '',
            record?.documentStatus as string
          )
            ? { version: record?.version ?? '' }
            : {}),
        })
    );
  }, [navigate, record?.version, taskName]);

  const deleteDocumentsApi = useCallback(
    async (
      documentIds: string[],
      attributeName: string,
      objectName: string,
      recordName: string
    ) => {
      if (!documentIds.length) return { success: true };

      try {
        await deleteDocuments(
          documentIds,
          objectName,
          attributeName,
          recordName
        );

        form.setValue(`deleted_${attributeName}`, []);

        return { success: true };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (_error) {
        raiseErrorToast(_error);
        return { success: false };
      }
    },
    [form]
  );

  const deleteHiddenFieldsFromPayload = useCallback(
    (payload: FormData) => {
      hiddenFieldsInFieldsState.forEach((field) => {
        if (!attributeDefinitions?.[field]?.isHidden) payload.delete(field);
      });
    },
    [hiddenFieldsInFieldsState, attributeDefinitions]
  );

  const onSubmit = async (data: FieldValues) => {
    if (!recordName || !objectDefinition) return;

    try {
      data = refactorFormData(objectDefinition, data);

      if (!data?.file__a?.documentId) data.file__a = form.getValues('file__a');

      const attachments = form.getValues(FieldEnum.ATTACHMENTS);
      if (attachments?.length > 0) {
        data.secondary_file__a = attachments.filter(
          (attachment: File | FileDataProps) => attachment instanceof File
        );
      }
      const markedForDeleteAttachments =
        form.getValues(`deleted_${FieldEnum.ATTACHMENTS}`) ?? [];
      const markedForDeleteFile =
        form.getValues(`deleted_${FieldEnum.FILE}`) ?? [];

      const fileDeleteResponse = await deleteDocumentsApi(
        markedForDeleteFile,
        FieldEnum.FILE,
        ObjectEnum.CONTROLLED_DOCUMENT,
        recordName
      );

      if (!fileDeleteResponse.success) {
        return;
      }

      delete data?.[FieldEnum.ATTACHMENTS];
      delete data?.[`deleted_${FieldEnum.ATTACHMENTS}`];
      delete data?.[`deleted_${FieldEnum.FILE}`];
      delete data?.[FieldEnum.ATTACHMENTS];
      delete data?.['name__s'];
      delete data?.['label__s'];
      delete data?.['isDirty'];
      delete data?.['lifecycle_stage__s'];
      delete data?.['document_status__a'];
      delete data?.['modified_by_user__s'];
      delete data?.['created_by_user__s'];
      delete data?.['created_at_utc__s'];
      delete data?.['modified_at_utc__s'];
      delete data?.version__s;
      delete data?.[RecorLevelUserContextFields.RECORD_AND_SYSTEM_ROLES];

      const payload = new FormData();

      if (markedForDeleteFile?.length > 0) {
        payload.append(`documentDeleted`, 'true');
      }

      if (markedForDeleteAttachments.length > 0) {
        payload.append(
          'deletedDocumentIds',
          JSON.stringify(markedForDeleteAttachments)
        );
      }

      Object.keys(data).forEach((key) => {
        if (data[key] !== undefined && !(data[key] instanceof Date)) {
          // for multi file uploads
          if (Array.isArray(data[key]) && key === 'secondary_file__a') {
            for (const value of data[key]) {
              payload.append(key, value);
            }
            return;
          }
          if (Array.isArray(data[key])) {
            payload.append(
              key,
              data[key].length ? JSON.stringify(data[key]) : 'null'
            );
          } else {
            payload.append(key, data[key]);
          }
        }
        if (data[key] instanceof Date) {
          payload.append(key, formatDateInTimezone(data[key]));
        }
        deleteHiddenFieldsFromPayload(payload);
      });

      const res = await saveDetailsAPI(
        recordName,
        payload,
        ObjectEnum.CONTROLLED_DOCUMENT,
        { version }
      );

      form.reset({ ...form.getValues() });

      if (res) {
        if (typeof res === 'object' && 'record' in res) {
          form.setValue(`deleted_${FieldEnum.ATTACHMENTS}`, []);

          const record = res.record as ControlledDocs;
          const editQueryKey = getRecordDataQueryKey(
            recordName,
            version,
            'edit'
          );
          const viewQueryKey = getRecordDataQueryKey(
            recordName,
            version,
            'view'
          );
          queryClient.setQueryData(viewQueryKey, record);
          queryClient.setQueryData(
            editQueryKey,
            Object.assign(record, {
              recordUserActions: (
                queryClient.getQueryData(editQueryKey) as ControlledDocs
              )?.recordUserActions,
            })
          );
          configureLayout({
            headerTitle: record.title,
            pageTitle: '',
            enablePadding: false,
          });
        }
        successToast({
          message: LocalizationString.SUCCESSFULLY_UPDATED_MSG,
        });
      }
    } catch (error) {
      raiseErrorToast(error);
      throw error;
    } finally {
      setInSubmission(false);
    }
  };

  const onSubmitClick = () => {
    setInSubmission(true);
  };

  const onSubmitErrorhandler = () => {
    setInSubmission(false);
  };

  const isSubmitSuccessful = form.formState.isSubmitSuccessful;

  useEffect(() => {
    if (isSubmitSuccessful) routeBackToViewPage();

    return () => {
      reset(undefined, { keepIsSubmitted: false });
    };
  }, [isSubmitSuccessful, routeBackToViewPage, reset]);

  useEffect(() => {
    if (inSubmission) handleSubmit(onSubmit, onSubmitErrorhandler)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inSubmission, handleSubmit]);

  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(isDirty);

  return {
    objectDefinition,
    attributeDefinitions,
    record,
    fieldsState,
    setFieldsState,
    mode,
    form,
    recordName,
    taskName,
    navigate,
    onCancelClick: routeBackToViewPage,
    onSubmitClick,
    disableSaveButton: false,
    mcContainerRef,
    recordDataIsLoading,
    sectionRefsMap: useMemo(
      () =>
        sectionRefs.current.reduce((acc, { ref, tabName }) => {
          acc[tabName] = ref;
          return acc;
        }, {} as Record<string, RefObject<HTMLDivElement>>),
      [sectionRefs]
    ),
    tablistProps: {
      sectionRefs: sectionRefs.current,
      scrollToSelectedPanel,
      hideKeyDates,
      mcContainerRef,
    },
    fieldsMap,
    mergedSections,
    userActions,
    confirmNavigation,
    cancelNavigation,
    showPrompt,
  };
};
