import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { isCancel } from 'axios';
import Endpoints from 'libs/core/src/config/endpoint';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import { Datum, ObjectData, SortConfig } from '../types';

export const TemporaryUnifyDefaultAndUserFilters = (
  defaultFilters?: RulesComponentListViewFilter,
  filters?: RulesComponentListViewFilter,
  recordDataFilter?: RulesComponentListViewFilter
) => {
  if (!defaultFilters && !filters) return [];

  const joinedFilters = [
    ...(defaultFilters?.conditions ? [defaultFilters.conditions] : []),
    ...(filters?.conditions ? [filters.conditions] : []),
    ...(recordDataFilter?.conditions ? [recordDataFilter.conditions] : []),
  ].filter(Boolean);

  const nestedCondition = { all: joinedFilters };

  const finalFilter = [
    {
      conditions: {
        ...nestedCondition,
      },
    },
  ];

  return joinedFilters?.length === 0 ? [] : finalFilter;
};

export interface GetObjectDataOptions {
  filters?: RulesComponentListViewFilter;
  sortConfig?: SortConfig;
  defaultFilters?: RulesComponentListViewFilter;
  recordDataFilter?: RulesComponentListViewFilter;
  defaultSortConfig?: SortConfig;
}

export const getObjectData = async <T = Datum>(
  objectName: string,
  paginationData: {
    limit: number;
    page: number;
  },
  {
    filters,
    sortConfig,
    defaultFilters,
    recordDataFilter,
    defaultSortConfig,
  }: GetObjectDataOptions = {},
  shouldNotGetLatestVersion?: boolean,
  signal?: AbortSignal
): Promise<ObjectData<T> | undefined> => {
  const requestBody = {
    filters: TemporaryUnifyDefaultAndUserFilters(
      defaultFilters,
      filters,
      recordDataFilter
    ),
    sort: sortConfig || defaultSortConfig,
  };

  try {
    const response = await performRequest<ObjectData<T>>(
      `${Endpoints.OBJECT_DATA_URL}/${objectName}`,
      ApiMethodType.POST,
      requestBody,
      {
        limit: paginationData.limit,
        page: paginationData.page,
        ...(shouldNotGetLatestVersion && { getAllVersions: true }),
      },
      undefined,
      signal
    );

    return response.data;
  } catch (err) {
    if (isCancel(err)) {
      return undefined;
    }

    throw err;
  }
};
