import { ColumnDef } from '@tanstack/react-table';

import { GridViewProps } from '../../grid-view.model';
import { RowItem } from '../../types';
import customColumn from './custom-column/custom-column';
import defaultColumn from './default-column/default-column';
import expandColumn from './expand-column/expand-column';
import selectColumn from './select-column/select-column';

const getColumnDefs = (
  columns: GridViewProps['columns'],
  disableSelection: GridViewProps['disableSelection'],
  areAllGroupsExpanded: boolean,
  hasGroups: boolean,
  enableMultiRowSelection?: boolean
) => {
  const cols: ColumnDef<RowItem>[] = [];

  if (hasGroups) {
    cols.push(expandColumn(areAllGroupsExpanded));
  }

  if (!disableSelection) {
    cols.push(selectColumn(enableMultiRowSelection));
  }

  columns.forEach((col, i) => {
    if (typeof col.onRender === 'function') {
      cols.push(customColumn(col, i, columns));
      return;
    }

    cols.push(defaultColumn(col));
  });

  return cols;
};

export default getColumnDefs;
