import { DeleteIcon, LocalizationString } from '@celito.clients/assets';
import { SortOrderEnum } from '@celito.clients/enums';
import { ConfirmDialog, GridView, GridViewProps } from '@celito.clients/shared';
import {
  getIsSortedAscending,
  getSortOrder,
} from 'libs/list-view-engine/src/lib/utils/sort.util';
import {
  ColumnData,
  RowItem,
} from 'libs/shared/src/lib/grid-view-new/src/types';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { getColumnsConfigByObjectName } from '../utils';
import { UserViewPanelWrapper } from './user-view-panel.component';

interface Props extends Pick<React.ComponentProps<typeof GridView>, 'items'> {
  onRemoveItem: (item: RowItem) => void;
  onDownloadItem?: (item: RowItem) => void;
  objectName: string;
  canReorderRows?: boolean;
  showDownloadButton?: boolean;
  onRowReorder?: (draggedRowIndex: number, targetRowIndex: number) => void;
  loading?: boolean;
  disabled?: boolean;
  label: string;
}

const ReferenceSelectorGrid: React.FC<Props> = ({
  onRemoveItem,
  onDownloadItem,
  objectName,
  showDownloadButton,
  loading,
  disabled,
  label,
  ...props
}) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [isUserViewPanelOpen, setIsUserViewPanelOpen] = useState(false);
  const [rowData, setRowData] = useState<GridViewProps['items']>(props.items);
  const [columnData, setColumnData] = useState<GridViewProps['columns']>([]);
  const rowAddedOrDeletedRef = useRef(false);

  useEffect(() => {
    setRowData(props.items);
    rowAddedOrDeletedRef.current = true;
  }, [props.items]);

  useEffect(() => {
    if (rowAddedOrDeletedRef.current) {
      rowAddedOrDeletedRef.current = false;
      setColumnData(
        getColumnsConfigByObjectName(
          objectName,
          (item) => {
            activeRowItem.current = item;
            setShowDeleteAlert(true);
          },
          undefined,
          disabled,
          showDownloadButton,
          onDownloadItem,
          onColumnClick,
          onUserView
        )
      );
    }
  }, [rowData]);

  const closeDeleteAlert = React.useCallback(
    (deleteFile?: RowItem) => {
      if (deleteFile) {
        onRemoveItem(deleteFile);
      }
      setShowDeleteAlert(false);
    },
    [onRemoveItem]
  );
  const activeRowItem = React.useRef<RowItem | undefined>();

  const onUserView = (name: string) => {
    setIsUserViewPanelOpen(true);
    setUserName(name);
  };

  const onDismiss = () => {
    setIsUserViewPanelOpen(false);
  };

  const onColumnClick: ColumnData['onColumnClick'] = (
    _ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column: ColumnData
  ) => {
    setColumnData((prev) =>
      prev.map((c) => {
        if (c.key === column.key) {
          return {
            ...c,
            isSortedAscending: getIsSortedAscending(
              c.isSorted,
              c.isSortedAscending
            ),
            isSorted: true,
          };
        }
        return {
          ...c,
          isSorted: false,
        };
      })
    );
    const sortedData = _.orderBy(
      rowData,
      [column.fieldName],
      [
        getSortOrder(column.isSorted, column.isSortedAscending, true) as
          | SortOrderEnum.ASC_LOWERCASE
          | SortOrderEnum.DESC_LOWERCASE,
      ]
    );
    setRowData(sortedData);
  };

  return (
    <>
      <ConfirmDialog
        open={showDeleteAlert}
        onCancelClicked={(e) => {
          e.stopPropagation();
          closeDeleteAlert();
        }}
        onConfirmClicked={(e) => {
          e.stopPropagation();
          closeDeleteAlert(activeRowItem.current);
        }}
        primaryButtonText={LocalizationString.DELETE}
        title={LocalizationString.DELETE_RECORD}
        iconSrc={DeleteIcon}
        description={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_DELETE}
        secondaryButtonText={LocalizationString.CANCEL}
        isBlocking
      />
      <GridView
        disableSelection
        disableDefaultSorting
        columns={columnData}
        enableShimmer={loading}
        {...props}
        items={rowData}
      />

      {isUserViewPanelOpen && userName && (
        <UserViewPanelWrapper
          isOpen={isUserViewPanelOpen}
          label={label}
          name={userName}
          onDismiss={onDismiss}
        />
      )}
    </>
  );
};

export default ReferenceSelectorGrid;
