import { UserRulesPicker } from '@celito.clients/shared';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { ControlledRulesPickerProps } from './controlled-rules-picker.model';

interface ControlledRulesPickerViewProps<T extends FieldValues>
  extends ControlledRulesPickerProps<T> {}

export const ControlledRulesPickerView = <T extends FieldValues>(
  props: ControlledRulesPickerViewProps<T>
): JSX.Element => {
  return (
    <Controller
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error, isTouched },
      }) => {
        return (
          <UserRulesPicker
            value={value}
            onChange={onChange}
            objectName={props.attribute?.rulesObjectName}
            error={error}
            mode={props.mode}
            onBlur={onBlur}
            isTouched={isTouched}
            isRequired={
              'isRequired' in props.attribute
                ? props.attribute.isRequired
                : props.attribute.isMandatory
            }
          />
        );
      }}
    />
  );
};
