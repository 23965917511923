import { CustomRouterProps } from './custom-router.model';
import CustomRouterView from './custom-router.view';

interface CustomRouterControllerProps extends CustomRouterProps {}

const CustomRouterController = (
  props: CustomRouterControllerProps
): JSX.Element => {
  return <CustomRouterView {...props} />;
};

export default CustomRouterController;
