import React from 'react';

import PeoplePickerController from './people-picker.controller';
import { PeoplePickerProps } from './people-picker.model';

const PeoplePicker = (props: PeoplePickerProps) => {
  return <PeoplePickerController {...props} />;
};

export default PeoplePicker;
