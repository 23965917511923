import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';

import { ControlledDocumentStatusEnum } from '../enums/controlled-document';
import { FormEngineModeTypeEnum } from '../enums/form-engine-mode-type';
import { Role } from '../types/user-types';

export const getNameInitials = (name: string) => {
  if (name) {
    const nameObj = name.split(' ');
    const firstName = nameObj[0].charAt(0).toUpperCase();
    const lastName =
      nameObj.length >= 2
        ? nameObj[nameObj.length - 1].charAt(0).toUpperCase()
        : '';

    return `${firstName}${lastName}`;
  }
  return '?';
};

export const sortUsersByName = (users?: IOption[]) => {
  return users?.sort((a, b) => {
    const nameA = a.text.toLowerCase();
    const nameB = b.text.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const getWidthInPercentage = (
  width: number,
  columnWidthInPercentage = 33.33
) => {
  return width * columnWidthInPercentage + (width % 2 === 0 ? 0.5 : 0);
};

export const canUserAccessTabsAndSubTabs = (
  tab: string,
  subTab = '',
  tabs: string[] = ['']
) => {
  const userAccessTabs = tabs;
  const isTabAccessible = userAccessTabs.includes(tab);
  if (subTab) return isTabAccessible && userAccessTabs.includes(subTab);
  return isTabAccessible;
};

export const canUserAccessModule = (
  isAdmin: boolean,
  isBizAdmin: boolean,
  moduleName = ''
) => {
  if (moduleName === 'admin__a' && !isAdmin && !isBizAdmin) return false;
  return true;
};

export const checkTabs = (tabName: string, tabs = ['']) => {
  // Return true or false based on the access check result
  return tabs.includes(tabName);
};

export const isActionAllowed = (
  loggedInUserRoles: Role[],
  roles: string[] | null | undefined,
  mode?: FormEngineModeTypeEnum
): boolean => {
  if (mode && mode === FormEngineModeTypeEnum.View) {
    return true;
  }
  if (!roles) {
    return true;
  }
  if (loggedInUserRoles.length === 0 || roles?.length === 0) {
    return true;
  }

  const userRoles = loggedInUserRoles.map((role) => role.name);
  return roles.some((role) => userRoles.includes(role));
};

type TElement = HTMLDivElement | HTMLHeadingElement | HTMLSpanElement;

export const doesElementHaveXoverflow = (e: TElement) => {
  return e.offsetWidth < e.scrollWidth;
};

export const doesElementHaveYoverflow = (e: TElement) => {
  return e.offsetHeight < e.scrollHeight;
};

export const doesElementHaveEllipses = (e: TElement) => {
  return doesElementHaveYoverflow(e) || doesElementHaveXoverflow(e);
};

export const getWizardStatusLabel = (status = '') => {
  const outputText = status.replace(/([a-z])([A-Z])/g, '$1 $2');
  return outputText;
};

export const isExistInUrl = (value: string) => {
  const url = window.location.href;
  return url.indexOf(value) > -1;
};

export const isArrayOfStrings = (value?: Array<unknown>): boolean => {
  return value?.every((item) => typeof item === 'string') ?? false;
};
export const debounce = (
  f: (inputValue: string) => void,
  interval = 1000
): any => {
  let timer: NodeJS.Timeout;

  return (args: string) => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(f(args)), interval);
    });
  };
};

export const isMajorVersion = (version: string) => {
  return !isNaN(Number(version)) && Number(version) % 1 === 0;
};

export const isRecordInState = (
  recordStatus: string,
  requiredState: string
) => {
  return recordStatus === requiredState;
};

export const requiresVersionParam = (version: string, recordState: string) => {
  if (!version) return false;

  return (
    isMajorVersion(version) ||
    isRecordInState(recordState, ControlledDocumentStatusEnum.CANCELED)
  );
};
