import { ObjectAttributeDefinition } from '@celito.clients/types';
import { AttributeDataTypeEnum } from 'apps/web-client/src/types/object-attribute-definition';
import { IOption } from 'libs/shared/src/lib/rules-component/rules-component.model';

import { FilterableColumn } from '../../../types';

export const getFilterFields = (
  filterableColumns: FilterableColumn[],
  objectAttributeDefinitions: ObjectAttributeDefinition[]
): IOption[] => {
  return filterableColumns
    .map(({ name, attributeNameToFilter, label: filterableColumnLabel }) => {
      const field = objectAttributeDefinitions.find(
        (item) => item.name === name
      );

      if (
        attributeNameToFilter &&
        attributeNameToFilter !== 'label' &&
        attributeNameToFilter !== 'label__s'
      )
        return {
          dataType: AttributeDataTypeEnum.PlainText,
          text: filterableColumnLabel,
          value: `${name.split('__')?.[0]}.${attributeNameToFilter}`,
          relationship: undefined,
          picklistName: undefined,
        };

      if (!field) return null;

      const { columnName, dataType, label, picklistName } = field;

      const item: IOption = {
        dataType: dataType,
        text: filterableColumnLabel ?? label,
        value: columnName,
        relationship: field.relationship,
        picklistName: picklistName,
      };

      return item;
    })
    .filter((field): field is IOption => field !== null);
};

export default getFilterFields;
