import { ColumnData } from '../../../grid-view-new/src/types';
import classes from './user-name-column.module.css';

interface UserNameColumnProps {
  item: any;
  index?: number;
  column?: ColumnData;
  onUserView: (name: string) => void;
}

export const UserNameColumn = ({
  item,
  onUserView,
}: UserNameColumnProps): JSX.Element => {
  return (
    <div
      onClick={() => {
        onUserView(item.name);
      }}
      className={classes.hyperlink}
    >
      {item.userName}
    </div>
  );
};
