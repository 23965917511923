import { ColumnSizingState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { GridViewLoading } from './components/grid-view-loading/grid-view-loading';
import GridViewTable from './components/grid-view-table/grid-view-table.component';
import { GridViewControllerProps } from './grid-view.model';

const GridViewController = (props: GridViewControllerProps): JSX.Element => {
  const {
    dataTestId,
    columns,
    items,
    disableSelection,
    enableShimmer,
    disableDefaultSorting,
    groupNames,
    className,
    resetColumnSizes,
    setResetColumnSizes,
  } = props;

  const noNullItems = items?.filter((v) => v !== null);
  const needsShimmering = noNullItems?.length === 0 && items.length > 0;
  const isLoading = enableShimmer || needsShimmering;
  const [columnSizes, setColumnSizes] = useState<ColumnSizingState>({});

  useEffect(() => {
    if (resetColumnSizes && setResetColumnSizes) {
      setColumnSizes({});
      setResetColumnSizes((prev) => !prev);
    }
  }, [resetColumnSizes, setResetColumnSizes]);

  return isLoading ? (
    <GridViewLoading
      className={className}
      columns={columns}
      disableSelection={disableSelection}
      dataTestId={dataTestId}
      disableDefaultSorting={disableDefaultSorting}
      hasGroups={!!groupNames}
      rowCount={items?.length || 10}
    />
  ) : (
    <GridViewTable
      {...props}
      items={noNullItems}
      columnSizes={columnSizes}
      onColumnSizingChange={setColumnSizes}
    />
  );
};

export default GridViewController;
