import { DateTimePicker } from '@celito.clients/shared';
import {
  getStartOfHour,
  getZonedBasedTime,
  isDateValid,
} from '@celito.clients/utils';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { ControlledDatetimePickerProps } from './controlled-datetime-picker.model';

interface ControlledDatetimePickerViewProps<T extends FieldValues>
  extends ControlledDatetimePickerProps<T> {}

const ControlledDatetimePickerView = <T extends FieldValues>(
  props: ControlledDatetimePickerViewProps<T>
): JSX.Element => {
  return (
    <Controller
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({
        field: { onChange, value },
        fieldState: { error, isDirty },
      }) => {
        return (
          <DateTimePicker
            error={error}
            label={props.attribute?.label}
            minDate={
              props.attribute.rules?.find((data) => data.type === 'min')?.value
                ? new Date(
                    props.attribute.rules?.find((data) => data.type === 'min')
                      ?.value as string
                  )
                : new Date(Date.now())
            }
            maxDate={
              props.attribute.rules?.find((data) => data.type === 'max')?.value
                ? new Date(
                    props.attribute.rules?.find((data) => data.type === 'max')
                      ?.value as string
                  )
                : undefined
            }
            value={
              props.attribute?.valueParser
                ? (props.attribute.valueParser(value) as Date)
                : isDateValid(value)
                ? value
                : getStartOfHour(getZonedBasedTime(new Date()))
            }
            onChange={onChange}
            datePickerProps={{
              disabled: !props.attribute?.isEditable,
            }}
            timePickerProps={{
              disabled: !props.attribute?.isEditable,
            }}
            {...props}
          />
        );
      }}
    />
  );
};

export default ControlledDatetimePickerView;
