import { DocumentDownloadActionEnum } from '@celito.clients/enums';
import axiosInstance from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import {
  CompareData,
  DocData,
  DocVersionRestorePayload,
  DocVersions,
} from '../doc-version-history.model';

export const versionHistoryService = () => {
  const getDocumentData = async (
    docId: string,
    objectName: string,
    recordName: string,
    version?: number,
    downloadType: DocumentDownloadActionEnum = DocumentDownloadActionEnum.View
  ): Promise<DocData> => {
    const response = await axiosInstance.get(
      version !== undefined && version !== null
        ? `${Endpoints.DOCUMENT_DATA_URL}/${docId}?objectName=${objectName}&action=${downloadType}&recordName=${recordName}&versionNumber=${version}&recordVersion=${version}`
        : `${Endpoints.DOCUMENT_DATA_URL}/${docId}?objectName=${objectName}&action=${downloadType}&recordName=${recordName}`
    );
    return response.data;
  };

  const getDocumentVersions = async (
    docId: string,
    objectName: string
  ): Promise<DocVersions[]> => {
    const response = await axiosInstance.get(
      `${Endpoints.DOCUMENT_DATA_URL}/${docId}/versions?objectName=${objectName}`
    );
    return response.data;
  };

  const restorePastVersion = async (
    payload: DocVersionRestorePayload,
    documentId: string,
    version: string
  ): Promise<DocVersions> => {
    const response = await axiosInstance.post(
      `${Endpoints.DOCUMENT_DATA_URL}/${documentId}/restore/${version}`,
      payload
    );
    return response.data;
  };

  const compareSelectedVersions = async (
    payload: CompareData
  ): Promise<string> => {
    const { documentId, versionLeft, versionRight, objectName } = payload;
    const response = await axiosInstance.get(
      `${Endpoints.DOCUMENT_DATA_URL}/${documentId}/compare?objectName=${objectName}&versionLeft=${versionLeft}&versionRight=${versionRight}`
    );
    return response.data;
  };

  return {
    getDocumentData,
    getDocumentVersions,
    compareSelectedVersions,
    restorePastVersion,
  };
};
