import { LocalizationString } from '@celito.clients/assets';
import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { errorToast } from '@celito.clients/utils';
import { useEffect, useRef, useState } from 'react';
import type { FieldValues, Path, PathValue } from 'react-hook-form';

import {
  FileDataProps,
  MultiFileUploadButtonProps,
} from './multi-file-upload.model';
import MultiFileUploadView from './multi-file-upload.view';

export const MultiFileUploadController = <T extends FieldValues>(
  props: MultiFileUploadButtonProps<T>
) => {
  const { setValue, attribute, watch } = props;
  const files = (watch?.(attribute?.name as Path<T>) as File[]) || [];
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileNames, setFileNames] = useState<(File | FileDataProps)[]>(
    props?.fileNames || []
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [markedAsDeleteFiles, setMarkedAsDeleteFiles] = useState<string[]>([]);
  const allowedFileExtensions = attribute?.allowAllExtensions
    ? '*'
    : attribute?.allowedExtensions?.join(',') ?? '*';

  const notAllowedFileExtensions = (
    (attribute?.notAllowedExtensions as string[]) ?? []
  ).join(',');
  const deletedAttribute = watch?.(`deleted_${attribute.name}` as Path<T>);

  const handleDragEnter = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isValidFileUploaded = (file: File) => {
    const fileExtension = file.name.split('.')?.pop() ?? '';
    if (notAllowedFileExtensions.includes(`.${fileExtension}`)) return false;
    if (allowedFileExtensions === '*') {
      return true;
    }

    return allowedFileExtensions.includes(`.${fileExtension}`);
  };

  const onFilesUpload = (files: File[]) => {
    const validFiles = files.filter((file) =>
      file instanceof File ? isValidFileUploaded : true
    );

    if (validFiles.length) {
      const hasMaxSizeExceededFiles = validFiles.some(
        (file) =>
          props.maxFileSizeInBytes && file.size > props.maxFileSizeInBytes
      );

      if (props.maxFileSizeInBytes && hasMaxSizeExceededFiles) {
        errorToast({
          message: `${
            files.length === 1 ? 'File size' : 'Some of the files'
          } exceed the maximum allowed size of ${
            props.maxFileSizeInBytes / 1024 / 1024 / 1024
          } GB`,
        });
        return;
      }

      props.onFilesSelect(validFiles);
      setIsDragging(false);
    } else {
      errorToast({ message: 'Invalid file type selected' });
      setIsDragging(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: React.DragEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    const selectedFiles = e.dataTransfer.files;
    if (selectedFiles?.length) {
      onFilesUpload([...files, ...Array.from(selectedFiles)]);
    }
  };

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const selectedFiles = event.target.files;
    if (selectedFiles?.length) {
      if (
        attribute.maximumFilesAllowed &&
        files.length + selectedFiles.length > attribute.maximumFilesAllowed
      ) {
        errorToast({
          message: LocalizationString.ATTACHMENTS_LIMIT_ERROR_MESSAGE,
        });
        return;
      }
      onFilesUpload([...files, ...Array.from(selectedFiles)]);
    }
  };

  const handleButtonClick = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.value = '';
    fileInputRef.current?.click();
  };

  // Function to remove the selected file
  const removeFile = (fileToRemove: string, key: number) => () => {
    if (setValue) {
      const updatedFiles = files.filter((file: File | FileDataProps, index) => {
        return (file as File).name
          ? !((file as File).name == fileToRemove && index == key)
          : !((file as FileDataProps).label == fileToRemove && index == key);
      });
      setValue(
        `${attribute.name}` as Path<T>,
        updatedFiles as PathValue<T, Path<T>>
      );

      if (
        props.mode === FormEngineModeTypeEnum.Edit ||
        props.mode === FormEngineModeTypeEnum.Create
      ) {
        const toBeDeletedFile = (files as unknown as FileDataProps[]).find(
          (file, index) => file.label === fileToRemove && index == key
        );

        if (toBeDeletedFile) {
          setValue(
            `deleted_${attribute.name}` as Path<T>,
            [...markedAsDeleteFiles, toBeDeletedFile.documentId] as PathValue<
              T,
              Path<T>
            >
          );
          setMarkedAsDeleteFiles((prev) => [
            ...prev,
            toBeDeletedFile.documentId,
          ]);
        }
      }

      setFileNames(updatedFiles);

      props.onFilesSelect(updatedFiles);
    }
  };

  useEffect(() => {
    if (
      files?.length &&
      setValue &&
      props.watch?.(props.attribute.name as Path<T>)
    ) {
      setFileNames(files);
    }
  }, [files]);

  useEffect(() => {
    if (setValue && deletedAttribute) {
      setMarkedAsDeleteFiles(deletedAttribute);
    }
  }, [deletedAttribute]);

  return (
    <MultiFileUploadView<T>
      {...{
        ...props,
        fileNames,
        fileObj: watch?.(attribute.name as Path<T>),
        handleFilesChange,
        handleButtonClick,
        handleDrop,
        isDragging,
        handleDragEnter,
        handleDragLeave,
        handleDragOver,
        removeFile,
        allowedFileExtensions,
      }}
      ref={fileInputRef as React.ForwardedRef<HTMLUListElement>}
    />
  );
};
