import { performRequest } from '@celito.clients/network';

import Endpoints from '../../config/endpoint';
import { ApiMethodType } from '../../enums/api-constants';
import {
  AddPermissionObjectType,
  PermissionResponseType,
  RolesType,
} from '../../types/permission-types';

export function usePermission() {
  const fetchAllRoles = async () => {
    try {
      const response = await performRequest<RolesType>(
        `${Endpoints.USER_ROLES}`,
        ApiMethodType.GET,
        null
      );
      return response.data;
    } catch (error) {
      return {} as RolesType;
    }
  };

  const addPermissionGroup = async (data: AddPermissionObjectType) => {
    try {
      const response = await performRequest<PermissionResponseType>(
        `${Endpoints.PERMISSION_URL}`,
        ApiMethodType.POST,
        data
      );
      return response.data;
    } catch (error) {
      return {} as PermissionResponseType;
    }
  };

  const fetchPermissionGroupInfo = async (name: string) => {
    try {
      const response = await performRequest<PermissionResponseType>(
        `${Endpoints.PERMISSION_URL}/name/${name}`,
        ApiMethodType.GET,
        null
      );
      return response.data;
    } catch (error) {
      return {} as PermissionResponseType;
    }
  };

  const updatePermissionGroup = async (
    data: AddPermissionObjectType,
    name: string
  ) => {
    if (name === 'Report Permission Group') {
      await performRequest<PermissionResponseType>(
        `${Endpoints.ADD_SUPERSET_USER}`,
        ApiMethodType.PUT,
        data.userNames
      );
    }
    const response = await performRequest<PermissionResponseType>(
      `${Endpoints.PERMISSION_URL}/${name}`,
      ApiMethodType.PUT,
      data
    );
    return response.data;
  };

  return {
    updatePermissionGroup,
    fetchAllRoles,
    fetchPermissionGroupInfo,
    addPermissionGroup,
  };
}
