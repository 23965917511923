import { Stack } from '@celito.clients/shared';
import NoResultIcon from 'libs/assets/src/images/search-result-no-result-icon.svg';

import classes from '../search-result.module.css';

const NoResult: React.FC = () => {
  return (
    <Stack className={classes.center}>
      <img src={NoResultIcon} alt="" />
      <p className={classes.no_result_label}>No Results found</p>
      <p className={classes.no_result_message}>
        Kindly modify the keyword and filters for better results.
      </p>
    </Stack>
  );
};

export default NoResult;
