import { LocalizationString } from '@celito.clients/assets';
import {
  ActionTypeEnum,
  AttributeTypeEnum,
  DocumentDownloadActionEnum,
} from '@celito.clients/enums';
import {
  Field,
  ObjectActionDefinition,
  ObjectAttributeDefinition,
  ObjectAttributeType,
  Section,
} from '@celito.clients/types';
import { errorToast, infoToast, raiseErrorToast } from '@celito.clients/utils';

import { getDocumentData } from './services';
import { ControlledDocs, DocumentFile, UserActions } from './types';

export const userType = {
  Collaborator: 'cd_create_collaboration',
  Owner: 'cd_create_complete_draft',
  Admin: ['cd_create_admin_review', 'cd_pre_approved_doc_admin_review'],
  Functional_Approval: 'cd_create_functional_approval',
  Quality_Approval: 'cd_create_quality_approval',
};

export const nonCollaborativeFiles = ['.jpeg', '.jpg', '.png', '.pdf'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TODO = any;

export const getLifeCycleStageGroups = (
  attributeObject: ObjectAttributeType,
  currentLifeCycleStage: string
) => {
  const lifecycleStageGroups = attributeObject?.lifecycleStageGroups;
  if (lifecycleStageGroups) {
    const lifecycleStages: string[] = [];
    const lifeCycleStageGroupEntries = Object.entries(lifecycleStageGroups);

    lifeCycleStageGroupEntries
      .sort((a, b) => a[1].displayOrder - b[1].displayOrder)
      .forEach((lifeCycleStageGroupEntry) => {
        const lifeCycleStageGroup = lifeCycleStageGroupEntry[1];
        const stageName = lifeCycleStageGroupEntry[0];

        if (lifeCycleStageGroup.displayActively) {
          lifecycleStages.push(stageName);
        } else {
          // if the displayActively is false check that the current lifecycle state is present in the lifecycleStageGroups, if yes then show in the chevron
          if (lifeCycleStageGroup?.stages?.includes(currentLifeCycleStage)) {
            lifecycleStages.push(stageName);
          }
        }
      });
    return lifecycleStages;
  }
};

export const getActiveLifeCycleStage = (
  attributeObject: ObjectAttributeType,
  response: ControlledDocs
) => {
  const wizardStates = attributeObject?.lifecycleStageGroups;
  for (const wizard in wizardStates) {
    if (wizardStates[wizard]?.stages.includes(response?.lifecycleStage)) {
      return wizard;
    }
  }
};

const ActionTypesThatRequireTodo = [
  ActionTypeEnum.DELEGATE_TASK,
  ActionTypeEnum.EDIT_DOCUMENT,
];

export const getUserActions = (
  useractions: UserActions[],
  listOfActions: ObjectActionDefinition[],
  userHasTodoTask: boolean
) => {
  const filterActions: ObjectActionDefinition[] = [];
  useractions.map((useraction: UserActions) => {
    listOfActions.map((actions: ObjectActionDefinition) => {
      if (
        actions.name === useraction.name &&
        // Tasks that require to do should not be shown if user does'nt have todo
        (userHasTodoTask ||
          !ActionTypesThatRequireTodo.includes(actions.actionType))
      ) {
        actions.permission = useraction.permission;
        // Removing the edit document from context menu
        if (actions.actionType !== ActionTypeEnum.EDIT_DOCUMENT) {
          filterActions.push(actions);
        }
      }
    });
  });
  return filterActions;
};

export const handleDownload = (documentData: DocumentFile) => {
  try {
    if (documentData?.fileBuffer) {
      const uint8Array = new Uint8Array(documentData?.fileBuffer?.data);
      const blob = new Blob([uint8Array], {
        type: documentData?.mimeType,
      });
      const downloadLink = document?.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = documentData?.label;
      downloadLink.click();
    }
  } catch (error) {
    errorToast({ message: `${error}` });
  } finally {
    // setLoading(false);
  }
};

export const getDocumentFile = (
  id: string,
  name: string,
  recordversion?: string,
  version?: string
) => {
  infoToast({
    message: LocalizationString.DOWNLOADING_MESSAGE,
  });
  getDocumentData(id, name, version, DocumentDownloadActionEnum.DownloadRaw)
    .then((documentDocs: DocumentFile) => {
      if (documentDocs) {
        if (documentDocs?.documentId) {
          handleDownload(documentDocs);
        }
      }
    })
    .catch((_error) => {
      raiseErrorToast(_error);
    });
};

export const getUserList = (list: TODO[]) => {
  const users: string[] = [];
  list?.forEach((user) => {
    users.push(user.name);
  });
  return users || [];
};

export const mergeSections = (sectionData: Partial<Section>[] | undefined) => {
  let mergedFields: Field[] = [];
  sectionData?.forEach((section) => {
    const fields = section.fields || [];

    mergedFields = mergedFields.concat(fields);
  });
  return { fields: mergedFields };
};

const getDefaultValuesByAttributeDataType = (
  attribute: ObjectAttributeDefinition
) => {
  if (attribute.dataType === AttributeTypeEnum.PlainText) {
    return '';
  } else if (attribute.dataType === AttributeTypeEnum.Reference) {
    return [];
  }
  return null;
};

export const getDefaultValues = (
  fieldsMap: Record<string, Field>,
  attributeDefinitions?: Record<string, ObjectAttributeDefinition>
) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.keys(fieldsMap).reduce<Record<string, any>>((acc, field) => {
    const attribute = attributeDefinitions?.[field];
    if (attribute) {
      return {
        ...acc,
        [field]: getDefaultValuesByAttributeDataType(attribute),
      };
    }
    return acc;
  }, {});

export const hasAction = (
  actions: ObjectActionDefinition[],
  actionType: ActionTypeEnum
) => {
  return actions.some((a) => a.actionType === actionType);
};
/**
 *
 * @param mergedSections
 * @param attributeDefinitions
 * @returns new mergedSections with isRequired set to false if the field is hidden from the attributeDefinition
 */
export const overrideIsRequired = (
  mergedSections: { fields: Field[] },
  attributeDefinitions?: Record<string, ObjectAttributeDefinition>
) => {
  mergedSections.fields.forEach((field) => {
    if (field.layoutConfiguration?.defaultLayoutRules?.isRequired) {
      if (
        attributeDefinitions?.[field.columnName]?.isHidden ||
        attributeDefinitions?.[field.columnName]?.isEditable === false
      ) {
        field.layoutConfiguration.defaultLayoutRules.isRequired = false;
        'rules' in field.layoutConfiguration.layoutConfig &&
          field.layoutConfiguration.layoutConfig.rules?.forEach((rule) => {
            if (rule.event.params?.isRequired)
              rule.event.params.isRequired = false;
          });
      }
    }
  });
  return mergedSections;
};
