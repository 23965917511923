import { Cell } from '@tanstack/react-table';

import { ColumnMeta } from '../../../grid-view.model';
import { RowItem } from '../../../types';

const getRenderValue = (cell: Cell<RowItem, unknown>) => {
  const { columnType } = (cell.column.columnDef.meta || {}) as ColumnMeta;

  if (
    columnType === 'custom' &&
    typeof cell.column.columnDef.cell === 'function'
  ) {
    return cell.column.columnDef.cell(cell.getContext());
  }

  return cell.renderValue();
};

export default getRenderValue;
