import { FormEngineModeTypeEnum } from '@celito.clients/enums';

import FileView from '../file-view/file-view.component';

interface IShowFile {
  fileName: string;
  removeFile: () => void;
  mode?: FormEngineModeTypeEnum;
  disabled?: boolean;
  objectName: string;
  documentId: string;
  showCustomPreviewComponent?: boolean;
  customPreviewComponent?: ({
    fileName,
    removeFile,
  }: {
    fileName: string;
    removeFile: () => void;
    disabled?: boolean;
  }) => JSX.Element;
  handleFileView?: () => void;
  recordName?: string;
}

export const ShowFilePreview = ({
  showCustomPreviewComponent,
  customPreviewComponent,
  fileName,
  handleFileView,
  removeFile,
  mode,
  objectName,
  documentId,
  disabled,
  recordName,
}: IShowFile): JSX.Element => {
  if (!showCustomPreviewComponent || !customPreviewComponent) {
    return (
      <FileView
        removeFile={removeFile}
        handleFileView={handleFileView}
        fileName={fileName}
        mode={mode}
        objectName={objectName}
        documentId={documentId}
        disabled={disabled}
        recordName={recordName}
      />
    );
  }

  return customPreviewComponent({
    fileName: fileName,
    removeFile: removeFile,
    disabled,
  });
};
