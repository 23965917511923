import { ApiMethodType, OperatorsEnum } from '@celito.clients/enums';
import { TemporaryUnifyDefaultAndUserFilters } from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import { UserTypes } from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';

import { RulesComponentListViewFilter } from '../../../rules-component/types/rules-component.types';
import { defaultCompanyAssignmentFilters } from '../config';
import {
  AssignmentData,
  AssignmentDetail,
  ILCAssignmentData,
  PaginatedData,
  SortConfig,
} from '../types';

interface GetObjectDataOptions {
  filters?: RulesComponentListViewFilter;
  sortConfig?: SortConfig;
}

export const defaultSortConfig: SortConfig = {
  attribute: 'createdAtUtc',
  order: 'DESC',
};

export const getMyAssignmentsData = async (
  paginationData: {
    limit: number;
    page: number;
  },
  { filters, sortConfig }: GetObjectDataOptions = {},
  user: UserTypes | null,
  signal?: AbortSignal
) => {
  const defaultFilters: RulesComponentListViewFilter = {
    conditions: {
      all: [
        {
          all: [
            {
              attribute: 'user__s',
              operator: OperatorsEnum.EQUALS,
              value: user?.name || '',
            },
            {
              attribute: 'isActive',
              operator: OperatorsEnum.EQUALS,
              value: true,
            },
          ],
        },
      ],
    },
  };

  const finalFilters = TemporaryUnifyDefaultAndUserFilters(
    filters,
    defaultFilters
  );

  const response = await performRequest<PaginatedData<AssignmentDetail[]>>(
    `${Endpoints.OBJECT_DATA_URL}/training_assignment__a`,
    ApiMethodType.POST,
    {
      filters: finalFilters,
      sort: sortConfig || defaultSortConfig,
    },
    paginationData,
    undefined,
    signal
  );
  return response.data;
};

export const getILCAssignmentsData = async () => {
  const response = await performRequest<ILCAssignmentData[]>(
    `${Endpoints.ASSIGNMENTS_URL}/training_type/ILC`,
    ApiMethodType.GET
  );
  return response.data;
};

export const getMyTeamsAssignmentsData = async (
  paginationData: {
    limit: number;
    page: number;
  },
  { filters, sortConfig }: GetObjectDataOptions = {},
  signal?: AbortSignal
) => {
  const finalFilters = TemporaryUnifyDefaultAndUserFilters(
    defaultCompanyAssignmentFilters,
    filters
  );

  const response = await performRequest<PaginatedData<AssignmentData>>(
    `${Endpoints.ASSIGNMENTS_URL}/team`,
    ApiMethodType.POST,
    {
      filters: finalFilters,
      sort: sortConfig || defaultSortConfig,
    },
    paginationData,
    undefined,
    signal
  );
  return response.data;
};

export const getCompanyAssignmentsData = async (
  paginationData: {
    limit: number;
    page: number;
  },
  { filters, sortConfig }: GetObjectDataOptions = {},
  signal?: AbortSignal
) => {
  const finalFilters = TemporaryUnifyDefaultAndUserFilters(
    defaultCompanyAssignmentFilters,
    filters
  );

  const response = await performRequest<PaginatedData<AssignmentData>>(
    `${Endpoints.ASSIGNMENTS_URL}/company`,
    ApiMethodType.POST,
    {
      filters: finalFilters,
      sort: sortConfig || defaultSortConfig,
    },
    paginationData,
    undefined,
    signal
  );
  return response.data;
};
