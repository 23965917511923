import { endpoint } from '@celito.clients/endpoints';
import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';

import { PickListData } from '../types';

export const getPicklistOptions = (picklistName: string) => {
  return performRequest<PickListData>(
    `${endpoint.PICKLIST_URL}/name/${picklistName}/picklist-items`,
    ApiMethodType.GET
  );
};
