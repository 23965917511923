import {
  AttributeTypeEnum,
  TrainingAssignmentTypeEnum,
} from '@celito.clients/enums';
import { FilterableColumn } from '@celito.clients/list-view-engine';
import {
  ObjectAttributeDefinitionBase,
  ObjectAttributeType,
  OwnerType,
  Relationship,
} from '@celito.clients/types';
import { createFormEngineConfig } from '@celito.clients/utils';
import { userObjectName } from 'libs/form-engine/src/lib/config';

import { courseObjectName, defaultCourseReferenceFilter } from '../config';

export const getCustomMetadata = (
  assignmentType: TrainingAssignmentTypeEnum
) => {
  const objectMetadata = createFormEngineConfig([
    {
      columnName: 'status',
      dataType: AttributeTypeEnum.Picklist,
      key: 'status',
      label: 'Status',
      name: 'status',
      ownerType: OwnerType.System,
      picklistName: 'training_plan_assignment_status__a',
    },
    {
      columnName: 'dueDate',
      dataType: AttributeTypeEnum.Date,
      key: 'dueDate',
      label: 'Assignment Due Date',
      name: 'dueDate',
      ownerType: OwnerType.System,
    },
    {
      columnName: 'completionDate',
      dataType: AttributeTypeEnum.Date,
      key: 'completionDate',
      label: 'Assignment Completion Date',
      name: 'completionDate',
      ownerType: OwnerType.System,
    },
  ]);

  if (
    assignmentType === TrainingAssignmentTypeEnum.CompanyAssignments ||
    assignmentType === TrainingAssignmentTypeEnum.MyTeamsAssignments
  ) {
    objectMetadata.objectAttributeDefinitions.push({
      columnName: 'user',
      dataType: AttributeTypeEnum.Reference,
      key: 'user',
      label: 'User Name',
      name: 'user__a',
      ownerType: OwnerType.System,
      relationship: {
        objectName: userObjectName,
      } as ObjectAttributeDefinitionBase['relationship'],
    });
    objectMetadata.objectAttributeDefinitions.push({
      columnName: 'course',
      dataType: AttributeTypeEnum.Reference,
      key: 'course',
      label: 'Course ID',
      name: 'course__a',
      ownerType: OwnerType.System,
      defaultReferenceFilter: defaultCourseReferenceFilter,
      relationship: {
        objectName: courseObjectName,
      } as ObjectAttributeDefinitionBase['relationship'],
    });
  }

  if (assignmentType === TrainingAssignmentTypeEnum.MyAssignments) {
    objectMetadata.objectAttributeDefinitions.push({
      columnName: 'label',
      dataType: AttributeTypeEnum.PlainText,
      key: 'label',
      label: 'Assignment ID',
      name: 'label',
      ownerType: OwnerType.System,
    });
    objectMetadata.objectAttributeDefinitions.push({
      columnName: 'course',
      dataType: AttributeTypeEnum.Reference,
      key: 'course',
      label: 'Course ID',
      name: 'course',
      ownerType: OwnerType.System,
      defaultReferenceFilter: defaultCourseReferenceFilter,
      relationship: {
        objectName: courseObjectName,
      } as ObjectAttributeDefinitionBase['relationship'],
    });
    objectMetadata.objectAttributeDefinitions.push({
      columnName: 'dependentCourse',
      dataType: AttributeTypeEnum.Reference,
      key: 'dependentCourse',
      label: 'Dependant Course',
      name: 'dependentCourse',
      ownerType: OwnerType.System,
      // defaultReferenceFilter: defaultCourseReferenceFilter,
      relationship: {
        objectName: 'training_assignment__a',
      } as ObjectAttributeDefinitionBase['relationship'],
    });
  }

  return objectMetadata;
};

export const getObjectAttributeRelationship = (
  objectName: string
): Relationship => {
  return {
    assosiatedField: 'department',
    label: 'Department Relationship',
    ownerType: OwnerType.System,
    name: 'department_relation__sr',
    createdAtUtc: '2023-07-17T13:47:09.076Z',
    modifiedAtUtc: '2023-08-16T19:01:38.243Z',
    deletedAtUtc: null,
    isActive: true,
    relationType: 'Outbound',
    objectName,
    deletionType: 'Block',
    objectMappingType: 'ManyToOne',
  };
};

export const getAllColumns = (
  objectMetadata: ObjectAttributeType
): FilterableColumn[] => {
  const filterableColumns: FilterableColumn[] = [];

  objectMetadata.objectAttributeDefinitions.map((col) => {
    if (col.dataType !== AttributeTypeEnum.Reference)
      filterableColumns.push({ name: col.name });
  });

  filterableColumns.push({
    name: 'course__a',
    label: 'Course ID',
  });

  filterableColumns.push({
    name: 'course__a',
    attributeNameToFilter: 'title',
    label: 'Course Name',
  });

  filterableColumns.push({
    name: 'course__a',
    attributeNameToFilter: 'version',
    label: 'Course Version',
  });

  filterableColumns.push({
    name: 'user__a',
    attributeNameToFilter: 'label',
    label: 'User Name',
  });

  return filterableColumns;
};
