import { LocalizationString } from '@celito.clients/assets';
import { useUser } from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import { delegateTask } from '@celito.clients/services';
import { Role, TaskApiPayloadsProps, UserTypes } from '@celito.clients/types';
import { raiseErrorToast, successToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useListViewContext } from '../../hooks';
import { DelegationModalProps } from './delegation-modal.model';
import { DelegationModalView } from './delegation-modal.view';

const schema = yup.object().shape({
  ownerName: yup.object(),
  delegateName: yup.object().required(LocalizationString.REQUIRED_MSG),
  // dueDate: yup.date(),
  justification: yup.string(),
}) as yup.ObjectSchema<FieldValues>;
export const DelelgationModalController = ({
  useCachedUsers = true,
  ...props
}: DelegationModalProps) => {
  const { fetchRowData, currentPageNumber, filters, sortConfig } =
    useListViewContext();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });
  const { user, isAdmin } = useContext(UserContext);
  const { fetchAllUsers } = useUser();
  const [approvalRoles, setApprovalRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const callAllUsers = async () => {
    try {
      const response = await fetchAllUsers({
        useCachedResponse: useCachedUsers,
      });
      const filterOwner = response
        ?.filter((item) => item?.name === props.rowItem?.ownerName)
        .map((item) => ({ label: item?.label, name: item?.name }));
      const filterDelegate = response?.filter((item) =>
        ((props.rowItem?.delegatedUserNames || []) as string[]).includes(
          item?.name
        )
      );
      setValue('ownerName', filterOwner?.[0]);
      setValue('delegateName', filterDelegate?.[0]);
      getOwnerRole(response);
    } catch (_error) {
      raiseErrorToast(_error);
    }
  };

  const getOwnerRole = (allUsers: UserTypes[]) => {
    const ownerUser = allUsers?.filter((user: UserTypes) => {
      return user?.name === props.rowItem?.ownerName;
    });
    const roles = ownerUser?.[0]?.roles?.map((role: Role) => {
      return { name: role?.name };
    });
    setApprovalRoles(roles || []);
  };

  const onFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    handleSubmit(() => onSubmitHandler);
  };

  const callTaskApi = async (params: TaskApiPayloadsProps, taskName = '') => {
    try {
      setIsLoading(true);
      await delegateTask(taskName, params);
      fetchRowData(props.objectName, currentPageNumber, filters, sortConfig);
      successToast({
        message: `Successfully delegated this task`,
      });
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      setIsLoading(false);
      reset();
      props.onClose();
    }
  };

  const onSubmitHandler = async () => {
    await handleSubmit(
      (data) => {
        const params = {
          ...(watch('ownerName') &&
            isDirty && {
              ownerName: data.ownerName?.name as string,
            }),
          ...(watch('delegateName') &&
            isDirty && {
              delegateName: data.delegateName?.name as string,
            }),
          ...(watch('justification') &&
            isDirty && {
              justification: data.justification as string,
            }),
        };
        callTaskApi(params, props.rowItem?.name as string);
      },
      (_err) => {}
    )();
  };

  const propsItem = JSON.stringify(props?.rowItem);

  useEffect(() => {
    const parsedRowitem = JSON.parse(propsItem);
    if (parsedRowitem?.roles) {
      let parsedRoles;
      if (typeof parsedRowitem?.roles === 'string') {
        parsedRoles = JSON.parse(parsedRowitem?.roles);
      } else {
        parsedRoles = parsedRowitem?.roles;
      }
      const roles = parsedRoles?.map((role: Role) => {
        return { name: role };
      });
      setApprovalRoles(roles);
    } else {
      callAllUsers();
    }
  }, [propsItem]);

  const onPopupClose = () => {
    props?.onClose();
    reset();
  };

  return (
    <DelegationModalView
      {...props}
      {...{
        control,
        handleSubmit,
        onFormSubmit,
        onSubmitHandler,
        currentUser: user,
        roles: approvalRoles,
        fromUser: watch('ownerName'),
        isAdmin: isAdmin,
        isLoading,
        setValue,
        onPopupClose,
        taskOwner: props?.rowItem?.ownerName,
      }}
    />
  );
};
