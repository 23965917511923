import { clamp, constant, isEqual, lte, merge } from 'lodash';

import { Environment } from '../../types/environment';
import { ConsoleTransport, SentryTransport } from './transports';
import { AppInsightsTransport } from './transports/appinsights';
import { LogLevel, TAttributes, TMessage, Transport, User } from './types';
import { getEnvironment, getMaxLogLevel } from './utils';

class Logger {
  // To prevent duplicate error logs, PS: Sometimes this doesn't work as expected.
  private errorStacks = new Set<Error['stack']>();
  private transports: Transport[] = [];
  private console: Console;
  private context: Record<string, unknown> = {};
  private maxLogLevel: LogLevel = 2;

  constructor() {
    this.console = console;
    if (!isEqual(getEnvironment(), Environment.Local)) {
      // eslint-disable-next-line no-global-assign, no-native-reassign
      console = {
        ...console,
        log: constant(null),
        info: constant(null),
        debug: constant(null),
        error: constant(null),
      };
    }
    window.onerror = (message, source, lineno, colno, error) => {
      // see `errorStacks` declaration for more info
      if (error && !this.errorStacks.has(error.stack)) {
        this.error(error, { origin: 'WindowOnError' });
        if (error.stack) this.errorStacks.add(error.stack);
      }
    };
    window.onunhandledrejection = (event) => {
      if (event.reason)
        this.error(event.reason, { origin: 'WindowOnUnhandledRejection' });
    };
  }

  /**
   * Merge the given attributes with the existing context.
   * @param attributes
   */
  setContext(attributes: Record<string, unknown> | null) {
    this.context = merge(this.context, attributes);
  }

  init() {
    switch (getEnvironment()) {
      case Environment.Local:
      case Environment.Testing:
        this.transports = [new ConsoleTransport(this.console)];
        break;
      case Environment.Development:
      case Environment.Experiments:
        this.transports = [
          new ConsoleTransport(this.console),
          new SentryTransport(),
          new AppInsightsTransport(),
        ];
        break;
      case Environment.Production:
        this.transports = [];
        break;
      case Environment.QA:
      case Environment.Uat:
      default:
        this.transports = [new SentryTransport(), new AppInsightsTransport()];
    }

    const maxLogLevel = Number(getMaxLogLevel());
    if (!isNaN(maxLogLevel)) {
      this.maxLogLevel = clamp(maxLogLevel, LogLevel.ERROR, LogLevel.DEBUG);
    }
  }

  /**
   * Info logs record all of the user actions + any major task the app undertakes.
   * @param message
   * @param attributes
   * @example function delete(id){
   *  logger.info("event description", {
   *    btnLabel: "delete",
   *    btnAnalyticsID: "btnDelete",
   *    taskID: id
   * })}
   */
  info(message: string, attributes?: TAttributes): void {
    this.log(message, attributes, LogLevel.INFO);
  }

  /**
   * Debug logs are valuable when we have to debug a hard-to-reproduce production issue.
   * Add debug logs when you have a prod bug which is not reproduced easily and you want to know values being used on user's browser/device.
   * e.g., variable values, API request / response payloads
   * @param message A string describing what exactly is being debugged
   * @param attributes an object containing the values like variables / payloads as described above.
   */
  debug(message: string, attributes?: TAttributes): void {
    this.log(message, attributes, LogLevel.DEBUG);
  }

  /**
   * Error method is used to record handled errors like getting an error response from the back-end.
   * This should be used in every case where we show the error messages to the user, but should be called before showing the message.
   * @param message The error message to be shown
   * @param attributes An object containing the details of the error message like error response payload
   */
  error(message: TMessage, attributes?: TAttributes): void {
    this.log(message, attributes, LogLevel.ERROR);
  }

  /**
   *
   * @param user
   */
  identity(user: User | null) {
    this.transports.forEach((transport) => transport.identity(user));
  }

  private log(
    message: TMessage,
    attributes?: TAttributes,
    level = LogLevel.INFO
  ): void {
    const attributesToLog = isEqual(level, LogLevel.ERROR)
      ? merge(this.context, attributes)
      : attributes;

    if (lte(level, this.maxLogLevel)) {
      this.transports.forEach((transport) =>
        transport.handle(message, level, attributesToLog)
      );
    }
  }
}

/**
 * Logger instance to be used throughout the app.
 *
 * @note Logger disables default console methods, use methods from this instance to log.
 *
 * @example logger.info('Location changed', { location });
 */
export const logger = new Logger();
