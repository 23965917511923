import { useQueryParams } from '@celito.clients/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getOnAddRuleFunc,
  getOnRemoveRuleFunc,
} from 'libs/shared/src/lib/rules-component/rules-component.component';
import { transformMultipleRulesIntoFilter } from 'libs/shared/src/lib/rules-component/utils/utils';
import { getRulesValidationSchema } from 'libs/shared/src/lib/rules-component/validation-schema/validation-schema';
import { useEffect, useMemo, useRef } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getFiltersFromQueryParams } from '../../utils';
import { FilterPanelProps, IFilterPanelFormData } from './filter-panel.model';
import FilterPanelView from './filter-panel.view';
import { getAttributeDefinationsOfColumns } from './utils/getAttributeDefinitionOfColumns';
import getDefaultFilterPanelFormData from './utils/getDefaultFilterPanelFormData';
import getFilterFields from './utils/getFilterFields';

interface FilterPanelControllerProps extends FilterPanelProps {}

const validationSchema = yup.object().shape({
  rules: getRulesValidationSchema(),
}) as yup.ObjectSchema<IFilterPanelFormData>;

const emptyPanelValue = { conditions: undefined };

const FilterPanelController = (
  props: FilterPanelControllerProps
): JSX.Element => {
  const { getSearchParams } = useQueryParams();
  const queryParamFilters = getFiltersFromQueryParams(getSearchParams);
  const isInitialRender = useRef(true);

  const methods = useForm<IFilterPanelFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: getDefaultFilterPanelFormData(emptyPanelValue),
  });

  const { control, reset, setValue } = methods;

  const {
    fields: rules,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'rules',
  });

  useEffect(() => {
    if (queryParamFilters && isInitialRender.current) {
      const newFilterPanelValue =
        getDefaultFilterPanelFormData(queryParamFilters);
      setValue('rules', newFilterPanelValue.rules, { shouldDirty: true });
      isInitialRender.current = false;
    }
  }, []);

  const onResetFilters = () => {
    props.applyFilters({});

    reset(getDefaultFilterPanelFormData());
  };

  const onApplyFilter: SubmitHandler<IFilterPanelFormData> = (
    data: IFilterPanelFormData
  ) => {
    const filters = transformMultipleRulesIntoFilter(
      data.rules,
      props.objectMetadata,
      props.viewMetadata
    );

    if (filters && filters?.[0]?.filtersToBeApplied?.[0])
      props.applyFilters(filters?.[0]?.filtersToBeApplied?.[0]);

    props.closeFilterPanel();
  };

  const conditionFieldsData = useMemo(
    () =>
      getFilterFields(
        props.filterableColumns || [],
        props.objectMetadata.objectAttributeDefinitions
      ),
    [props.filterableColumns, props.objectMetadata.objectAttributeDefinitions]
  );

  const attributeDefinitionOfColumns = useMemo(
    () =>
      getAttributeDefinationsOfColumns(
        props.filterableColumns || [],
        props.objectMetadata.objectAttributeDefinitions
      ),
    [props.filterableColumns, props.objectMetadata.objectAttributeDefinitions]
  );

  useEffect(() => {
    if (props.resetFilterPanel && props.setResetFilterPanel) {
      onResetFilters();
      props.setResetFilterPanel((prev) => !prev);
    }
  }, [props?.resetFilterPanel, props?.setResetFilterPanel]);

  return (
    <FilterPanelView
      {...{
        ...props,
        methods,
        rules,
        onAddRule: getOnAddRuleFunc(append),
        onRemoveRule: getOnRemoveRuleFunc(remove),
        conditionFieldsData,
        onApplyFilter,
        onResetFilters,
        attributeDefinitionOfColumns,
      }}
    />
  );
};

export default FilterPanelController;
