import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledPickerController } from './controlled-picker.controller';
import { ControlledPickerProps } from './controlled-picker.model';

const ControlledPicker = <T extends FieldValues>(
  props: ControlledPickerProps<T>
) => {
  return <ControlledPickerController {...props} />;
};

export default ControlledPicker;
