import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { takingAssignmentObjectName } from '../config';
import { AssignmentData } from '../types';

export const getAssignmentDetails = async (
  assignmentName: string,
  signal?: AbortSignal
) => {
  const response = await performRequest<AssignmentData>(
    `${Endpoints.OBJECT_DATA_URL}/${takingAssignmentObjectName}/${assignmentName}`,
    ApiMethodType.GET,
    undefined,
    undefined,
    undefined,
    signal
  );
  return response.data;
};
