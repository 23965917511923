import {
  CourseViewCourseData,
  CourseViewTrainee,
  Curriculum,
  SmartGroup,
} from '@celito.clients/types';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';

import { UserAssignmentType } from '../enums/enums';

export const getCourseViewRow = (
  courseData: CourseViewCourseData[],
  handleSubRowToggle: (itemKey: string | number) => void
): RowItem[] => {
  const rowData: RowItem[] = [];

  let courseKey = 1;
  Object.keys(courseData).forEach((course) => {
    const {
      title: courseTitle,
      label: courseLabel,
      version: courseVersion,
      curriculums,
      smartGroups,
      trainees,
    } = courseData[Number(course)];

    const courseObj: RowItem = {
      key: courseKey,
      label: courseLabel,
      title: courseTitle,
      version: courseVersion,
      subRowToggleFieldName: 'curriculum',
      onSubRowToggle: handleSubRowToggle,
      subRow: [],
    };

    const userAssignmentList: Record<string, string>[] = [];
    const smartGroupsList: SmartGroup[] = smartGroups ?? [];
    const tranieesList: CourseViewTrainee[] = trainees ?? [];

    userAssignmentList.push(...getSmartGroupObjectList(smartGroupsList));
    userAssignmentList.push(...getTraineeObjectList(tranieesList));

    const curriculumObj: RowItem = {
      key: '',
      curriculum: '',
      userAssignment: userAssignmentList,
      isSubRow: true,
    };
    if (userAssignmentList.length > 0) courseObj.subRow?.push(curriculumObj);

    const curriculumList: Curriculum[] = curriculums ?? [];
    Object.keys(curriculumList).forEach((curriculum) => {
      const {
        title: curriculumTitle,
        label: curriculumLabel,
        smartGroups,
        trainees,
      } = curriculumList[Number(curriculum)];
      const userAssignmentList: Record<string, string>[] = [];

      const smartGroupsList: SmartGroup[] = smartGroups ?? [];
      const tranieesList: CourseViewTrainee[] = trainees ?? [];

      userAssignmentList.push(...getSmartGroupObjectList(smartGroupsList));
      userAssignmentList.push(...getTraineeObjectList(tranieesList));

      const curriculumObj: RowItem = {
        key: curriculumLabel,
        curriculum: `${curriculumTitle} (${curriculumLabel})`,
        userAssignment: userAssignmentList,
        isSubRow: true,
      };

      courseObj.subRow?.push(curriculumObj);
    });

    if (courseObj.subRow?.length) {
      courseObj.curriculum = `(${courseObj.subRow?.length})`;
    }

    rowData.push(courseObj);
    courseKey++;
  });

  return rowData;
};

const getSmartGroupObjectList = (
  smartGroupsList: SmartGroup[]
): Record<string, string>[] => {
  const objList: Record<string, string>[] = [];

  Object.keys(smartGroupsList).forEach((smartGroup) => {
    const { label: smartGroupLabel, name: smartGroupName } =
      smartGroupsList[Number(smartGroup)];
    const smartGroupObject = {
      key: smartGroupLabel,
      userAssignment: smartGroupLabel,
      type: UserAssignmentType.SMART_GROUP,
      smartGroupName: smartGroupName,
    };
    objList.push(smartGroupObject);
  });

  return objList;
};

const getTraineeObjectList = (
  tranieesList: CourseViewTrainee[]
): Record<string, string>[] => {
  const objList: Record<string, string>[] = [];

  Object.keys(tranieesList).forEach((traniee) => {
    const { label: tranieeLabel, name: tranieeName } =
      tranieesList[Number(traniee)];
    const tranieeObject = {
      key: tranieeLabel,
      userAssignment: tranieeLabel,
      type: UserAssignmentType.TRANIEES,
      tranieeName: tranieeName,
    };
    objList.push(tranieeObject);
  });

  return objList;
};
