import { LocalizationString, UrlString } from '@celito.clients/assets';
import { ActionTypeEnum } from '@celito.clients/enums';
import { useLayout } from '@celito.clients/hooks';
import { triggerWorkflow } from '@celito.clients/list-view-engine';
import { ActionContext } from '@celito.clients/provider';
import {
  getCDHomePageUrl,
  isExistInUrl,
  raiseErrorToast,
  successToast,
} from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldOption } from 'apps/web-client/src/screens/e-signature/e-signature.model';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import {
  checkInDetailsAPI,
  getControlledDocumentData,
  saveDetailsAPI,
} from './apiService';
import { UserActionPopupControllerProps } from './user-action-popup.model';
import UserActionPopupView from './user-action-popup.view';

const UserActionPopupController = (
  props: UserActionPopupControllerProps
): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { configureLayout } = useLayout();
  const { onClickCheckInAction } = useContext(ActionContext);
  const navigate = useNavigate();
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<yup.AnyObject> | undefined
  >(yup.object().shape({}));

  const methods = useForm({
    resolver: yupResolver(validationSchema!),
  });
  const { handleSubmit, control, reset, watch, setValue, trigger } = methods;

  const onSave = async () => {
    await handleSubmit((data: Record<string, any>) => {
      const formData = Object.keys(data).map((key) => {
        return {
          label: key,
          value: getRequiredFormatData(data[key]),
        };
      });
      filteredFormData(formData);
    })();
  };
  const filteredFormData = (formDataObj: Record<string, string>[]) => {
    const filteredData: Record<string, string>[] = [];
    props?.userActionData?.properties?.fields?.map((field: FieldOption) => {
      formDataObj?.map((formData) => {
        if (field?.columnName === formData?.label) {
          filteredData.push(formData);
        }
      });
    });
    const formData = new FormData();
    Object.keys({ ...filteredData }).forEach((key) => {
      if (filteredData[Number(key)]?.value) {
        formData.append(
          filteredData[Number(key)]?.label,
          filteredData[Number(key)]?.value
        );
      }
    });
    if (formData) {
      if (props?.userActionData?.actionType === ActionTypeEnum.CHECK_IN) {
        CheckInDetails(props?.recordName, formData);
        return;
      }
      saveFormDetails(props?.recordName, formData);
    }
  };
  const saveFormDetails = (name: string, payload: FormData) => {
    setLoading(true);
    saveDetailsAPI(name, payload)
      .then((res: Record<string, unknown>) => {
        if (res) {
          if (props?.userActionData?.actionType !== ActionTypeEnum.CANCEL)
            successToast({
              message: LocalizationString.FORM_DETAILS_Updated,
            });
          callTriggerWorkflow(
            props?.userActionData?.name,
            props?.recordName,
            res?.version as string
          );
        }
      })
      .catch((_error) => {
        setLoading(false);
        raiseErrorToast(_error);
        props?.onCancelClicked?.();
      });
  };

  const CheckInDetails = (name: string, payload: FormData) => {
    setLoading(true);
    checkInDetailsAPI(name, payload)
      .then((res: Record<string, unknown>) => {
        if (res) {
          successToast({
            message: LocalizationString.FORM_DETAILS_Updated,
          });
          callTriggerWorkflow(
            props?.userActionData?.name,
            props?.recordName,
            res?.version as string
          );
        }
      })
      .catch((_error) => {
        raiseErrorToast(_error);
        props?.onCancelClicked?.();
      });
  };

  const callTriggerWorkflow = async (
    actionName: string,
    recordName: string,
    version: string
  ) => {
    try {
      await triggerWorkflow(actionName, recordName, version);
      const message =
        props?.userActionData?.actionType === ActionTypeEnum.CANCEL
          ? LocalizationString.CANCEL_DOCUMENT
          : LocalizationString.CHECK_IN_DOCUMENT;
      successToast({
        message: message,
      });
      if (
        props?.userActionData?.actionType === ActionTypeEnum.CANCEL &&
        (isExistInUrl(UrlString.CONTROLLED_DOCS_CUSTOM_EDIT) ||
          isExistInUrl(UrlString.CONTROLLED_DOCS_CUSTOM_VIEW))
      ) {
        navigate(getCDHomePageUrl());
      }
      if (props?.userActionData?.actionType === ActionTypeEnum.CHECK_IN) {
        onClickCheckInAction(version);
        getControlledDocumentData(recordName, version).then((res: any) => {
          if (res) {
            configureLayout({
              pageTitle: '',
              enablePadding: false,
              headerTitle: (res?.title as string) || '',
              checkInCheckOutIcon: {
                checkoutTime: res?.lastCheckedOutAtUtc,
                checkoutBy: res?.lastCheckedOutByUser?.label,
                checkinTime: res?.lastCheckedInAtUtc,
                checkinBy: res?.lastCheckedInByUser?.label,
                isLocked: res?.isLocked,
              },
            });
          }
        });
      }
      props?.onCancelClicked?.();
    } catch (_error) {
      raiseErrorToast(_error);
      props?.onCancelClicked?.();
    }
  };

  const getRequiredFormatData = (value: Record<string, string>[] | string) => {
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        if (value.length) {
          const formatedData: string[] = [];
          value?.forEach((val) => {
            formatedData.push(val?.name);
          });
          return JSON.stringify(formatedData);
        }
      } else {
        return value;
      }
    }
    return '';
  };
  return (
    <FormProvider {...methods}>
      <UserActionPopupView
        {...{
          ...props,
          control,
          reset,
          watch,
          setValue,
          trigger,
          onSave,
          loading,
          methods,
          setValidationSchema,
        }}
      />
    </FormProvider>
  );
};

export default UserActionPopupController;
