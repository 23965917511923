import { isEqual } from 'lodash';
import {
  Dispatch,
  ElementRef,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react';

export const useSelectedTabHighlighter = (
  refs: { ref: RefObject<ElementRef<'div'>>; tabName: string }[],
  setSelectedTab: Dispatch<SetStateAction<string>>,
  args: Partial<IntersectionObserverInit> = {}
) => {
  const intersectionRatios = useRef<Record<string, number>>(
    Object.fromEntries(refs.map(({ tabName }) => [tabName, 0]))
  );
  const intersectionCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const newIntersectionRatios = entries.reduce((prev, current) => {
        return {
          ...prev,
          [current.target.getAttribute('data-tab-name') as string]:
            current.intersectionRatio,
        };
      }, {} as Record<string, number>);

      intersectionRatios.current = (() => {
        return {
          ...intersectionRatios.current,
          ...newIntersectionRatios,
        };
      })();

      if (isEqual(intersectionRatios.current['General'], 1))
        return setSelectedTab('General');

      const selectedTab = Object.entries(intersectionRatios.current).reduce(
        (prev, current) => {
          return current[1] >= prev[1] ? current : prev;
        },
        ['General', 0]
      )[0];

      setSelectedTab(selectedTab);
    },
    [setSelectedTab]
  );
  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback, {
      threshold: 0.5,
      root: null,
      rootMargin: '0%',
      ...args,
    });

    for (const { ref, tabName } of refs) {
      if (ref.current) {
        ref.current.dataset.tabName = tabName;
        observer.observe(ref.current);
      }
    }
    return () => {
      observer.disconnect();
    };
  }, [intersectionCallback, refs, args]);
};
