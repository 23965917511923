import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledMultiFileUploadProps } from './controlled.multi-fileupload.model';
import { ControlledMultiFileUploadView } from './controlled.multi-fileupload.view';

export const ControlledMultiFileUploadController = <T extends FieldValues>(
  props: ControlledMultiFileUploadProps<T>
) => {
  return <ControlledMultiFileUploadView {...props} />;
};
