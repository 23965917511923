export enum ActionTypeEnum {
  TRIGGER_WORKFLOW = 'TriggerWorflow',
  UPGRADE_MAJOR_VERSION = 'UpgradeMajorVersion',
  SUPERSEED_EFFECTIVE_VERSION = 'SuperSeedEffectiveVersion',
  ADD_DOCUMENT_PAGE_TO_PDF = 'AddDocumentPageToPdf',
  CHANGE_LIFECYCLE_STATE = 'ChangeLifecycleState',
  AUDIT_TRAIL = 'AuditTrail',
  LIST = 'List',
  CREATE = 'Create',
  EDIT = 'Edit',
  CUSTOM = 'Custom',
  VIEW = 'View',
  UPDATE = 'Update',
  DELETE = 'Delete',
  COMPLETE_TASK = 'CompleteTask',
  CUSTOM_VIEW = 'CustomView',
  DELEGATE_TASK = 'Delegate',
  DOWNLOAD = 'Download',
  DOWNLOAD_RAW = 'DownloadRaw',
  DOWNLOAD_CERTIFICATE = 'DownloadCertificate',
  CANCEL = 'Cancel',
  CANCEL_CHECK_OUT = 'CancelCheckOut',
  ACTIVATE = 'Activate',
  DEACTIVATE = 'Deactivate',
  CHECK_IN = 'CheckIn',
  CHECK_OUT = 'CheckOut',
  VERSION_HISTORY = 'VersionHistory',
  RETIRE = 'Retire',
  EDIT_DOCUMENT = 'EditDocument',
  STAGE = 'stage-actions',
  TASKGROUP = 'TaskGroup',
  TASK = 'Task',
  WORKFLOW = 'workflow',
  UPDATE_WORKFLOW = 'UpdateWorkflow',
  REASSIGN_TASK = 'ReassignTask',
  CHNAGE_DUE_DATE_TASK = 'ChangeTaskDueDate',
  ADD_PARTICIPANTS = 'AddTaskParticipants',
}

export enum ActionLevelEnum {
  RecordAction = 'RecordAction',
  BulkAction = 'BulkAction',
  TaskGroup = 'TaskGroup',
  Task = 'Task',
}
