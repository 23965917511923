import { raiseErrorToast } from '@celito.clients/utils';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { getCurrentModule } from '../search-result/utils/search-result.util';
import { getSearchSuggestions } from './services/apiServices';
import { SuggestionMenuProps } from './suggestion-menu.model';
import SuggestionMenuView from './suggestion-menu.view';
import { getTopResultCardDataFromApiResponse } from './utils/suggestion-menu.utils';

const SuggestionMenuController: React.FC<SuggestionMenuProps> = (props) => {
  const {
    placeholder,
    searchInput,
    onChangeHandler,
    onKeydownHandler,
    isIcon,
    onTopResultClickHandler,
  } = props;

  const params = useParams();

  const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);
  const [topResults, setTopResults] = useState<Record<string, string>[]>([]);
  const [selectedOption, setSelectedOption] = useState<string[]>([]);

  const currentModule: string = getCurrentModule(params.moduleName);

  useEffect(() => {
    debounceApiCall(searchInput);
    setSelectedOption([]);
  }, [searchInput]);

  const onOptionSelectHandler = (data: any) => {
    onChangeHandler(data.optionText ?? '');
    setSelectedOption(data.selectedOptions);

    if (data.optionValue.includes('topResult')) {
      const values = data.optionValue.split('|');
      onTopResultClickHandler(values[1], values[2], values[3]);
    } else {
      onKeydownHandler(data.optionValue);
    }
  };

  const clearSuggestions = () => {
    setSearchSuggestions([]);
    setTopResults([]);
  };

  const setSuggestions = (input: string) => {
    let newTopResults: Record<string, string>[] = [];

    getSearchSuggestions(input, currentModule)
      .then((resp) => {
        newTopResults = getTopResultCardDataFromApiResponse(resp.topResults);

        setSearchSuggestions(resp.suggestions);
        setTopResults(newTopResults);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  };

  const debounceApiCall = useCallback(
    debounce((input) => {
      if (input.trim().length > 2) {
        setSuggestions(input);
      } else {
        clearSuggestions();
      }
    }, 400),
    []
  );

  return (
    <SuggestionMenuView
      placeholder={placeholder}
      searchInput={searchInput}
      suggestions={searchSuggestions}
      topResults={topResults}
      onInputChangeHandler={onChangeHandler}
      onKeydownHandler={onKeydownHandler}
      isIcon={isIcon}
      selectedOption={selectedOption}
      onOptionSelectHandler={onOptionSelectHandler}
      onTopResultClickHandler={onTopResultClickHandler}
    />
  );
};

export default SuggestionMenuController;
