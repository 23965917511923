import {
  ColumnData,
  RowItem,
} from 'libs/shared/src/lib/grid-view-new/src/types';
import { Icon } from 'libs/shared/src/lib/shared';

import classes from './subrow-column-renderer.styles.module.css';

const subrowColumnRenderer: ColumnData['onRender'] = (item: RowItem) => {
  if (item.subGridData?.subGridItemData?.length) {
    return (
      <button
        onClick={() => {
          item?.subGridData?.onSubGridToggle?.(item.key);
        }}
        className={classes.iconBtn}
      >
        {item.subGridData.isSubGridOpen ? (
          <div className={classes.minusIcon} />
        ) : (
          <Icon iconName="Add16Regular" className={classes.plusIcon} />
        )}
      </button>
    );
  }
  return null;
};

export default subrowColumnRenderer;
